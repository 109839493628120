import React, { Component } from 'react';
import { isBoolean } from 'lodash';
import PropTypes from 'prop-types';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '../base/table-cell';
import TableHead from '../base/table-head';
import TableRow from '../base/table-row';
import Checkbox from '../base/checkbox';

const tableCellStyle = {
  paddingBottom: 4,
  paddingRight: 10,
  paddingTop: 4,
  minWidth: '101px',
  fontSize: '1rem',
};

const darkModeTableCellStyle = {
  padding: '4px 10px 4px 20px',
  minWidth: '101px',
  fontSize: '1rem',
  color: 'white',
  backgroundColor: 'black',
};

const smallCellStyle = Object.assign({}, tableCellStyle, {
  maxWidth: '30px',
  minWidth: '30px',
});

const darkModeSmallCellStyle = Object.assign({}, tableCellStyle, {
  maxWidth: '30px',
  minWidth: '30px',
  color: 'white',
  backgroundColor: 'black',
});

const checkCellStyle = Object.assign({}, tableCellStyle, {
  paddingRight: '0px',
  marginRight: '0px',
  width: '10px',
});

const DarkModeTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const DarkModeCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    '&$checked': {
      color: '#fd6120',
    },
  },
}))(Checkbox);


const styles = {};

class SortedTableHead extends Component {
  static defaultProps = {
    children: null,
    columnData: [],
    order: '',
    orderBy: '',
  };

  static propTypes = {
    children: PropTypes.node,
    columnData: PropTypes.array,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    onRequestSort: PropTypes.func.isRequired,
  };

  createSortHandler = property => (event) => {
    this.props.onRequestSort(event, property);
  }
  handleSelectAllClick = () => {
    this.props.onChangeCheckbox();
  }

  render() {
    const { columnData, order, orderBy, darkMode, allChecked } = this.props;

    return (
      <TableHead>
        <TableRow>
          {this.props.includeCheckbox && (
            <TableCell padding="checkbox" style={checkCellStyle}>
              <Checkbox
                checked={allChecked}
                onChange={this.handleSelectAllClick}
              />
            </TableCell>
          )}
          {columnData.map((column) => {
            if(isBoolean(column.sortable) && !column.sortable) {
              if (darkMode) {
                return (
                  <DarkModeTableCell
                    key={column.id}
                    numeric={column.numeric}
                    padding={column.disablePadding ? 'none' : 'normal'}
                    style={column.small === true ? darkModeSmallCellStyle : darkModeTableCellStyle}
                  >
                    {this.props.includeCheckbox && (
                      <DarkModeCheckbox
                        checked={allChecked}
                        onChange={this.handleSelectAllClick}
                      />
                    )}
                    {column.label}
                  </DarkModeTableCell>
                );
              } else {
                return (
                  <TableCell
                    key={column.id}
                    numeric={column.numeric}
                    padding={column.disablePadding ? 'none' : 'normal'}
                    style={column.small === true ? smallCellStyle : tableCellStyle}
                  >
                    {column.label}
                  </TableCell>
                );
              }
            }
            if(darkMode){
              return (
                <DarkModeTableCell
                  key={column.id}
                  numeric={column.numeric}
                  padding={column.disablePadding ? 'none' : 'normal'}
                  sortDirection={(orderBy === column.id || orderBy === column.sortId ) ? order : false}
                  style={column.small === true ? darkModeSmallCellStyle : darkModeTableCellStyle}
                >
                  <Tooltip
                    title="Sort"
                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                    style={{color: 'white', backgroundColor: 'black'}}
                      active={orderBy === column.id || orderBy === column.sortId}
                      direction={order}
                      onClick={this.createSortHandler(column.sortId || column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                </DarkModeTableCell>
              );
            } else {
              return (
                <TableCell
                  key={column.id}
                  numeric={column.numeric}
                  padding={column.disablePadding ? 'none' : 'normal'}
                  sortDirection={(orderBy === column.id || orderBy === column.sortId ) ? order : false}
                  style={column.small === true ? smallCellStyle : tableCellStyle}
                >
                  <Tooltip
                    title="Sort"
                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === column.id || orderBy === column.sortId}
                      direction={order}
                      onClick={this.createSortHandler(column.sortId || column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              );
            }
          })}
          {this.props.children}
        </TableRow>
      </TableHead>
    );
  }
}

export default withStyles(styles)(SortedTableHead)
