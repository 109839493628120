import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MUISelect from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import UAParser from 'ua-parser-js';
import moment from 'moment';
import { forEach } from 'lodash';

import Loading from './loading';
import { apiFetch } from '../lib/fetch';
import formatPro from '../lib/pro-formatter';
import Select from './pro-select';
import Checkbox from '../base/checkbox';
import SortableTable from './sortable-table';
import Hidden from './hidden-content';
import DeviceDetail from './device-detail';
import ProDevDetails from './pro-developer-details';

import { blueBtnStyle, colors } from '../lib/styles';

const styles = {
  btn: {
    ...blueBtnStyle,
  },
  downloadBtn: {
    ...blueBtnStyle,
    whiteSpace: 'nowrap',
    fontSize: '.6rem',
    margin: 0,
  },
  proSelection: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: '.5rem',
    marginTop: '20px',
  },
  sortableTableWrapper: {
    maxWidth: '100%',
    '& td': {
      whiteSpace: 'pre-line',
    },
  },
  deviceLabel: {
    fontWeight: 'bold',
  },
  error: {
    color: colors.errorRed,
    margin: '10px 0',
  },
  formControl: {
    minWidth: 100,
  },
};

const columnData = [
  {
    id: 'pro_type', align: false, disablePadding: true, label: 'Type', small: true,
  },
  {
    id: 'score', align: false, disablePadding: true, label: 'Results', small: true, sortable: false, maxLength: 300,
  },
  {
    id: 'lastAction', align: false, disablePadding: true, label: 'Last Action', small: true, sortId: 'lastActionDate',
  },
  {
    id: 'resend', align: false, disablePadding: true, label: 'Resend', small: false, sortable: false,
  },
  {
    id: 'docs', align: false, disablePadding: true, label: 'Documents', small: false, sortable: false,
  },
  {
    id: 'requested', align: false, disablePadding: true, label: 'Requested', small: false, sortable: false,
  },
  {
    id: 'scheduling_provider_email', align: false, disablePadding: true, label: 'Requesting Provider', small: false, sortable: false,
  },
  {
    id: 'scheduled_event_campaign_type', align: false, disablePadding: true, label: 'Care Pathway', small: false, sortable: false,
  },
  {
    id: 'scheduled_event_status', align: false, disablePadding: true, label: 'Care Pathway Status', small: false, sortable: false,
  },
  {
    id: 'user_agent', align: false, disablePadding: true, label: 'Device Details', small: false, sortable: false,
  },
];

const buildPatientHeaders = (appointment) => {
  const {
    first_name,
    last_name,
    cell_phone,
    email,
  } = appointment;

  const headers = 'Last Name, First Name, Phone, Email';
  const patientData = `${last_name},${first_name},${cell_phone},${email}`;

  return `${headers}\n${patientData}`;
}

const buildProReport = (proData) => {
  const header = 'Pro Type, Last Action, Score, Requested, Requesting Provider Email, Browser, Browser Version, OS, OS Version';
  const rows = [header];

  proData.forEach((pro) => {
    formatPro(pro);
    let { pro_type, lastAction, score, requested, scheduling_provider_email } = pro;
    let browser = '';
    let browserVersion = '';
    let os = '';
    let osVersion = '';
    if (pro.user_agent) {
      const userAgent = UAParser(pro.user_agent);
      if (userAgent.os) {
        os = userAgent.os.name;
        osVersion = userAgent.os.version;
      }
      if (userAgent.browser) {
        browser = userAgent.browser.name;
        browserVersion = userAgent.browser.version;
      }
    }
    lastAction = lastAction.replace('\n', ' ');
    score = score.score.replace(/\n/g, '').replace(/,/g, ' --');

    rows.push(`${pro_type},${lastAction},${score},${requested},${scheduling_provider_email},${browser},${browserVersion},${os},${osVersion}`);
  });

  return rows.join('\n');
}

const docPROs = {
  'JOHNS-HOPKINS-CONSENT': 'JOHNS-HOPKINS-CONSENT',
  'BLOOMKIDZ-SIGNATURES': 'BLOOMKIDZ-SIGNATURES',
};

class ProSubmissionsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      proSubmissions: this.props.proSubmissions,
      proReleaseData: {}, // release data for pros, keyed by pro id
    };

    this.updateProsWithReleaseData();
  }

  async updateProsWithReleaseData() {
    const { proReleaseData } = this.state;
    const proSubmissions = [];
    const newProReleaseData = {};

    forEach(this.props.proSubmissions, async (pro) => {
      proSubmissions.push(new Promise(async (resolve) => {
        if (docPROs[pro.pro_type] && pro.completion_date !== null) {
          const { pro_type, user_id, id } = pro;

          if (proReleaseData[id]) {
            pro = { ...pro, ...proReleaseData[id] };
          } else {
            const res = await apiFetch(`/users/${user_id}/pro_submissions/${id}/releases/${pro_type}`);
            pro = { ...pro, ...res };
            newProReleaseData[id] = res;
          }
          
          return resolve(pro);
        }
        resolve(pro);
      }));
    });

    await Promise.all(proSubmissions)
      .then(pros => {
        this.setState((state) => {
          const proReleaseData = { ...state.proReleaseData, ...newProReleaseData };
          return { proSubmissions: pros, proReleaseData };
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.proSubmissions !== this.props.proSubmissions) {
      this.updateProsWithReleaseData();
    }
  }

  handleResendNotification = async (proId, userId) => {
    await apiFetch(`/users/${userId}/pro_submissions/${proId}/resend-notification`, {
      method: 'PUT',
    });
    this.props.handleBrowseProSubmissions();
  }

  handleDownloadProReport = () => {
    const { appointment, proSubmissions } = this.props;
    const { first_name, last_name } = appointment;

    const patientHeaders = buildPatientHeaders(appointment);
    const proReport = buildProReport(proSubmissions);
    const csvReport = `${patientHeaders}\n,\n${proReport}`;
    const time = moment().format('DD-MM-YYYY_hh:mma')
  
    const downloadTag = document.createElement('a');
    downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvReport));
    downloadTag.setAttribute('download', `${last_name}_${first_name}_${time}.csv`);
    downloadTag.style.display = 'none';
  
    document.body.appendChild(downloadTag);
  
    downloadTag.click();
  
    document.body.removeChild(downloadTag);
  };

  render() {
    const {
      classes, requestedPro, handleProChange, handleSendProRequest, proTypes, error, hidden, features,
    } = this.props;
    const { proSubmissions } = this.state;
    const proWithReleases = [];
    const hasDevOptions = features && features.includes('API_KEYS');
    proSubmissions.forEach(async (pro) => {
      let docs;
      let resend;
      let user_agent;
      const lastAction = hasDevOptions ?
      (<ProDevDetails pro={pro} content={pro.lastAction}/>)
      : pro.lastAction;
      formatPro(pro);
      if (docPROs[pro.pro_type] && pro.completion_date !== null) {
        if (!pro.url && !pro.urls) {
          docs = <Loading />;
        }
        if (pro.url || pro.urls) {
          docs = (
            <Button
              variant="contained"
              className={classes.downloadBtn}
              onClick={() => window.open(pro.url, '_blank')}
            >
              Download
            </Button>
          );
          if (pro.urls) {
            const menuItems = [];
            forEach(pro.urls, url => {
              // regex for uuid and - preceding file name
              let name = url.split(/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}-/)[1];
              name = name.split('.')[0];
              menuItems.push((
                <MenuItem
                  key={name}
                  value={url}
                  selected
                >
                  {`${name}`}
                </MenuItem>));
            })
            docs = (
              <FormControl className={classes.formControl}>
                <InputLabel id="docs">Select Document</InputLabel>
                <MUISelect
                  labelId="docs"
                  id="docSelect"
                  value={'Select Document'}
                  onChange={(e) => window.open(e.target.value, '_blank')}
                >
                  {menuItems}
                </MUISelect>
              </FormControl>
            );
          }
        }
      }
      if (pro.show_reminder_button && !pro.completion_date) {
        resend = (
          <Button
            variant="contained"
            className={classes.downloadBtn}
            onClick={() => this.handleResendNotification(pro.id, pro.user_id)}
          >
            Resend
          </Button>
        )
      }
      if (pro.user_agent) {
        user_agent = <DeviceDetail agent={UAParser(pro.user_agent)}/>
      }
      let status;
      if (pro.scheduled_event_id) {
        status = 'ACTIVE';    
        if (pro.cancelled_at) {
          status = 'CANCELLED';
        } else if (pro.completion_date && proSubmissions) {
          const remainingCampaignPros = proSubmissions
            .filter(sub => sub.scheduled_event_id === pro.scheduled_event_id && sub.id !== pro.id);
    
          const completed = remainingCampaignPros.find(evt => evt.completion_date !== null);
          if (completed) {
            status = 'COMPLETED';
          }
        }
      }
      return proWithReleases.push({
        ...pro,
        docs,
        resend,
        user_agent,
        scheduled_event_status: status,
        lastAction,
      });
    });

    return (
      <div style={{ maxWidth: '100%' }}>
        <section className={classes.proSelection}>
          <FormControl>
            <Select
              multiple
              displayEmpty
              value={requestedPro}
              onChange={handleProChange}
              input={<Input disableUnderline={true} />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (<em>Select PROs</em>);
                }
                return selected.join(', ');
              }}
            >
              <MenuItem value={[]} disabled><em>Select PROs</em></MenuItem>
              {proTypes.map(pro => (
                <MenuItem
                  key={pro}
                  value={pro}
                  selected
                >
                  <Checkbox
                    checked={requestedPro.includes(pro)}
                  />
                  {pro}
                </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={handleSendProRequest}
          >
            Send PRO Request(s)
          </Button>
          {proSubmissions.length ? (
            <Button
              variant="contained"
              style={{ marginLeft: 'auto' }}
              onClick={this.handleDownloadProReport}
            >
              Export PROs
            </Button>
          ) : null}
        </section>
        <Hidden hidden={hidden}><div style={styles.error}>{error}</div></Hidden>
        <div className={classes.sortableTableWrapper}>
          <SortableTable
            columnData={columnData}
            emptyMessage="This patient hasn't submitted any PROs yet."
            rows={proWithReleases}
          />
        </div>
      </div>
    );
  }
}

ProSubmissionsTab.defaultProps = {};

ProSubmissionsTab.propTypes = {
  appointment: PropTypes.object.isRequired,
  handleBrowseProSubmissions: PropTypes.func.isRequired,
  handleProChange: PropTypes.func.isRequired,
  handleSendProRequest: PropTypes.func.isRequired,
  proTypes: PropTypes.array.isRequired,
  proSubmissions: PropTypes.array.isRequired,
  requestedPro: PropTypes.array.isRequired,
};

export default withStyles(styles)(ProSubmissionsTab);
