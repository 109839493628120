import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { chain, map, noop, upperFirst, sortBy } from 'lodash';

import { apiFetch } from '../lib/fetch';
import { logout } from '../actions/user';
import { dismissNotification } from '../actions/notifications';
import { openModal, closeModal } from '../state/modal';
import { browsePainLocationsIfNeeded, browseProTypes } from '../state/app-data';
import { browseProviders } from '../state/clinic';
import { dismissScanError } from '../state/appointments';
import { addRecent } from '../state/recent';
import { createProSubmission, browseProCampaigns, createProCampaign } from '../state/pro-submissions';

import CheckIn from '../components/check-in';
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import { colors } from '../lib/styles';
import ChatWindow from '../components/chat-window';

const baseStyles = {
  container: {
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    textAlign: 'center',
    width: '100%',
    
  },
  mainBody: {
    display: 'flex',
    flex: 1,
    height: '100%',
    width: '100%',
  },
};

class AppWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      sortedCampaignTypes: [],
    };
  }
  componentWillMount() {
    const { clinicId } = this.props;
    this.props.browsePainLocationsIfNeeded();
    this.props.browseProviders();
    this.props.browseProTypes();
    apiFetch(`/clinics/${clinicId}/campaigns`)
      .then((data) => {
        const campaignTypes = data || [];
        const sortedCampaignTypes = sortBy([...campaignTypes ], (o) => { if (o.name) { return upperFirst(o.name); } return o; });
        this.setState({ sortedCampaignTypes });
      })
      .catch(noop);
  }
  render() {
    const {
      addRecent, appFeatures, appointments, dismissNotification, logout, createProCampaign,
      notifications, openModal, recentPatients, router, user, proTypes, createProSubmission,
    } = this.props;

    return (
      <div style={baseStyles.container}>
        <Header
          appFeatures={appFeatures}
          addRecent={addRecent}
          clinicId={this.props.clinicId}
          dismissNotification={dismissNotification}
          logout={logout}
          notifications={notifications}
          openModal={openModal}
          proTypes={proTypes}
          appointments={appointments.data}
          router={router}
          firstName={user.first_name}
          lastName={user.last_name}
          createProSubmission={createProSubmission}
          createProCampaign={createProCampaign}
          sortedCampaignTypes={this.state.sortedCampaignTypes}
        />
        <div style={baseStyles.mainBody}>
          <Sidebar
            appFeatures={appFeatures}
            iconSidebar={this.props.iconSidebar}
            recent={recentPatients}
            router={router}
          />

          <CheckIn />
          {this.props.children && React.cloneElement(this.props.children, {
            user: this.props.user,
          })}
          <ChatWindow router={router} proTypes={proTypes} clinicId={this.props.clinicId} user={this.props.user} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    appData,
    appointments,
    clinic,
    notifications,
    recent,
    modal,
    user
  } = state;

  const sortedNotifications = chain(notifications)
    .map(notification => notification)
    .sortBy('receivedAt')
    .value();

  return {
    appFeatures: clinic.data.app_features,
    appointments,
    clinicId: clinic.clinicId,
    notifications: sortedNotifications,
    proTypes: map(appData.proTypes, p => p.pro_type).sort(),
    providers: clinic.providers,
    recentPatients: recent,
    modal,
    user
  };
}

const actionCreators = {
  addRecent,
  browsePainLocationsIfNeeded,
  browseProCampaigns,
  browseProviders,
  browseProTypes,
  createProCampaign,
  createProSubmission,
  closeModal,
  dismissNotification,
  dismissScanError,
  logout,
  openModal,
};

AppWrapper.defaultProps = {
  appointments: {
    data: [],
    isFetching: false,
  },
  iconSidebar: false,
  notifications: [],
  proTypes: [],
  router: {},
  recentPatients: {},
  user: {},
};

AppWrapper.propTypes = {
  appFeatures: PropTypes.array.isRequired,
  addRecent: PropTypes.func.isRequired,
  appointments: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }),
  browsePainLocationsIfNeeded: PropTypes.func.isRequired,
  browseProviders: PropTypes.func.isRequired,
  browseProTypes: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  clinicId: PropTypes.number.isRequired,
  createProCampaign: PropTypes.func.isRequired,
  createProSubmission: PropTypes.func.isRequired,
  dismissNotification: PropTypes.func.isRequired,
  iconSidebar: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  notifications: PropTypes.array,
  proTypes: PropTypes.array,
  recentPatients: PropTypes.object,
  router: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps, actionCreators)(AppWrapper);
