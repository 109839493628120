import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import HiddenContent from '../components/hidden-content';

const styles = {
  bundledEvent: {
    marginTop: '20px',
  },
  bundledEventIconButton: {
    height: '10px',
    width: '10px',
  },
  iconButton: {
    height: '20px',
    width: '20px',
  },
  eventText: {
    margin: '20px 10px 0px',
  },
  input: {
    maxWidth: '40px',
  },
  formControl: {
    margin: '0px 10px',
    minWidth: 180,
    maxWidth: 300,
  },
  root: {
    margin: '40px 0px',
  },
  weirdFlexButOkay: {
    display: 'flex',
  }
};

function isEventUsed(key, usedEvents) { 
  return usedEvents.reduce((used, event) => {
    if (event.event_type === key) {
      return true
    }

    if (event.voice_broadcast_id === key) {
      return true
    }

    if (event.video_broadcast_id === key) {
      return true
    }

    return used;
  }, false);
}

const BundledEvent = (props) => {
  const {
    classes,
    bundledEventPosition,
    proTypes,
    usedEvents,
    videoBroadcastTypes,
    voiceBroadcastTypes,
    bundleType,
  } = props;
  let videoBroadcastOptions;
  let voiceBroadcastOptions;

  const eventOptions = proTypes.map(type => (
    <MenuItem
      disabled={bundleType === 'CAMPAIGN_BUNDLE' ? false : isEventUsed(type, usedEvents)}
      key={type}
      value={type}
    >
      {type}
    </MenuItem>
  ));

  if (videoBroadcastTypes.length) {
    eventOptions.push(<MenuItem key="VIDEO_BROADCAST" value="VIDEO_BROADCAST">VIDEO BROADCAST</MenuItem>)
    videoBroadcastOptions = videoBroadcastTypes.map(vb => <MenuItem disabled={isEventUsed(vb.id, usedEvents)} key={vb.id} value={vb.id}>{vb.name}</MenuItem>);
  }
  if (voiceBroadcastTypes.length) {
    eventOptions.push(<MenuItem key="VOICE_BROADCAST" value="VOICE_BROADCAST">VOICE BROADCAST</MenuItem>)
    voiceBroadcastOptions = voiceBroadcastTypes.map(vb => <MenuItem disabled={isEventUsed(vb.id, usedEvents)} key={vb.id} value={vb.id}>{vb.name}</MenuItem>);
  }

  const handleUpdate = field => (e) => {
    const { value } = e.target
    const update = { ...props.bundledEvent, [field]: value };
    if (field === 'event_type') {
      update.voice_broadcast_id = '';
      update.video_broadcast_id = '';
    }

    props.onEditEvent(bundledEventPosition, update);
  };

  const handleRemove = (e) => {
    props.onRemoveEvent(bundledEventPosition);
  };

  return (
    <li className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="event-type-select">Select Event</InputLabel>
        <Select
          value={props.bundledEvent.event_type}
          onChange={handleUpdate('event_type')}
          input={<Input disableUnderline={true} id="event-type-select" />}
        >
          <MenuItem value=""><em>Select Event</em></MenuItem>
          {eventOptions}
        </Select>
      </FormControl>
      <HiddenContent hidden={props.bundledEvent.event_type !== 'VIDEO_BROADCAST'}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="video-broadcast-select">Select Broadcast</InputLabel>
          <Select
            value={props.bundledEvent.video_broadcast_id}
            onChange={handleUpdate('video_broadcast_id')}
            input={<Input disableUnderline={true} id="video-broadcast-select" />}
          >
            <MenuItem value=""><em>Select Broadcast</em></MenuItem>
            {videoBroadcastOptions}
          </Select>
        </FormControl>
      </HiddenContent>
      <HiddenContent hidden={props.bundledEvent.event_type !== 'VOICE_BROADCAST'}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="voice-broadcast-select">Select Broadcast</InputLabel>
          <Select
            value={props.bundledEvent.voice_broadcast_id}
            onChange={handleUpdate('voice_broadcast_id')}
            input={<Input disableUnderline={true} id="voice-broadcast-select" />}
          >
            <MenuItem value=""><em>Select Broadcast</em></MenuItem>
            {voiceBroadcastOptions}
          </Select>
        </FormControl>
      </HiddenContent>
      <Fab aria-label="Remove Event" onClick={handleRemove} color="secondary" size="small" variant="circular">
        <DeleteIcon classes={{ root: classes.iconButton }} size="small" />
      </Fab>
    </li>
  );
};

BundledEvent.propTypes = {
  bundledEvent: PropTypes.object.isRequired,
  bundledEventPosition: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  onEditEvent: PropTypes.func.isRequired,
  onRemoveEvent: PropTypes.func.isRequired,
  voiceBroadcastTypes: PropTypes.array.isRequired,
  videoBroadcastTypes: PropTypes.array.isRequired,
  bundleType: PropTypes.string.isRequired,
};

export default withStyles(styles)(BundledEvent);
