import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Avatar from './patient-avatar';
import TableCell from '../base/table-cell';
import TableRow from '../base/table-row';

import { colors } from '../lib/styles';

const styles = {
  avatar: {
    height: '60px',
    marginRight: '8px',
    verticalAlign: 'middle',
    width: '60px',
  },
  bold: {
    fontWeight: 900,
  },
  checkoutColumn: {
    textAlign: 'left',
  },
  patientName: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '1rem',
  },
  painTd: {
    fontSize: '1rem',
    marginLeft: '2rem',
    textAlign: 'center',
  },
  providerTd: {
    padding: '4px 0px',
  },
  statusTd: {
    padding: '4px 0px',
  },
  td: {
    fontSize: '1rem',
  },
  warning: {
    color: colors.errorRed,
    marginLeft: '0 auto',
    width: '100%',
  },
};

const DashboardTableListItem = (props) => {
  const { classes } = props;
  return (
    <Fragment>
      <TableRow selected={props.selected}>
        <TableCell
          onClick={props.onClick}
          className={`${classes.patientName} ${props.unviewedPro && classes.bold}`}
        >
          <Avatar
            image={props.image}
            classes={{ avatar: classes.avatar }}
          />
          {props.lastName}, {props.firstName}
        </TableCell>
        <TableCell className={classes.td} padding="none">
          {props.flag ? (
            <img
              alt="icon"
              height={25}
              src={props.flag.icon}
              width={25}
            />
          ) : null}
        </TableCell>
        <TableCell className={`classes.painTd ${props.unviewedPro && classes.bold}`}>{moment(props.birthdate).format('MM/DD/YYYY')}</TableCell>
        <TableCell className={classes.providerTd}>{props.providerMenu}</TableCell>
        <TableCell
          className={`classes.painTd ${props.unviewedPro && classes.bold}`}
          padding="none"
        >{props.recentPain || 'None Reported'}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

DashboardTableListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  image: PropTypes.string,
  lastName: PropTypes.string,
  firstName: PropTypes.string,
  patient: PropTypes.object.isRequired,
  flag: PropTypes.object.isRequired,
  birthdate: PropTypes.string,
  sex: PropTypes.array,
  providerMenu: PropTypes.object.isRequired,
  checkedIn: PropTypes.bool.isRequired,
  onUpdateAppointment: PropTypes.func.isRequired,
  onCreateAppointment: PropTypes.func.isRequired,
  recentPain: PropTypes.number,
  unviewedPro: PropTypes.bool.isRequired,
};

DashboardTableListItem.defaultProps = {
  image: '',
  birthdate: '',
  sex: [],
  firstName: '',
  lastName: '',
  recentPain: null,
};

export default withStyles(styles)(DashboardTableListItem);
