import React, { Component } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

import AppWrapper from '../wrappers/app-wrapper';

import { SortableTable } from 'common-ui';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const styles = {
  headerText: {
    fontWeight: 100,
    textAlign: 'left',
  },
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  patientLists: {
    padding: '25px',
    width: '100%',
    marginBottom: '150px',
  },
  subHeaderText: {
    color: colors.highlightDark,
    margin: '5px 5px 5px 0',
    fontSize: '0.875rem',
    textAlign: 'center',
    width: '100%',
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: colors.highlightGray,
    },
    cursor: 'pointer',
  },
};

const columnData = [
  {
    id: 'name', align: false, disablePadding: false, label: 'Name', small: true, sortId: 'lastName'
  },
  {
    id: 'email', align: false, disablePadding: false, label: 'Email', small: true,
  },
  {
    id: 'status', align: false, disablePadding: false, label: 'Invite Status', small: true, 
  },
  {
    id: 'formattedCreatedAt', align: false, disablePadding: false, label: 'Invite Date', small: true, sortId: 'created_at',
  },
];

function formatPendingPatients(invites) {
  return invites.map(item => {
    item.lastName = item.meta.last_name;
    item.name = `${item.meta.first_name} ${item.meta.last_name}`
    item.email = item.meta.email;
    item.phone = item.meta.phone;
    item.formattedCreatedAt = moment(item.created_at).format('MM/DD/YYYY');
    return item;
  })
}

export class PendingPatients extends Component {
  state = {
    pendingPatients: [],
    fetching: false,
    error: null
  }
  componentWillMount() {
    const { clinicId } = this.props;
    this.setState({ error: null, fetching: true });
    apiFetch(`/clinics/${clinicId}/invitations?status=PENDING`)
      .then((pendingPatients) => {
        this.setState({
          pendingPatients: formatPendingPatients(pendingPatients),
          fetching: false,
          error: null,
        });
      })
      .catch(e => {
        this.setState({
          fetching: false,
          error: 'Error Fetching Pending Patients'
        });
      })
  }
  render() {
    const { pendingPatients } = this.state;
    const title = 'Pending Patient Invitations';

    if(!pendingPatients.length) {
      return (
        <AppWrapper router={this.props.router}>
          <div style={styles.patientLists}>
            <div style={styles.headerText}>
              <h1 style={styles.headerTitle}>{title}</h1>
              <p style={styles.subHeaderText}>No pending patients at this time.</p>
            </div>
          </div>
        </AppWrapper>
      )
    }

    return (
      <AppWrapper router={this.props.router}>
        <div style={styles.patientLists}>
          <div style={styles.headerText}>
            <h1 style={styles.headerTitle}>{title}</h1>
          </div>
          <SortableTable
            columnData={columnData}
            emptyMessage="No pending patients at this time."
            rows={this.state.pendingPatients}
            rowClasses={{ row: this.props.classes.tableRow }}
          />
        </div>
      </AppWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {
    clinic: { clinicId },
  } = state;


  return {
    clinicId,
  };
}

const actionCreators = {};


PendingPatients.propTypes = {
  clinicId: PropTypes.number.isRequired
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(PendingPatients));
