import { arrayToObject } from '../utilities';

export default (pro) => {
  let { data } = pro.pro_data;

  data = arrayToObject(data);

  const s = [];

  s.push(`Increases shortness of breath: ${data.increasedShortnessOfBreath}`);
  if (data.increasedShortnessOfBreath === 'Y') {
    s.push(`Worse with activity: ${data.breathingWorseWithActivity}`);
    s.push(`Home Oxygen: ${data.onHomeOxygen}`);
    if (data.SPO2) s.push(`SpO2: ${data.SPO2}`);
  }

  s.push(`Cough: ${data.hasCough}`);
  if (data.hasCough === 'Y') {
    s.push(`New: ${data.coughIsNew}`);
    s.push(`Worse than yesterday: ${data.coughWorseThanYesterday}`);
  }

  s.push(`Fever: ${data.hasFever}`);
  if (data.temperature) s.push(`Temperature: ${data.temperature} F`);

  pro.score.score = s.join(' | ');
  return pro;
};
