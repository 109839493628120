import React from 'react';
import PropTypes from 'prop-types';
import { find, filter } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreIcon from '@material-ui/icons/More';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment-timezone';

import { TimePicker } from "@material-ui/pickers";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import HiddenContent from '../components/hidden-content';
import compareAlphabetically from '../lib/compare-alphabetically';

const styles = {
  bundledEvent: {
    marginTop: '20px',
  },
  bundledEventIconButton: {
    height: '10px',
    width: '10px',
  },
  iconButton: {
    height: '20px',
    width: '20px',
  },
  eventText: {
    margin: '20px 10px 0px',
  },
  input: {
    maxWidth: '40px',
  },
  formControl: {
    margin: '0px 10px',
    minWidth: 180,
    maxWidth: 300,
  },
  root: {
    margin: '40px 0px',
  },
  weirdFlexButOkay: {
    display: 'flex',
  }
};

const CampaignEvent = (props) => {
  const { classes, position: campaignEventPosition, proTypes, bundledEventTypes, videoBroadcastTypes, voiceBroadcastTypes } = props;
  let bundledEventOptions;
  let videoBroadcastOptions;
  let voiceBroadcastOptions;
  const filteredBundledEventTypes = filter(bundledEventTypes, ['bundle_type', 'CAMPAIGN_BUNDLE']);

  const eventOptions = proTypes.map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)

  if (videoBroadcastTypes.length) {
    eventOptions.push(<MenuItem key="VIDEO_BROADCAST" value="VIDEO_BROADCAST">VIDEO BROADCAST</MenuItem>)
    videoBroadcastOptions = videoBroadcastTypes.map(vb => <MenuItem key={vb.id} value={vb.id}>{vb.name}</MenuItem>);
  }

  if (voiceBroadcastTypes.length) {
    eventOptions.push(<MenuItem key="VOICE_BROADCAST" value="VOICE_BROADCAST">VOICE BROADCAST</MenuItem>)
    voiceBroadcastOptions = voiceBroadcastTypes.map(vb => <MenuItem key={vb.id} value={vb.id}>{vb.name}</MenuItem>);
  }

  if (filteredBundledEventTypes.length) {
    eventOptions.push(<MenuItem key="BUNDLED_EVENT" value="BUNDLED_EVENT">BUNDLED EVENT</MenuItem>)
    bundledEventOptions = filteredBundledEventTypes.map(be => <MenuItem key={be.id} value={be.id}>{be.name}</MenuItem>);
  }
  const firstEvent = Number(campaignEventPosition) === 0;

  // props.children in the case below is always a text string
  // So this sorts all of the possible options by their text value(menu option)
  eventOptions.sort((a, b) => compareAlphabetically(a.props.children, b.props.children));
  const handleUpdate = field => (e) => {
    const { value } = e.target
    const update = { ...props.campaignEvent, [field]: value };
    if (field === 'event_type') {
      update.voice_broadcast_id = '';
      update.video_broadcast_id = '';
      update.bundled_event_id = '';
      update.bundled_events = [];
    }

    props.onEditEvent(campaignEventPosition, update);
  };

  const handleUpdateTime = (time) => {
    const tz = moment.tz.guess(); 
    const update = { ...props.campaignEvent, time: moment(time).toISOString(true), tz };
    props.onEditEvent(campaignEventPosition, update);
  };

  const handleUpdateDelay = (e) => {
    const delay = e.target.value;
    if (!Number.isInteger(Number(delay)) && Number(delay) !== 0) {
      return;
    }

    if (Number(delay) < 0) {
      return;
    }
    props.onEditEvent(campaignEventPosition, { ...props.campaignEvent, delay });
  };

  const handleRemove = (e) => {
    props.onRemoveEvent(campaignEventPosition);
  };

  let delayWording = 'more days then trigger a';
  let delayPlaceholder = 'days between steps';
  if (firstEvent) {
    delayWording = 'days then trigger a';
    delayPlaceholder = 'days before starting';
  }

  let bundledEventDetails = ''
  const selectedBundledEvent = find(filteredBundledEventTypes, { id: props.campaignEvent.bundled_event_id });

  if (selectedBundledEvent) {
    bundledEventDetails = selectedBundledEvent.bundle_spec.events.map(e => e.event_type).join(' => ');
  }

  return (
    <li className={classes.root}>
      <div className={classes.weirdFlexButOkay}>
        <div className={classes.eventText}>Wait</div>
        <Input
          type="number"
          classes={{
            root: classes.input
          }}
          value={props.campaignEvent.delay}
          onChange={handleUpdateDelay}
          placeholder={delayPlaceholder}
          label={delayPlaceholder}
        />
        <div className={classes.eventText}>{delayWording}</div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="event-type-select">Select Event</InputLabel>
          <Select
            value={props.campaignEvent.event_type}
            onChange={handleUpdate('event_type')}
            input={<Input disableUnderline={true} id="event-type-select" />}
          >
            <MenuItem value=""><em>Select Event</em></MenuItem>
            {eventOptions}
          </Select>
        </FormControl>
        <HiddenContent hidden={props.campaignEvent.event_type !== 'VIDEO_BROADCAST'}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="video-broadcast-select">Select Broadcast</InputLabel>
            <Select
              value={props.campaignEvent.video_broadcast_id}
              onChange={handleUpdate('video_broadcast_id')}
              input={<Input disableUnderline={true} id="video-broadcast-select" />}
            >
              <MenuItem value=""><em>Select Broadcast</em></MenuItem>
              {videoBroadcastOptions}
            </Select>
          </FormControl>
        </HiddenContent>
        <HiddenContent hidden={props.campaignEvent.event_type !== 'BUNDLED_EVENT'}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="bundled-event-select">Select Bundled Event</InputLabel>
            <Select
              value={props.campaignEvent.bundled_event_id}
              onChange={handleUpdate('bundled_event_id')}
              input={<Input disableUnderline={true} id="bundled-event-select" />}
            >
              <MenuItem value=""><em>Select Event</em></MenuItem>
              {bundledEventOptions}
            </Select>
          </FormControl>
          <HiddenContent hidden={!props.campaignEvent.bundled_event_id || !bundledEventDetails}>
            <Tooltip title={bundledEventDetails}>
              <MoreIcon style={{ opacity: 0.5, marginTop: '20px' }} />
            </Tooltip>
          </HiddenContent>
        </HiddenContent>
        <HiddenContent hidden={props.campaignEvent.event_type !== 'VOICE_BROADCAST'}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="voice-broadcast-select">Select Broadcast</InputLabel>
            <Select
              value={props.campaignEvent.voice_broadcast_id}
              onChange={handleUpdate('voice_broadcast_id')}
              input={<Input disableUnderline={true} id="voice-broadcast-select" />}
            >
              <MenuItem value=""><em>Select Broadcast</em></MenuItem>
              {voiceBroadcastOptions}
            </Select>
          </FormControl>
        </HiddenContent>
        <div className={classes.eventText}>at</div>
        <TimePicker
          autoOk
          emptyLabel="Pick a Time"
          label="Approximate Time"
          value={props.campaignEvent.time || null}
          onChange={handleUpdateTime}
        />
        <div className={classes.eventText}>(triggers on day {props.totalDelay})</div>
        <HiddenContent hidden={firstEvent}>
          <Fab aria-label="Delete" onClick={handleRemove} color="secondary" size="small" variant="circular">
            <DeleteIcon classes={{ root: classes.iconButton }} size="small" />
          </Fab>
        </HiddenContent>
      </div>
    </li>
  );
};

CampaignEvent.propTypes = {
  bundledEventTypes: PropTypes.array.isRequired,
  campaignEvent: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onEditEvent: PropTypes.func.isRequired,
  onRemoveEvent: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  videoBroadcastTypes: PropTypes.array.isRequired,
  voiceBroadcastTypes: PropTypes.array.isRequired,
};

export default withStyles(styles)(CampaignEvent);
