import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { capitalize, filter } from 'lodash';
import moment from 'moment';
import useSWR, { trigger } from 'swr';

import { KeypadDate } from 'common-ui';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { TimePicker } from "@material-ui/pickers";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import UAParser from 'ua-parser-js';

import PatientViewContentSection from './patient-view-content-section';
import HiddenContent from './hidden-content';
import DeviceDetail from './device-detail';

import { blueBtnStyle } from '../lib/styles';

import SortableTable from './sortable-table';
import { apiFetch } from '../lib/fetch';
import ProSelect from './pro-select';

const styles = {
  btn: {
    ...blueBtnStyle,
    minWidth: '215px',
  },
  keypadDate: {
    minWidth: '250px',
    width: '250px',
  },
  inlineDate: {
    display: 'flex',
    width: '500px',
    justifyContent: 'space-between',
  },
  timePicker: {
    marginTop: '12px',
  },
  mdash: {
    paddingLeft: 8,
  },
  scheduleContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '800px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexGrow: 7,
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '500px',
    justifyContent: 'space-around',
  },
  select: {
    width: '150px',
    fontSize: '.65rem',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  radioRow: {
    display: 'flex',
    alignItems: 'center',
  },
};

const columnData = [
  {
    id: 'date', align: false, disablePadding: true, label: 'Appointment Time', small: true, sortId: 'sortDate',
  },
  {
    id: 'appointmentType', align: false, disablePadding: true, label: 'Type', small: true, sortable: false,
  },
  {
    id: 'notificationMethod', align: false, disablePadding: true, label: 'Link', small: true, sortable: false,
  },
  {
    id: 'status', align: false, disablePadding: true, label: 'Status', small: true, sortId: 'lastActionDate',
  },
  {
    id: 'enterExam', align: false, disablePadding: true, label: 'Enter Exam', small: true, sortable: false,
  },
  {
    id: 'userAgent', align: false, disablePadding: true, label: ' ', small: false, sortable: false,
  },
];

const baseAppt = {
  status: 'SCHEDULED',
  appointment_type: 'TELEMEDICINE',
  checked_in: null,
  checked_out: null,
  in_session: null,
  bundled_event_id: null,
};

const PatientAppointmentsOverviewTab = (props) => {
  const { classes, clinicId, userId, router } = props;
  const { data: appointmentData } = useSWR(`/clinics/${clinicId}/users/${userId}/appointments`, apiFetch);
  const { data: bundledEvents } = useSWR(`/clinics/${clinicId}/bundled_events`, apiFetch);
  const appointmentEvents = filter(bundledEvents, ['bundle_type', 'APPOINTMENT_BUNDLE']);
  let bundledEventComponents = [];
  if (appointmentEvents.length > 0) {
    bundledEventComponents = appointmentEvents.map(be => <MenuItem key={be.id} value={be.id}>{be.name}</MenuItem>);
  }
  const [newAppointment, setAppointment] = React.useState({ ...baseAppt });
  const [dateData, setDate] = React.useState({ date: '', time: null });
  const [duration, setDuration] = React.useState(15);
  const [editDateData, setEditDate] = React.useState({ date: '', time: null });
  const [editingData, setEdit] = React.useState({ id: null });
  const [contactPreference, setContactPreference] = React.useState(props.appointment.contact_preference || props.contact_preference);

  const handleEditClick = (item) => {
    setEdit({ ...item });
    setEditDate({
      date: moment(item.start_date).format('MM/DD/YYYY'),
      time: moment(item.start_date),
    });
  };

  const handleUpdate = field => (e) => {
    if (field === 'date' || field === 'time') {
      return setDate({ ...dateData, [field]: e });
    }

    return setAppointment({ ...newAppointment, [field]: e.target.value });
  };

  const handleUpdateEdit = field => (e) => {
    if (field === 'date' || field === 'time') {
      return setEditDate({ ...editDateData, [field]: e });
    }

    return setEdit({ ...editingData, [field]: e.target.value });
  };


  const isSubmitDisabled = () => {
    return !dateData.date || !dateData.time;
  };

  const handleCreateAppointment = async () => {
    const startDate = moment(dateData.date)
      .startOf('day')
      .hours(dateData.time.hours())
      .minutes(dateData.time.minutes());

    newAppointment.bundled_event_id = newAppointment.bundled_event_id === 'NONE' ? null : newAppointment.bundled_event_id;
    const apptOptions = {
      method: 'POST',
      body: {
        ...newAppointment,
        contact_preference: contactPreference,
        provider_id: props.appointment.provider_id,
        start_date: startDate,
        end_date: startDate.clone().add(duration, 'minutes'),
      },
    };

    try {
      await apiFetch(`/clinics/${clinicId}/users/${userId}/appointments`, apptOptions);
      trigger(`/clinics/${clinicId}/users/${userId}/appointments`);
      setAppointment({ ...baseAppt });
      setDate({ date: '', time: null });
    } catch (e) {
      console.error('An error occurred', e);
    }
  };

  const handleSaveEdit = async (id) => {
    const startDate = moment(editDateData.date)
      .startOf('day')
      .hours(editDateData.time.hours())
      .minutes(editDateData.time.minutes());

    const apptOptions = {
      method: 'PUT',
      body: {
        status: editingData.status,
        contact_preference: contactPreference,
        start_date: startDate,
        end_date: startDate.clone().add(duration, 'minutes'),
      },
    };

    try {
      await apiFetch(`/clinics/${clinicId}/users/${userId}/appointments/${editingData.id}`, apptOptions);
      trigger(`/clinics/${clinicId}/users/${userId}/appointments`);
      setEdit({ id: null });
      setEditDate({ date: '', time: null });
    } catch (e) {
      console.error('An error occurred', e);
    }
  };


  const formattedData = (appointmentData || []).map((a) => {
    const sortDate = a.start_date || a.checked_in;
    let notificationMethod = a.notification_method;
    if (notificationMethod === 'text') notificationMethod = 'cell phone';
    if (editingData.id === a.id) {
      return {
        id: a.id,
        key: a.id,
        sortDate,
        date: (
          <div className={classes.inlineDate}>
            <KeypadDate
              classes={{
                root: classes.keypadDate,
              }}
              includeCalendar={true}
              label="Date"
              onChange={handleUpdateEdit('date')}
              value={editDateData.date}
            />
            <TimePicker
              autoOk
              classes={{
                root: classes.timePicker,
              }}
              emptyLabel="Pick a Time"
              label="Time"
              minutesStep={5}
              value={editDateData.time}
              onChange={handleUpdateEdit('time')}
            />
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
              <InputLabel id="duration-label" style={{ fontSize: '12px' }}>Duration</InputLabel>
              <Select
                labelId="duration-label"
                id="demo-simple-select"
                style={{ width: '50px' }}
                value={duration}
                onChange={(e) => { setDuration(e.target.value); }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={35}>35</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={45}>45</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={55}>55</MenuItem>
                <MenuItem value={60}>60</MenuItem>
              </Select>
            </div>
          </div>
        ),
        appointmentType: capitalize(a.appointment_type || 'IN_PERSON').replace(/_/gi, ' '),
        notificationMethod: capitalize(notificationMethod || 'cell phone').replace(/_/gi, ' '),
        status: (
          <React.Fragment>
            <Select
              value={editingData.status}
              onChange={handleUpdateEdit('status')}
              input={<Input disableUnderline={true} />}
            >
              <MenuItem value="SCHEDULED">Scheduled</MenuItem>
              <MenuItem value="CANCELLED">Cancelled</MenuItem>
            </Select>
            <IconButton onClick={handleSaveEdit} size="small"><SaveIcon fontSize="inherit" /></IconButton>
          </React.Fragment>
        ),
      };
    }
    return {
      id: a.id,
      key: a.id,
      sortDate,
      date: moment(sortDate).format('MM/DD/YYYY LT'),
      appointmentType: capitalize(a.appointment_type || 'IN_PERSON').replace(/_/gi, ' '),
      notificationMethod: capitalize(notificationMethod || 'cell phone').replace(/_/gi, ' '),
      status: (
        <React.Fragment>
        {capitalize(a.status).replace(/_/gi, ' ')}{(!editingData.id && a.status === 'SCHEDULED') && (<IconButton onClick={() => handleEditClick(a)} size="small"><EditIcon fontSize="inherit" /></IconButton>)}
        </React.Fragment>
      ),
      userAgent: a.user_agent ? (
        <DeviceDetail agent={UAParser(a.user_agent)} />
      ) : undefined,
      enterExam: (a.status === 'WAITING' || a.status === 'IN_SESSION') ?
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() =>
            router.push({
              pathname: '/app/patient/video-call',
              state: { userId: a.user_id, appointmentId: a.id },
            })
          }
        >
          Enter Exam
        </Button>
        : <span style={styles.mdash}>&mdash;</span>,
    };
  });
  return (
    <Fragment>
      {props.children}
      <PatientViewContentSection>
        <section>
          <div style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
            <div style={styles.selectContainer}>
              <HiddenContent hidden={bundledEventComponents.length === 0}>
                <ProSelect
                  style={styles.select}
                  value={newAppointment.bundled_event_id || 'DEFAULT'}
                  onChange={handleUpdate('bundled_event_id')}
                  input={<Input disableUnderline={true} id="bundled-event-select" />}
                >
                  <MenuItem value="DEFAULT" disabled><em>Select Event</em></MenuItem>
                  <MenuItem value="NONE">None</MenuItem>
                  {bundledEventComponents}
                </ProSelect>
              </HiddenContent>
            </div>
            <div style={styles.scheduleContainer}>
              <KeypadDate
                classes={{
                  root: classes.keypadDate,
                }}
                includeCalendar={true}
                label="Date"
                onChange={handleUpdate('date')}
                value={dateData.date}
              />
              <TimePicker
                autoOk
                classes={{
                  root: classes.timePicker,
                }}
                emptyLabel="Pick a Time"
                label="Time"
                minutesStep={5}
                initialFocusedDate={moment().startOf('day')}
                value={dateData.time}
                onChange={handleUpdate('time')}
              />
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                <InputLabel id="duration-label" style={{ fontSize: '12px' }}>Duration</InputLabel>
                <Select
                  labelId="duration-label"
                  id="demo-simple-select"
                  style={{ width: '50px' }}
                  value={duration}
                  onChange={(e) => { setDuration(e.target.value); }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={35}>35</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={45}>45</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={55}>55</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                </Select>
              </div>
              <div style={styles.radioContainer}>
                <span style={{ fontWeight: 600, fontSize: '.9rem' }}>Send link via:</span>
                <div style={styles.radioRow}>
                  <Radio
                    size="small"
                    checked={contactPreference === 'email'}
                    onChange={() => {
                      setContactPreference('email');
                      props.updatePatient(userId, { contact_preference: 'email' });
                    }}
                    value="email"
                    color="primary"
                    name="radio-button-contact-preference-email"
                    inputProps={{ 'aria-label': 'email' }}
                  />
                  <span style={{fontSize: '.9rem'}}>Email</span>
                </div>
                <div style={styles.radioRow}>
                  <Radio
                    size="small"
                    checked={contactPreference === 'text'}
                    onChange={() => {
                      setContactPreference('text');
                      props.updatePatient(userId, { contact_preference: 'text' });
                    }}
                    value="text"
                    color="primary"
                    name="radio-button-contact-preference-text"
                    inputProps={{ 'aria-label': 'text' }}
                  />
                  <span style={{fontSize: '.9rem'}}>Cell Phone</span>
                </div>
              </div>
              <Button
                disabled={isSubmitDisabled()}
                variant="contained"
                className={classes.btn}
                onClick={handleCreateAppointment}
              >
                Schedule Appointment
              </Button>
            </div>
          </div>
          <SortableTable
            columnData={columnData}
            emptyMessage="This patient hasn't had any appointments."
            rows={formattedData}
            initialOrderBy="sortDate"
            initialOrder="desc"
          />
        </section>
      </PatientViewContentSection>
    </Fragment>
  );
};

PatientAppointmentsOverviewTab.defaultProps = {
  appointment: {
    waitTime: '00:00',
  },
  forms: {},
  painLevel: '',
};

PatientAppointmentsOverviewTab.propTypes = {
  appointment: PropTypes.object,
  classes: PropTypes.object.isRequired,
  forms: PropTypes.object,
  handleOpenAllForms: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  updatePatient: PropTypes.func.isRequired,
  painLevel: PropTypes.string,
  contact_preference: PropTypes.string,
  router: PropTypes.object.isRequired,
  updateAppointment: PropTypes.func.isRequired,
};

export default withStyles(styles)(PatientAppointmentsOverviewTab);
