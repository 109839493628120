import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../lib/styles';

const baseStyles = {
  square: {
    height: '8rem',
    width: '8rem',
    borderRadius: '6px',
    backgroundColor: colors.calendarGreen,
    marginLeft: '1rem',
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pSmallText: {
    color: colors.white,
    margin: 0,
    fontSize: '0.8rem',
  },
  pBigText: {
    fontSize: '2rem',
    color: colors.white,
    margin: '0.5rem',
    padding: '0.8rem',
  },
};

const ProSquare = props => {
  const squareStyle = Object.assign({}, baseStyles.square, props.squareStyle)
  const smallTextStyle = Object.assign({}, baseStyles.pSmallText, props.smallTextStyle)
  const bigTextStyle = Object.assign({}, baseStyles.pBigText, props.bigTextStyle)
  return (
    <div style={squareStyle}>
      <p style={smallTextStyle}>{props.text}</p>
      <p style={bigTextStyle}>{`${props.percentComplete}%`}</p>
      <p style={smallTextStyle}>{`${props.totalPatients} Patients`}</p>
    </div>
  )
};

ProSquare.defaultProps = {
  percentComplete: '0',
  text: 'Pre-Op',
  totalPatients: '0',
  squareStyle: {},
  smallTextStyle: {},
  bigTextStyle: {},
};

ProSquare.propTypes = {
  percentComplete: PropTypes.number,
  text: PropTypes.string,
  totalPatients: PropTypes.number,
  squareStyle: PropTypes.object,
  bigTextStyle: PropTypes.object,
  smallTextStyle: PropTypes.object,
};

export default ProSquare;
