import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find, debounce, get, capitalize, join, map } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Input from '@material-ui/core/Input';
import Link from '@material-ui/core/Link';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import CameraIcon from '@material-ui/icons/CameraAlt';
import CallEndIcon from '@material-ui/icons/CallEnd';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import SendIcon from '@material-ui/icons/Send';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import moment from 'moment';

import { readAppointment } from '../state/appointments';
import { browseImages, addImage } from '../state/user-images';

import AppWrapper from '../wrappers/app-wrapper';
import HiddenContent from '../components/hidden-content';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';
import PatientSummaryItem from '../components/patient-summary-item';
import PatientAvatar from '../components/patient-avatar';
import EventEmiiter from 'events';
import createOffer, { createLocalOfferAfterReset, getIceServers } from '../lib/createOffer';

import { BloodPressure, HeartBeat, Lungs, Spo2 } from '../lib/icons';
import camConstraints from '../lib/cam-constraints';
import {
  getSocket,
  messagePatient,
  messageAll,
  VITAL_CORE_MSGS_IN,
  VITAL_CORE_MSGS_OUT,
} from '../websocket';
import { throttledReset } from '../initializers/activity';
import { addRecent } from '../state/recent';

const VITAL_CORE_STATE = {
  disabled: 'disabled',
  enabled: 'Vitals enabled',
  initializingCoreModule: 'Initializing Core module',
  measuring: 'Measuring vitals',
  measured: 'Measured Vitals',
  submitted: 'Vitals received',
  saved: 'Vitals saved',
  error: 'Error',
}

const styles = {
  contentStyle: {
    width: '100%',
    minWidth: '768px',
    minHeight: '500px',
    display: 'flex',
  },
  callButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: '-60px auto 0px',
    width: '80%',
  },
  callPresentation: {
    margin: '0px auto',
    minHeight: '80vh',
    height: '100%',
    width: 'fit-content',
    maxWidth: '100vw',
  },
  centerColumn: {
    maxHeight: '100vh',
    height: 'min-content',
    minHeight: '90vh',
    width: '40%',
    minWidth: '200px',
  },
  rightColumn: {
    maxHeight: '100vh',
    height: 'min-content',
    minHeight: '80vh',
    width: '40%',
    minWidth: '200px',
  },
  closeBtn: {
    border: `1px solid ${colors.highlightDark}`,
    boxShadow: 'none',
    margin: '5px',
  },
  iconTab: {
    display: 'flex',
    alignItems: 'center',
  },
  inkBar: {
    backgroundColor: colors.primaryAccent,
  },
  healthCardIcon: {
    height: '40px',
  },
  healthCardSection: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '220px',
    margin: '10px',
  },
  healthCardTitle: {},
  heart: {
    position: 'relative',
    top: '-57px',
    left: '12px',
  },
  leftColumn: {
    minWidth: '200px',
    width: '20%',
  },
  name: {
    color: colors.black,
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '1.2rem',
    marginTop: '20px',
  },
  patientSummary: {
    color: colors.black,
    marginLeft: '10px',
    fontSize: '12px',
  },
  healthCard: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: '150px',
    width: '100px',
    fontWeight: 600,
    fontSize: '13px',
  },
  myVideo: {
    float: 'right',
    height: 'auto',
    position: 'absolute',
    marginLeft: '-75px',
    top: '56px',
    transform: 'scaleX(-1)',
    width: '75px',
    zIndex: 1000,
  },
  patientVideoUser: {
    height: '90vh',
    transform: 'scaleX(-1)',
    width: '100%',
    objectFit: 'cover',
  },
  patientVideoEnvironment: {
    height: '90vh',
    transform: 'scaleX(1)',
    width: '100%',
    objectFit: 'cover',
  },
  patientImages: {
    display: 'flex',
    flexDirection: 'row',
  },
  patientVideoPresentation: {
    alignItems: 'center',
    backgroundColor: 'rgb(240, 240, 240)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 auto',
    height: '80vh',
    padding: '50px 0',
    width: '100%',
  },
  poorConnectionMessage: {
    fontSize: '1.5rem',
    marginTop: 10,
  },
  presentationMessage: {},
  tab: {
    color: colors.highlightDarker,
    fontSize: '.85em',
    fonWeight: 100,
    textTransform: 'capitalize',
  },
  tabTemplate: {
    backgroundColor: colors.primaryHighlight,
  },
  topPatientSummaryItemContainer: {
    marginTop: '0.5rem',
  },
  thumb: {
    height: '25px',
    width: '25px',
    borderRadius: '3px',
  },
  vitals: {
    display: 'flex',
    color: 'red',
    fontSize: '2em',
    alignItems: 'center',
    justifyContent: 'center',
  },
  savingVitalsError: {
    color: colors.errorRed,
    marginLeft: '10px',
    fontSize: '12px',
  },
  messageBox: {
    display: 'flex',
  },
  message: {
    marginBottom: '15px',
  },
  chat: {
    position: 'absolute',
    bottom: 0,
    margin: 0,
    padding: 0,
  },
  messageSection: {
    backgroundColor: colors.white,
    minHeight: 'inherit',
  },
  messageDisplayBox: {
    padding: 10,
    overflow: 'hidden',
    overflowY: 'scroll',
    textAlign: 'left',
    height: '75vh',
  },
  messageField: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    borderRadius: 30,
    backgroundColor: '#eaeaea',
  },
  bottomNavMenu: {
    position: 'absolute',
    bottom: 50,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  bottomNavIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
    width: 60,
    backgroundColor: '#fff',
    borderRadius: '100%',
  },
  tabPanel: {
    minHeight: '100%',
  },
  tabPanelContainer: {
    display: 'flex',
    minHeight: '100%',
  },
  currTab: {
    backgroundColor: colors.primaryColor,
    color: '#fff !important',
    fontWeight: 'bold',
  },
  vitalsState: {
    marginBottom: 5,
    marginLeft: 10,
    marginTop: 5,
    textAlign: 'left',
  },
  patientLink: {
    textDecoration: 'underline',
  },
  patientLinkWrapper: {
    marginleft: 10,
  },
};

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      style={{ maxHeight: '600px' }}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ maxHeight: 'inherit' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const HealthCard = ({ classes, color, comingSoon, Icon, title, value }) => (
  <div style={{ color }} className={classes.healthCard}>
    <div style={{ color }} className={classes.healthCardTitle}>{title}</div>
    <Icon style={{ color }} className={classes.healthCardIcon} />
    <div style={{ color }} className={classes.healthCardValue}>
      {value}
      {comingSoon ? <div>Coming Soon</div> : null}
    </div>
  </div>
);

class VideoCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callStarting: false,
      connected: false,
      me: {
        events: new EventEmiiter(),
        candidates: [],
        setDescription: false,
        setShowPoorConnectionMessage: this.setShowPoorConnectionMessage,
        restartCall: this.restartCall,
        audioTrack: {
          enabled: true,
          muted: false,
        },
        track: {
          enabled: true,
          muted: false,
        },
        stream: {
          active: true,
        }
      },
      vitals: {
        signalPercent: 0,
      },
      medications: false,
      chief_complaint: false,
      hpi: false,
      images: [],
      savingVitals: false,
      showSavingVitalsError: false,
      soundPlayed: false,
      callStartTime: null,
      showPoorConnectionMessage: false,
      iceServers: null,
      displayVitalsStream: false,
      currTab: 0,
      patient_is_typing: false,
      clinic_is_typing: false,
      message: '',
      messageHx: [],
      viewedAll: true,
      unreadMessages: 0,
      micOn: true,
      videoOn: true,
      patientVideoMode: 'user',
      vitalCoreState: VITAL_CORE_STATE.disabled,
      canSaveVitals: false,
    }
  }
  myVidRef = React.createRef();
  patientVidRef = React.createRef();
  messagesEndRef = React.createRef()
  timer = React.createRef() 

  scrollToBottom = () => {
    const myMessageElement = this.messagesEndRef.current;
    if (myMessageElement) {
      myMessageElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  async componentWillMount() {
    const { clinicId, userId, appointmentId } = this.props;
    this.sound = new Audio();
    this.sound.src = '/sound/patient_in_session.mp3';
    const socket = getSocket();
    socket.otherEvents.on('CLINIC-IS-TYPING', this.clinicIsTypingEventListener);
    socket.otherEvents.on('CLINIC-IS-NOT-TYPING',  this.clinicIsNotTypingEventListener);
    socket.otherEvents.on('PATIENT_HEARTBEAT', this.heartBeatEventListener);
    socket.otherEvents.on('PATIENT_HEARTBEAT_STOP', this.heartBeatStopEventListener);
    socket.otherEvents.on('PATIENT-IS-TYPING', this.patientIsTypingEventListener);
    socket.otherEvents.on('PATIENT-IS-NOT-TYPING', this.patientIsNotTypingEventListener);
    socket.otherEvents.on('PATIENT-SMS', this.SMSEventListener);
    socket.otherEvents.on('CLINIC-SMS', this.SMSEventListener);
    socket.otherEvents.on('PATIENT_CAMERA_MODE', this.patientCameraModeEventListener);

    socket.otherEvents.on(VITAL_CORE_MSGS_IN.initializingCoreModule, () => this.updateVitalCoreState(VITAL_CORE_STATE.initializingCoreModule));
    socket.otherEvents.on(VITAL_CORE_MSGS_IN.measuring, () => this.updateVitalCoreState(VITAL_CORE_STATE.measuring));
    socket.otherEvents.on(VITAL_CORE_MSGS_IN.measured, () => this.updateVitalCoreState(VITAL_CORE_STATE.measured));
    socket.otherEvents.on(VITAL_CORE_MSGS_IN.error, () => this.updateVitalCoreState(VITAL_CORE_STATE.error));
    socket.otherEvents.on(VITAL_CORE_MSGS_IN.results, (data) => this.updateVitalCoreState(VITAL_CORE_STATE.submitted, data));

    this.setState({ callStartTime: new Date() });

    this.props.browseImages(userId);
    apiFetch(`/users/${userId}/medication_hx`, { method: 'GET' })
      .then((data) => {
        const medications = data.map((med) => { return `${med.name} ${med.dosage}`})
        this.setState({ medications });
      }).catch(() => this.setState({ medications: false }));
    apiFetch(`/users/${userId}/general_hx`, { method: 'GET' })
      .then((data) => {
        this.setState({ chief_complaint: data.chief_complaint });
      }).catch(() => this.setState({ chief_complaint: false }));
    apiFetch(`/clinics/${clinicId}/video_vitals/users/${userId}/hpi`, { method: 'GET' })
      .then((data) => {
        this.setState({ hpi: data.hpi });
      }).catch(() => this.setState({ hpi: false }));
    apiFetch(`/users/${userId}/vitals`, {
      query: {
        user_id: userId,
      },
    })
      .then((response) => {
        this.setState({
          vitals: response
        })
      })
    this.props.readAppointment(userId);
    apiFetch(`/users/${userId}/appointments/${appointmentId}/messages`, { method: 'GET' })
    .then((data) => {
      const messages = data.map((msg) => { return { from: msg.user_id, message: msg.message, created_at: msg.created_at }; });
      const sorted = messages.sort((msgA, msgB) => ((msgA.created_at > msgB.created_at) ? 1 : -1));
      this.setState({ messageHx: sorted });
    }).catch(() => this.setState({ messageHx: [] }));
  }
  componentWillUnmount = () => {
    try {
      const socket = getSocket();
      clearInterval(this.activityTimer);
      // Clear all Event Listeners..
      socket.otherEvents.off('PATIENT_HEARTBEAT', this.heartBeatEventListener);
      socket.otherEvents.off('PATIENT_HEARTBEAT_STOP', this.heartBeatStopEventListener);
      socket.otherEvents.off('ANSWERER_ICECANDIDATE', this.answererICECandidateListener);
      socket.otherEvents.off('CLINIC-IS-TYPING', this.clinicIsTypingEventListener);
      socket.otherEvents.off('CLINIC-IS-NOT-TYPING', this.clinicIsNotTypingEventListener);
      socket.otherEvents.off('CLINIC-SMS', this.SMSEventListener);
      socket.otherEvents.off('PATIENT-IS-TYPING', this.patientIsTypingEventListener);
      socket.otherEvents.off('PATIENT-IS-NOT-TYPING', this.patientIsNotTypingEventListener);
      socket.otherEvents.off('PATIENT-SMS', this.SMSEventListener);
      socket.otherEvents.off('PATIENT_CAMERA_MODE', this.patientCameraModeEventListener);
  
      socket.otherEvents.removeAllListeners('RTC_ANSWER_RESTART');
      this.state.me.events.removeAllListeners('peer_message');
      this.endCall();

    } catch (e) { }
  }

  updateVitalCoreState = (state, data = null) => {
    let updatedState = { vitalCoreState: state };

    if (data) {
      const {
        BP_DIA,
        BP_SYS,
        BR,
        HR,
        SPO2,
      } = data;

      updatedState = {
        ...updatedState,
        canSaveVitals: true,
        vitals: {
          HR: { value: HR },
          BR: { value: BR },
          SPO2: { value: SPO2 },
          BP: { value: BP_SYS, secondary_value: BP_DIA },
        },
      }
    }

    this.setState(updatedState);
  }

  enableVitalCore = () => {
    messagePatient(this.props.userId, { type: VITAL_CORE_MSGS_OUT.enableVitalCore });

    this.setState({ vitalCoreState: VITAL_CORE_STATE.enabled });
  }

  setShowPoorConnectionMessage = (showMessage) => {
    this.setState({ showPoorConnectionMessage: showMessage });
  }

  patientIsTypingEventListener = (data) => {
    if (this.state.patient_is_typing) return;

    const { userId } = this.props;
    if (data.from === userId) {
      this.setState({ patient_is_typing: true });
    }
    this.scrollToBottom();
  }

  patientIsNotTypingEventListener = (data) => {
    if (!this.state.patient_is_typing) return;
    
    const { userId } = this.props;
    if (data.from === userId) {
      this.setState({ patient_is_typing: false });
    }
  }

  SMSEventListener = (data) => {
    const { user, userId } = this.props;
    if (data.from === userId || data.from === user.id) {
      const { messageHx, currTab } = this.state;
      const newMessageHx = [...messageHx, { from: data.from, message: data.message }];

      this.setState({ messageHx: newMessageHx, viewedAll: currTab === 0, unreadMessages: currTab !== 0 && this.state.unreadMessages + 1 });
      
      this.scrollToBottom();
    }
  }

  clinicIsTypingEventListener = (data) => {
    if (this.state.clinic_is_typing) return;

    const { id } = this.props.user;
    if (data.from !== id) {
      this.setState({ clinic_is_typing: true });
    }
    this.scrollToBottom();
  }

  clinicIsNotTypingEventListener = (data) => {
    if (!this.state.clinic_is_typing) return;

    const { id } = this.props.user;
    if (data.from !== id) {
      this.setState({ clinic_is_typing: false });
    }
  }

  patientCameraModeEventListener = (data) => {
    this.setState({ patientVideoMode: data.cameraMode });
  }

  heartBeatEventListener = (data) => {
    const { userId } = this.props;
    let soundPlayed = this.state.soundPlayed;
    if (data.from === userId) {
      if (this.sound && !this.state.soundPlayed) {
        try{ 
          this.sound.play();
          soundPlayed = true;
        } catch (e) {
          console.log('error playing patientReady sound', e);
        }
      }
      this.setState({ patientReady: true, soundPlayed });
    }
  }
  heartBeatStopEventListener = (data) => {
    const { userId } = this.props;
    if (data.from === userId) {
      this.setState({ patientReady: false, soundPlayed: false });
    }
  }
  answererICECandidateListener = (data) => {
    const { me } = this.state;
    if (me.setDescription) {
      me.peer.addIceCandidate(data.candidate).catch((ce) => {
        console.error('error adding ice candidate direct from offerer', ce);
      });
    } else {
      this.setState(prevState => ({
        me: {
          ...prevState.me,
          candidates: prevState.me.candidates.concat(data.candidate)
        }
      }));
    }
  }
  debouncedUpdateVitals = debounce((vitals) => {
    if (this.state.displayVitalsStream) {
      this.setState({ vitals });
    }
  }, 3000, { maxWait: 3000, leading: true })

  addStream = async (stream) => {
    const { me, displayVitalsStream } = this.state;
    const { showVitals } = this.props;
    if (!this.state.connected) {
      try {
        this.setState({ connected: true });
        const vid = this.patientVidRef.current;
        vid.srcObject = stream;
        await vid.play();
        me.events.on('peer_message', (msg) => {
          if (msg.vitals && showVitals && displayVitalsStream) {
            this.debouncedUpdateVitals(msg.vitals);
          }
        });
      }
      catch (exp) {
        console.error('error adding stream', exp);
      }
    }
  }

  startCall = debounce(async () => {
    const { userId, clinic, clinicId, appointmentId } = this.props;
    const { callStarting, connected, me } = this.state;

    this.activityTimer = setInterval(throttledReset, 5000);

    if (!callStarting && !connected) {
      this.setState({ callStarting: true });
      try {
        apiFetch(`/clinics/${clinicId}/users/${userId}/appointments/${appointmentId}/status_update`, { method: 'PUT', body: { status: 'IN_SESSION' } });
        const stream = await this.startVideo();
        this.setState({ stream });

        me.events.on('gotStream', this.addStream);
        me.events.on('gotTrack', this.addStream);
        me.events.on('OFFERER_ICECANDIDATE', (candidate) => {
          messagePatient(userId, { type: 'OFFERER_ICECANDIDATE', candidate });
        });

        const socket = getSocket();
        socket.otherEvents.on('ANSWERER_ICECANDIDATE', this.answererICECandidateListener);
        socket.otherEvents.once('RTC_ANSWER', (data) => {
          if (data.from === userId) {
            const answerDesc = new RTCSessionDescription(data.answer);
            this.state.me.peer.setRemoteDescription(answerDesc);
            this.state.me.setDescription = true;
          }
        });
        socket.otherEvents.on('RTC_ANSWER_RESTART', (data) => {
          createLocalOfferAfterReset();
          if (data.from === userId) {
            const answerDesc = new RTCSessionDescription(data.answer);
            this.state.me.peer.setRemoteDescription(answerDesc);
          }
        });

        const iceServers = await getIceServers(me, clinicId);
        this.setState({ iceServers });
        const offer = await createOffer(this.state.me, clinicId);
        const msg = {
          offer,
          iceServers: iceServers,
          clinic_id: clinicId,
          clinic_name: clinic.data.name
        };
        messagePatient(userId, msg);
      } catch (e) {
        console.error('error starting call: ', e);
        this.endCall();
      }
      apiFetch(`/clinics/${clinicId}/users/${userId}/appointments/${appointmentId}/status_update`, { method: 'PUT', body: { status: 'IN_SESSION' } });
      this.setState({ callStarting: false });
    }
  }, 1000, { maxWait: 1000, leading: true })

  restartCall = async () => {
    const { userId, clinicId } = this.props;
    const { me } = this.state;
    const offer = await createLocalOfferAfterReset(me, clinicId);
    const msg = {
      offer,
      type: 'RTC_OFFER_RESTART',
    };
    messagePatient(userId, msg);
  }

  startVideo = async () => {
    const { me } = this.state;

    const stream = await navigator.mediaDevices.getUserMedia(camConstraints);

    const track = stream.getVideoTracks()[0];
    const audioTrack = stream.getAudioTracks()[0];
    me.track = track;
    me.stream = stream;
    me.audioTrack = audioTrack;

    me.events.on('peerCreated', () => {
      if (track) {
        me.peer.addTrack(track, stream);
      }
      if (audioTrack) {
        me.peer.addTrack(audioTrack, stream);
      }
    });

    const myVideoElement = this.myVidRef.current;

    myVideoElement.addEventListener('loadeddata', (e) => {
      myVideoElement.height = myVideoElement.videoHeight;
      myVideoElement.width = myVideoElement.videoWidth;
    });

    const ms = new MediaStream();
    ms.addTrack(track);
    this.setState({ ms });

    myVideoElement.srcObject = ms;
    myVideoElement.load();
    myVideoElement.play()
      .catch(error => {
        console.error('Auto Play Error', error);
      });

    return stream;
  }

  takePicture = () => {
    const vid = this.patientVidRef.current;
    const { userId } = this.props.router.location.state;
    if (vid) {
      const canvas = document.createElement('canvas');
      canvas.height = vid.videoHeight;
      canvas.width = vid.videoWidth;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(vid, 0, 0, vid.videoWidth, vid.videoHeight);
      canvas.toBlob((blob) => {
        this.props.addImage(userId, blob)
          .then((img) => {
            this.props.browseImages(userId);
          });
      });
    }
  }

  endCall = debounce(async (onHold = false) => {
    const { userId, clinicId, appointmentId } = this.props;
    messagePatient(userId, { type: 'PROVIDER_HANGUP' });
    try {
      if (onHold) apiFetch(`/clinics/${clinicId}/users/${userId}/appointments/${appointmentId}/status_update`, { method: 'PUT', body: { status: 'ON_HOLD' } });

      clearInterval(this.activityTimer);
      const socket = getSocket();
      // Clear all Event Listeners..
      // socket.otherEvents.off('PATIENT_HEARTBEAT', this.heartBeatEventListener);
      socket.otherEvents.off('ANSWERER_ICECANDIDATE', this.answererICECandidateListener);
      const videoElement1 = this.myVidRef.current;
      const videoElement2 = this.patientVidRef.current;
      if (get(this, 'state.me.stream.getTracks')) {
        this.state.me.stream.getTracks().forEach(track => track.enabled = false);
        this.state.me.stream.getTracks().forEach(track => track.stop());
      }
      if (get(videoElement1, 'srcObject.getTracks')) {
        videoElement1.srcObject.getTracks().forEach(track => track.enabled = false);
        videoElement1.srcObject.getTracks().forEach(track => track.stop());
      }
      if (get(videoElement2, 'srcObject.getTracks')) {
        videoElement2.srcObject.getTracks().forEach(track => track.enabled = false);
        videoElement2.srcObject.getTracks().forEach(track => track.stop());
      }
      try {
        this.state.me.audioTrack.stop();
      } catch (e) { console.error('Error stopping audio track', e) };
      this.clearCallState();
      videoElement1.srcObject = null;
      videoElement2.srcObject = null;
      this.setState({ videoOn: true, micOn: true, screenSharing: false });
    } catch (e) {
      console.error('error disconnecting call', e);
    }
  }, 5000, { maxWait: 5000, leading: true })

  endSession = debounce(async () => {
    const { userId, clinicId, appointmentId } = this.props;
    const { callStartTime } = this.state;
    const callEndTime = new Date();
    const duration = Math.round((callEndTime.getTime() - callStartTime.getTime()) / 1000);
    let endIt = window.confirm('This will end the call for the patient and complete the appointment, are you sure?')
    if (endIt) {
      messagePatient(userId, { type: 'END_SESSION' });
      await this.endCall();
      const body = {
        status: 'COMPLETED',
        start_time: callStartTime.toISOString(),
        end_time: callEndTime.toISOString(),
        duration,
      };
      await apiFetch(`/clinics/${clinicId}/users/${userId}/appointments/${appointmentId}/status_update`, { method: 'PUT', body });
      this.clearCallState({ appointmentEnded: true });
    }
    await apiFetch(`/users/${userId}/appointments/${appointmentId}/messages`, { method: 'DELETE' }).then(() => this.setState({ messageHx: [] }));
  }, 5000, { maxWait: 5000, leading: true })


  screenShare = debounce(async () => {
    const { userId } = this.props;
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
      this.swapProviderVideoTrack(screenStream);
      messagePatient(userId, { type: 'PROVIDER_SCREENSHARE', providerSharingScreen: true });

      this.setState({
        screenSharing: true,
        screenStream,
      });
    } catch (e) {
      console.error('error sharing screen', e);
    }
  }, 5000, { maxWait: 5000, leading: true })

  stopScreenShare = debounce(async () => {
    const { userId } = this.props;
    const { me, videoOn } = this.state;

    try {
      const stream = await navigator.mediaDevices.getUserMedia(camConstraints);

      this.swapProviderVideoTrack(stream);
      const track = stream.getVideoTracks()[0];

      me.track = track;
      me.stream = stream;
      track.enabled = videoOn

      messagePatient(userId, { type: 'PROVIDER_SCREENSHARE', providerSharingScreen: false });
      this.setState({
        screenSharing: false,
        screenStream: stream,
      });
    } catch (e) {
      console.error('error ending screen share', e);
    }
  }, 5000, { maxWait: 5000, leading: true })

  swapProviderVideoTrack = async (stream) => {
    const track = stream.getVideoTracks()[0];
    const senders = this.state.me.peer.getSenders();
    senders[0].replaceTrack(track);

    const oldTrack = this.state.ms.getVideoTracks()[0];
    this.state.ms.removeTrack(oldTrack);
    oldTrack.stop();
    this.state.ms.addTrack(track);
  }

  clearCallState = async (additionalState = {}) => {
    if (this.state.me.peer) this.state.me.peer.close();
    this.setState({
      connected: false,
      me: {
        events: new EventEmiiter(),
        candidates: [],
        setDescription: false,
        setShowPoorConnectionMessage: this.setShowPoorConnectionMessage,
        restartCall: this.restartCall,
      },
      ms: null,
      ...additionalState,
    });
  }

  saveVitals = () => {
    const { userId } = this.props;
    const { vitals } = this.state;

    const opts = {
      method: 'POST',
      body: {
        recorded_at: new Date().toISOString(),
        vitals_data: {
          BP_DIA: vitals.BP.secondary_value,
          BP_SYS: vitals.BP.value,
          BR: vitals.BR.value,
          HR: vitals.HR.value,
          SPO2: vitals.SPO2.value,
        },
      },
    };

    this.setState({
      savingVitals: true,
      showSavingVitalsError: false,
    });

    apiFetch(`/users/${userId}/vitals`, opts)
      .then(() => {
        this.setState({
          canSaveVitals: false,
          savingVitals: false,
          vitalCoreState: VITAL_CORE_STATE.saved,
        });
      }).catch(() => {
        this.setState({
          savingVitals: false,
          showSavingVitalsError: true,
        });
      });
  }

  handleChangeTabs = (e, value) => {
    this.setState({currTab: value});
    if (value === 0) {
      const { messageHx } = this.state;
      const { userId, appointmentId } = this.props;

      messageHx.find((msg) => {
        return !msg.clinic_viewed && apiFetch(`/users/${userId}/appointments/${appointmentId}/messages`, { method: 'PUT', body: { clinic_viewed: true } });
      });
      this.setState({ viewedAll: true, unreadMessages: 0 });
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    }
  };

  clinicIsNotTyping = () => messageAll(this.props.userId, this.props.clinicId, { type: 'CLINIC-IS-NOT-TYPING', from: this.props.user.id });

  handleChangeMessage = (e) => {
    const { value } = e.target;
    const { userId, clinicId, user: { id } } = this.props;

    this.setState({ message: value });
    if (value.length > 0) {
      messageAll(userId, clinicId, { type: 'CLINIC-IS-TYPING', from: id });
      clearTimeout(this.timer.current);
      this.timer.current = setTimeout(() => this.clinicIsNotTyping(), 2000);
    } else {
      this.clinicIsNotTyping();
    }
  };

  handleSendMessage = () => {
    const { message } = this.state;
    const { userId, clinicId, appointmentId, user: { id } } = this.props;

    if (message.length > 0) {
      messageAll(userId, clinicId, { type: 'CLINIC-SMS', message, appointment_id: appointmentId, from: id });
      this.clinicIsNotTyping();
      this.setState({ message: '' })
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    }
  }

  handleSubmitOnEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.handleSendMessage();
    }
  }

  toggleMic = () => {
    const { micOn, me: { audioTrack }} = this.state;
    audioTrack.enabled = !audioTrack.enabled;
    return this.setState({
      micOn: !micOn,
    })
  }

  toggleCamera = () => {
    const { videoOn, me: { track }} = this.state;
    track.enabled = !track.enabled;
    return this.setState({
      videoOn: !videoOn,
    })
  }

  goToPatientPage = (e) => {
    e.preventDefault();
    const {
      addRecent,
      appointment,
      router,
      userId,
    } = this.props;

    if (window.confirm('Are you sure you would like to leave the page?')) {
      addRecent(appointment);
      router.push({ pathname: '/app/patient', state: { userId, contact_preference: appointment.contact_preference } });
    }
  };

  render() {
    const {
      appointment,
      classes,
      enableVideoVitals,
      showVitals,
    } = this.props;
    const {
      appointmentEnded,
      connected,
      medications,
      chief_complaint,
      hpi,
      patientReady,
      screenSharing,
      vitals,
      savingVitals,
      showSavingVitalsError,
      showPoorConnectionMessage,
      currTab,
      patient_is_typing,
      clinic_is_typing,
      message,
      messageHx,
      viewedAll,
      unreadMessages,
      micOn,
      videoOn,
      patientVideoMode,
      vitalCoreState,
      canSaveVitals,
    } = this.state;
    const birthday = moment(appointment.birth_date).isValid() ? `${moment(appointment.birth_date).format('MM/DD/YYYY')}` : appointment.birth_date;

    const sex = appointment.sex ? capitalize(appointment.sex) : '--';
    const insurance = appointment.primary_insurance_name ? capitalize(appointment.primary_insurance_name) : '--';
    const formattedMedications = medications.length ? join(medications, ', ') : '--';
    const formattedChiefComplaint = chief_complaint || '--';

    const VITAL_CORE_STATE = {
      disabled: 'disabled',
      enabled: 'Vitals enabled',
      initializingCoreModule: 'Initializing Core module',
      measuring: 'Measuring vitals',
      measured: 'Measured Vitals',
      submitted: 'Vitals received',
      saved: 'Vitals saved',
      error: 'Error',
    }

    const startMeasurementsButtonDisabled = vitalCoreState === VITAL_CORE_STATE.enabled
      || vitalCoreState === VITAL_CORE_STATE.initializingCoreModule
      || vitalCoreState === VITAL_CORE_STATE.measuring
      || vitalCoreState === VITAL_CORE_STATE.measured;

    return (
      <AppWrapper iconSidebar={true} router={this.props.router}>
        <div className={classes.contentStyle}>
          {appointment ? (
              <div style={{ display: 'flex', width: '100%' }}>
                <div className={classes.leftColumn}>
                  <div className={classes.name}>
                    {appointment.last_name}, {appointment.first_name}
                    <div className={classes.patientLinkWrapper}>
                      <Link
                        className={classes.patientLink}
                        href="/app/patient"
                        onClick={this.goToPatientPage}
                      >
                        <i>Go to patient's record</i>
                      </Link>
                    </div>
                    <div className={classes.patientSummary}>
                      <PatientSummaryItem classes={{ container: classes.topPatientSummaryItemContainer }} title="Sex" value={sex} />
                      <PatientSummaryItem classes={{ container: classes.topPatientSummaryItemContainer }} title="Date of Birth" value={birthday} />
                      <PatientSummaryItem classes={{ container: classes.topPatientSummaryItemContainer }} title="Insurance" value={insurance} />
                      <PatientSummaryItem title="Reason For Visit" value={formattedChiefComplaint} />
                      <PatientSummaryItem title="Medications" value={formattedMedications} />
                      <PatientSummaryItem title="Summary" value={hpi} />
                    </div>
                    <HiddenContent hidden={appointmentEnded || appointment.status === 'COMPLETED'}>
                      <Button color="primary" variant="contained" onClick={this.endSession}>
                        End Session
                      </Button>
                    </HiddenContent>
                  </div>

                  <HiddenContent hidden={!showVitals}>
                    <div style={{ flex: 1 }}>
                      {vitalCoreState !== VITAL_CORE_STATE.disabled ? (
                        <div className={classes.vitalsState}><strong>Vital Core status: </strong>{vitalCoreState}</div>
                      ) : null}
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <div className={classes.healthCardSection}>
                          <HealthCard
                            classes={classes}
                            color={colors.healthyRed}
                            Icon={HeartBeat}
                            title="Heart Rate"
                            value={vitals.HR ? vitals.HR.value : '--'}
                          />
                          <HealthCard
                            classes={classes}
                            color={colors.healthyRed}
                            Icon={BloodPressure}
                            title="Blood Pressure"
                            value={vitals.BP ? `${vitals.BP.value}/${vitals.BP.secondary_value}` : '--'}
                          />
                        </div>
                        <div className={classes.healthCardSection}>
                          <HealthCard
                            classes={classes}
                            color={colors.primaryColor}
                            Icon={Lungs}
                            title="Breath Rate"
                            value={vitals.BR ? vitals.BR.value : '--'}
                          />
                          <HealthCard
                            classes={classes}
                            color={colors.primaryColor}
                            Icon={Spo2}
                            title="SpO2"
                            value={vitals.SPO2 && vitals.SPO2.value ? vitals.SPO2.value : '--'}
                          />
                        </div>
                      </div>
                      {showSavingVitalsError ? (
                        <div className={classes.savingVitalsError}>
                          Error saving vitals measurements
                        </div>
                      ) : null}
                      <Button
                        color="primary"
                        disabled={!canSaveVitals || savingVitals}
                        variant="contained"
                        onClick={this.saveVitals}
                      >
                        Save Measurements
                      </Button>
                    </div>
                  </HiddenContent>
                  <div className={classes.patientImages}>
                    <ul>
                      {map(this.props.userImages.browseImages, (img, id) => {
                        return (
                          <li key={id}><img src={img.url} className={classes.thumb} alt="patient" /> <a href={img.url} target="_blank" rel="noopener noreferrer">view</a></li>
                        )
                      })}
                    </ul>
                  </div>
                </div>

                <div className={classes.centerColumn} >
                  <div className={connected ? classes.callPresentation : classes.patientVideoPresentation}>
                    <HiddenContent hidden={!appointmentEnded}>
                      <div className={classes.presentationMessage}>Appointment Completed. If you wish to communicate with this patient you will need to schedule a new appointment.</div>
                    </HiddenContent>
                    <HiddenContent hidden={patientReady || appointmentEnded}>
                      <PatientAvatar image={appointment.avatarImgLarge} />
                      <div className={classes.presentationMessage}>Waiting for Patient</div>
                    </HiddenContent>
                    <HiddenContent hidden={connected || !patientReady || appointmentEnded}>
                      <PatientAvatar image={appointment.avatarImgLarge} />
                      <Button color="primary" disabled={this.state.callStarting} variant="contained" onClick={this.startCall}>
                        Join session
                      </Button>
                    </HiddenContent>
                    <div>
                      <video id="patientVideo" ref={this.patientVidRef} style={{ display: connected ? '' : 'none' }} className={patientVideoMode === 'user' ? classes.patientVideoUser : classes.patientVideoEnvironment} />
                      <video id="myVideo" ref={this.myVidRef} style={{ display: connected ? '' : 'none' }} muted className={classes.myVideo} />
                    </div>
                  </div>
                  <HiddenContent hidden={!connected}>
                    <div className={classes.callButtonContainer}>
                      {videoOn ? (
                        <Fab
                          aria-label="Camera On"
                          color="primary"
                          onClick={this.toggleCamera}
                          disabled={screenSharing}
                        >
                          <VideocamIcon />
                        </Fab>
                      ) : (
                        <Fab
                          aria-label="Camera Off"
                          color="default"
                          onClick={this.toggleCamera}
                          disabled={screenSharing}
                        >
                          <VideocamOffIcon />
                        </Fab>
                      )}
                      {micOn ? (
                        <Fab
                          aria-label="Mic On"
                          color="primary"
                          onClick={this.toggleMic}
                        >
                          <MicIcon />
                        </Fab>
                      ) : (
                        <Fab
                          aria-label="Mic Off"
                          color="default"
                          onClick={this.toggleMic}
                        >
                          <MicOffIcon />
                        </Fab>
                      )}
                      <Fab
                        aria-label="Hold Call"
                        color="secondary"
                        onClick={() => this.endCall(true)}
                      >
                        <CallEndIcon />
                      </Fab>
                      {screenSharing ? (
                        <Fab
                          aria-label="Stop Share Screen"
                          color="secondary"
                          onClick={this.stopScreenShare}
                        >
                          <StopScreenShareIcon />
                        </Fab>
                      ) : (
                        <Fab
                          aria-label="Share Screen"
                          color="primary"
                          onClick={this.screenShare}
                        >
                          <ScreenShareIcon />
                        </Fab>
                      )}
                      <Fab
                        aria-label="take picture"
                        color="primary"
                        onClick={this.takePicture}
                      >
                        <CameraIcon />
                      </Fab>
                      <HiddenContent hidden={!enableVideoVitals}>
                        <Fab
                          aria-label="start vitals measurements"
                          color="primary"
                          onClick={this.enableVitalCore}
                          disabled={startMeasurementsButtonDisabled}
                        >
                          <HeartBeat style={{ width: 24 }} />
                        </Fab>
                      </HiddenContent>
                    </div>
                  </HiddenContent>
                  <HiddenContent hidden={!showPoorConnectionMessage || appointmentEnded || appointment.status === 'COMPLETED'}>
                    <div className={classes.poorConnectionMessage}>
                      Unable to perform video call due to a poor connection
                    </div>
                  </HiddenContent>
                </div>
                <div className={classes.rightColumn} >
                  <AppBar position='static' color='default'>
                    <Tabs
                      value={currTab}
                      onChange={this.handleChangeTabs}
                      indicatorColor='primary'
                      textColor='primary'
                      variant='scrollable'
                      scrollButtons='on'
                    >
                      <Tab
                        className={!viewedAll && classes.currTab}
                        label={
                          <Badge style={{ paddingRight: 10 }} color='secondary' badgeContent={unreadMessages}  invisible={viewedAll} >
                            <Typography style={{ fontWeight: !viewedAll && 'bold' }}>Chat</Typography>
                          </Badge>
                        }
                      />
                      <Tab label='Notes' disabled />
                      <Tab label='Prescriptions' disabled />
                      <Tab label='Lab Work' disabled />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={currTab} index={0}>
                    <div className={classes.messageSection}>
                      <Box flexGrow={1} className={classes.messageDisplayBox}>
                        <div style={{minHeight: '96%'}}>
                          {messageHx.map((message) => {
                          const sender = message.from === this.props.userId ? 'Patient' : 'Clinic';
                          return (
                            <div>
                              <Typography variant='body1' className={classes.message}><strong>{sender}:</strong> {message.message}</Typography>
                            </div>
                          );
                        })}
                        </div>
                        {patient_is_typing && <Typography variant='body1'><em>Patient is typing...</em></Typography>}
                        {clinic_is_typing && <Typography variant='body1'><em>Another provider is typing...</em></Typography>}
                        <div ref={el => this.messagesEndRef.current = el } />
                      </Box>
                      <Box  alignSelf='flex-end' className={classes.messageBox}>
                        <Input
                          type='text'
                          placeholder='Tap to enter message'
                          variant='filled'
                          multiline
                          disableUnderline
                          maxRows={4}
                          value={message}
                          onChange={this.handleChangeMessage}
                          onKeyPress={this.handleSubmitOnEnter}
                          className={classes.messageField}
                        />
                        <Button onClick={this.handleSendMessage}>
                          <SendIcon color='primary' style={{ fontSize: 40 }} />
                        </Button>
                      </Box>
                    </div>
                  </TabPanel>
                  <TabPanel value={currTab} index={1}>
                    Notes
                  </TabPanel>
                  <TabPanel value={currTab} index={2}>
                    Prescriptions
                  </TabPanel>
                  <TabPanel value={currTab} index={3}>
                    Lab Work
                  </TabPanel>
                </div>
              </div>
          ) : ''}
        </div>
      </AppWrapper>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    appointments,
    clinic,
    user,
    userImages,
  } = state;

  const userId = get(ownProps, 'location.state.userId');
  const appointmentId = get(ownProps, 'location.state.appointmentId');
  return {
    appointmentId,
    appointment: find(appointments.data, { user_id: userId }) || {},
    clinicId: clinic.clinicId,
    showVitals: clinic.data.app_features.includes('VITALS_OVERVIEW'),
    enableVideoVitals: clinic.data.app_features.includes('VIDEO_VITALS'),
    user,
    userId,
    clinic,
    userImages,
  };
}

const actionCreators = {
  browseImages,
  addImage,
  readAppointment,
  addRecent,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(VideoCall));
