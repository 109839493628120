import moment from 'moment';
import { ceil, get, mean } from 'lodash';

import getSeverity from './get-severity-rating';

function getMigraineStats(currentDate, migraines) {
  const monthStart = moment(currentDate).startOf('month');
  const monthEnd = moment(currentDate).endOf('month');
  const initialStats = {
    severity: 0,
    hours: 0,
    days: {
      severe: 0,
      moderate: 0,
      light: 0,
    },
  };

  const severities = [];

  const migraineStats = migraines.reduce((stats, migraine) => {
    const migrainePain = get(migraine, 'pain[0]', null);
    if (!migrainePain) {
      return stats;
    }
    severities.push(migrainePain.severity);
    const originalStart = moment(migraine.start_time);
    const originalEnd = moment(migraine.end_time);

    const migraineStart = (originalStart < monthStart) ? monthStart.clone() : originalStart;
    const migraineEnd = (originalEnd > monthEnd) ? monthEnd.clone() : originalEnd;

    stats.hours += migraineEnd.diff(migraineStart, 'hours', true);
    const days = migraineEnd.endOf('day').diff(migraineStart.startOf('day'), 'days', true);
    stats.days[getSeverity(migrainePain.severity)] += ceil(days);

    return stats;
  }, initialStats);

  // This block feels bad, but rounding needs to be done after they're totaled
  migraineStats.hours = ceil(migraineStats.hours);
  migraineStats.days.severe = ceil(migraineStats.days.severe);
  migraineStats.days.moderate = ceil(migraineStats.days.moderate);
  migraineStats.days.light = ceil(migraineStats.days.light);
  migraineStats.severity = ceil(mean(severities) || 0, 0.1);

  return migraineStats;
}

export default getMigraineStats;
