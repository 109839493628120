import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { KeypadDate } from 'common-ui';

import { colors } from '../lib/styles';

const styles = {
  container: {
    margin: '15px',
  },
  name: {
    color: colors.primaryColor,
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '1.5rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    padding: '10px',
    width: '190px',
    alignSelf: 'center',
  },
  fieldLabel: {
    fontSize: '1rem',
    color: '#000',
    alignSelf: 'center',
  },
  inputAlignment: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textFieldInput: {
    width: '190px',
    height: '75%',
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: '-1px -1px 1px #d8d8d8',
    border: '.5px solid #d8d8d8',
    borderRadius: '1px',
    textIndent: '7px',
  },
  input: {
    textIndent: '7px',
  },
};

const languages = ['ENGLISH', 'SPANISH'];

const EditView = ({
  classes,
  first_name,
  last_name,
  birth_date,
  cell_phone,
  email,
  language,
  member_id,
  flag_id,
  flags,
  onUpdateFirstName,
  onUpdateLastName,
  onUpdateCellPhone,
  onUpdateBirthDate,
  onUpdateEmail,
  onUpdateLanguage,
  onUpdateMemberId,
  onUpdateFlag,
  roles,
}) => (
  <div style={styles.form}>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className={classes.inputAlignment}>
        <div className={classes.fieldLabel}>First Name:</div>
        <TextField
          id="first_name"
          fullWidth
          InputProps={{
          disableUnderline: true,
          className: classes.textFieldInput,
          inputProps: { className: classes.input },
        }}
          className={classes.field}
          onChange={onUpdateFirstName}
          value={first_name}
        />
      </div>
      <div className={classes.inputAlignment}>
        <div className={classes.fieldLabel}>Last Name:</div>
        <TextField
          id="last_name"
          fullWidth
          onChange={onUpdateLastName}
          InputProps={{
          disableUnderline: true,
          className: classes.textFieldInput,
          inputProps: { className: classes.input },
        }}
          className={classes.field}
          value={last_name}
        />
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className={classes.inputAlignment}>
        <div className={classes.fieldLabel}>Date of Birth:</div>
        <KeypadDate onChange={onUpdateBirthDate} value={birth_date} />
      </div>
      <div style={styles.inputAlignment}>
        <div style={styles.fieldLabel}>Cell Phone:</div>
        <TextField
          id="phone"
          fullWidth
          onChange={onUpdateCellPhone}
          InputProps={{
          disableUnderline: true,
          className: classes.textFieldInput,
          inputProps: { className: classes.input },
        }}
          className={classes.field}
          value={cell_phone}
        />
      </div>
    </div>
    {roles[0] === 'SHADOW_PATIENT' ?
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={styles.inputAlignment}>
          <div style={styles.fieldLabel}>Email:</div>
          <TextField
            id="email"
            fullWidth
            onChange={onUpdateEmail}
            InputProps={{
              disableUnderline: true,
              className: classes.textFieldInput,
              inputProps: { className: classes.input },
            }}
            className={classes.field}
            value={email}
          />
        </div>
      </div>
    : null}
    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
      <div className={classes.inputAlignment}>
        <div className={classes.fieldLabel}>Language:</div>
        <Select
          labelId="language-label"
          id="language-select"
          style={{ width: '150px' }}
          value={language}
          onChange={onUpdateLanguage}
        >
          <MenuItem key={0} value="" disabled>Select language</MenuItem>
          {
            languages.map(lang => (
              <MenuItem key={lang} value={lang}>{lang}</MenuItem>
            ))
          }
        </Select>
      </div>
      <div style={styles.inputAlignment}>
        <div style={styles.fieldLabel}>Member ID:</div>
        <TextField
          id="member_id"
          fullWidth
          onChange={onUpdateMemberId}
          InputProps={{
            disableUnderline: true,
            className: classes.textFieldInput,
            inputProps: { className: classes.input },
          }}
          className={classes.field}
          value={member_id}
        />
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
      <div style={{ display: 'flex', width: '293px'}}>
        <div className={classes.fieldLabel}>Flag:</div>
        <Select
          labelId="flag-label"
          id="flag-select"
          style={{ width: '150px' }}
          value={flag_id}
          onChange={onUpdateFlag}
        >
          <MenuItem key={0} value="None">None</MenuItem>
          {
            flags.map(flag => (
              <MenuItem key={flag.id} value={flag.id}>{flag.description}</MenuItem>
            ))
          }
        </Select>
      </div>
    </div>
  </div>
);

EditView.propTypes = {
  classes: PropTypes.object.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  birth_date: PropTypes.string.isRequired,
  cell_phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  flags: PropTypes.object.isRequired,
  flag_id: PropTypes.string.isRequired,
  onUpdateFirstName: PropTypes.func.isRequired,
  onUpdateLastName: PropTypes.func.isRequired,
  onUpdateCellPhone: PropTypes.func.isRequired,
  onUpdateBirthDate: PropTypes.func.isRequired,
  onUpdateEmail: PropTypes.func.isRequired,
  onUpdateLanguage: PropTypes.func.isRequired,
  onUpdateFlag: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};

export default withStyles(styles)(EditView);
