import Table from '@material-ui/core/Table';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../lib/styles';

export default withStyles({
  root: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.highlightMedium}`,
    height: '100%',
  },
})(Table);
