import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import PatientAvatar from './patient-avatar';
import { colors } from '../lib/styles';

const styles = {
  container: {
    alignContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '0',
    minHeight: '235px',
    width: '100%',
  },
  rightContent: {
    margin: '5px 0px 0px 0px',
    flex: 1,
  },
  summary: {
    flex: 1,
  },
  topSectionIcon: {
    color: colors.primaryAccent,
    height: '18px',
    width: '18px',
  },
};

class PatientViewHeader extends PureComponent {
  render() {
    return (
      <div style={styles.container}>
        <PatientAvatar image={this.props.avatarImg} />
        <div style={styles.summary}>
          {this.props.centerContent}
        </div>
        <div style={styles.rightContent}>
          {this.props.rightContent}
        </div>
      </div>
    );
  }
}

PatientViewHeader.propTypes = {
  avatarImg: PropTypes.string,
  centerContent: PropTypes.node.isRequired,
  rightContent: PropTypes.node.isRequired,
};

export default withStyles(styles)(PatientViewHeader);
