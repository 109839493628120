import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar';

const styles = {
  avatar: {
    height: '170px',
    width: '170px',
  },
};

function PatientAvatar(props) {
  return (
      <Avatar
        src={props.image}
        className={props.classes.avatar}
        imgProps={{
          onError: (e) => { e.target.src = '/img/default-avatar.png' }
        }}
      />
  );
}

PatientAvatar.defaultProps = {
  image: '',
};

PatientAvatar.propTypes = {
  image: PropTypes.string,
};

export default withStyles(styles)(PatientAvatar);
