import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { filter, range, orderBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import PeniclIcon from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CancelIcon from '@material-ui/icons/DeleteForever';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import { TimePicker } from '@material-ui/pickers';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { KeypadDate } from 'common-ui';

import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';
import TableBody from '../base/table-body';
import TableCell from '../base/table-cell';
import TableHead from '../base/table-head';
import TableRow from '../base/table-row';

import { chatEvents } from '../websocket';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  keypadDate: {
    minWidth: '250px',
    width: '250px',
  },
  calendarBox: { borderRadius: '5px' },
  dayTile: { borderRadius: '50%' },
  cancel: {
    hover: {
      '&:hover': {
        backgroundColor: 'red',
      },
    },
  },
});

const colorStyle = {
  WAITING: colors.calendarYellow,
  IN_SESSION: colors.calendarGreen,
  SCHEDULED: colors.black,
};

const mapFunctions = {
  WAITING: 0,
  ON_HOLD: 0,
  IN_SESSION: 0,
  SCHEDULED: 1,
  COMPLETED: 2,
  CANCELLED: 2,
  CHECKED_IN: 2,
  CHECKED_OUT: 2,
};

export default function TelemedSchedule(props) {
  const { appointmentRows, providers, clinic_id, router } = props;
  const [date, changeDate] = useState(new Date());
  const [editIdx, changeIdx] = useState(-1);
  const [edit, toggleEdit] = useState(true);
  const [editDate, changeEditDate] = useState(moment(new Date()));
  const [editTime, changeTime] = useState(moment(new Date()));
  const [duration, setDuration] = useState(15);
  const [toggle, changeToggle] = useState(true);
  const [month, changeMonth] = useState(date);
  const [modal, toggleModal] = useState(false);
  const [currentAppointment, setCurrentAppointment] = useState({ string: '', data: {} });
  const classes = useStyles();

  const cancelAppt = () => {
    apiFetch(
      `/clinics/${clinic_id}/users/${currentAppointment.data.user_id}/appointments/${currentAppointment.data.id}/status_update`,
      { method: 'PUT', body: { status: 'CANCELLED' } },
    );
  };

  const saveAppt = (id, user_id, contact_preference) => {
    const start_date = moment(editDate)
      .startOf('day')
      .hours(editTime.hours())
      .minutes(editTime.minutes());
    const end_date = start_date.clone().add(duration, 'minutes');
    const apptOptions = {
      method: 'PUT',
      body: {
        start_date,
        end_date,
        contact_preference,
      },
    };
    apiFetch(
      `/clinics/${clinic_id}/users/${user_id}/appointments/${id}`,
      apptOptions,
    ).then(() => toggleEdit(!edit));
  };

  const handleScheduled = (res) => {
    const { id, user_id } = res;
    const current = `${res.first_name} ${res.last_name} ${moment(res.date).format('MM/DD/YYYY')}   ${moment(res.time).format('h:mm A')}`;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '14px' }}>
        {editIdx === id && !edit ? (
          <div style={{ display: 'flex' }}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => saveAppt(id, user_id, res.contact_preference)}
            >
              Save
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => toggleEdit(!edit)}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div>
            <PeniclIcon
              onClick={() => {
                changeIdx(id);
                toggleEdit(!edit);
                setDuration(res.duration);
                changeEditDate(res.date);
                changeTime(res.time);
              }}
              style={{ margin: '0 10' }}
            />
            <CancelIcon
              className={classes.cancel}
              style={{ margin: '0 10' }}
              onClick={() => { setCurrentAppointment({ string: current, data: { id, user_id } }); toggleModal(true); }}
            />
          </div>
        )}
      </div>
    );
  };

  const handleWaiting = (res) => {
    return (
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={() =>
          router.push({
            pathname: '/app/patient/video-call',
            state: { userId: res.user_id, appointmentId: res.id },
          })
        }
      >
        Enter Exam
      </Button>
    );
  };

  const handleSendMessage = (res) => {
    if (res.status === 'WAITING' || res.status === 'ON_HOLD' || res.status === 'IN_SESSION') {
      return (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() =>
          chatEvents.emit('OPEN-APPOINTMENT-CHAT', res.id)
        }
        >
        Send Message
        </Button>
      );
    }
  };

  const buildRow = (res) => {
    const handleRowClick = () => {
      if (edit) {
        router.push({ pathname: '/app/patient', state: { userId: res.user_id } });
      }
    };
    return (
      <TableRow key={res.id} >
        <TableCell onClick={handleRowClick} width="175px" align="left">
          {`${res.first_name} ${res.last_name}`}
        </TableCell>
        {editIdx === res.id && !edit ? (
          <TableCell width="175px">
            <KeypadDate
              classes={{
                root: classes.keypadDate,
              }}
              includeCalendar={true}
              label="Date"
              onChange={e => changeEditDate(moment(e))}
              value={moment(editDate).format('MM/DD/YYYY')}
            />
          </TableCell>
        ) : (
          <TableCell onClick={handleRowClick} size="small" align="left">
            {`${res.date.clone().format('MM/DD/YYYY')}  ${res.time.clone().format('h:mm A')}`}
          </TableCell>
        )}
        {editIdx === res.id && !edit ? (
          <Fragment>
            <TableCell size="small">
              <TimePicker
                autoOk
                emptyLabel="Pick a Time"
                label="Time"
                minutesStep={5}
                initialFocusedDate={editTime}
                value={editTime}
                onChange={e => changeTime(moment(e))}
              />
            </TableCell>
            <TableCell size="small">
              <InputLabel id="duration-label" style={{ fontSize: '12px' }}>Duration</InputLabel>
              <Select
                labelId="duration-label"
                id="duration-selection"
                style={{ width: '50px' }}
                value={duration}
                onChange={(e) => { setDuration(e.target.value); }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={35}>35</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={45}>45</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={55}>55</MenuItem>
                <MenuItem value={60}>60</MenuItem>
              </Select>
            </TableCell>
          </Fragment>
        ) : (
          <Fragment>
            <TableCell onClick={handleRowClick} size="small">
              {`${res.duration}mins`}
            </TableCell>
            <TableCell onClick={handleRowClick} size="small" style={{ color: colorStyle[res.status] }}>
              {res.status}
            </TableCell>
            <TableCell onClick={handleRowClick} size="small">
              <div>{res.provider}</div>
            </TableCell>
          </Fragment>
        )}
        <TableCell>
          {[handleWaiting, handleScheduled, () => ''][mapFunctions[res.status]](res)}
        </TableCell>
        <TableCell>
          {handleSendMessage(res)}
        </TableCell>
      </TableRow>
    );
  };

  const currentDate = moment(date);
  const weekStart = currentDate.clone().startOf('week');
  const weekEnd = currentDate.clone().endOf('week');
  const columns = ['Patient', 'Appointment Time', 'Duration', 'Status', 'Provider', '', ''];

  const dates = [];
  const column = columns.map(res => <TableCell key={res}>{res}</TableCell>);
  appointmentRows.forEach((res) => {
    const provider = filter(providers, { id: res.provider_id });
    const currentDuration = moment.duration(moment(res.end_date).diff(moment(res.start_date)));
    dates.push({
      id: res.id,
      user_id: res.user_id,
      clinic_id,
      first_name: res.first_name,
      last_name: res.last_name,
      contact_preference: res.contact_preference,
      date: moment(res.start_date),
      time: moment(res.start_date),
      duration: currentDuration.asMinutes(),
      status: res.status,
      provider: provider[0] ? provider[0].name : 'None',
    });
  });

  const validAppointments = dates.filter((res) => {
    if (toggle) {
      return moment(res.date).isSame(date, 'day') && res.status !== 'CANCELLED';
    }
    return moment(res.date).isBetween(weekStart, weekEnd, undefined, '[]') && res.status !== 'CANCELLED';
  });

  const groupAndLabel = () => {
    const groups = [];
    range(7).forEach((i) => {
      const day = weekStart.clone().add(i, 'days');
      groups[i] = {
        day,
        label: day.clone().format('MMMM D, YYYY'),
        appts: [],
      };
    });
    validAppointments.forEach((appt) => {
      groups.forEach((day, i) => {
        if (moment(appt.date).isSame(groups[i].day, 'day')) {
          groups[i].appts.push(appt);
        }
      });
    });
    groups.map((g) => {
      const appts = orderBy(g.appts, ['date'], ['asc']);
      g.appts = appts;
      return g;
    });
    return groups;
  };

  const buildTables = () => {
    const groups = groupAndLabel();
    const tables = [];
    if (toggle) {
      const day = filter(groups, d => d.day.clone().isSame(currentDate, 'day'));
      const rows = [];
      day[0].appts.forEach((appt) => {
        rows.push(buildRow(appt));
      });
      tables.push((
        <Fragment>
          <h3>{day[0].label}</h3>
          <TableBody>
            {rows}
          </TableBody>
        </Fragment>
      ));
      return tables;
    }
    groups.forEach((day) => {
      const rows = [];
      day.appts.forEach((appt) => {
        rows.push(buildRow(appt));
      });
      tables.push((
        <Fragment>
          <h3>{day.label}</h3>
          <TableBody>
            {rows}
          </TableBody>
        </Fragment>
      ));
    });
    return tables;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row-reverse', flexGrow: 1 }}>
      <Dialog
        maxWidth="sm"
        open={modal}
        onClose={toggleModal}
        fullWidth={true}
      >
        <div style={{ height: '200px', margin: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ fontWeight: '600', margin: '20px' }}>Are you sure you would like to delete this appointment?</div>
          <div style={{ margin: '20px' }}>{currentAppointment.string}</div>
          <div style={{ display: 'flex', margin: '20px' }}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => { cancelAppt(); toggleModal(false); }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => toggleModal(false)}
            >
              No
            </Button>
          </div>
        </div>
      </Dialog>
      <div style={{ flexGrow: 1, paddingRight: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 style={{ marginLeft: '10px' }}>{moment(month).format('MMMM YYYY')}</h1>
          <h1>
            <ToggleButtonGroup exclusive aria-label="text alignment">
              <ToggleButton
                onClick={() => changeToggle(!toggle)}
                value="left"
                aria-label="day"
                selected={toggle}
                style={{
                  color: toggle ? '#000000' : '#d8d8d8',
                  backgroundColor: toggle ? '#ffffff' : '#f5f5f5',
                  fontWeight: toggle ? 700 : 400,
                }}
              >
                Day
              </ToggleButton>
              <ToggleButton
                onClick={() => changeToggle(!toggle)}
                value="right"
                aria-label="week"
                selected={!toggle}
                style={{
                  color: !toggle ? '#000000' : '#d8d8d8',
                  backgroundColor: !toggle ? '#ffffff' : '#f5f5f5',
                  fontWeight: !toggle ? 700 : 400,
                }}
              >
                Week
              </ToggleButton>
            </ToggleButtonGroup>
          </h1>
        </div>
        <TableContainer
          component={Paper}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              height: '676px',
              display: 'flex',
              marginLeft: '10px',
            }}
          >
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>{column}</TableHead>
              {buildTables()}
            </Table>
          </div>
        </TableContainer>
      </div>
      <div style={{ backgroundColor: 'lightgrey', padding: '10px' }}>
        <h2>Calendar</h2>
        <main style={{ width: '250px' }}>
          <Calendar
            onClickDay={e => changeMonth(e)}
            onChange={(date) => { changeDate(date); changeEditDate(moment(date)); }}
            value={date}
            className={classes.calendarBox}
            tileClassName={classes.dayTile}
          />
        </main>
      </div>
    </div>
  );
}

TelemedSchedule.defaultProps = {
  appointmentRows: [],
  providers: [],
};

TelemedSchedule.propTypes = {
  appointmentRows: PropTypes.array,
  router: PropTypes.object.isRequired,
  clinic_id: PropTypes.number.isRequired,
  providers: PropTypes.array,
};
