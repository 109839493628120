import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import MoreIcon from '@material-ui/icons/More';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    background: 'white',
    color: 'black',
  },
});

const TruncText = (props) => {
  const { content, defaultMessage, maxLength } = props;
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const classes = useStyles();

  const addTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.opacity = 0;
    textArea.style.height = 1;
    textArea.style.width = 1;

    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    setSnackbarOpen(true);
  };

  if (!content) {
    return <Fragment>{defaultMessage}</Fragment>
  }
  let icon = null;
  let abbreviatedText = content.substring(0, maxLength);

  if (content.length > abbreviatedText.length) {
    abbreviatedText = `${abbreviatedText}...`;
    icon = (
      <Tooltip title={<Typography>{content}</Typography>}>
        <MoreIcon
          onClick={() => addTextToClipboard(content)}
        />
      </Tooltip>
    );
  }

  return (
    <Fragment>
      {abbreviatedText} {icon}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <SnackbarContent
          classes={{ root: classes.root }}
          message="Results copied to clipboard"
          action={
            <IconButton
              size="small"
              aria-label="close"
              onClick={() => setSnackbarOpen(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Snackbar>
    </Fragment>
  );
};


TruncText.defaultProps = {
  content: '',
  defaultMessage: 'None Listed',
  maxLength: 70,
}

TruncText.propTypes = {
  content: PropTypes.string,
  defaultMessage: PropTypes.string,
  maxLength: PropTypes.number,
}

export default TruncText;