import config from '../config';

export const xhr = {
    xhrData: function (formData, path, progressCallback, finishedCallback) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${config.API_URL}/${path}`);
        xhr.withCredentials = true;
        xhr.upload.addEventListener('progress', function (event) {
            progressCallback(event);
        });
        xhr.send(formData);
        xhr.onload = function () {
            if (xhr.status === 200) {
                finishedCallback(xhr.status);
            } else {
                finishedCallback(xhr.status);
            }
        }
    }
} 
