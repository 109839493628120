import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';

const styles = {
  deviceLabel: {
    fontWeight: 'bold',
  },
};

function DeviceDetail(props) {
  const [visible, setVisible] = useState(false);
  const { agent } = props;
  if (visible) {
    return (
      <table>
        <tbody>
          <tr>
            <td />
            <td align="right"><CloseIcon onClick={() => setVisible(false)} /></td>
          </tr>
          <tr>
            <td style={styles.deviceLabel}>OS</td>
            <td>{agent.os.name} {agent.os.version}</td>
          </tr>
          <tr>
            <td style={styles.deviceLabel}>Browser</td>
            <td>{agent.browser.name} {agent.browser.version}</td>
          </tr>
          <tr>
            <td style={styles.deviceLabel}>Engine</td>
            <td>{agent.engine.name} {agent.engine.version}</td>
          </tr>
          <tr>
            <td style={styles.deviceLabel}>Device</td>
            <td>{agent.device.type} {agent.device.vendor} {agent.device.model}</td>
          </tr>
          <tr>
            <td style={styles.deviceLabel}>CPU</td>
            <td>{agent.cpu.architecture}</td>
          </tr>
        </tbody>
      </table>
    );
  }
  return (
    <InfoIcon onClick={() => setVisible(true)} />
  );
}

DeviceDetail.propTypes = {
  agent: PropTypes.object.isRequired,
};

export default DeviceDetail;
