import formatGIOncology from './pro-formatters/gi-oncology';
import formatRespiratoryOncology from './pro-formatters/respiratory-oncology';
import formatNeutropenia from './pro-formatters/neutropenia';
import formatGad7 from './pro-formatters/gad7';
import bloomkidzNewClient from './pro-formatters/bloomkidz-new-client';
import formatBloomkidzMealAndNutrition from './pro-formatters/bloomkidz-meal-and-nutrition';
import bloomkidzClientWellnessProfile from './pro-formatters/bloomkidz-client-wellness-profile';
import bloomkidzMedicalHistory from './pro-formatters/bloomkidz-medical-history';
import bloomkidzParentGuardianInformation from './pro-formatters/bloomkidz-parent-guardian-information';
import bloomkidzSignatures from './pro-formatters/bloomkidz-signatures';
import ivcFormatters from './pro-formatters/ivc';
import IvcFormattersForCsvExport from './pro-formatters/ivc-for-csv-export';
import dataCollectionFormatters from './pro-formatters/data-collection';
import config from '../config';

const formatters = {
  'OFFLINE-DATA-COLLECTION': dataCollectionFormatters.OFFLINE,
  'VITALS-DATA-COLLECTION': dataCollectionFormatters.VITALS,
  'FULL-DATA-COLLECTION': dataCollectionFormatters.FULL,
  'GI-ONCOLOGY': formatGIOncology,
  'RESPIRATORY-ONCOLOGY': formatRespiratoryOncology,
  NEUTROPENIA: formatNeutropenia,
  GAD7: formatGad7,
  'BLOOMKIDZ-NEW-CLIENT': bloomkidzNewClient,
  'BLOOMKIDZ-MEAL-AND-NUTRITION': formatBloomkidzMealAndNutrition,
  'BLOOMKIDZ-CLIENT-WELLNESS-PROFILE': bloomkidzClientWellnessProfile,
  'BLOOMKIDZ-MEDICAL-HISTORY': bloomkidzMedicalHistory,
  'BLOOMKIDZ-PARENT-GUARDIAN-INFORMATION': bloomkidzParentGuardianInformation,
  'BLOOMKIDZ-SIGNATURES': bloomkidzSignatures,
  'IVC-PR-CLINICAL-STUDY': ivcFormatters.IVC_PR_CLINICAL_STUDY,
  'VITAL-CORE': ivcFormatters.VITAL_CORE,
  'VITAL-CORE-BENCH-HR': ivcFormatters.VITAL_CORE_BENCH_HR,
  'VITAL-CORE-RR': ivcFormatters.VITAL_CORE_RR,
  'IVC-RR-CLINICAL-STUDY': ivcFormatters.IVC_RR_CLINICAL_STUDY,
};

const formattersForCsvExport = {
  'VITAL-CORE-RR': IvcFormattersForCsvExport.VITAL_CORE_RR,
  'IVC-RR-CLINICAL-STUDY': IvcFormattersForCsvExport.IVC_RR_CLINICAL_STUDY,
};

function format(pro, forCsvExport = false) {
  const {
    completion_date,
    created_at,
    locked_out_at,
    lockout_duration,
  } = pro;
  const proCreatedAt = new Date(created_at).getTime();
  const now = Date.now();

  let proFormatters = formatters;

  if (forCsvExport) {
    proFormatters = { ...proFormatters, ...formattersForCsvExport };
  }

  // check if pro has expired (created more than 3 days again and not completed)
  if (!completion_date && (now - proCreatedAt > config.notificationLifetime)) {
    pro.score.score = 'Link Expired';
    return pro;
  }

  // check if authentication with pro's notification is locked
  if (locked_out_at && lockout_duration) {
    const proLockedOutAt = new Date(locked_out_at).getTime();
    const proLockoutDuration = Number(lockout_duration);
    if (proLockedOutAt + proLockoutDuration > now) {
      let lockoutDurationMessage;
      if (proLockoutDuration === 900000) {
        lockoutDurationMessage = '15-minute';
      } else if (proLockoutDuration === 3600000) {
        lockoutDurationMessage = '60-minute';
      } else {
        lockoutDurationMessage = 'indefinite';
      }

      pro.score.score = `DOB FAILURE - ${lockoutDurationMessage} delay`;
      return pro;
    }
  }

  if (proFormatters[pro.pro_type] && pro.pro_data) {
    try {
      return proFormatters[pro.pro_type](pro);
    } catch (e) {
      console.error('formatting error', e, pro); // eslint-disable-line no-console
      return pro;
    }
  }
  return pro;
}

export default format;
