import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../lib/styles';

const styles = {
  container: {
    backgroundColor: colors.white,
    //    marginTop: '10px',
    borderLeft: `1px solid ${colors.boxShadowOutline}`,
    borderTop: `1px solid ${colors.boxShadowOutline}`,
    borderRight: `2px solid ${colors.boxShadowOutline}`,
    borderBottom: `2px solid ${colors.boxShadowOutline}`,
  },
};

class PatientViewContentSection extends PureComponent {
  render() {
    return (
      <div className={this.props.classes.container}>
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(styles)(PatientViewContentSection);
