import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { colors } from '../lib/styles';

const baseStyles = {
  formListItem: {
    display: 'flex',
    color: colors.highlightDarker,
    padding: '5px',
    fontSize: '.85em',
    cursor: 'pointer',
  },
  iconStyles: {
    marginRight: '5px',
    color: colors.primaryColor,
  },
  link: {
    textDecoration: 'none',
  },
};

// Destructuring and using the icon here doesn't work
// where as calling it from this.props.icon does
export default function FormLink(props) {
  const { isSubmission, urlPath, name } = props;
  return (
    <Link
      to={`/app/form${isSubmission ? '-submissions' : 's'}/${urlPath}`}
      href={`/app/forms/${urlPath}`}
      style={baseStyles.link}
    >
      <li style={baseStyles.formListItem}>
        <props.icon style={baseStyles.iconStyles} />
        {name}
      </li>
    </Link>
  );
}

FormLink.defaultProps = {
  isSubmission: false,
};

FormLink.propTypes = {
  icon: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  urlPath: PropTypes.string.isRequired,
  isSubmission: PropTypes.bool,
};

