import React, { Component } from 'react';
import { connect } from 'react-redux';
import rawr from 'rawr';
import transport from 'rawr/transports/worker';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';

import { colors } from '../lib/styles';
import { scanPatient, clearPatientScanner } from '../state/appointments';
import { closeModal  } from '../state/modal';

const baseStyles = {
  container: {},
  cameraInstructions: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: '#323232',
    color: colors.white,
    padding: '18px',
    borderRadius: '2px',
    margin: '1px',
  },
  scanner: {
    width: '100%',
    transform: 'rotateY(180deg)'
  },
  error: {
    color: 'red',
    margin: '5px',
  },
  message: {
    margin: '5px',
  },
};

const rawrPeer = rawr({transport: transport(new Worker('/workers/scan-decoder.js'))});
const { detectUrl } = rawrPeer.methods;

let ready = false;
let lastScan = Date.now();
let lastQR;
const TIME_BETWEEN_SCANS = 1000 * 10;

rawrPeer.notifications.onready(() => {
  ready = true;
});

function decode (context) {
  if(!ready) {
    return Promise.resolve(null);
  }
  let canvas = context.canvas;
  let width = canvas.width;
  let height = canvas.height;
  let imageData = context.getImageData(0, 0, width, height);
  return detectUrl(width, height, imageData);
};

class CheckIn extends Component {

  constructor(props) {
    super(props);
    this.vidRef = React.createRef();
  }

  handleEntered = () => {
    const { props } = this;
    props.clearPatientScanner();
    navigator.mediaDevices.getUserMedia({video: true})
    .then((stream) => {
      const vid = this.vidRef.current;
      vid.srcObject = stream;
      this.stream = stream;

      const cameraCanvas = document.createElement('canvas');

      vid.addEventListener('loadeddata', (e) => {
        cameraCanvas.height = vid.videoHeight;
        cameraCanvas.width = vid.videoWidth;
        vid.height = vid.videoHeight;
        vid.width = vid.videoWidth;
        const ctx = cameraCanvas.getContext('2d');
        const onframe = async () => {
          if(vid.videoWidth > 0) {
            ctx.drawImage(vid, 0, 0, vid.videoWidth, vid.videoHeight);
            decode(ctx)
            .then((bc) => {
              if(bc) {
                if ((lastQR !== bc.rawValue) || (Date.now() - lastScan > TIME_BETWEEN_SCANS)) {
                  props.scanPatient(bc.rawValue, this.handleClose)
                  .catch((error) => {
                    // This shouldn't happen, but a 500 on the server could get passed normal handling
                  })
                  .then(() => {
                    if(this.props.modal.checkInModalOpen) {
                      requestAnimationFrame(onframe);
                    }
                  });
                }

                lastQR = bc.rawValue;
                lastScan = Date.now();

                if(this.props.modal.checkInModalOpen) {
                  requestAnimationFrame(onframe);
                }

              } else if(this.props.modal.checkInModalOpen) {
                requestAnimationFrame(onframe);
              }
            });
          }
          
        };

        requestAnimationFrame(onframe);
      });

      vid.play();
      
    });
  }

  handleClose = () => {
    this.props.closeModal();
    try {
      if(this.stream && this.stream.getTracks()[0]) {
        this.stream.getTracks()[0].stop();
      }
    }
    catch(err){}
  }

  render() {
    const { props } = this;

    return (
      <div style={baseStyles.container}>
        <Snackbar
          key="snacky-scan-error"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(props.appointments.scanPatientError)}
          autoHideDuration={6000}
          onClose={props.onCloseError}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">An Error Occured, Please Try a Different QR Code.</span>}
        />
        <Dialog
          maxWidth="sm"
          open={props.modal.checkInModalOpen}
          onClose={this.handleClose}
          fullWidth={true}
          TransitionProps={{
            onEntered: this.handleEntered
          }}
        >
          <video ref={this.vidRef} muted style={baseStyles.scanner}></video>
          <div style={baseStyles.cameraInstructions}>Point at a QR code</div>
          <div style={baseStyles.message}>{props.appointments.scanPatientPending ? 'validating...' : ' '}</div>
        </Dialog>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { appointments, modal } = state;
  return { appointments, modal };
}

export default connect(mapStateToProps, { scanPatient, clearPatientScanner, closeModal })(CheckIn);
