import { promiseHandler, resetReducer } from 'cooldux';
import { isUndefined, omitBy, keyBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'surgeryHx');
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', 'surgeryHx');
const { editStart, editEnd, editError, editHandler } = promiseHandler('edit', 'surgeryHx');

export function browseSurgeryHx(userId) {
  return function dispatcher(dispatch, getState) {
    const promise = apiFetch(`/users/${userId}/surgery_hx`);
    return browseHandler(promise, dispatch);
  };
}

export function addSurgeryHx(userId, newSurgeryHx) {
  return function dispatcher(dispatch, getState) {
    const options = {
      method: 'POST',
      body: omitBy(newSurgeryHx, isUndefined),
    };
    const promise = apiFetch(`/users/${userId}/surgery_hx`, options);
    return addHandler(promise, dispatch);
  };
}

export function editSurgeryHx(userId, surgeryId, body) {
  return function dispatcher(dispatch, getState) {
    const options = {
      method: 'PUT',
      body,
    };
    const promise = apiFetch(`/users/${userId}/surgery_hx/${surgeryId}`, options);
    return editHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  addError: null,
  browsePending: false,
  addPending: false,
  data: {},
};

function finishBrowse(state, surgeryHx) {
  const actualSurgeries = omitBy(surgeryHx, { surgery_id: 1 }); // omits 'None Reported'
  const data = keyBy(actualSurgeries, 'id');
  return { ...state, data, browsePending: false, browseError: null };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, addPending: false, addError: null, data };
}

function finishEdit(state, item) {
  const oldItem = state.data[item.id];
  const data = { ...state.data, [item.id]: {...oldItem, ...item } };
  return { ...state, editPending: false, editError: null, data };
}

const surgeryHx = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true, browseError: null, data: {} };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload, data: {} };
    case addStart.type:
      return { ...state, addPending: true, addError: null };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, editPending: false, editError: action.payload };
    case editStart.type:
      return { ...state, editPending: true, editError: null };
    case editEnd.type:
      return finishEdit(state, action.payload);
    case editError.type:
      return { ...state, editPending: false, editError: action.payload };
    default:
      return state;
  }
});

export default surgeryHx;
