import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useSWR from 'swr';

import AppWrapper from '../wrappers/app-wrapper';
import Schedule from '../components/schedule';
import { apiFetch } from '../lib/fetch';
import {
  createAppointment,
  fetchAppointments,
  updateAppointment,
  updatePatient,
} from '../state/appointments';

const TeleSchedule = (props) => {
  const { clinicId } = props;
  const { data } = useSWR(`/clinics/${clinicId}/scheduled_telemedicine_calls`, apiFetch, { refreshInterval: 2000, compare: () => false });
  const { data: providers } = useSWR(`/clinics/${clinicId}/providers`, apiFetch);

  return (
    <AppWrapper router={props.router} iconSidebar={true}>
      <Schedule
        clinic_id={clinicId}
        onUpdateAppointment={updateAppointment}
        onUpdatePatient={updatePatient}
        appointmentRows={data}
        providers={providers}
        router={props.router}
      />
    </AppWrapper>
  );
};

const actionCreators = {
  createAppointment,
  fetchAppointments,
  updateAppointment,
  updatePatient,
};

function mapStateToProps(state) {
  const {
    clinic: { clinicId },
  } = state;
  return {
    clinicId,
  };
}

TeleSchedule.propTypes = {
  router: PropTypes.object.isRequired,
  clinicId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, actionCreators)(TeleSchedule);
