import React from 'react';
import { get, filter, map } from 'lodash';
import useSWR from 'swr';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { apiFetch } from '../lib/fetch';
import { browseProTypes } from '../state/app-data';
import CampaignEvent from './campaign-event';
import ButtonWithProgress from './button-with-progress';

const styles = {
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  root: {
    marginBottom: '150px',
    padding: '25px',
    textAlign: 'left',
    width: '100%',
  },
};

const baseEvent = { delay: '', event_type: '', voice_broadcast_id: '', video_broadcast_id: '', time: null, bundled_events: [] };
const Campaign = (props) => {
  const { classes, clinicId } = props;
  const [name, setName] = React.useState('');
  const { data: voiceBroadcastTypes } = useSWR(`/clinics/${clinicId}/voice_broadcasts`, apiFetch);
  const { data: videoBroadcastTypes } = useSWR(`/clinics/${clinicId}/video_broadcasts`, apiFetch);
  const { data: bundledEventTypes } = useSWR(`/clinics/${clinicId}/bundled_events`, apiFetch);
  const [events, setEvents] = React.useState([{ ...baseEvent }]);
  const [enabled, setEnabled] = React.useState(null);
  const [togglingEnabled, setTogglingEnabled] = React.useState(false);

  React.useEffect(() => { props.browseProTypes() });
  React.useEffect(() => {
    if (!props.campaign) return;
    setEvents(get(props.campaign, 'campaign_spec.schedule'));
    setName(get(props.campaign, 'name'));
    setEnabled(props.campaign.enabled);
  }, [props.campaign]);

  const allEventsValid = events.reduce((valid, event) => {
    if (!valid || event.delay === '' || !event.event_type) {
      return false;
    }

    if(event.event_type === 'VOICE_BROADCAST' && !event.voice_broadcast_id) {
      return false;
    }

    if(event.event_type === 'VIDEO_BROADCAST' && !event.video_broadcast_id) {
      return false;
    }

    return true;
  }, true);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleAddEvent = () => {
    setEvents([...events, { ...baseEvent }]);
  };
  const handleRemoveEvent = (position) => {
    const filteredEvents = filter(events, (item, idx) => {
      if (idx === position) {
        return false;
      }
      return true;
    });
    setEvents(filteredEvents);
  };

  const handleEditEvent = (position, update) => {
    const newEvents = [...events];
    newEvents[position] = update;
    setEvents(newEvents);
  };

  const handleSave = async () => {
    await props.onSave(name, events);
  };

  const handleToggleEnabled = async () => {
    setTogglingEnabled(true);

    try {
      const res = await props.onToggleEnabled(!enabled);
      setEnabled(res.enabled);
      setTogglingEnabled(false);
    } catch (e) {
      setTogglingEnabled(false);
    }
  };

  let totalDays = 0;
  const displayedEvents = events.map((e, idx) => {
    totalDays += Number(e.delay);
    const totalDelay = totalDays;
    return (
      <CampaignEvent
        bundledEventTypes={bundledEventTypes || []}
        campaignEvent={e}
        key={idx}
        onEditEvent={handleEditEvent}
        onRemoveEvent={handleRemoveEvent}
        position={idx}
        proTypes={props.proTypes}
        totalDelay={totalDelay}
        videoBroadcastTypes={videoBroadcastTypes || []}
        voiceBroadcastTypes={voiceBroadcastTypes || []}
      />
    );
  });

  return (
    <div className={classes.root}>
      <div>
        <h1 className={classes.headerTitle}>{props.headerText}</h1>
        <TextField
          value={name}
          onChange={handleChange}
          label="Care Pathway Name"
        />
        <ol>
          {displayedEvents}
        </ol>
        <div style={{ display: 'flex' }}>
          <Button color="primary" onClick={handleAddEvent} variant="contained">Add an Event</Button>
          <Button color="primary" disabled={!allEventsValid || !name} onClick={handleSave} variant="contained">{props.saveText}</Button>
          {props.onToggleEnabled && enabled !== null ? (
            <ButtonWithProgress
              onClick={handleToggleEnabled}
              inProgress={togglingEnabled}
            >
              {enabled ? 'Disable' : 'Enable'}
            </ButtonWithProgress>
          ) : null}
        </div>

      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    appData: { proTypes },
    clinic: { clinicId },
  } = state;

  return {
    clinicId,
    proTypes: map(proTypes, p => p.pro_type).sort(),
  };
}

Campaign.defaultProps = {
  campaign: null,
  headerText: 'Create a Care Pathway',
  saveText: 'Save Care Pathway',
  proTypes: [],
  onToggleEnabled: null,
};
Campaign.propTypes = {
  browseProTypes: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clinicId: PropTypes.number.isRequired,
  campaign: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  proTypes: PropTypes.array,
  saveText: PropTypes.string,
  onToggleEnabled: PropTypes.func,
};

export default connect(mapStateToProps, { browseProTypes })(withStyles(styles)(Campaign));
