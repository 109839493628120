import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    margin: '10px',
  },
  primaryText: {
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    minWidth: '40%',
    textAlign: 'left',
  },
  secondaryText: {
    color: '#66676C',
    textAlign: 'left',
  },
};

class EmrListItem extends PureComponent {
  render() {
    const { props } = this;

    return (
      <div className={props.classes.container}>
        {props.leftCheckbox}
        <div className={props.classes.textContainer}>
          <div className={props.classes.primaryText}>{props.primaryText}</div>
          {!props.secondaryTextHidden && <div className={props.classes.secondaryText}>{props.secondaryText || '---' }</div>}
        </div>
      </div>
    );
  }
};

EmrListItem.defaultProps = {
  leftCheckbox: null,
  secondaryTextHidden: false,
};

EmrListItem.propTypes = {
  leftCheckbox: PropTypes.node,
  primaryText: PropTypes.string.isRequired,
  secondayText: PropTypes.string,
  secondaryTextHidden: PropTypes.bool,
};

export default withStyles(styles)(EmrListItem);
