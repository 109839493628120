import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const calendarPainColors = {
  painLevel1: '#309940',
  painLevel2: '#eea321',
  painLevel3: '#e54353',
};

const styles = {
  calendar: {
    color: '#2c3135',
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 12,
    margin: '0 20px',
  },
  calendarItem: {
    alignItems: 'center',
    borderRadius: 3,
    bottom: 8,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  calendarItemWrapper: {
    boxSizing: 'border-box',
    position: 'relative',
    width: `${(1 / 7) * 100}%`,
  },
  dayNames: {
    color: '#7f8fa4',
  },
  heightExpander: {
    paddingBottom: 'calc(100% + 8px)',
  },
  otherMonthDays: {
    background: '#edf2f5',
    color: '#bac2c7',
  },
  spaceOnRight: {
    right: 1,
  },
  painLevel1: {
    background: calendarPainColors.painLevel1,
    color: 'white',
  },
  painLevel2: {
    background: calendarPainColors.painLevel2,
    color: 'white',
  },
  painLevel3: {
    background: calendarPainColors.painLevel3,
    color: 'white',
  },
  highlighted: {
    background: '#8ac1ff',
  },
};

class CalendarCurrentMonthItem extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClickDay = this.handleClickDay.bind(this);
  }
  handleClickDay() {
    this.props.onClickDay(this.props.formattedDate);
  }
  render() {
    const {
      day,
      dayCount,
      painLevel,
      selectedDateColor,
      highlighted,
    } = this.props;
    const spaceOnRight = (dayCount % 7) ? styles.spaceOnRight : {};
    let painLevelStyles;

    if (painLevel === 0 || painLevel === undefined) {
      painLevelStyles = {};
    } else if (painLevel < 4) {
      painLevelStyles = styles.painLevel1;
    } else if (painLevel < 8) {
      painLevelStyles = styles.painLevel2;
    } else {
      painLevelStyles = styles.painLevel3;
    }

    const borderProperties = {};
    if (this.props.isSelected) {
      borderProperties.border = `3px solid ${selectedDateColor}`;
      borderProperties.borderRadius = '6px';
    }

    const highlightedStyles = highlighted ? styles.highlighted : {};

    const calendarItemStyles = {
      ...highlightedStyles,
      ...painLevelStyles,
      ...styles.calendarItem,
      ...spaceOnRight,
      color: 'black',
      textDecoration: 'none',
      cursor: this.props.showHover ? 'pointer' : 'auto',
      ...borderProperties,
    };

    return (
      <div
        onClick={this.handleClickDay}
        style={styles.calendarItemWrapper}
      >
        <div style={styles.heightExpander} />
        <div style={calendarItemStyles}>
          {day}
        </div>
      </div>
    );
  }
}

CalendarCurrentMonthItem.defaultProps = {
  painLevel: 0,
  showHover: false,
  selectedDateColor: '',
};

CalendarCurrentMonthItem.propTypes = {
  day: PropTypes.number.isRequired,
  dayCount: PropTypes.number.isRequired,
  formattedDate: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClickDay: PropTypes.func.isRequired,
  painLevel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showHover: PropTypes.bool,
  selectedDateColor: PropTypes.string,
  highlighted: PropTypes.bool.isRequired,
};

export default CalendarCurrentMonthItem;
