export default (pro) => {
  const { data } = pro.pro_data;

  const {
    guardianOneFirstName,
    guardianOneLastName,
    guardianOneEmail,
    guardianOneTelephone,
    guardianOneCell,
    guardianOneAddress,
    guardianOneCity,
    guardianOneState,
    guardianOneZipcode,
    guardianOneEducation,
    guardianOneOccupation,
    guardianOneContactMethod,
    guardianTwoFirstName,
    guardianTwoLastName,
    guardianTwoEmail,
    guardianTwoTelephone,
    guardianTwoCell,
    guardianTwoAddress,
    guardianTwoCity,
    guardianTwoState,
    guardianTwoZipcode,
    guardianTwoEducation,
    guardianTwoOccupation,
    guardianTwoContactMethod,
    maritalStatus,
    maritalStatusOther,
    whoHasPrimaryCustody,
    legalSituationAffectingServices,
    legalSituationAffectingServicesExplanation,
    emergencyContactFirstName,
    emergencyContactLastName,
    emergencyContactNumber,
    emergencyContactRelationship,
    siblings,
    siblingsDiagnosis,
    siblingsServices,
    changesAtHome,
    changesAtHomeExplanation,
    primaryInsuranceCompany,
    primaryInsurancePlan,
    primaryInsuranceHolderName,
    primaryInsuranceHolderBirthday,
    primaryInsuranceHolderSsn,
    primaryInsuranceHolderMemberId,
    primaryInsuranceHolderGroupId,
    hasSecondaryInsurance,
    hasAHCCCSorALTCS,
    secondaryInsuranceCompany,
    secondaryInsurancePlan,
    secondaryInsuranceHolderName,
    secondaryInsuranceHolderBirthday,
    secondaryInsuranceHolderSsn,
    secondaryInsuranceHolderMemberId,
    secondaryInsuranceHolderGroupId,
    employerName,
    employerAddress,
    employerCity,
    employerState,
    employerZipcode,
  } = data;

  const s = [];

  s.push(`Guardian 1 first name: ${guardianOneFirstName}`);
  s.push(`Guardian 1 last name: ${guardianOneLastName}`);
  s.push(`Guardian 1 email: ${guardianOneEmail}`);
  s.push(`Guardian 1 cell number: ${guardianOneCell}`);
  if (guardianOneTelephone) s.push(`Guardian 1 telephone number: ${guardianOneTelephone}`);
  s.push(`Guardian 1 address: ${guardianOneAddress}`);
  s.push(`Guardian 1 city: ${guardianOneCity}`);
  s.push(`Guardian 1 state: ${guardianOneState}`);
  s.push(`Guardian 1 zipcode: ${guardianOneZipcode}`);
  s.push(`Guardian 1 education: ${guardianOneEducation}`);
  s.push(`Guardian 1 occupation: ${guardianOneOccupation}`);
  s.push(`Guardian 1 contact method: ${guardianOneContactMethod}`);

  if (guardianTwoFirstName && guardianTwoLastName && guardianTwoEmail && guardianTwoCell) {
    s.push(`Guardian 2 first name: ${guardianTwoFirstName}`);
    s.push(`Guardian 2 last name: ${guardianTwoLastName}`);
    s.push(`Guardian 2 email: ${guardianTwoEmail}`);
    s.push(`Guardian 2 cell number: ${guardianTwoCell}`);
    if (guardianTwoTelephone) s.push(`Guardian 2 telephone number: ${guardianTwoTelephone}`);
    s.push(`Guardian 2 address: ${guardianTwoAddress}`);
    s.push(`Guardian 2 city: ${guardianTwoCity}`);
    s.push(`Guardian 2 state: ${guardianTwoState}`);
    s.push(`Guardian 2 zipcode: ${guardianTwoZipcode}`);
    s.push(`Guardian 2 education: ${guardianTwoEducation}`);
    s.push(`Guardian 2 occupation: ${guardianTwoOccupation}`);
    s.push(`Guardian 2 contact method: ${guardianTwoContactMethod}`);
  }

  s.push(`Marital status: ${maritalStatus}`);
  if (maritalStatusOther) s.push(`Marital status explanation: ${maritalStatusOther}`);
  if (whoHasPrimaryCustody) s.push(`Primary custody: ${whoHasPrimaryCustody}`);
  s.push(`Legal status affecting services: ${legalSituationAffectingServices ? 'Y' : 'N'}`);
  // eslint-disable-next-line max-len
  if (legalSituationAffectingServices && legalSituationAffectingServicesExplanation) s.push(`Concerns: ${legalSituationAffectingServicesExplanation}`);

  s.push(`Emergency Contact first name: ${emergencyContactFirstName}`);
  s.push(`Emergency Contact last name: ${emergencyContactLastName}`);
  s.push(`Emergency Contact phone number: ${emergencyContactNumber}`);
  s.push(`Emergency Contact relationship: ${emergencyContactRelationship}`);

  if (siblings.length) {
    let siblingStr = '';
    siblings.forEach((sibling) => {
      // eslint-disable-next-line max-len
      siblingStr = `${siblingStr}${siblingStr ? '; ' : ''} ${sibling.name}, ${sibling.age}`;
    });
    s.push(`siblings: ${siblingStr}`);
  } else {
    s.push('siblings: N');
  }
  s.push(`Sibling diagnosis: ${siblingsDiagnosis}`);
  s.push(`Sibling services: ${siblingsServices}`);

  s.push(`Changes at home: ${changesAtHome ? 'Y' : 'N'}`);
  if (changesAtHome && changesAtHomeExplanation) s.push(`Changes at home expanation: ${changesAtHomeExplanation}`);

  s.push(`Primary insurance company: ${primaryInsuranceCompany}`);
  s.push(`Primary insurance plan: ${primaryInsurancePlan}`);
  s.push(`Primary insurance holder name: ${primaryInsuranceHolderName}`);
  s.push(`Primary insurance holder birthday: ${primaryInsuranceHolderBirthday}`);
  s.push(`Primary insurance holder ssn: ${primaryInsuranceHolderSsn}`);
  s.push(`Primary insurance member id: ${primaryInsuranceHolderMemberId}`);
  s.push(`Primary insurance group id: ${primaryInsuranceHolderGroupId}`);
  s.push(`Secondary insurance: ${hasSecondaryInsurance ? 'Y' : 'N'}`);
  s.push(`AHCCCS or ALTCS: ${hasAHCCCSorALTCS ? 'Y' : 'N'}`);

  if (hasSecondaryInsurance) {
    s.push(`Secondary insurance company: ${secondaryInsuranceCompany}`);
    s.push(`Secondary insurance plan: ${secondaryInsurancePlan}`);
    s.push(`Secondary insurance holder name: ${secondaryInsuranceHolderName}`);
    s.push(`Secondary insurance holder birthday: ${secondaryInsuranceHolderBirthday}`);
    s.push(`Secondary insurance holder ssn: ${secondaryInsuranceHolderSsn}`);
    s.push(`Secondary insurance member id: ${secondaryInsuranceHolderMemberId}`);
    s.push(`Secondary insurance group id: ${secondaryInsuranceHolderGroupId}`);
  }

  s.push(`Employer name: ${employerName}`);
  s.push(`Employer address: ${employerAddress}`);
  s.push(`Employer city: ${employerCity}`);
  s.push(`Employer state: ${employerState}`);
  s.push(`Employer zipcode: ${employerZipcode}`);

  pro.score.score = s.join(' | ');
  return pro;
};
