import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';

import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const formattedCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const useStyles = makeStyles({
  totalsCard: {
    borderRadius: 4,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250,
  },
  totalsSection: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  greyText: {
    color: '#959595',
  },
  root: {
    display: 'flex',
  },
  timeValue: {
    fontSize: '2rem',
  },
  totalTime: {
    background: 'black',
    borderRadius: 4,
    color: 'white',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& th': {
      borderCollapse: 'collapse',
      padding: 5,
    },
    '& td': {
      borderCollapse: 'collapse',
      padding: 5,
    },
    tableLayout: 'fixed',
  },
  thead: {
    background: 'black',
    color: 'white',
  },
  exportButton: {
    backgroundColor: colors.primaryColor,
    border: `1px solid ${colors.primaryColor}`,
    color: colors.white,
    paddingLeft: 5,
    paddingRight: 5,
  },
  monthlySummaryDurationCol: {
    textAlign: 'right',
    width: 120,
  },
  monthlySummaryDownloadCsvCol: {
    width: 80,
  },
  monthlySummaryShowDetailsCol: {
    width: 110,
  },
  monthlySummaryDurationRow: {
    textAlign: 'right',
  },
  monthlySummaryDownloadCsvRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  monthlySummaryNameRow: {
    alignItems: 'center',
    display: 'flex',
  },
  showDetailsBtn: {
    background: 'none',
    border: 'none',
    color: colors.primaryColor,
    cursor: 'pointer',
    padding: 0,
  },
  downloadCsvIcon: {
    cursor: 'pointer',
  },
  hideDetailsBtn: {
    background: 'none',
    border: 'none',
    color: colors.errorRed,
    cursor: 'pointer',
    padding: 0,
  },
  startStopCols: {
    width: 80,
  },
  patientTotalDurationCol: {
    width: 270,
  },
  patientExpandCol: {
    width: 30,
  },
  tableGrey: {
    border: 'solid 1px #d8d8d8',
    marginTop: 10,
    '& td': {
      padding: 10,
    },
  },
  tableGreyHeader: {
    background: '#d8d8d8',
    color: 'black',
  },
  tableGreyRow: {
    borderTop: 'solid 1px #d8d8d8',
  },
  tableBlack: {
    border: 'solid 1px black',
    '& td': {
      padding: 10,
    },
  },
  tableBlackRow: {
    borderTop: 'solid 1px black',
  },
  bold: {
    fontWeight: 'bold',
  },
  avatar: {
    background: 'none',
    border: 'solid 1px black',
    color: 'black',
    marginRight: 5,
  },
});

const exportCsv = (report, startOfMonth) => {
  const dateStr = startOfMonth.format('YYYY-MM');

  const downloadTag = document.createElement('a');
  downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(report));
  downloadTag.setAttribute('download', `invoice_${dateStr}.csv`);
  downloadTag.style.display = 'none';

  document.body.appendChild(downloadTag);

  downloadTag.click();

  document.body.removeChild(downloadTag);
};

const exportPerEventInvoice = (invoice, startOfMonth) => {
  const { invoiceData, totals } = invoice;

  // aggregate data
  const aggTitle = 'Totals';
  const aggHeaders = 'Appointments,Pro Submissions,Vitals,Total Events,Cost';
  const aggRow = `${totals.appointments},${totals.proSubmissions},${totals.vitals},${totals.events},${formattedCurrency.format(totals.cost)}`; // eslint-disable-line max-len
  const aggReport = [aggTitle, aggHeaders, aggRow].join('\n');

  // appointments
  const appointmentsTitle = 'Appointments';
  const appointmentsHeaders = 'Patient,Recorded At';
  const appointmentsRows = [];
  invoiceData.appointments.forEach((appointment) => {
    appointmentsRows.push(`${appointment.cell_phone},${appointment.recorded_at}`);
  });
  const appointmentsReport = [appointmentsTitle, appointmentsHeaders, ...appointmentsRows].join('\n');

  // vitals
  const vitalsTitle = 'Vitals';
  const vitalsHeaders = 'Patient,Recorded At';
  const vitalsRows = [];
  invoiceData.vitals.forEach((vital) => {
    vitalsRows.push(`${vital.cell_phone},${vital.recorded_at}`);
  });
  const vitalsReport = [vitalsTitle, vitalsHeaders, ...vitalsRows].join('\n');

  // pro submissions
  const proTitle = 'Pro Submissions';
  const proHeaders = 'Type,Patient,Recorded At';
  const proRows = [];
  Object.entries(invoiceData.proSubmissions).forEach(([pro_type, proSubmissions]) => {
    proSubmissions.forEach((proSubmission) => {
      proRows.push(`${pro_type},${proSubmission.cell_phone},${proSubmission.recorded_at}`);
    });
  });
  const proReport = [proTitle, proHeaders, ...proRows].join('\n');

  const perEventInvoiceReport = [aggReport, appointmentsReport, vitalsReport, proReport].join('\n,\n');

  return exportCsv(perEventInvoiceReport, startOfMonth);
};

const exportPerPatientInvoice = (invoice, startOfMonth) => {
  const { invoiceData, totals } = invoice;

  const aggTitle = 'Totals';
  const aggHeaders = 'Patients,Cost';
  const aggRow = `${totals.patients},${formattedCurrency.format(totals.cost)}`;
  const aggReport = [aggTitle, aggHeaders, aggRow].join('\n');

  const patientTitle = 'Patients';
  const patientHeaders = 'Patient,Appointments,Pro Submissions,Vitals';
  const patientRows = [];
  Object.entries(invoiceData).forEach(([patientNumber, patientData]) => {
    patientRows.push(`${patientNumber},${patientData.appointments},${patientData.proSubmissions},${patientData.vitals}`); // eslint-disable-line max-len
  });
  const patientReport = [patientTitle, patientHeaders, ...patientRows].join('\n');

  const perPatientInvoiceReport = [aggReport, patientReport].join('\n,\n');

  return exportCsv(perPatientInvoiceReport, startOfMonth);
};

const DashboardInvoicing = ({
  clinicId,
  startOfMonth,
}) => {
  const [fetchingInvoice, setFetchingInvoice] = useState(true);
  const [invoice, setInvoice] = useState();

  const classes = useStyles();

  const month = startOfMonth.month();
  const year = startOfMonth.year();

  useEffect(() => {
    const options = {
      query: {
        month,
        year,
      },
    };

    setInvoice(null);
    setFetchingInvoice(true);

    apiFetch(`/clinics/${clinicId}/invoice`, options)
      .then((response) => {
        setInvoice(response);
        setFetchingInvoice(false);
      })
      .catch(() => {
        setFetchingInvoice(false);
      });
  }, [month, year]);

  return (
    <div className={classes.root}>
      {invoice ? (
        <Box display="flex" flexDirection="column" flexGrow={0}>
          <div className={classes.totalsCard}>
            <div className={`${classes.totalTime} ${classes.totalsSection}`}>
              <div className={classes.greyText}>TOTAL</div>
              <div className={classes.timeValue}>
                {formattedCurrency.format(invoice.totals.cost)}
              </div>
            </div>
            {invoice.type === 'perEvent' ? (
              <>
                <div className={classes.totalsSection}>
                  <div>Total Events</div>
                  <div className={classes.timeValue}>
                    {invoice.totals.events}
                  </div>
                </div>
                <div className={classes.totalsSection}>
                  <div>Appointments</div>
                  <div className={classes.timeValue}>
                    {invoice.totals.appointments}
                  </div>
                </div>
                <div className={classes.totalsSection}>
                  <div>Pro Submissions</div>
                  <div className={classes.timeValue}>
                    {invoice.totals.proSubmissions}
                  </div>
                </div>
                <div className={classes.totalsSection}>
                  <div>Vitals</div>
                  <div className={classes.timeValue}>
                    {invoice.totals.vitals}
                  </div>
                </div>
              </>
            ) : (
              <div className={classes.totalsSection}>
                <div>Patients</div>
                <div className={classes.timeValue}>
                  {invoice.totals.patients}
                </div>
              </div>
            )}
          </div>
          {invoice.type === 'perEvent' ? (
            <Button
              className={classes.exportButton}
              onClick={() => exportPerEventInvoice(invoice, startOfMonth)}
              variant="contained"
            >
              Export Invoice
            </Button>
          ) : (
            <Button
              className={classes.exportButton}
              onClick={() => exportPerPatientInvoice(invoice, startOfMonth)}
              variant="contained"
            >
              Export Invoice
            </Button>
          )}
        </Box>
      ) : null}
      {!invoice && !fetchingInvoice ? (
        <div>No invoice found</div>
      ) : null}
    </div>
  );
};

DashboardInvoicing.propTypes = {
  clinicId: PropTypes.number.isRequired,
  startOfMonth: PropTypes.object.isRequired,
};

export default memo(DashboardInvoicing);
