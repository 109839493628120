import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map, get, sortBy } from 'lodash';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import { selectClinic } from '../state/clinic';

import { login } from '../actions/user';
import { colors } from '../lib/styles';
import brand from '../lib/brand';

const styles = {
  btn: {
    color: colors.white,
    fontSize: '18px',
    marginTop: '20px',
    width: '80px',
  },
  container: {
    backgroundAttachment: 'scroll',
    backgroundColor: colors.primaryColor,
    backgroundImage: `url("/img/${brand.name}/loginBackground.png")`,
    backgroundRepeat: 'repeat',
    height: '100%',
    minHeight: '900px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  headerImg: {
    height: '180px',
    paddingBottom: '30px',
    marginTop: '10%',
  },
  selectIcon: {
    color: colors.white,
  },
  selectRoot: {
    borderBottom: `1px solid ${colors.white}`,
    color: colors.white,
    textAlign: 'center',
    width: '400px',
  },
};

export class ClinicSelection extends Component {
  constructor(props) {
    super(props);
    const clinics = get(props, 'user.clinics', []);
    this.state = { selectedClinic: clinics.length === 1 ? clinics[0].id : '' };
    this.chooseClinic = this.chooseClinic.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const clinics = get(nextProps, 'user.clinics', []);
    if (clinics.length === 1 && !this.state.selectedClinic) {
      this.setState({
        selectedClinic: clinics[0].id,
      });
    }
  }
  chooseClinic(evt) {
    this.setState({
      selectedClinic: evt.target.value,
    });
  }

  handleContinue() {
    this.props.selectClinic(this.state.selectedClinic)
      .then(() => this.props.router.push('/app/patientlisting'));
  }
  render() {
    const { classes, user } = this.props;
    const clinics = get(user, 'clinics', []);
    const sortedClinics = sortBy(clinics, clinic => clinic.name.toLowerCase());
    const items = map(sortedClinics, (clinic) => {
      return (<MenuItem value={clinic.id} key={clinic.id}>{clinic.name}</MenuItem>);
    });
    return (
      <div className={classes.container} >
        <img
          alt="Logo"
          src={`img/${brand.name}/${brand.loginLogo}`}
          className={classes.headerImg}
        />
        <FormControl>
          <Select
            className={classes.selectRoot}
            classes={{
              icon: classes.selectIcon,
            }}
            displayEmpty
            value={this.state.selectedClinic}
            input={<Input
              disableUnderline={true}
              name="clinic"
              id="clinic-label-placeholder"
            />}
            onChange={this.chooseClinic}
            autoWidth={true}
          >
            <MenuItem value="" disabled>
              Please Select a Clinic
            </MenuItem>
            {items}
          </Select>
        </FormControl>
        <Button
          color="secondary"
          className={classes.btn}
          onClick={this.handleContinue}
          variant="contained"
        >Go
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}
ClinicSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  selectClinic: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, { login, selectClinic })(withStyles(styles)(ClinicSelection));
