import { makeActionCreator } from 'cooldux';

export const updateTableSetting = makeActionCreator('updateTableSetting');
export const clearTableSetting = makeActionCreator('clearTableSetting');

const initialState = {
  appointments: { page: 0 },
  care_pathways: { page: 0 },
  bundled_events: { page: 0 },
  voice_broadcasts: { page: 0 },
  video_broadcasts: { page: 0 },
  patients: { page: 0 },
};

function tableSettings(state = initialState, { type, payload }) {
  switch (type) {
    case updateTableSetting.type: {
      const settings = { ...state[payload.type], ...payload.value };
      return { ...state, [payload.type]: settings };
    }
    case clearTableSetting.type: {
      return initialState;
    }
    default:
      return state;
  }
}

export default tableSettings;
