import React from 'react';
import useSWR from 'swr';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';
import AppWrapper from '../wrappers/app-wrapper';

const styles = {
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  inputField: {
    margin: '10px 10px 0px',
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    marginBottom: '150px',
    padding: '25px',
    textAlign: 'left',
    width: '100%',
  },
  video: {
    margin: '0 auto',
    minWidth: '450px',
    width: '50%',
  },
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '20px',
    width: '100%',
  }
};

export const DeveloperOptions = (props) => {
  const { clinicId, classes } = props;
  const [name, setName] = React.useState('');
  const [error, setError] = React.useState('');
  const [saving, setSaving] = React.useState(false);
  const [msg, setMsg] = React.useState('');

  const { data, loading, revalidate } = useSWR(`/clinics/${clinicId}/api_keys`, apiFetch);

  const handleDelete = async () => {
    setMsg('');
    const options = {
      method: 'DELETE',
    };
    try {
      await apiFetch(`/clinics/${clinicId}/api_keys/${data[0].id}`, options);
      revalidate();
    } catch (e) {
      setError(e.message);
    }
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = async () => {
    setError('');
    setSaving(true);
    const options = {
      method: 'POST',
      body: { name },
    };
    try {
      const result = await apiFetch(`/clinics/${clinicId}/api_keys`, options);
      setMsg(result.key);
      setSaving(false);
      setName('');
      revalidate();
    } catch (e) {
      setError(e.message);
      setSaving(false);
    }
  };

  return (
    <AppWrapper router={props.router}>
      <div className={classes.root}>
        <h1 className={classes.headerTitle}>API Key</h1>
        <div style={{ color: colors.errorRed, textAlign: 'center' }}>{error}</div>
        {msg ? (
          <div style={{ margin: '10px' }}>
            <div>New Key:</div>
            <div>
              <span style={{ margin: '10px', fontWeight: 'bold' }}>{msg}</span>
            </div>
          </div>
        ) : ''}
        {loading ? <CircularProgress /> : (
          <div>
            {data && data[0] ? (
              <div>
                Name: {data[0].name}
                <Button style={{ backgroundColor: colors.errorRed, color: colors.white }} onClick={handleDelete} variant="contained">
                  <DeleteIcon />Delete Key
                </Button>
              </div>
            ) : (
              <div className={classes.inputRow}>
                <TextField
                  placeholder="Name"
                  label="Name"
                  value={name}
                  onChange={handleChange}
                />
                <Button
                  variant="contained"
                  component="label"
                  onClick={handleSave}
                  disabled={saving || !name}
                >
                  Create New API Key
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </AppWrapper>
  );
};

function mapStateToProps(state, ownProps) {
  const {
    clinic: { clinicId },
  } = state;

  return {
    broadcastId: ownProps.routeParams.broadcastId,
    clinicId,
  };
}


export default connect(mapStateToProps, {})(withStyles(styles)(DeveloperOptions));
