import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import SendIcon from '@material-ui/icons/Send';

import { apiFetch } from '../lib/fetch';

import { getSocket, messageAll, chatEvents } from '../websocket';

const useStyles = makeStyles(theme => ({
  root: {
    width: '400px',
    bottom: '0',
    right: '20px',
    zIndex: '1000',
    position: 'fixed',
  },
  accordion: {
    borderRadius: '10px',
  },
  accordionDetails: {
    maxHeight: '30%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: 10,
    marginRight: 5,
    width: '100%',
    textAlign: 'left',
    flexGrow: 1,
  },
  message: {
    marginBottom: '15px',
  },
  messageBox: {
    display: 'flex',
  },
  chat: {
    position: 'absolute',
    bottom: 0,
    margin: 0,
    padding: 0,
  },
  messageSection: {
    backgroundColor: '#fff',
    minHeight: 'inherit',
    width: '100%',
    height: 300,
  },
  messageDisplayBox: {
    padding: 10,
    overflow: 'hidden',
    overflowY: 'scroll',
    textAlign: 'left',
    height: 220,
  },
  messageField: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    borderRadius: 30,
    backgroundColor: '#eaeaea',
  },
  patient: {
    fontWeight: 'bold',
    display: 'flex',
    padding: 10,
  },
  patientName: {
    width: '100%',
    alignSelf: 'center',
    marginLeft: '10px',
    textAlign: 'start',
  },
  patientRoomNum: {
    backgroundColor: 'black',
  },
  title: {
    backgroundColor: '#000',
    color: '#fff',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  expandMoreIcon: {
    color: '#fff',
    transform: 'rotate(180deg)',
  },
}));

function ChatWindow({
  clinicId, router, user,
}) {
  if (router.location.pathname === '/app/patient/video-call') return null;
  const classes = useStyles();

  const [appointments, setAppointments] = useState([]);
  const [patient, setPatient] = useState(null);
  const [patientHasUnreadMsgs, setPatientHasUnreadMsgs] = useState([]);
  const [message, setMessage] = useState('');
  const [messageHx, setMessageHx] = useState([]);
  const [patientIsTyping, setPatientIsTyping] = useState(false);
  const [clinicIsTyping, setClinicIsTyping] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [unreadIconAlert, setUnreadIconAlert] = useState(false);

  const clinicIsTypingRef = useRef(null);
  const unreadMessagesRef = useRef(null);
  const messagesEndRef = useRef(null);
  const currAppt = useRef(null);
  const msgHx = useRef(null);
  const timer = useRef(null);
  const isOpenRef = useRef(null);
  const selectedPatientRef = useRef(null);

  const scrollToBottom = () => {
    const scroll = messagesEndRef.current && messagesEndRef.current.scrollIntoView({
      behavior: 'smooth', block: 'end',
    });
    return scroll;
  };

  const updatePatient = (patient) => {
    const options = { method: 'PUT', body: { clinic_viewed: true } };
    return apiFetch(`/users/${patient.user_id}/appointments/${patient.id}/messages`, options);
  };

  const handleUnreadIcon = (patient, unreadArray = patientHasUnreadMsgs) => {
    const unreadMsgs = unreadArray.filter(pt => pt.appt_id !== patient.id).find(msg => msg.unreadMsgs === true);
    return setUnreadIconAlert(Boolean(unreadMsgs));
  };

  const loadPatientMessages = (patient) => {
    if (!patient) return;
    apiFetch(`/users/${patient.user_id}/appointments/${patient.id}/messages`, { method: 'GET' })
      .then((data) => {
        const messages = data.map((msg) => {
          return { from: msg.user_id, message: msg.message, created_at: msg.created_at };
        });
        const sorted = messages.sort((msgA, msgB) => ((msgA.created_at > msgB.created_at) ? 1 : -1));
        setMessageHx(sorted);
      }).catch(() => setMessageHx([]));
  };

  const retrieveAppointmentsDetails = async () => {
    setAppointments([]);
    const apptRes = await apiFetch(`/clinics/${clinicId}/active_telemedicine_calls`, { method: 'GET' });

    setAppointments(apptRes);
    const patientList = Promise.all(apptRes.map(async (patient) => {
      const res = await apiFetch(`/users/${patient.user_id}/appointments/${patient.id}/messages`, { method: 'GET' })
        .then(async (data) => {
          const unreadMsgs = Boolean(data.find((msg) => {
            return msg.clinic_viewed === false;
          }));
          if (unreadIconAlert === false && unreadMsgs) setUnreadIconAlert(true);
          const patientDetails = { appt_id: patient.id, unreadMsgs };
          return patientDetails;
        });
      return res;
    }));
    if (patient) handleUnreadIcon(patient, await patientList);
    return setPatientHasUnreadMsgs(await patientList);
  };

  const patientIsTypingEventListener = (data) => {
    const appt = currAppt.current;
    if (!appt || patientIsTyping) return;

    if (data.from === appt.user_id) {
      setPatientIsTyping(true);
    }
    scrollToBottom();
  };

  const patientIsNotTypingEventListener = (data) => {
    const appt = currAppt.current;
    if (!appt) return;

    if (data.from === appt.user_id) {
      setPatientIsTyping(false);
    }
  };

  const clinicIsTypingEventListener = (data) => {
    const anotherClinicTyping = clinicIsTypingRef.current;

    if (anotherClinicTyping) return;
    if (data.from !== user.id) {
      setClinicIsTyping(true);
    }
    scrollToBottom();
  };

  const clinicIsNotTypingEventListener = (data) => {
    const anotherClinicTyping = clinicIsTypingRef.current;

    if (!anotherClinicTyping) return;
    const { id } = user.id;
    if (data.from !== id) {
      setClinicIsTyping(false);
    }
  };

  const SMSEventListener = async (data) => {
    const chatIsOpen = isOpenRef.current;
    const appt = currAppt.current;
    const msgs = msgHx.current;
    const unreadMsgs = unreadMessagesRef.current;
    const selectedPatient = selectedPatientRef.current;

    const patient = await unreadMsgs.find(p => p.appt_id === Number(data.appointment_id));

    retrieveAppointmentsDetails();
    handleUnreadIcon(patient);
    if (appt) {
      if (chatIsOpen) {
        updatePatient(appt);
        setUnreadIconAlert(false);
        patient.unreadMsgs = false;
      } else {
        patient.unreadMsgs = true;
      }
      if (selectedPatient.user_id === data.from || user.id === data.from) {
        const newMessageHx = [...msgs, { from: data.from, message: data.message }];
        setMessageHx(newMessageHx);
        scrollToBottom();
      }
    }
  };

  const handlePatientSelection = async (patient) => {
    setPatient(patient);
    selectedPatientRef.current = patient;
    loadPatientMessages(patient);

    updatePatient(patient);
    handleUnreadIcon(patient);
    return retrieveAppointmentsDetails();
  };

  const openAppointmentChatEventListener = async (appt_id) => {
    if (!appt_id) return;

    await apiFetch(`/clinics/${clinicId}/active_telemedicine_calls`, { method: 'GET' })
      .then((res) => {
        if (!res.length) return;
        const pt = res.find(patient => patient.id === appt_id);
        handlePatientSelection(pt);
        setIsOpen(true);
      }).catch(() => setAppointments([]));
  };

  const clinicIsNotTyping = () => {
    messageAll(patient.user_id, clinicId, { type: 'CLINIC-IS-NOT-TYPING', from: user.id });
  };

  const handleChangeMessage = (e) => {
    const { value } = e.target;

    setMessage(value);
    if (value.length > 0) {
      messageAll(patient.user_id, clinicId, { type: 'CLINIC-IS-TYPING', from: user.id });
      clearTimeout(timer.current);
      timer.current = setTimeout(() => clinicIsNotTyping(), 2000);
    } else {
      return clinicIsNotTyping();
    }
  };

  const handleSendMessage = () => {
    const { user_id, id } = patient;

    if (message.length > 0) {
      messageAll(user_id, clinicId, {
        type: 'CLINIC-SMS', message, from: user.id, appointment_id: id,
      });
      clinicIsNotTyping();
      setMessage('');
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  };

  const handleSubmitOnEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const findPatient = (patient_id) => {
    const res = patientHasUnreadMsgs.find((pt) => {
      return pt.appt_id === patient_id;
    });
    return res.unreadMsgs;
  };

  const handleIsOpen = () => {
    const chatIsOpen = !isOpenRef.current;
    const unreadMsgs = unreadMessagesRef.current;

    setIsOpen(chatIsOpen);
    if (patient && chatIsOpen) {
      const pt = unreadMsgs.find(p => p.appt_id === patient.id);
      pt.unreadMsgs = false;
      handlePatientSelection(patient);
    }
  };

  useEffect(() => {
    unreadMessagesRef.current = patientHasUnreadMsgs;
    currAppt.current = patient;
    msgHx.current = messageHx;
    isOpenRef.current = isOpen;
    clinicIsTypingRef.current = clinicIsTyping;
  }, [patient, messageHx, patientHasUnreadMsgs, isOpen, clinicIsTyping]);

  useEffect(() => {
    const socket = getSocket();

    socket.otherEvents.on('PATIENT-IS-TYPING', patientIsTypingEventListener);
    socket.otherEvents.on('PATIENT-IS-NOT-TYPING', patientIsNotTypingEventListener);
    socket.otherEvents.on('CLINIC-IS-TYPING', clinicIsTypingEventListener);
    socket.otherEvents.on('CLINIC-IS-NOT-TYPING', clinicIsNotTypingEventListener);
    socket.otherEvents.on('PATIENT-SMS', SMSEventListener);
    socket.otherEvents.on('CLINIC-SMS', SMSEventListener);
    chatEvents.on('OPEN-APPOINTMENT-CHAT', openAppointmentChatEventListener);

    retrieveAppointmentsDetails();

    loadPatientMessages(patient);
    setTimeout(() => {
      scrollToBottom();
    }, 500);

    return () => {
      socket.otherEvents.off('PATIENT-IS-TYPING', patientIsTypingEventListener);
      socket.otherEvents.off('PATIENT-IS-NOT-TYPING', patientIsNotTypingEventListener);
      socket.otherEvents.off('CLINIC-IS-TYPING', clinicIsTypingEventListener);
      socket.otherEvents.off('CLINIC-IS-NOT-TYPING', clinicIsNotTypingEventListener);
      socket.otherEvents.off('PATIENT-SMS', SMSEventListener);
      socket.otherEvents.off('CLINIC-SMS', SMSEventListener);
      chatEvents.off('OPEN-APPOINTMENT-CHAT', openAppointmentChatEventListener);
    };
  }, [null]);

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion} expanded={isOpen}>
        <AccordionSummary
          onClick={handleIsOpen}
          expandIcon={
            <ExpandMoreIcon className={classes.expandMoreIcon} />
          }
          className={classes.title}
        >
          {patient ? (
            <>
              <Badge color="secondary" variant="dot" overlap="circular" invisible={!unreadIconAlert}>
                <KeyboardArrowLeftIcon
                  style={{ color: '#fff' }}
                  onClick={() => {
                    setPatient();
                    selectedPatientRef.current = null;
                  }}
                />
              </Badge>
              <Badge
                color="secondary"
                variant="dot"
                invisible={isOpen || (
                  patientHasUnreadMsgs.length === appointments.length
                  && findPatient(patient.id) === false
                )}
              >
                <Typography className={classes.heading} onClick={handleIsOpen}>
                  {patient.last_name},  {patient.first_name}
                </Typography>
              </Badge>

            </>
          ) : (
            <>
              <Badge color="secondary" variant="dot" overlap="circular" invisible={!unreadIconAlert}>
                <ChatBubbleIcon style={{ color: '#fff' }} />
              </Badge>
              <Typography className={classes.heading} onClick={handleIsOpen}>
                Messages
              </Typography>
            </>
          )}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.messageSection}>
            {patient ? (
              <>
                <Box flexGrow={1} className={classes.messageDisplayBox}>
                  {messageHx && messageHx.map((message) => {
                  const sender = message.from === patient.user_id ? 'Patient' : 'Clinic';
                  return (
                    <div>
                      <Typography
                        variant="body1"
                        className={classes.message}
                      ><strong>{sender}:</strong> {message.message}
                      </Typography>
                    </div>
                  );
                })}
                  {clinicIsTyping && <Typography variant="body1"><em>Another provider is typing...</em></Typography>}
                  {patientIsTyping && <Typography variant="body1"><em>Patient is typing...</em></Typography>}
                  <div ref={(el) => { messagesEndRef.current = el; }} />
                </Box>
                <Box className={classes.messageBox}>
                  <Input
                    type="text"
                    placeholder="Tap to enter message"
                    variant="filled"
                    multiline
                    disableUnderline
                    maxRows={4}
                    value={message}
                    onChange={handleChangeMessage}
                    onKeyPress={handleSubmitOnEnter}
                    className={classes.messageField}
                  />
                  <Button onClick={handleSendMessage}>
                    <SendIcon color="primary" style={{ fontSize: 40 }} />
                  </Button>
                </Box>
              </>
            ) : (
              <>
                {appointments.length ? (appointments.map((patient, idx) => (
                  <>
                    <Box
                      className={classes.patient}
                      onClick={() => handlePatientSelection(patient)}
                    >
                      <Badge
                        color="secondary"
                        variant="dot"
                        overlap="circular"
                        invisible={
                          patientHasUnreadMsgs.length === 0
                          || (
                            patientHasUnreadMsgs.length === appointments.length
                            && findPatient(patient.id) === false
                          )
                        }
                      >
                        <Avatar className={classes.patientRoomNum}>{idx + 1}</Avatar>
                      </Badge>
                      <Typography className={classes.patientName}>{patient.last_name}, {patient.first_name}</Typography>
                    </Box>
                    <Divider />
                  </>
                ))) : (<Typography className={classes.patientName}>No appointments to be found.</Typography>)}
              </>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

ChatWindow.defaultProps = {
  user: {},
};

ChatWindow.propTypes = {
  clinicId: PropTypes.number.isRequired,
  user: PropTypes.object,
  router: PropTypes.object.isRequired,
};

export default ChatWindow;
