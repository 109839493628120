import React from 'react';
import useSWR from 'swr';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { SortableTable } from 'common-ui';
import { updateTableSetting } from '../state/tables';
import { apiFetch } from '../lib/fetch';
import AppWrapper from '../wrappers/app-wrapper';
import { btnLink, colors } from '../lib/styles';

const columnData = [
  {
    id: 'name', align: false, disablePadding: false, label: 'Name', small: true,
  },
  {
    id: 'enabled', align: false, disablePadding: false, label: 'Active Status', small: true,
  },
];

const styles = {
  btnLink,
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  root: {
    marginBottom: '150px',
    padding: '25px',
    textAlign: 'left',
    width: '100%',
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: colors.highlightGray,
    },
    cursor: 'pointer',
  },
};

export const CarePathways = (props) => {
  const { clinicId, classes, tableSettings, updateTableSetting } = props;
  const { data } = useSWR(`/clinics/${clinicId}/campaigns`, apiFetch);
  const rowNumber = window.localStorage.getItem('care_pathways_rows');
  let rowData;

  if (data) {
    rowData = data.map((row) => {
      return {
        ...row,
        enabled: row.enabled ? 'enabled' : 'disabled',
      };
    });
  }

  return (
    <AppWrapper router={props.router}>
      <div className={classes.root}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 className={classes.headerTitle}>Configure Care Pathway</h1>
          <Link className={classes.btnLink} to="/app/care-pathways/new">
            <Button color="primary" variant="contained">Create a Care Pathway</Button>
          </Link>
        </div>
        <SortableTable
          columnData={columnData}
          emptyMessage="No care pathways exist at this time."
          rows={rowData}
          handleClick={(row) => { props.router.push(`/app/care-pathways/${row.id}`); }}
          hover={true}
          rowClasses={{ row: classes.tableRow }}
          rowNumber={rowNumber && Number(rowNumber)}
          page={tableSettings.page && Number(tableSettings.page)}
          saveSetting={rows => window.localStorage.setItem('care_pathways_rows', rows)}
          updateTableSetting={page => updateTableSetting({ type: 'care_pathways', value: { page } })}
        />
      </div>
    </AppWrapper>
  );
};

function mapStateToProps(state) {
  const {
    clinic: { clinicId },
    tableSettings: { care_pathways },
  } = state;

  return {
    clinicId,
    tableSettings: care_pathways,
  };
}
CarePathways.propTypes = {
  classes: PropTypes.object.isRequired,
  tableSettings: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};


export default connect(mapStateToProps, { updateTableSetting })(withStyles(styles)(CarePathways));
