import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppWrapper from '../wrappers/app-wrapper';

import ICAForm from '../components/form-ica';
// TODO The goal here is to eventually make the final part of the url
// a parameter and choose the correct form
// const formTypes = {
//   ica: ICAForm,
// };

export class IcaForm extends PureComponent {
  componentWillMount() {
    // TODO: Just using this to prevent randos from somehow navigating here
    // Eventually will be based on the feature
    if (!this.props.appFeatures.length) {
      this.props.router.push('/app');
    }
  }
  render() {
    // TODO: we'll do this dynamic later
    return (
      <AppWrapper router={this.props.router}>
        <ICAForm
          type="ica"
          router={this.props.router}
        />
      </AppWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {
    clinic,
  } = state;

  return {
    appFeatures: clinic.data.app_features || [],
  };
}

const actionCreators = {};

IcaForm.propTypes = {
  appFeatures: PropTypes.array.isRequired,
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(IcaForm);
