import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { capitalize, find } from 'lodash';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import { editPatient, readPatient } from '../state/appointments';
import HiddenContent from './hidden-content';
import PatientSummaryItem from './patient-summary-item';
import EditView from './patient-view-summary-edit-view';
import { apiFetch } from '../lib/fetch';

import { blueBtnStyle, colors } from '../lib/styles';

const styles = {
  container: {
    marginLeft: '15px',
  },
  editBtn: {
    ...blueBtnStyle,
    height: '36px',
    minWidth: '140px',
    padding: '6px',
  },
  cancelBtn: {
    ...blueBtnStyle,
    height: '36px',
    minWidth: '140px',
    padding: '6px',
    backgroundColor: colors.secondaryColor,
    border: `1px solid ${colors.secondaryColor}` 
  },
  centerButtons: {
    display: 'flex',
    flexDirection: 'row',
  }, 
  items: {
    alignItems: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: colors.primaryColor,
    marginLeft: '1rem',
    maxWidth: '22rem',
  },
  itemsAlign: {
    display: 'flex',
    marginBottom: '10px',
    width: '100%',
    justifyContent: 'start',
    flexWrap: 'wrap',
  },
  name: {
    color: colors.primaryColor,
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '1.5rem',
  },
  errorMessage: {
    color: colors.errorRed,
    marginLeft: 10,
    textAlign: 'left',
  },
  flag: {
    display: 'flex'
  },
};

let language;

const PatientSummaryView = withStyles(styles)(({
  classes,
  sex,
  birthday,
  primary_insurance_name,
  lastAppointment,
  cell_phone,
  email,
  primary_language,
  contactStatus,
  flag_id,
  flags,
  member_id,
}) => {
  let contactStatusMessage = '';

  if (primary_language === 'en') {
    language = 'ENGLISH'
  } else if (primary_language === 'es') {
    language = 'SPANISH'
  }

  const currentFlag = find(flags, { id: flag_id });
  const flagIcon = currentFlag ? (
    <div className={classes.flag}>
      <img
        alt="icon"
        height={25}
        src={currentFlag.icon}
        width={25}
      />
      <div>{currentFlag.description}</div>
    </div>
  ) : 'None';

  if (contactStatus === 'blacklisted') {
    contactStatusMessage = 'Disabled (STOP request)';
  } else if (contactStatus === 'landline') {
    contactStatusMessage = 'Disabled (landline)';
  } else if (contactStatus === 'enabled') {
    contactStatusMessage = 'Enabled';
  }

  return (
  <div className={classes.itemsAlign}>
    <div className={classes.items}>
      <PatientSummaryItem title="Sex" value={capitalize(sex)} />
      <PatientSummaryItem title="Date of Birth" value={birthday} />
    </div>
    <div className={classes.items}>
      <PatientSummaryItem title="Insurance" value={capitalize(primary_insurance_name)} />
      <PatientSummaryItem title="Last Visit" value={lastAppointment} />
    </div>
    <div className={classes.items}>
      <PatientSummaryItem title="Cell Phone" value={cell_phone} />
      <PatientSummaryItem title="Email" value={email} />
    </div>
    <div className={classes.items}>
      <PatientSummaryItem title="Primary Language" value={language} />
      <PatientSummaryItem title="Phone Status" value={contactStatusMessage} />
    </div>
    <div className={classes.items}>
      <PatientSummaryItem title="Member ID" value={member_id} />
      <PatientSummaryItem title="Flag" value={flagIcon} />
    </div>
  </div>
)});

class PatientSummary extends Component {
  state = {
    editing: false,
    inviteSent: false,
    editError: '',
  }

  componentDidMount() {
    apiFetch(`/clinics/${this.props.clinicId}/flags`).then((flags) => {
      this.setState({ flags });
    });
  }

  handleUpdate = (field, evt) => {
    let val = evt;
    if (field !== 'birth_date') {
      val = evt.target.value;
    }
    this.setState({
      [field]: val
    });
  }
  handleSave = () => {
    const {
      first_name,
      last_name,
      birth_date,
      cell_phone,
      email,
      language,
      member_id,
    } = this.state;
    const { clinicId, userId } = this.props;
    const emailField = this.props.roles[0] === 'SHADOW_PATIENT' ? email : undefined;
    const flag_id = this.state.flag_id === 'None' ? null : this.state.flag_id;
    this.setState({ editError: '' });
    
    apiFetch(`/users/pro/${userId}`, {
      method: 'PUT',
      body: {
        first_name,
        last_name,
        birth_date,
        cell_phone,
        email: emailField,
        primary_language: language,
        clinic_id: clinicId,
        member_id,
        flag_id,
      },
    })
      .then((res) => {
        return this.props.onReadPatient(res.id);
      })
      .catch((error) => {
        if (error.status === 422) {
          this.setState({ editError: 'The email address is already in use' });
        } else {
          this.setState({ editError: 'There is a problem updating the patient' });
        }
      });
  }
  handleCancel = () => {
    this.setState({
      first_name: '',
      last_name: '',
      birth_date: '',
      cell_phone: '',
      email: '',
      language,
      editing: false,
      editError: '',
      flag_id: 'None',
    });
  }
  handleEdit = () => {
    this.setState({
      first_name: this.props.first_name,
      last_name: this.props.last_name,
      cell_phone: this.props.cell_phone,
      birth_date: this.props.birth_date,
      email: this.props.email,
      language,
      editing: true,
      editError: '',
      flag_id: this.props.flag_id || 'None',
      member_id: this.props.member_id,
    })
  }
  videoCall = async () => {
    const { clinicId, userId, contact_preference } = this.props;
    const startDate = moment()
    const apptOptions = {
      method: 'POST',
      body: {
        appointment_type: 'TELEMEDICINE',
        status: 'SCHEDULED',
        checked_in: null,
        checked_out: null,
        in_session: null,
        contact_preference,
        start_date: startDate,
        end_date: startDate.clone().add('15', 'minutes'),
        now: true,
      },
    }
    const { id: appointmentId } = await apiFetch(`/clinics/${clinicId}/users/${userId}/appointments`, apptOptions); 

    this.props.router.push({ pathname: '/app/patient/video-call', state: { userId: this.props.user_id, appointmentId }});
  }
  handleSendAccountInvitation = () => {
    const { userId, clinicId } = this.props;
    const options = {
      method: 'POST',
      body: {
        user_id: userId,
      },
    }
    this.setState({
      inviteSent: true,
    })
    apiFetch(`/clinics/${clinicId}/resend_account_invitation`, options)
      .then(() => {
        this.setState({
          summaryMsg: 'Patient Invite Message Sent Successfully'
        })
      })
      .catch(() => this.setState({ inviteSent: false }));
  }
  render() {
    const { classes, last_name, first_name, birth_date, last_appointment_time, hideCallBtn, roles } = this.props;

    let lastAppointment = 'None';
    if (last_appointment_time) {
      lastAppointment = moment(last_appointment_time).format('MM/DD/YYYY');
    }

    const callDisabled = !this.props.cell_phone;

    return (
      <div className={classes.container}>
        <span style={{ color: colors.successGreen }}>{this.state.summaryMsg}</span>
        <div className={classes.name}>{last_name}, {first_name}</div>
        {this.state.editing ? (
          <EditView
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            birth_date={moment(this.state.birth_date).isValid() ? `${moment(this.state.birth_date).format('MM/DD/YYYY')}` : this.state.birth_date }
            cell_phone={this.state.cell_phone}
            email={this.state.email}
            language={this.state.language}
            member_id={this.state.member_id}
            flag_id={this.state.flag_id}
            flags={this.state.flags}
            onUpdateFirstName={(e) => this.handleUpdate('first_name', e)}
            onUpdateLastName={(e) => this.handleUpdate('last_name', e)}
            onUpdateCellPhone={(e) => this.handleUpdate('cell_phone', e)}
            onUpdateBirthDate={(e) => this.handleUpdate('birth_date', e)}
            onUpdateEmail={(e) => this.handleUpdate('email', e)}
            onUpdateLanguage={(e) => this.handleUpdate('language', e)}
            onUpdateMemberId={(e) => this.handleUpdate('member_id', e)}
            onUpdateFlag={(e) => this.handleUpdate('flag_id', e)}
            roles={roles}
          />
        ) : (
          <PatientSummaryView
            {...this.props}
            classes={{}}
            birthday={moment.utc(birth_date).format('MM/DD/YYYY')}
            lastAppointment={lastAppointment}
            flags={this.state.flags}
          />
        )}
        {this.state.editError ?
          <div className={classes.errorMessage}>{this.state.editError}</div>
        : null}
        <div className={classes.centerButtons}>
          <HiddenContent hidden={!this.state.editing}>
            <Button
              variant="contained"
              className={classes.cancelBtn} 
              onClick={this.handleCancel}
            >Cancel</Button>
            <Button
              variant="contained"
              className={classes.editBtn}
              onClick={this.handleSave}
            >Save</Button>
          </HiddenContent>
          <HiddenContent hidden={this.state.editing}>
            <Button
              variant="contained"
              onClick={this.handleEdit}
              className={classes.editBtn}
            >Edit</Button>
            <HiddenContent hidden={hideCallBtn}>
              {callDisabled ? (
                <Tooltip title="Please enter a Cell Phone for this Patient">
                  {/* span needs to be here for tooltip to work with btn disabled*/}
                  <span>
                    <Button
                      disabled={callDisabled}
                      variant="contained"
                      onClick={this.videoCall}
                      className={classes.editBtn}
                    >call</Button>
                  </span>
                </Tooltip>
              ) :
                  (
                    <Button
                      disabled={callDisabled}
                      variant="contained"
                      onClick={this.videoCall}
                      className={classes.editBtn}
                    >call</Button>
                  )
              }
            </HiddenContent>
            <HiddenContent hidden={!this.props.isShadow}>
              <Button
                disabled={this.state.inviteSent}
                variant="contained"
                onClick={this.handleSendAccountInvitation}
                className={classes.editBtn}
              >Send Invite</Button>
            </HiddenContent>
          </HiddenContent>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { clinic } = state;

  return {
    hideCallBtn: !clinic.data.app_features.includes('VIDEO_CALLS'),
    isShadow: ownProps.roles.includes('SHADOW_PATIENT'),
    clinicId: clinic.clinicId,
  };
}

PatientSummary.defaultProps = {
  birth_date: '',
  cell_phone: '',
  email: '',
  first_name: '',
  id: null,
  last_name: '',
  painLevel: '',
  sex: '',
  status: '',
  roles: [],
  member_id: null,
};

PatientSummary.propTypes = {
  birth_date: PropTypes.string,
  blacklistedPhone: PropTypes.bool.isRequired,
  cell_phone: PropTypes.string,
  email: PropTypes.string,
  first_name: PropTypes.string,
  id: PropTypes.number,
  landlinePhone: PropTypes.bool.isRequired,
  last_name: PropTypes.string,
  onEditPatient: PropTypes.func.isRequired,
  painLevel: PropTypes.string,
  roles: PropTypes.array,
  sex: PropTypes.string,
  status: PropTypes.string,
  clinicId: PropTypes.number,
  verifiedPatientNumber: PropTypes.bool.isRequired,
};

const actionCreators = {
  onEditPatient: editPatient,
  onReadPatient: readPatient,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(PatientSummary));
