import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import TableRow from '../base/table-row';
import TableCell from '../base/table-cell';
import { colors } from '../lib/styles';
import TruncText from './trunc-text';

const styles = {
  cell: {
    fontSize: '16px',
  },
  triggers: {
    color: colors.errorRed,
  },
};

const ProRow = ({ item, columnData }) => {
  const cells = map(columnData, (column) => {
    let content = item[column.id];
    let stringContent = content;
    let triggersContent = false;
    if (column.id === 'score') {
      stringContent = content.score;
      triggersContent = content.triggers;
    }
    if (column.maxLength) {
      content = (
        <Fragment>
          {triggersContent ? (<div style={styles.triggers}>Triggers: <TruncText maxLength={column.maxLength} content={triggersContent} /></div>) : null}
          
          <TruncText maxLength={column.maxLength} content={stringContent} />
        </Fragment>
      );
    }
    return (<TableCell style={styles.cell} key={`${column.id}`}>{content}</TableCell>);
  });
  return (
    <TableRow
      hover={false}
      key={item.id}
      tabIndex={0}
    >
      {cells}
    </TableRow>
  );
};

ProRow.propTypes = {
  columnData: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProRow);
