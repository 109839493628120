import { arrayToObject } from '../utilities';
import config from '../../config';

function commonScore(pro, scoreFunc) {
  let { data } = pro.pro_data;
  data = arrayToObject(data);
  pro.score.score = scoreFunc(data);
  return pro;
}

const getDataStatusCodesStr = (signsMsgs) => {
  const dataStatusCodesObj = {};
  let dataStatusCodes;

  signsMsgs.forEach((signsMsg) => {
    dataStatusCodes = signsMsg.dataStatus.split(',');

    dataStatusCodes.forEach((dataStatusCode) => {
      if (dataStatusCode) { // account for empty strings
        if (dataStatusCodesObj[dataStatusCode]) {
          dataStatusCodesObj[dataStatusCode]++;
        } else {
          dataStatusCodesObj[dataStatusCode] = 1;
        }
      }
    });
  });

  let dataStatusCodesStr = '';

  Object.keys(dataStatusCodesObj).forEach((dataStatusCode) => {
    dataStatusCodesStr += ` ${dataStatusCode}: ${dataStatusCodesObj[dataStatusCode]};`;
  });

  return dataStatusCodesStr;
};

function scoreIvcRR(data) {
  if (data.declinedTermsAndConditions) {
    return 'Patient declined Terms and Conditions';
  }

  if (data.declinedConsentToRecord) {
    return 'Patient declined Consent to Record Vitals';
  }

  if (data.declinedTermsAndConditions === false) {
    return 'Patient accepted Terms and Conditions';
  }

  // if a user gets to the point where they click 'never mind' after declining consent to record,
  // they have already accepted the terms and conditions, so that decision is reflected in the scoring
  if (data.declinedConsentToRecord === false) {
    return 'Patient accepted Terms and Conditions and Consent to Record Vitals';
  }

  if (data.noValidMeasurements || (data.vitals && data.vitals.HR === 0)) {
    return 'Unable to measure vitals.';
  }

  const dataStatusCodesStr = getDataStatusCodesStr(data.signsMsgs);

  let brVal;
  let hrVal;

  // some vital-core-rr submissions have an object with the finalVitalsMeasurementValues property
  // that contains results for BR and RR. Older submissions do not have this property
  if (data.finalVitalsMeasurementValues) {
    brVal = data.finalVitalsMeasurementValues.BR;
    hrVal = data.finalVitalsMeasurementValues.HR;
  } else {
    brVal = data.vitals.BR;
    hrVal = data.vitals.HR || 0;
  }

  if (brVal <= 0) {
    brVal = 'Unable to measure';
  } else if (brVal < 8 || brVal > 30) {
    brVal = 'Out of range';
  }

  if (hrVal <= 0) {
    hrVal = 'Unable to measure';
  } else if (hrVal < 40 || hrVal > 120) {
    hrVal = 'Out of range';
  }

  // eslint-disable-next-line max-len
  return `RR: ${brVal} | PR: ${hrVal} | IVC Algorithm v ${data.ivcVersion} | IVC App v ${data.webAppVersion} | Ave Frame Rate - ${data.vitals.averageFrameRate} | KDE Max Value - ${data.vitals.snrBR} | Peak Timestamps - ${data.vitals.sessionbrIntervalsStr} | Status Codes: ${dataStatusCodesStr}`;
}

function scoreIvcRRClinicalStudy(data) {
  const { timestamp, userId, vitals } = data;
  const imgUrl = `${config.API_URL}/users/${userId}/vital_images/${timestamp}/${timestamp}.jpeg`;

  const dataStatusCodesStr = getDataStatusCodesStr(data.signsMsgs);

  let brVal = vitals.BR;

  if (vitals.BR <= 0) {
    brVal = 'Unable to measure';
  } else if (vitals.BR < 8 || vitals.BR > 30) {
    brVal = 'Out of range';
  }

  // eslint-disable-next-line max-len
  return `User: ${userId} | Timestamp: ${timestamp} | RR: ${brVal} | IVC Algorithm v ${data.ivcVersion} | IVC App v ${data.webAppVersion} | Image: ${imgUrl} | Ave Frame Rate - ${data.vitals.averageFrameRate} | KDE Max Value - ${data.vitals.snrBR} | Peak Timestamps - ${data.vitals.sessionbrIntervalsStr} | Status Codes: ${dataStatusCodesStr}`;
}

const scoreFuncs = {
  VITAL_CORE_RR: (pro) => {
    return commonScore(pro, scoreIvcRR);
  },
  IVC_RR_CLINICAL_STUDY: (pro) => {
    return commonScore(pro, scoreIvcRRClinicalStudy);
  },
};

export default scoreFuncs;
