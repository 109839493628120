import React, { Component } from 'react';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import memoize from 'memoize-one';

import painCalendarFormatter from '../lib/pain-calendar-formatter';
import CalendarCurrentMonthItem from './calendar-current-month-item';
import CalendarHeader from './calendar-header';

const calendarPainColors = {
  painLevel1: '#309940',
  painLevel2: '#eea321',
  painLevel3: '#e54353',
};

const styles = {
  calendar: {
    color: '#2c3135',
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 12,
    margin: '0 20px',
  },
  calendarItem: {
    alignItems: 'center',
    borderRadius: 3,
    bottom: 8,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
  },
  calendarItemWrapper: {
    boxSizing: 'border-box',
    position: 'relative',
    width: `${(1 / 7) * 100}%`,
  },
  dayNames: {
    color: '#7f8fa4',
  },
  heightExpander: {
    paddingBottom: 'calc(100% + 8px)',
  },
  otherMonthDays: {
    background: '#edf2f5',
    color: '#a4abb0',
  },
  otherMonthDaysHighlighted: {
    background: '#dbecff',
    color: '#a4abb0',
  },
  spaceOnRight: {
    right: 1,
  },
  painLevel1: {
    background: calendarPainColors.painLevel1,
    color: 'white',
  },
  painLevel2: {
    background: calendarPainColors.painLevel2,
    color: 'white',
  },
  painLevel3: {
    background: calendarPainColors.painLevel3,
    color: 'white',
  },
};

const dayNames = [
  'SU',
  'M',
  'TU',
  'W',
  'TH',
  'F',
  'SA',
];

class CalendarOtherMonthItem extends React.PureComponent {
  render() {
    const {
      day,
      dayCount,
      highlighted,
      onClickDay,
      formattedDate,
      isSelected,
      selectedDateColor,
    } = this.props;
    const spaceOnRight = (dayCount % 7) ? styles.spaceOnRight : {};
    const itemStyles = highlighted ? styles.otherMonthDaysHighlighted : styles.otherMonthDays;
    const borderProperties = {};
    if (isSelected) {
      borderProperties.border = `3px solid ${selectedDateColor}`;
      borderProperties.borderRadius = '6px';
    }

    const calendarItemStyles = {
      ...styles.calendarItem,
      ...itemStyles,
      ...spaceOnRight,
      ...borderProperties,
    };

    return (
      <div
        onClick={() => onClickDay(formattedDate)}
        style={styles.calendarItemWrapper}
      >
        <div style={styles.heightExpander} />
        <div style={calendarItemStyles}>{day}</div>
      </div>
    );
  }
}
CalendarOtherMonthItem.propTypes = {
  day: PropTypes.number.isRequired,
  dayCount: PropTypes.number.isRequired,
  highlighted: PropTypes.bool.isRequired,
  onClickDay: PropTypes.func.isRequired,
  formattedDate: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectedDateColor: PropTypes.string.isRequired,
};

const CalendarDayLabel = ({ day }) => {
  return (
    <div style={styles.calendarItemWrapper}>
      <div style={styles.heightExpander} />
      <div style={{ ...styles.calendarItem, ...styles.dayNames }}>{day}</div>
    </div>
  );
};
CalendarDayLabel.propTypes = {
  day: PropTypes.string.isRequired,
};

const DayLabels = dayNames.map(day => (
  <CalendarDayLabel day={day} key={`dayLabel${day}`} />
));

const getCalendarItems = (
  painLogCalendarData,
  month,
  year,
  selectedDate,
  onClickDay,
  clickableDates,
  selectedDateColor,
  daysWithVitals
) => {
  const calendarItems = [];
  let dayCount = 0;

  // pad the beginning of the first row with the last days of the previous month
  const firstDayDate = new Date(year, month, 1);
  const firstDay = firstDayDate.getDay();

  if (firstDay !== 0) {
    for (let i = firstDay; i > 0; i--) {
      const prevDay = 1 - i;
      const prevDate = moment(new Date(year, month, prevDay));
      const prevDateFormatted = prevDate.format('YYYY-MM-DD');
      const highlighted = daysWithVitals.has(prevDateFormatted);

      dayCount++;
      calendarItems.push(
        <CalendarOtherMonthItem
          day={prevDate.date()}
          dayCount={dayCount}
          key={`calendarStartPadding-${i}`}
          onClickDay={onClickDay}
          highlighted={highlighted}
          formattedDate={prevDateFormatted}
          isSelected={prevDateFormatted === selectedDate}
          selectedDateColor={selectedDateColor}
        />
      );
    }
  }

  // render calendar items with pain information
  const lastDayDate = new Date(year, month + 1, 0);
  const lastDay = lastDayDate.getDate();

  for (let i = 1; i <= lastDay; i++) {
    const formattedDate = moment({ year, month, day: i }).format('YYYY-MM-DD');
    const painLevel = get(painLogCalendarData, [formattedDate, 'level'], 0);
    const highlighted = daysWithVitals.has(formattedDate);

    dayCount++;
    calendarItems.push(<CalendarCurrentMonthItem
      day={i}
      dayCount={dayCount}
      key={`curr${i}`}
      month={month}
      formattedDate={formattedDate}
      isSelected={formattedDate === selectedDate}
      onClickDay={onClickDay}
      painLevel={painLevel}
      selectedDateColor={selectedDateColor}
      showHover={clickableDates}
      year={year}
      highlighted={highlighted}
    />);
  }

  // pad the end of the last row with the first days of the next month
  if (dayCount % 7) {
    const remainingDays = 7 - (dayCount % 7);

    for (let i = 1; i <= remainingDays; i++) {
      dayCount++;

      const curRemainingDay = moment(new Date(year, month + 1, i));
      const curRemainingDayFormatted = curRemainingDay.format('YYYY-MM-DD');
      const highlighted = daysWithVitals.has(curRemainingDayFormatted);

      calendarItems.push(<CalendarOtherMonthItem
        day={i}
        dayCount={dayCount}
        key={`CalendarEndPadding-${i}`}
        onClickDay={onClickDay}
        highlighted={highlighted}
        formattedDate={curRemainingDayFormatted}
        isSelected={curRemainingDayFormatted === selectedDate}
        selectedDateColor={selectedDateColor}
      />);
    }
  }

  return calendarItems;
};

class Calendar extends Component {
  constructor(props) {
    super(props);
    const { month, year } = props;

    const currDate = new Date();
    const calendarMonth = month ? parseInt(month, 10) : currDate.getMonth();
    const calendarYear = year ? parseInt(year, 10) : currDate.getFullYear();

    this.state = {
      calendarMonth,
      calendarYear,
    };
  }
  componentWillMount() {
    const currDate = new Date();
    const currMonth = currDate.getMonth();
    const currYear = currDate.getFullYear();

    this.props.onDateChange(currMonth, currYear);
  }

  buildCalendarData = memoize((painHxData) => {
    return painCalendarFormatter([...Object.values(painHxData)]);
  });

  render() {
    const {
      clickableDates,
      data,
      onClickDay,
      onClickCurrentMonth,
      selectedDate,
      selectedDateColor,
      daysWithVitals,
      onClickBack,
      onClickForward,
      month,
      year,
    } = this.props;
    const calendarData = this.buildCalendarData(data);

    return (
      <Paper style={{ minWidth: 260 }}>
        <CalendarHeader
          month={month}
          onClickBackButton={onClickBack}
          onClickForwardButton={onClickForward}
          onClickCurrentMonth={onClickCurrentMonth}
          year={year}
        />
        <div style={styles.calendar}>
          {DayLabels}
          {getCalendarItems(
            calendarData,
            month,
            year,
            selectedDate,
            onClickDay,
            clickableDates,
            selectedDateColor,
            daysWithVitals
          )}
        </div>
      </Paper>
    );
  }
}

Calendar.defaultProps = {
  clickableDates: false,
  onClickDay: () => {},
  selectedDate: '',
  selectedDateColor: '#e54353',
  onClickCurrentMonth: () => {},
  month: null,
  year: null,
};

Calendar.propTypes = {
  clickableDates: PropTypes.bool,
  data: PropTypes.array.isRequired,
  month: PropTypes.number,
  onDateChange: PropTypes.func.isRequired,
  onClickDay: PropTypes.func,
  onClickCurrentMonth: PropTypes.func,
  selectedDate: PropTypes.string,
  selectedDateColor: PropTypes.string,
  year: PropTypes.number,
  daysWithVitals: PropTypes.object.isRequired,
  onClickBack: PropTypes.func.isRequired,
  onClickForward: PropTypes.func.isRequired,
};

export default Calendar;
