import { capitalize } from 'lodash';
import { arrayToObject } from '../utilities';

const commonScore = (pro, scoreFunc) => {
  let { data } = pro.pro_data;
  data = arrayToObject(data);
  pro.score.score = scoreFunc(data);
  return pro;
};

const score = (data, additionalDetails = []) => {
  const {
    timestamp,
    userId,
    vitals,
    FPS,
    seconds,
    age,
    feet,
    inches,
    makeup,
    makeupAmount,
    weight,
    facialHair,
    facialTattoos,
    glasses,
    manualBloodPressureFirst,
    manualBloodPressureSecond,
    bpDeviceManufacturer,
    bpDeviceModelNumber,
  } = data;
  const { HR, BR, SPO2 } = (vitals || {});

  return [
    `User: ${userId}`,
    `Timestamp: ${timestamp}`,
    `FPS: ${FPS}`,
    `Seconds: ${seconds}`,
    `HR: ${HR || '---'}`,
    `BR: ${BR || '---'}`,
    `SPO2: ${SPO2 || '---'}`,
    manualBloodPressureFirst ? `First manual BP: ${manualBloodPressureFirst}` : '',
    manualBloodPressureSecond ? `Second manual BP: ${manualBloodPressureSecond}` : '',
    bpDeviceManufacturer ? `BP device manufacturer: ${bpDeviceManufacturer}` : '',
    bpDeviceModelNumber ? `BP device model: ${bpDeviceModelNumber}` : '',
    `Age: ${age}`,
    `Height: ${feet}' ${inches}"`,
    `Weight: ${weight}`,
    `Makeup: ${capitalize(makeup)}`,
    makeupAmount ? `Makeup Amount: ${makeupAmount}` : '',
    `Facial Hair: ${capitalize(facialHair)}`,
    `Facial Tattoos: ${capitalize(facialTattoos)}`,
    `Glasses: ${capitalize(glasses)}`,
    `Sex: ${data.sex}`,
    `Ethnicity: ${data.ethnicity}`,
    `Race: ${data.race}`,
    `FitzpatrickScale: ${data.fitzpatrickScale}`,
    ...additionalDetails,
  ].filter(Boolean).join(' | ');
};

const scoreOffline = (data) => {
  const {
    timestamp,
    userId,
    type = 'bp',
    cameraPermissions,
    deviceError,
    vitals,
    manualBloodPressure,
    bpDeviceManufacturer,
    bpDeviceModelNumber,
    secondBloodPressure,
    secondBpDeviceManufacturer,
    secondBpDeviceModelNumber,
    secondCuffPosition,
    FPS,
    seconds,
    age,
    feet,
    inches,
    makeup,
    makeupAmount,
    weight,
    facialHair,
    facialTattoos,
    glasses,
    sex,
    fitzpatrickScale,
    monkScale,
    ethnicity,
    race,
    light_source,
    light_coverage,
    light_direction,
    light_direction_other,
    stability,
    ui_device,
    notes,
    caffeine,
    exercise,
    cuffPosition,
    cuffSize,
    htnCurrent,
    htnMedication,
    arrhythmiaType,
    operator1BP,
    operator2BP,
    operator1BPRetake,
    operator2BPRetake,
    secondaryOperator1BP,
    secondaryOperator2BP,
    secondaryOperator1BPRetake,
    secondaryOperator2BPRetake,
    location,
    location_other,
    environment,
  } = data;
  const {
    HR, BR, SPO2, SYS, DIAS,
  } = (vitals || {});

  if (!cameraPermissions) {
    return 'Patient declined.';
  }

  if (deviceError) {
    return `Device Error: ${deviceError}`;
  }

  let collection_type_data = [];
  let collection_type_title = '';

  switch (type) {
    case 'spo2':
      collection_type_title = 'SPO2 (Masimo)';
      collection_type_data = [
        `HR: ${HR}`,
        `BR: ${BR}`,
        `SPO2: ${SPO2}`,
        htnCurrent ? `Current Hypertension Diagnosis: ${capitalize(htnCurrent)}` : '',
        htnMedication ? `Current Hypertension Medications: ${capitalize(htnMedication)}` : '',
      ];
      break;
    case 'bp':
      collection_type_title = 'Blood Pressure (Vitalstream)';
      collection_type_data = [
        `HR: ${HR}`,
        `BR: ${BR}`,
        `SYS: ${SYS || '---'}`,
        `DIAS: ${DIAS || '---'}`,
        `Initial Manual BP: ${manualBloodPressure || '---'}`,
        `Initial BP device manufacturer: ${bpDeviceManufacturer || '---'}`,
        `Initial BP device model: ${bpDeviceModelNumber || '---'}`,
        `Initial Cuff Position: ${cuffPosition || '---'}`,
        `Second Manual BP: ${secondBloodPressure || '---'}`,
        `Second BP device manufacturer: ${secondBpDeviceManufacturer || '---'}`,
        `Second BP device model: ${secondBpDeviceModelNumber || '---'}`,
        `Second Cuff Position: ${secondCuffPosition || '---'}`,
        `Cuff Size: ${cuffSize || '---'}`,
        htnCurrent ? `Current Hypertension Diagnosis: ${capitalize(htnCurrent)}` : '',
        htnMedication ? `Current Hypertension Medications: ${capitalize(htnMedication)}` : '',
      ];
      break;
    case 'bp-spo2':
      collection_type_title = 'Blood Pressure (Masimo)';
      collection_type_data = [
        `HR: ${HR}`,
        `BR: ${BR}`,
        `SPO2: ${SPO2}`,
        `Initial BP - Operator 1: ${operator1BP || '---'}  Operator 2: ${operator2BP || '---'}`,
        `Initial BP Retakes - Operator 1: ${operator1BPRetake || '---'}  Operator 2: ${operator2BPRetake || '---'}`,
        `Initial Cuff Position: ${cuffPosition || '---'}`,
        `Second BP - Operator 1: ${secondaryOperator1BP || '---'}  Operator 2: ${secondaryOperator2BP || '---'}`,
        `Second BP Retakes - Operator 1: ${secondaryOperator1BPRetake || '---'}  ` +
        `Operator 2: ${secondaryOperator2BPRetake || '---'}`,
        `Second Cuff Position: ${secondCuffPosition || '---'}`,
        `Cuff Size: ${cuffSize || '---'}`,
        htnCurrent ? `Current Hypertension Diagnosis: ${capitalize(htnCurrent)}` : '',
        htnMedication ? `Current Hypertension Medications: ${capitalize(htnMedication)}` : '',
      ];
      break;
    case 'arrhythmia':
      collection_type_title = 'Arrhythmia';
      collection_type_data = [
        `Arrhythmia Type: ${arrhythmiaType || '---'}`,
      ];
      break;
    default:
      break;
  }

  const results = [
    `User: ${userId}`,
    `Timestamp: ${timestamp}`,
    `Collection Type: ${collection_type_title}`,
    `FPS: ${FPS}`,
    `Seconds: ${seconds}`,
    ...collection_type_data,
    `Sex: ${sex}`,
    `Ethnicity: ${ethnicity}`,
    `Race: ${race}`,
    `Age: ${age}`,
    `Height: ${feet}' ${inches}"`,
    `Weight: ${weight}`,
    `Fitzpatrick Scale: ${fitzpatrickScale}`,
    `Monk Scale: ${monkScale}`,
    `Facial Hair: ${capitalize(facialHair)}`,
    `Facial Tattoos: ${capitalize(facialTattoos)}`,
    `Glasses: ${capitalize(glasses)}`,
    `Makeup: ${capitalize(makeup)}`,
    makeupAmount ? `Makeup Amount: ${makeupAmount}` : '',
    `Exercise: ${capitalize(exercise)}`,
    `Caffeine: ${capitalize(caffeine)}`,
    `Stability: ${stability}`,
    `Light Source: ${light_source}`,
    `Light Coverage: ${light_coverage}`,
    `Light Direction: ${light_direction === 'other' ? light_direction_other : light_direction}`,
    location ? `Environment location: ${location === 'other' ? location_other : location}` : '',
    environment ? `Environment: ${environment}` : '',
    `Device: ${ui_device}`,
    `Notes: ${notes || 'None'}`,
  ];

  return results.filter(Boolean).join(' | ');
};

const scoreFull = (data) => {
  const ui_device = data.ui_device || data[10];
  const notes = data.notes || data[11];

  const additionalDetails = [
    `Stability: ${data.stability}`,
    `Light Source: ${data.light_source}`,
    `Light Direction: ${data.light_direction === 'other' ? data.light_direction_other : data.light_direction}`,
    `Light Coverage: ${data.light_coverage}`,
    `Device: ${ui_device}`,
    `Notes: ${notes || 'None'}`,
  ];

  return score(data, additionalDetails);
};

const scoreVitals = data => score(data);

const scoreFuncs = {
  OFFLINE: pro => commonScore(pro, scoreOffline),
  FULL: pro => commonScore(pro, scoreFull),
  VITALS: pro => commonScore(pro, scoreVitals),
};

export default scoreFuncs;
