import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blueBtnStyle, colors } from '../lib/styles';

const styles = {
  btnContainer: {
    display: 'flex',
    marginRight: '10px',
  },
  btnProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    textAlign: 'left',
    color: colors.primaryColor,
    fontWeight: 600,
    fontSize: '20px',
    marginTop: '10px',
    width: '50%',
  },
  topSection: {
    margin: '10px 0px 30px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
    width: '100%',
  },
};

const EmrMergeHeader = ({ onCancel, onNext, nextLabel, nextLoading, title }) => (
  <div style={styles.topSection}>
    <div style={styles.title}>{title}</div>
    <div style={styles.btnContainer}>
      <Button
        style={blueBtnStyle}
        onClick={onCancel}
        >Cancel Update</Button>
      <Button
        disabled={nextLoading}
        onClick={onNext}
        style={blueBtnStyle}
        >{nextLabel || 'Next'}
        {nextLoading && <CircularProgress size={24} style={styles.btnProgress} />}
      </Button>
    </div>
  </div>
);


export default EmrMergeHeader;
