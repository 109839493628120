import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  bottomBtnContainer: {
    padding: '3px 10px 10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottomBtnSecondRow: {
  },
};

class PatientViewFooter extends PureComponent {
  render() {
    return (
      <div className={this.props.classes.bottomBtnContainer}>
        {this.props.children}
      </div>
    );
  }
}

PatientViewFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(PatientViewFooter);
