import moment from 'moment';
import { get } from 'lodash';

function buildInitialObject(lastDayDate) {
  const end = Number(lastDayDate.format('DD')) || 31;
  const data = {};
  for (let i = 1; i <= end; i++) {
    data[i] = null;
  }
  return data;
}
function headachePainLineBuilder(data = [], month, year) {
  const firstDayDate = moment(new Date(year, month, 1));
  const lastDayDate = moment(new Date(year, month + 1, 0));

  const initialObject = buildInitialObject(lastDayDate);

  /* eslint-disable no-param-reassign */
  return data.reduce((lineData, currentPain) => {
    const severity = get(currentPain, 'pain.0.severity', 0);
    if (!severity) {
      return lineData;
    }
    const { start_time, end_time } = currentPain;
    const startDate = moment(start_time);
    const endDate = moment(end_time);
    const startDay = Number(startDate.format('DD'));

    const endDay = Number(endDate.format('DD'));

    if (startDate.isBetween(firstDayDate, lastDayDate) && lineData[startDay] < severity) {
      lineData[startDay] = severity;
    }

    if (startDate < endDate) {
      if (endDate.isBetween(firstDayDate, lastDayDate) && lineData[endDay] < severity) {
        lineData[endDay] = severity;
      }

      for (let currentDate = startDate; moment(currentDate) < endDate; currentDate = moment(currentDate).add(1, 'day')) {
        if (currentDate.isBetween(firstDayDate, lastDayDate)) {
          const currentDay = Number(currentDate.format('DD'));

          if (!lineData[currentDay] || lineData[currentDay] < severity) {
            lineData[currentDay] = severity;
          }
        }
      }
    }

    return lineData;
  }, initialObject);
  /* eslint-enable no-param-reassign */
}

export default headachePainLineBuilder;
