import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../themes/mindset/styles';
import { HeartBeat, Lungs, Thermometer, Scale, Spo2, BloodPressure } from '../lib/icons';

const useStyles = makeStyles({
  iconBlue: {
    color: colors.iconBlue,
  },
  iconRed: {
    color: colors.iconRed,
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: 70,
  },
  iconText: {
    fontSize: 12,
    marginBottom: 5,
  },
  iconTh: {
    verticalAlign: 'top',
  },
  iconWrapper: {
    width: 50,
  },
  noData: {
    margin: 5,
  },
  table: {
    borderSpacing: 0,
    '& td, & th': {
      padding: 5,
    },
  },
  tableBody: {
    '& > tr:nth-child(even)': {
      background: '#DEEBF6',
    },
  },
});

const VitalsTable = (props) => {
  const { vitalsData } = props;

  const classes = useStyles();

  if (!vitalsData.length) {
    return (
      <div className={classes.noData}>No measurements found for the day</div>
    );
  }

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <td colSpan="1" />
          <th className={classes.iconTh}>
            <div className={`${classes.iconContainer} ${classes.iconRed}`}>
              <div className={classes.iconText}>Heart Rate</div>
              <div className={classes.iconWrapper}>
                <HeartBeat />
              </div>
            </div>
          </th>
          <th className={classes.iconTh}>
            <div className={`${classes.iconContainer} ${classes.iconBlue}`}>
              <div className={classes.iconText}>Breath Rate</div>
              <div className={classes.iconWrapper}>
                <Lungs />
              </div>
            </div>
          </th>
          <th className={classes.iconTh}>
            <div className={`${classes.iconContainer} ${classes.iconRed}`}>
              <div className={classes.iconText}>Blood Pressure</div>
              <div className={classes.iconWrapper}>
                <BloodPressure />
              </div>
            </div>
          </th>
          <th className={classes.iconTh}>
            <div className={`${classes.iconContainer} ${classes.iconBlue}`}>
              <div className={classes.iconText}>SpO2</div>
              <div className={classes.iconWrapper}>
                <Spo2 />
              </div>
            </div>
          </th>
          <th className={classes.iconTh}>
            <div className={`${classes.iconContainer} ${classes.iconBlue}`}>
              <div className={classes.iconText}>BMI</div>
              <div className={classes.iconWrapper}>
                <Scale />
              </div>
            </div>
          </th>
          <th className={classes.iconTh}>
            <div className={`${classes.iconContainer} ${classes.iconRed}`}>
              <div className={classes.iconText}>Temperature</div>
              <div className={classes.iconWrapper}>
                <Thermometer />
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className={classes.tableBody}>
        {vitalsData.map((datum) => {
          const {
            time,
            HR,
            BR,
            SPO2,
            temperature,
            BMI,
            systolic,
            diastolic,
          } = datum;
          return (
            <tr key={`${time}${HR}${BR}${SPO2}${temperature}${BMI}${systolic}${diastolic}`}>
              <th>{time}</th>
              <td>{HR}</td>
              <td>{BR}</td>
              <td>{systolic && diastolic ? `${systolic}/${diastolic}` : null}</td>
              <td>{SPO2}</td>
              <td>{BMI}</td>
              <td>{temperature}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

VitalsTable.propTypes = {
  vitalsData: PropTypes.array.isRequired,
};

export default VitalsTable;
