export default (pro) => {
  const { data } = pro.pro_data;

  const {
    physicianName,
    physicianEmail,
    physicianPhone,
    diagnoses,
    medications,
    lengthOfPregnancy,
    daysInNICU,
    pregnancyComplications,
    illness,
    visionHearing,
    visionHearingExplanation,
    sleep,
    diet,
    allergies,
    allergiesExplanation,
    aversions,
    failureToThrive,
    diabetes,
    familyConflict,
    bullying,
    anxiety,
    hyperactivity,
    hitting,
    foodSelectivity,
    propertyDestruction,
    febrileSeizures,
    asthma,
    tantrums,
    physicalAbuse,
    dealthLoss,
    depression,
    restrictedInterests,
    kicking,
    inappropriateTouch,
    headBanging,
    epilepsy,
    eatingDifficulties,
    selfInjuriousBehavior,
    emotionalAbuse,
    abandonment,
    seizures,
    biting,
    runningAway,
    suicidalIdeation,
    prolongedHospitalization,
    otherConcerns,
    experienceElaboration,
    existingTherapies,
    existingTherapiesExplanation,
    communicationDelay,
    crawlingDelay,
    walkingDelay,
    feedingDelay,
    socialSkillsDelay,
    selfHelpSkillsDelay,
    regression,
    regressionExplanation,
    primaryLanguage,
    handLeading,
    gesturePointing,
    soundsVocalization,
    singleWords,
    shortPhrases,
    communicationDeviceLowTech,
    communicationDeviceTablet,
    toiletTrained,
    toiletTrainedExplanation,
    ongoingConcerns,
    learningDifficultiesFamily,
    alcoholismFamily,
    ocdFamily,
    adhdFamily,
    seizuresFamily,
    drugAbuseFamily,
    personalityDisorderFamily,
    autismSpectrumDisorderFamily,
    depressionFamily,
    anxietyFamily,
    mthfrGeneMutationFamily,
    familyHistoryElaboration,
    currentSchool,
    grade,
    schoolAddress,
    typePlacement,
    strengthsConcerns,
    supports,
    schoolTransfer,
    schedule,
  } = data;

  const s = [];

  s.push(`Primary Physician Name: ${physicianName}`);
  s.push(`Primary Physician Email: ${physicianEmail}`);
  s.push(`Primary Physician Phone: ${physicianPhone}`);
  if (diagnoses.length) {
    let diagnosesStr = '';
    diagnoses.forEach((diagnosis) => {
      // eslint-disable-next-line max-len
      diagnosesStr = `${diagnosesStr}${diagnosesStr ? '; ' : ''} ${diagnosis.name}, ${diagnosis.date}, ${diagnosis.physician}`;
    });
    s.push(`Diagnoses: ${diagnosesStr}`);
  } else {
    s.push('Diagnoses: N');
  }
  if (medications.length) {
    let medicationsStr = '';
    medications.forEach((medication) => {
      // eslint-disable-next-line max-len
      medicationsStr = `${medicationsStr}${medicationsStr ? '; ' : ''} ${medication.medication}, ${medication.dose}, ${medication.reason}, ${medication.physician}, ${medication.length}`;
    });
    s.push(`Medications: ${medicationsStr}`);
  } else {
    s.push('Medications: N');
  }
  s.push(`Length of Pregnancy: ${lengthOfPregnancy} months`);
  s.push(`Days in NICU: ${daysInNICU}`);
  s.push(`Pregnancy Complications: ${pregnancyComplications}`);
  s.push(`Serious Illness/Injury: ${illness}`);

  if (visionHearing && visionHearing === 'yes') {
    s.push('Vision or Hearing Difficulties: Y');
  } else {
    s.push('Vision or Hearing Difficulties: N');
  }
  if (visionHearing && visionHearing === 'yes' && visionHearingExplanation) {
    s.push(`Vision or Hearing Difficulties details: ${visionHearingExplanation}`);
  }

  s.push(`Sleep Habits: ${sleep}`);
  s.push(`Diet/Eating Habits: ${diet}`);

  if (allergies && allergies === 'yes') {
    s.push('Food Allergies: Y');
  } else {
    s.push('Food Allergies: N');
  }
  if (allergies && allergies === 'yes' && allergiesExplanation) {
    s.push(`Food Allergies details: ${allergiesExplanation}`);
  }

  s.push(`Aversions/Sensativities: ${aversions}`);

  s.push(`Failure-to-thrive: ${failureToThrive ? 'Y' : 'N'}`);
  s.push(`Diabetes: ${diabetes ? 'Y' : 'N'}`);
  s.push(`Family Conflict: ${familyConflict ? 'Y' : 'N'}`);
  s.push(`Bullying: ${bullying ? 'Y' : 'N'}`);
  s.push(`Anxiety: ${anxiety ? 'Y' : 'N'}`);
  s.push(`Hyperactivity: ${hyperactivity ? 'Y' : 'N'}`);
  s.push(`Hitting: ${hitting ? 'Y' : 'N'}`);
  s.push(`Food Selectivity: ${foodSelectivity ? 'Y' : 'N'}`);
  s.push(`Property Desctruction: ${propertyDestruction ? 'Y' : 'N'}`);
  s.push(`Febrile Seizures: ${febrileSeizures ? 'Y' : 'N'}`);
  s.push(`Asthma: ${asthma ? 'Y' : 'N'}`);
  s.push(`Tantrums: ${tantrums ? 'Y' : 'N'}`);
  s.push(`Physical Abuse: ${physicalAbuse ? 'Y' : 'N'}`);
  s.push(`Death/Loss: ${dealthLoss ? 'Y' : 'N'}`);
  s.push(`Depression: ${depression ? 'Y' : 'N'}`);
  s.push(`Resctricted Interests: ${restrictedInterests ? 'Y' : 'N'}`);
  s.push(`Kicking: ${kicking ? 'Y' : 'N'}`);
  s.push(`Inappropriate Touch: ${inappropriateTouch ? 'Y' : 'N'}`);
  s.push(`Head Banging: ${headBanging ? 'Y' : 'N'}`);
  s.push(`Epilepsy: ${epilepsy ? 'Y' : 'N'}`);
  s.push(`Eating Difficulties: ${eatingDifficulties ? 'Y' : 'N'}`);
  s.push(`Self-Injurious Behavior: ${selfInjuriousBehavior ? 'Y' : 'N'}`);
  s.push(`Emotional Abuse: ${emotionalAbuse ? 'Y' : 'N'}`);
  s.push(`Abandonment: ${abandonment ? 'Y' : 'N'}`);
  s.push(`Seizures: ${seizures ? 'Y' : 'N'}`);
  s.push(`Biting: ${biting ? 'Y' : 'N'}`);
  s.push(`Running Away: ${runningAway ? 'Y' : 'N'}`);
  s.push(`Suicidal Ideation: ${suicidalIdeation ? 'Y' : 'N'}`);
  s.push(`Prolonged Hospitalization: ${prolongedHospitalization ? 'Y' : 'N'}`);
  s.push(`Other Behavior or Experiences: ${otherConcerns ? 'Y' : 'N'}`);

  if (experienceElaboration) {
    s.push(`Behavior or Experiences details: ${experienceElaboration}`);
  }

  if (existingTherapies && existingTherapies === 'yes') {
    s.push('Past/Existing therapies or school accomodations: Y');
  } else {
    s.push('Past/Existing therapies or school accomodations: N');
  }
  if (existingTherapies && existingTherapies === 'yes' && existingTherapiesExplanation) {
    s.push(`Past/Existing therapies or school accomodations details: ${existingTherapiesExplanation}`);
  }

  s.push(`Delayed Communication: ${communicationDelay ? 'Y' : 'N'}`);
  s.push(`Delayed Crawling: ${crawlingDelay ? 'Y' : 'N'}`);
  s.push(`Delayed Walking: ${walkingDelay ? 'Y' : 'N'}`);
  s.push(`Delayed Feeding: ${feedingDelay ? 'Y' : 'N'}`);
  s.push(`Delayed Social Skills: ${socialSkillsDelay ? 'Y' : 'N'}`);
  s.push(`Delayed Self-Help Skills: ${selfHelpSkillsDelay ? 'Y' : 'N'}`);

  if (regression && regression === 'yes') {
    s.push('Regressions: Y');
  } else {
    s.push('Regressions: N');
  }
  if (regression && regression === 'yes' && regressionExplanation) {
    s.push(`Regressions details: ${regressionExplanation}`);
  }

  s.push(`Primary Language: ${primaryLanguage}`);
  s.push(`(Primary Communication) Hand Leading: ${handLeading ? 'Y' : 'N'}`);
  s.push(`(Primary Communication) Gesture/Pointing: ${gesturePointing ? 'Y' : 'N'}`);
  s.push(`(Primary Communication) Sounds/Vocalization: ${soundsVocalization ? 'Y' : 'N'}`);
  s.push(`(Primary Communication) Single Words: ${singleWords ? 'Y' : 'N'}`);
  s.push(`(Primary Communication) Short Phrases: ${shortPhrases ? 'Y' : 'N'}`);
  s.push(`(Primary Communication) Low-Tech Communucation Device: ${communicationDeviceLowTech ? 'Y' : 'N'}`);
  s.push(`(Primary Communication) Tablet Communucation Device: ${communicationDeviceTablet ? 'Y' : 'N'}`);

  if (toiletTrained && toiletTrained === 'yes') {
    s.push('Toilet Training: Y');
  } else {
    s.push('Toilet Training: N');
  }
  if (toiletTrained && toiletTrained === 'yes' && regressionExplanation) {
    s.push(`When toilet trained: ${toiletTrainedExplanation}`);
  }

  s.push(`Ongoing toileting concerns: ${ongoingConcerns}`);
  s.push(`(Family History) Learning Difficulties: ${learningDifficultiesFamily ? 'Y' : 'N'}`);
  s.push(`(Family History) Alcoholism: ${alcoholismFamily ? 'Y' : 'N'}`);
  s.push(`(Family History) OCD: ${ocdFamily ? 'Y' : 'N'}`);
  s.push(`(Family History) ADHD: ${adhdFamily ? 'Y' : 'N'}`);
  s.push(`(Family History) Seizures: ${seizuresFamily ? 'Y' : 'N'}`);
  s.push(`(Family History) Drug Abuse: ${drugAbuseFamily ? 'Y' : 'N'}`);
  s.push(`(Family History) Personality Disorder: ${personalityDisorderFamily ? 'Y' : 'N'}`);
  s.push(`(Family History) Autism Spectrum Disorder: ${autismSpectrumDisorderFamily ? 'Y' : 'N'}`);
  s.push(`(Family History) Depression: ${depressionFamily ? 'Y' : 'N'}`);
  s.push(`(Family History) Anxiety: ${anxietyFamily ? 'Y' : 'N'}`);
  s.push(`(Family History) MTHFR Gene Mutation: ${mthfrGeneMutationFamily ? 'Y' : 'N'}`);
  if (familyHistoryElaboration) {
    s.push(`Family history details: ${familyHistoryElaboration}`);
  }

  s.push(`Current School: ${currentSchool}`);
  s.push(`Grade: ${grade}`);
  s.push(`School Address: ${schoolAddress}`);
  s.push(`Type of Placement: ${typePlacement}`);
  s.push(`Teacher Reported Strenghts/Concerns: ${strengthsConcerns}`);
  s.push(`Supports in place for child: ${supports}`);
  if (schoolTransfer && schoolTransfer === 'yes') {
    s.push('School Transfers: Y');
  } else {
    s.push('School Transfers: N');
  }

  if (schedule.length) {
    let scheduleStr = '';
    schedule.forEach((day) => {
      scheduleStr = `${scheduleStr}${scheduleStr ? '; ' : ''} ${day.day}, ${day.elaboration}`;
    });
    s.push(`School Schedule: ${scheduleStr}`);
  } else {
    s.push('School Schedule: N');
  }

  pro.score.score = s.join(' | ');
  return pro;
};
