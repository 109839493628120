import { combineReducers } from 'redux';

import appData from './app-data';
import appointments from './appointments';
import clinic from './clinic';
import devices from './devices';
import emr from './emr';
import emrMessages from './emr-messages';
import esign from './esign';
import forms from './forms';
import mergeData from './merge-data';
import migraines from './migraines';
import modal from './modal';
import notifications from './notifications';
import painHx from './pain-hx';
import proSubmissions from './pro-submissions';
import surgeryHx from './surgery-hx';
import tableSettings from './tables';
import recent from './recent';
import user from './user';
import userImages from './user-images';
import { LOGOUT } from '../actions/user';

const reducers = combineReducers({
  appData,
  appointments,
  clinic,
  devices,
  emr,
  emrMessages,
  esign,
  forms,
  notifications,
  mergeData,
  migraines,
  modal,
  painHx,
  proSubmissions,
  recent,
  surgeryHx,
  tableSettings,
  user,
  userImages,
});

const appReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return reducers(undefined, action);
  }

  return reducers(state, action);
};

export default appReducer;
