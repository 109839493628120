/* eslint-disable */
export const email = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const phone = /^\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})$/;
/* eslint-enable */

export const isPasswordValid = (oldPassword, newPassword, confirmPassword) => {
  const err = {};

  if (!oldPassword) {
    err.oldPassword = 'Please enter your old password';
  }

  if (!newPassword) {
    err.newPassword = 'Please enter your new password';
  }

  if (!confirmPassword) {
    err.confirmPassword = 'Please confirm your new password';
  }

  if (Object.keys(err).length === 0) {
    if (newPassword !== confirmPassword) {
      err.newPassword = 'Passwords do not match';
      err.confirmPassword = 'Passwords do not match';
    } else if (newPassword.length <= 12) {
      err.newPassword = 'Password must be at least 12 characters long';
    } else if (newPassword === oldPassword) {
      err.newPassword = 'New password must be different from old password';
    } else if (newPassword.search(/\d/) === -1) {
      err.newPassword = 'Password must contain at least one number';
    } else if (newPassword.search(/[a-zA-Z]/) === -1) {
      err.newPassword = 'Password must contain at least one letter';
    } else if (newPassword.search(/[!@#$%^&*]/) === -1) {
      err.newPassword = 'Password must contain at least one of the following special characters: !@#$%^&*';
    } else if (newPassword.search(/\s/) !== -1) {
      err.newPassword = 'Password cannot contain spaces';
    }
  }

  return err;
};
