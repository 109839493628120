import { get, isUndefined } from 'lodash';
import moment from 'moment';

export function stateFormat(insurance, emrIns) {
  const emrPNI = isUndefined(get(emrIns[0], 'Insured.FirstName')) ? null : `${get(emrIns[0], 'Insured.FirstName')} ${get(emrIns[0], 'Insured.LastName') || ''}`;
  //  const emrSNI = isUndefined(get(emrIns[1], 'Insured.FirstName')) ? null : `${get(emrIns[1], 'Insured.FirstName')} ${get(emrIns[1], 'Insured.LastName') || ''}`;
  const emrPED = isUndefined(get(emrIns[0], 'EffectiveDate')) ? null : moment(get(emrIns[0], 'EffectiveDate')).format('DD-MM-YYYY');
  const emrPExD = isUndefined(get(emrIns[0], 'EffectiveDate')) ? null : moment(get(emrIns[0], 'EffectiveDate')).format('DD-MM-YYYY');
  //  const emrSED = isUndefined(get(emrIns[1], 'EffectiveDate')) ? null : moment(get(emrIns[1], 'EffectiveDate')).format('DD-MM-YYYY');
  //  const emrSExD = isUndefined(get(emrIns[1], 'ExpirationDate')) ? null : moment(get(emrIns[1], 'ExpirationDate')).format('DD-MM-YYYY');
  return {
    primary_insurance_name: {
      selection: 'mindset',
      mindset: insurance.primary_insurance_name,
      emr: get(emrIns[0], 'Company.Name'),
      label: 'Name',
    },
    primary_name_insured: {
      selection: 'mindset',
      mindset: insurance.primary_name_insured,
      emr: emrPNI,
      label: 'Insured Named',
    },
    primary_address: {
      selection: 'mindset',
      mindset: insurance.primary_address,
      emr: get(emrIns[0], 'Company.Address.StreetAddress'),
      label: 'Street Address',
    },
    primary_city: {
      selection: 'mindset',
      mindset: insurance.primary_city,
      emr: get(emrIns[0], 'Company.Address.City'),
      label: 'City',
    },
    primary_state: {
      selection: 'mindset',
      mindset: insurance.primary_state,
      emr: get(emrIns[0], 'Company.Address.State'),
      label: 'State',
    },
    primary_zip: {
      selection: 'mindset',
      mindset: insurance.primary_zip,
      emr: get(emrIns[0], 'Company.Address.ZIP'),
      label: 'Zip Code',
    },
    primary_phone: {
      selection: 'mindset',
      mindset: insurance.primary_phone,
      emr: get(emrIns[0], 'Company.PhoneNumber'),
      label: 'Phone',
    },
    primary_policy_number: {
      selection: 'mindset',
      mindset: insurance.primary_policy_number,
      emr: get(emrIns[0], 'PolicyNumber'),
      label: 'Policy Number',
    },
    primary_policy_group_number: {
      selection: 'mindset',
      mindset: insurance.primary_policy_group_number,
      emr: get(emrIns[0], 'GroupNumber'),
      label: 'Policy Group Number',
    },
    primary_policy_claim_number: {
      selection: 'mindset',
      mindset: insurance.primary_policy_claim_number,
      emr: '',
      label: 'Policy Claim Number',
    },
    primary_policy_copay_number: {
      selection: 'mindset',
      mindset: insurance.primary_policy_copay_number,
      emr: '',
      label: 'Policy Copay Number',
    },
    primary_effective_date: {
      selection: 'mindset',
      mindset: insurance.primary_effective_date ? moment(insurance.primary_effective_date).format('MM-DD-YYYY') : '',
      emr: emrPED,
      label: 'Effective Date',
    },
    primary_expiration_date: {
      selection: 'mindset',
      mindset: insurance.primary_expiration_date ? moment(insurance.primary_expiration_date).format('MM-DD-YYYY') : '',
      emr: emrPExD,
      label: 'Expiration Date',
    },
    /* Removed per designs
    secondary_insurance_name: {
      selection: 'mindset',
      mindset: insurance.secondary_insurance_name,
      emr: get(emrIns[1], 'Company.Name'),
      label: 'Secondary Insurance',
    },
    secondary_name_insured: {
      selection: 'mindset',
      mindset: insurance.secondary_name_insured,
      emr: emrSNI,
      label: '(Secondary) Name Insured',
    },
    secondary_address: {
      selection: 'mindset',
      mindset: insurance.secondary_address,
      emr: get(emrIns[1], 'Company.Address.StreetAddress'),
      label: '(Secondary) Address',
    },
    secondary_city: {
      selection: 'mindset',
      mindset: insurance.secondary_city,
      emr: get(emrIns[1], 'Company.Address.City'),
      label: '(Secondary) City',
    },
    secondary_state: {
      selection: 'mindset',
      mindset: insurance.secondary_state,
      emr: get(emrIns[1], 'Company.Address.State'),
      label: '(Secondary) State',
    },
    secondary_zip: {
      selection: 'mindset',
      mindset: insurance.secondary_zip,
      emr: get(emrIns[1], 'Company.Address.ZIP'),
      label: '(Secondary) ZIP',
    },
    secondary_phone: {
      selection: 'mindset',
      mindset: insurance.secondary_phone,
      emr: get(emrIns[1], 'Company.PhoneNumber'),
      label: '(Secondary) Phone',
    },
    secondary_policy_number: {
      selection: 'mindset',
      mindset: insurance.secondary_policy_number,
      emr: get(emrIns[1], 'PolicyNumber'),
      label: '(Secondary) Policy Number',
    },
    secondary_policy_group_number: {
      selection: 'mindset',
      mindset: insurance.secondary_policy_group_number,
      emr: get(emrIns[1], 'GroupNumber'),
      label: '(Secondary) Group Number',
    },
    secondary_policy_claim_number: {
      selection: 'mindset',
      mindset: insurance.secondary_policy_claim_number,
      emr: '',
      label: '(Secondary) Claim Number',
    },
    secondary_policy_copay_number: {
      selection: 'mindset',
      mindset: insurance.secondary_policy_claim_number,
      emr: '',
      label: '(Secondary) Copay Number',
    },
    secondary_effective_date: {
      selection: 'mindset',
      mindset: insurance.secondary_effective_date ? moment(insurance.secondary_effective_date).format('MM-DD-YYYY') : '',
      emr: emrSED,
      label: '(Secondary) Effective Date',
    },
    secondary_expiration_date: {
      selection: 'mindset',
      mindset: insurance.secondary_expiration_date ? moment(insurance.secondary_expiration_date).format('MM-DD-YYYY') : '',
      emr: emrSExD,
      label: '(Secondary) Expiration Date',
    },
    workers_comp_pre_auth: {
      selection: 'mindset',
      mindset: insurance.workers_comp_pre_auth,
      emr: '',
      label: 'Workers Comp Preauthorization',
    },
    workers_comp_injury_date: {
      selection: 'mindset',
      mindset: insurance.workers_comp_injury_date ? moment(insurance.workers_comp_injury_date).format('MM-DD-YYYY') : '',
      emr: '',
      label: 'Workers Comp Injury Date',
    },
  */
  };
}

export function mergeFormat(state) {
  return {
    primary_insurance_name: state.primary_insurance_name[state.primary_insurance_name.selection],
    primary_name_insured: state.primary_name_insured[state.primary_name_insured.selection],
    primary_address: state.primary_address[state.primary_address.selection],
    primary_city: state.primary_city[state.primary_city.selection],
    primary_state: state.primary_state[state.primary_state.selection],
    primary_zip: state.primary_zip[state.primary_zip.selection],
    primary_phone: state.primary_phone[state.primary_phone.selection],
    primary_policy_number: state.primary_policy_number[state.primary_policy_number.selection],
    primary_policy_group_number: state.primary_policy_group_number[state.primary_policy_group_number.selection],
    primary_policy_claim_number: state.primary_policy_claim_number[state.primary_policy_claim_number.selection],
    primary_policy_copay_number: state.primary_policy_copay_number[state.primary_policy_copay_number.selection],
    primary_effective_date: state.primary_effective_date[state.primary_effective_date.selection],
    primary_expiration_date: state.primary_expiration_date[state.primary_expiration_date.selection],
    //    secondary_insurance_name: state.secondary_insurance_name[state.secondary_insurance_name.selection],
    //    secondary_name_insured: state.secondary_name_insured[state.secondary_name_insured.selection],
    //    secondary_address: state.secondary_address[state.secondary_address.selection],
    //    secondary_city: state.secondary_city[state.secondary_city.selection],
    //    secondary_state: state.secondary_state[state.secondary_state.selection],
    //    secondary_zip: state.secondary_zip[state.secondary_zip.selection],
    //    secondary_phone: state.secondary_phone[state.secondary_phone.selection],
    //    secondary_policy_number: state.secondary_policy_number[state.secondary_policy_number.selection],
    //    secondary_policy_group_number: state.secondary_policy_group_number[state.secondary_policy_group_number.selection],
    //    secondary_policy_claim_number: state.secondary_policy_claim_number[state.secondary_policy_claim_number.selection],
    //    secondary_policy_copay_number: state.secondary_policy_copay_number[state.secondary_policy_copay_number.selection],
    //    secondary_effective_date: state.secondary_effective_date[state.secondary_effective_date.selection],
    //    secondary_expiration_date: state.secondary_expiration_date[state.secondary_expiration_date.selection],
    //    workers_comp_pre_auth: state.workers_comp_pre_auth[state.workers_comp_pre_auth.selection],
    //    workers_comp_injury_date: state.workers_comp_injury_date[state.workers_comp_injury_date.selection],
  };
}
