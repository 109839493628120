import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import PatientViewContentSection from './patient-view-content-section';
import FormList from './form-list';

import { blueBtnStyle } from '../lib/styles';

const styles = {
  btn: blueBtnStyle,
};

const PatientOverviewTab = (props) => {
  const { classes, handleOpenAllForms, handleOpenForm } = props;

  return (
    <Fragment>
      {props.children}
      <PatientViewContentSection>
        <section>
          <Button
            className={classes.btn}
            onClick={() => {
              handleOpenAllForms();
            }}
            variant="contained"
          >
            Review Form(s)
          </Button>
        </section>
        <FormList
          forms={props.forms}
          onOpenForm={handleOpenForm}
        />
      </PatientViewContentSection>
    </Fragment>
  );
};

PatientOverviewTab.defaultProps = {
  appointment: {
    waitTime: '00:00',
  },
  forms: {},
  painLevel: '',
};

PatientOverviewTab.propTypes = {
  appointment: PropTypes.object,
  classes: PropTypes.object.isRequired,
  forms: PropTypes.object,
  handleOpenAllForms: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  painLevel: PropTypes.string,
  router: PropTypes.object.isRequired,
  updateAppointment: PropTypes.func.isRequired,
};

export default withStyles(styles)(PatientOverviewTab);
