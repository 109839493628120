import { promiseHandler } from 'cooldux';
import { get, keyBy } from 'lodash';
import { apiFetch } from '../lib/fetch';

const {
  browsePainLocationsStart,
  browsePainLocationsEnd,
  browsePainLocationsError,
  browsePainLocationsHandler,
} = promiseHandler('browsePainLocations', 'application');
const {
  browseAggravatingFactorsStart,
  browseAggravatingFactorsEnd,
  browseAggravatingFactorsError,
  browseAggravatingFactorsHandler,
} = promiseHandler('browseAggravatingFactors', 'application');
const {
  browseAlleviatingFactorsStart,
  browseAlleviatingFactorsEnd,
  browseAlleviatingFactorsError,
  browseAlleviatingFactorsHandler,
} = promiseHandler('browseAlleviatingFactors', 'application');
const {
  browseFunctionalLimitationsStart,
  browseFunctionalLimitationsEnd,
  browseFunctionalLimitationsError,
  browseFunctionalLimitationsHandler,
} = promiseHandler('browseFunctionalLimitations', 'application');

const {
  browseSurgeryStart,
  browseSurgeryEnd,
  browseSurgeryError,
  browseSurgeryHandler,
} = promiseHandler('browseSurgery', 'application');

const {
  browseProTypesStart,
  browseProTypesEnd,
  browseProTypesError,
  browseProTypesHandler,
} = promiseHandler('browseProTypes', 'application');

export function browseSurgery() {
  return (dispatch, getState) => {
    const { appData } = getState();
    if (!appData.surgery) {
      const clinicId = get(getState(), 'clinic.clinicId');
      const promise = apiFetch(`/clinics/${clinicId}/surgeries`);
      return browseSurgeryHandler(promise, dispatch);
    }
  };
}

export function browseProTypes() {
  return (dispatch, getState) => {
    const { appData } = getState();
    if (!appData.proTypes) {
      const clinicId = get(getState(), 'clinic.clinicId');
      const promise = apiFetch(`/clinics/${clinicId}/pros`);
      return browseProTypesHandler(promise, dispatch);
    }
  };
}

export function browsePainLocations() {
  return (dispatch) => {
    const promise = apiFetch('/pain_locations');
    return browsePainLocationsHandler(promise, dispatch);
  };
}

export function browsePainLocationsIfNeeded() {
  return function dispatcher(dispatch, getState) {
    const { appData } = getState();
    if (!appData.painLocations) {
      dispatch(browsePainLocations());
    }
  };
}

export function browseAggravatingFactors() {
  return (dispatch) => {
    const promise = apiFetch('/aggravating_factors');
    return browseAggravatingFactorsHandler(promise, dispatch);
  };
}

export function browseAlleviatingFactors() {
  return (dispatch) => {
    const promise = apiFetch('/alleviating_factors');
    return browseAlleviatingFactorsHandler(promise, dispatch);
  };
}

export function browseFunctionalLimitations() {
  return (dispatch) => {
    const promise = apiFetch('/functional_limitations');
    return browseFunctionalLimitationsHandler(promise, dispatch);
  };
}

const initialState = {
  browseAggravatingFactorsError: null,
  browseAlleviatingFactorsError: null,
  browseFunctionalLimitationsError: null,
  browsePainLocationsError: null,
  browseSurgeryError: null,
  browseProTypesError: null,

  browseAggravatingFactorsPending: false,
  browseAlleviatingFactorsPending: false,
  browseFunctionalLimitationsPending: false,
  browsePainLocationsPending: false,
  browseSurgeryPending: false,
  browseProTypesPending: false,

  aggravatingFactors: null,
  alleviatingFactors: null,
  functionalLimitations: null,
  painLocations: null,
  surgery: null,
  proTypes: null,

};


function finishBrowse(state, response, table, method, options) {
  return {
    ...state,
    [table]: options.hasId ? keyBy(response, 'id') : response,
    [`${method}Error`]: null,
    [`${method}Pending`]: false,
  };
}


function appData(state = initialState, { type, payload }) {
  switch (type) {
    case browsePainLocationsStart.type:
      return { ...state, browsePainLocationsError: null, browsePainLocationsPending: true };
    case browsePainLocationsEnd.type:
      return finishBrowse(state, payload, 'painLocations', 'browsePainLocations', { hasId: true });
    case browsePainLocationsError.type:
      return { ...state, browsePainLocationsError: payload };

    case browseAggravatingFactorsStart.type:
      return { ...state, browseAggravatingFactorsError: null, browseAggravatingFactorsPending: true };
    case browseAggravatingFactorsEnd.type:
      return finishBrowse(state, payload, 'aggravatingFactors', 'browseAggravatingFactors', { hasId: true });
    case browseAggravatingFactorsError.type:
      return { ...state, browseAggravatingFactorsError: payload };

    case browseAlleviatingFactorsStart.type:
      return { ...state, browseAlleviatingFactorsError: null, browseAlleviatingFactorsPending: true };
    case browseAlleviatingFactorsEnd.type:
      return finishBrowse(state, payload, 'alleviatingFactors', 'browseAlleviatingFactors', { hasId: true });
    case browseAlleviatingFactorsError.type:
      return { ...state, browseAlleviatingFactorsError: payload };

    case browseFunctionalLimitationsStart.type:
      return { ...state, browseFunctionalLimitationsError: null, browseFunctionalLimitationsPending: true };
    case browseFunctionalLimitationsEnd.type:
      return finishBrowse(state, payload, 'functionalLimitations', 'browseFunctionalLimitations', { hasId: true });
    case browseFunctionalLimitationsError.type:
      return { ...state, browseFunctionalLimitationsError: payload };

    case browseSurgeryStart.type:
      return { ...state, browseSurgeryError: null, browseSurgeryPending: true };
    case browseSurgeryEnd.type:
      return finishBrowse(state, payload, 'surgery', 'browseSurgery', { hasId: false });
    case browseSurgeryError.type:
      return { ...state, browseSurgeryError: payload };

    case browseProTypesStart.type:
      return { ...state, browseProTypesError: null, browseProTypesPending: true };
    case browseProTypesEnd.type:
      return finishBrowse(state, payload, 'proTypes', 'browseProTypes', { hasId: false });
    case browseProTypesError.type:
      return { ...state, browseProTypesError: payload, browseProTypesPending: false  };

    default:
      return state;
  }
}

export default appData;
