import React from 'react';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import PropTypes from 'prop-types';

import BundledEvent from '../components/bundled-event';
import AppWrapper from '../wrappers/app-wrapper';
import { apiFetch } from '../lib/fetch';

const pageDetails = {
  CAMPAIGN_BUNDLE: {
    title: 'Create a Care Pathway Bundle',
    route: 'care-pathway-bundled-events',
  },
  APPOINTMENT_BUNDLE: {
    title: 'Create an Appointment Bundle',
    route: 'appointment-bundled-events',
    instructions: 'Bundled Events will trigger as a single notification to the patient. Patients are then guided through each of the events in the order listed below. Each event type may only be used a single time. To send a bundled event to a patient, first add it when scheduling an appointment.',
  },
};

const CreateBundledEvent = (props) => {
  const bundle_type = includes(props.route.path, 'care-pathway') ? 'CAMPAIGN_BUNDLE' : 'APPOINTMENT_BUNDLE';
  const handleSave = async (name, events) => {
    const opts = {
      method: 'POST',
      body: {
        name,
        bundle_spec: { events },
        bundle_type,
      },
    };
    try {
      await apiFetch(`/clinics/${props.clinicId}/bundled_events`, opts);
      props.router.push(`/app/${pageDetails[bundle_type].route}?message=created`);
    } catch (e) {
      console.error('error saving: ', e);
    }
  };

  return (
    <AppWrapper router={props.router}>
      <BundledEvent
        onSave={handleSave}
        headerText={pageDetails[bundle_type].title}
        instructions={pageDetails[bundle_type].instructions}
        bundleType={bundle_type}
      />
    </AppWrapper>
  );
};

function mapStateToProps(state) {
  const {
    clinic: { clinicId },
  } = state;

  return {
    clinicId,
  };
}
CreateBundledEvent.propTypes = {
  clinicId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
};


export default connect(mapStateToProps, {})(CreateBundledEvent);
