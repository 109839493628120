import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import moment from 'moment';
import useSWR from 'swr';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'; // circle
import StopIcon from '@material-ui/icons/Stop'; // square
import WarningIcon from '@material-ui/icons/Warning'; // triangle

import { apiFetch } from '../lib/fetch';
import AppWrapper from '../wrappers/app-wrapper';
import { colors } from '../lib/styles';
import camConstraints from '../lib/cam-constraints';
import { chatEvents } from '../websocket';

const styles = {
  cardAvatar: {
    marginLeft: 10,
    marginTop: 5,
  },
  cardBtn: {
    width: '100%',
    backgroundColor: '#474044',
    color: '#fff',
    fontWeight: 'bold',
  },
  cardContent: {
    textAlign: 'center',
  },
  cardHeader: {
    borderBottom: `1px solid ${colors.unoccupiedExamRoomGrey}`,
    paddingBottom: 8,
    backgroundColor: colors.questionBackground,
  },
  cardTitle: {
    fontWeight: 'bold',
  },
  cardWidth100: {
    width: '100%',
  },
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  root: {
    marginBottom: '150px',
    padding: '25px',
    textAlign: 'left',
    width: '100%',
  },
  avatar: {
    height: '60px',
    marginRight: '8px',
    verticalAlign: 'middle',
    width: '60px',
  },
  columns: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    padding: '15px 0',
  },
  room: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '372px',
    height: '248px',
    margin: '10px',
    cursor: 'pointer',
    borderRadius: 15,
  },
  patient: {
    display: 'flex',
    alignItems: 'center',
  },
  IN_SESSION: {
    border: `5px solid ${colors.calendarGreen}`,
  },
  ON_HOLD: {
    border: `5px solid ${colors.onHoldExamRoomYellow}`,
  },
  WAITING: {
    border: `5px solid ${colors.errorRed}`,
  },
  unoccupied: {
    border: `5px solid ${colors.unoccupiedExamRoomGrey}`,
    cursor: 'auto',
  },
  inSessionIcon: {
    color: colors.calendarGreen,
  },
  onHoldIcon: {
    color: colors.onHoldExamRoomYellow,
  },
  waitingIcon: {
    color: colors.errorRed,
  },
  unoccupiedIcon: {
    color: colors.lightGrayText,
  },
};

const Room = ({
  classes, now, room, index, router,
}) => {
  const roomId = index + 1;
  const waitTime = room && Math.round((now.diff(room.updated_at) / 1000) / 60);

  let roomStatus = room ? room.status : '--';
  if (roomStatus === 'IN_SESSION') roomStatus = 'In Session';
  if (roomStatus === 'ON_HOLD') roomStatus = 'On Hold';
  if (roomStatus === 'WAITING') roomStatus += ` for ${waitTime} min`;

  let doctor = room ? room.provider_name : '--';
  if (!doctor) doctor = '--';

  let icon;

  if (room && room.status === 'IN_SESSION') {
    icon = <FiberManualRecordIcon className={classes.inSessionIcon} />;
  } else if (room && room.status === 'WAITING') {
    icon = <WarningIcon className={classes.waitingIcon} />;
  } else if (room && room.status === 'ON_HOLD') {
    icon = <StopIcon className={classes.onHoldIcon} />;
  } else {
    icon = <FiberManualRecordIcon className={classes.unoccupiedIcon} />;
  }

  const handleSendMessage = () => {
    chatEvents.emit('OPEN-APPOINTMENT-CHAT', room.id);
  };

  return (
    <Card
      variant="outlined"
      className={`${classes.room} ${classes[room ? room.status : 'unoccupied']}`}
    >
      <CardHeader
        className={`${classes.cardHeader} ${classes.cardWidth100}`}
        avatar={
          <div className={classes.cardAvatar}>
            {icon}
          </div>
        }
        title={
          <Typography>
            <strong>Room {roomId}:</strong> {room ? `${room.last_name}, ${room.first_name}` : '--'}
          </Typography>
        }
        onClick={async () => {
          if (!room) return;
          try {
            await navigator.mediaDevices.getUserMedia(camConstraints);
          } catch (e) {
            console.log('error authing cam', e);
            return;
          }
          router.push({ pathname: '/app/patient/video-call', state: { appointmentId: room.id, userId: room.user_id } });
        }}
      />
      <CardContent
        className={`${classes.cardContent} ${classes.cardWidth100}`}
        onClick={async () => {
          if (!room) return;
          try {
            await navigator.mediaDevices.getUserMedia(camConstraints);
          } catch (e) {
            console.log('error authing cam', e);
            return;
          }
          router.push({ pathname: '/app/patient/video-call', state: { appointmentId: room.id, userId: room.user_id } });
        }}
      >
        <Typography className={classes.cardTitle} gutterBottom>
      Status:
        </Typography>
        <Typography>
          {roomStatus}
        </Typography>
        <Typography className={classes.cardTitle} gutterBottom>
      Assigned Doctor:
        </Typography>
        <Typography>
          {doctor}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardWidth100}>
        <Button
          disabled={!room}
          variant="contained"
          className={classes.cardBtn}
          onClick={handleSendMessage}
        >
          Send Message
        </Button>
      </CardActions>
    </Card>
  );
};

const VirtualAppointments = (props) => {
  const { classes, clinicId, router } = props;
  const rooms = [];
  const { data: activeCalls } = useSWR(`/clinics/${clinicId}/active_telemedicine_calls`, apiFetch, { refreshInterval: 15000, compare: () => false });
  const { data: appointment_rooms } = useSWR(`/clinics/${clinicId}`, apiFetch);
  const roomSize = get(appointment_rooms, 'appointment_rooms') || 0;
  for (let i = 0; i < roomSize; i++) {
    rooms.push(i);
  }
  const roomComponents = rooms.map((r, idx) => <Room room={get(activeCalls, [idx], null)} key={get(activeCalls, [idx, 'id'], idx)} index={idx} now={moment()} {...props} />);
  return (
    <AppWrapper router={router}>
      <div className={classes.root}>
        <h1 className={classes.headerTitle}>Virtual Exam Rooms</h1>
        <div className={classes.row}>
          {roomComponents}
        </div>
      </div>
    </AppWrapper>
  );
};

function mapStateToProps(state) {
  const {
    appointments,
    clinic: {
      clinicId,
      providers,
    },
  } = state;


  return {
    clinicId,
    providers,
    appointments,
  };
}

VirtualAppointments.propTypes = {
  classes: PropTypes.object.isRequired,
  clinicId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
};

Room.propTypes = {
  classes: PropTypes.object.isRequired,
  now: PropTypes.object.isRequired,
  room: PropTypes.object,
  index: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
};

Room.defaultProps = {
  room: null,
};

export default connect(mapStateToProps, {})(withStyles(styles)(VirtualAppointments));
