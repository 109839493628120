import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { find } from 'lodash';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Warning from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import memoize from 'memoize-one';
import { colors } from '../lib/styles';

const styles = {
  emrLabel: {
    marginLeft: 0,
    paddingLeft: 0,
  },
  emrLabelIcon: {
    paddingTop: 0,
    paddingLeft: '20px',
  },
  emrLabelWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  indicator: {
    backgroundColor: colors.primaryColor,
  },
  tab: {
    color: colors.highlightDarker,
    fontSize: '0.95rem',
    textTransform: 'capitalize',
  },
  tabsFlexContainer: {
    overflowX: 'scroll',
  },
  tabTemplate: {
    backgroundColor: colors.primaryHighlight,
  },
  warning: {
    color: colors.errorRed,
  },
  labelIcon: {
    paddingLeft: '3rem',
    paddingTop: 0,
  },
};

class PatientViewTabs extends Component {

  memoShowProNotification = memoize((proSubmissions) => {
    return find(proSubmissions, p => !p.clinic_viewed_result && p.pro_score_data);
  })
  memoSelectValidTabs = memoize((tabs, features) => {
    return tabs.reduce((visibleTabs, tab) => {
      if(features.includes(tab.value)) {
        visibleTabs.push(<Tab {...tab} />);
      }
      return visibleTabs;
    }, []);
  })

  render() {
    const {
      classes,
      features,
      showEmrAlert,
    } = this.props;
    const shownTabs = this.memoSelectValidTabs([
      {
        key: 'VITALS_OVERVIEW',
        label: 'Vitals Overview',
        value: 'VITALS_OVERVIEW',
        style: styles.tab,
      },
      {
        key: 'PRO_SUBMISSIONS',
        label: 'PRO',
        value: 'PRO_SUBMISSIONS',
        style: styles.tab,
        classes: {
          labelIcon: classes.labelIcon,
          wrapper: classes.emrLabelWrapper,
        }
      },
      {
        key: 'HEADACHE_OVERVIEW',
        label: 'Headache Overview',
        value: 'HEADACHE_OVERVIEW',
        style: styles.tab,
      },
      {
        key: 'PAIN_OVERVIEW',
        label: 'Pain Overview',
        value: 'PAIN_OVERVIEW',
        style: styles.tab, 
      },
      {
        key: 'IMAGES_OVERVIEW',
        label: 'Images Overview',
        value: 'IMAGES_OVERVIEW',
        style: styles.tab,
      },
      { key: 'VIDEO_CALLS', label: 'Appointments', value: 'APPOINTMENTS', style: styles.tab },
      { key: 'PATIENT_OVERVIEW', label: 'Patient Overview', value: 'PATIENT_OVERVIEW', style: styles.tab },
      {
        key: 'EMR_INTEGRATION',
        icon: showEmrAlert ? <Warning className={classes.warning} /> : null,
        classes: {
          labelIcon: classes.emrLabelIcon,
          wrapper: classes.emrLabelWrapper,
        },
        label: 'EMR Data',
        value: 'EMR_INTEGRATION',
        style: styles.tab,
      },
      { key: 'ESIGN_RELEASES', label: 'Releases', value: 'ESIGN_RELEASES', style: styles.tab, },
    ], features);

    return (
      <Tabs
        value={this.props.activeTab}
        onChange={this.props.onChangeTab}
        variant="scrollable"
        classes={{
          indicator: classes.indicator,
        }}
      >
        {shownTabs}
      </Tabs>
    );
  }
};

PatientViewTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  showEmrAlert: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PatientViewTabs);
