import makeActionCreator from '../lib/make-action-creator';
import { apiFetch } from '../lib/fetch';

export const FETCH_MIGRAINES_START = 'migraines-FETCH_MIGRAINES_START';
export const FETCH_MIGRAINES_END = 'migraines-FETCH_MIGRAINES_END';
export const FETCH_MIGRAINES_ERROR = 'migraines-FETCH_MIGRAINES_ERROR';

const fetchMigrainesStart = makeActionCreator(FETCH_MIGRAINES_START);
const fetchMigrainesEnd = makeActionCreator(FETCH_MIGRAINES_END);
const fetchMigrainesError = makeActionCreator(FETCH_MIGRAINES_ERROR);

export function fetchMigraines(userId) {
  return function dispatcher(dispatch) {
    dispatch(fetchMigrainesStart());
    const requestOptions = {
      query: {
        populate: true,
      },
    };
    return apiFetch(`/users/${userId}/migraine_hx`, requestOptions)
      .then((migrainesWithPain) => {
        const result = {};
        result[userId] = migrainesWithPain;
        dispatch(fetchMigrainesEnd(result));
        return migrainesWithPain;
      })
      .catch((err) => {
        dispatch(fetchMigrainesError());
        throw err;
      });
  };
}
