import { makeActionCreator, promiseHandler } from 'cooldux';
import { get, keyBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const setLocalMergeStatus = makeActionCreator('setLocalMergeStatus');
const { readEmrStart, readEmrEnd, readEmrError, readEmrHandler } = promiseHandler('readEmr', 'emr');
const { editEmrStart, editEmrEnd, editEmrError, editEmrHandler } = promiseHandler('editEmr', 'emr');

export const defaultMergeStatus = [false, false, false, false, false, false, false, false];

// Force a new merge every time
function clearMergeStatus(data) {
  return data.map(i => {
    return {
      ...i,
      merge_status: defaultMergeStatus
    }
  });
}

export function setUserEmrMergeStatus(emrId, mergeStatus) {
  return (dispatch) => {
    dispatch(setLocalMergeStatus({ emrId, mergeStatus }));
  };
}

export function readUserEmr(userId) {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId');
    const fetchRes = apiFetch(`/clinics/${clinicId}/users/${userId}/emr_patients`)
      .then(clearMergeStatus)
      .then(data => ({ userId, data }));

    return readEmrHandler(fetchRes, dispatch);
  };
}

export function editUserEmr(userId, emrPatientId, body) {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId');
    const opts = {
      method: 'PUT',
      body,
    };

    const fetchRes = apiFetch(`/clinics/${clinicId}/users/${userId}/emr_patients/${emrPatientId}`, opts)
      .then(data => ({ userId, data }));

    return editEmrHandler(fetchRes, dispatch);
  };
}

const initialState = {
  isFetching: false,
  data: {},
};

function finishEdit(state, payload) {
  const data = {
    ...state.data,
    [payload.data.id]: payload.data,
  };
  return {
    ...state,
    data,
    isFetching: false,
  };
}

function finishRead(state, payload) {
  const data = {
    ...state.data,
    ...keyBy(payload.data, 'id'),
  };

  return {
    ...state,
    data,
    isFetching: false,
  };
}

function emr(state = initialState, action) {
  switch (action.type) {
    case readEmrStart.type:
      return {
        ...state,
        isFetching: true,
      };
    case readEmrEnd.type:
      return finishRead(state, action.payload);
    case readEmrError.type:
      return {
        ...state,
        isFetching: false,
      };
    case editEmrStart.type:
      return {
        ...state,
        isFetching: true,
      };
    case editEmrEnd.type:
      return finishEdit(state, action.payload);
    case editEmrError.type:
      return {
        ...state,
        isFetching: false,
    };
    case setLocalMergeStatus.type:
      const { emrId, mergeStatus } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [emrId]: {
            ...state.data[emrId],
            merge_status: mergeStatus,
          }
        }
    }
    default:
      return state;
  }
}

export default emr;
