import React from 'react';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import PropTypes from 'prop-types';
import useSWR from 'swr';

import BundledEvent from '../components/bundled-event';
import AppWrapper from '../wrappers/app-wrapper';
import { apiFetch } from '../lib/fetch';

const pageDetails = {
  CAMPAIGN_BUNDLE: {
    title: 'Edit Care Pathway Bundle',
    route: 'care-pathway-bundled-events',
  },
  APPOINTMENT_BUNDLE: {
    title: 'Edit Appointment Bundle',
    route: 'appointment-bundled-events',
    instructions: 'Bundled Events will trigger as a single notification to the patient. Patients are then guided through each of the events in the order listed below. Each event type may only be used a single time. To send a bundled event to a patient, first add it when scheduling an appointment.',
  },
};

const ViewBundledEvent = (props) => {
  const bundle_type = includes(props.route.path, 'care-pathway') ? 'CAMPAIGN_BUNDLE' : 'APPOINTMENT_BUNDLE';
  const { eventId, clinicId } = props;
  const { data } = useSWR(`/clinics/${clinicId}/bundled_events/${eventId}`, apiFetch);
  const handleSave = async (name, events) => {
    const opts = {
      method: 'PUT',
      body: {
        name,
        bundle_spec: {
          events,
        },
        bundle_type,
      },
    };
    try {
      await apiFetch(`/clinics/${props.clinicId}/bundled_events/${props.eventId}`, opts);
      props.router.push(`/app/${pageDetails[bundle_type].route}?message=created`);
    } catch (e) {
      console.error('error saving: ', e);
    }
  };

  return (
    <AppWrapper router={props.router}>
      <BundledEvent
        bundledEvent={data}
        headerText={pageDetails[bundle_type].title}
        onSave={handleSave}
        instructions={pageDetails[bundle_type].instructions}
      />
    </AppWrapper>
  );
};

function mapStateToProps(state, ownProps) {
  const {
    clinic: { clinicId },
  } = state;

  return {
    clinicId,
    eventId: Number(ownProps.routeParams.eventId),
  };
}
ViewBundledEvent.propTypes = {
  eventId: PropTypes.number.isRequired,
  clinicId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
};


export default connect(mapStateToProps, {})(ViewBundledEvent);

