import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Point from '@material-ui/icons/ArrowDropDown';
import SwapVert from '@material-ui/icons/SwapVert';

import Table from '../base/table';
import TableBody from '../base/table-body';
import TableCell from '../base/table-cell';
import TableFooter from '../base/table-footer';
import TableHead from '../base/table-head';
import TablePagination from '../base/table-pagination';
import TableRow from '../base/table-row';

import { colors } from '../lib/styles';

const StyledTablePagination = withStyles({
  toolbar: {
    paddingLeft: 5,
  },
  caption: {
    fontSize: '1rem',
  },
  select: {
    marginRight: '4px',
  },
})(TablePagination);

const styles = {
  caption: {
    fontSize: '1rem',
  },
  clickableTableHeader: {
    boxSizing: 'border-box',
    color: colors.black,
    cursor: 'pointer',
    fontSize: '1rem',
    padding: '4px 10px',
    textAlign: 'left',
    width: '35%',
    whiteSpace: 'nowrap',
    marginRight: '5px',
  },
  container: {
    minWidth: '768px',
    paddingLeft: '20px',
  },
  displayNumberMenuIcon: {
    width: '140px',
    height: '30px',
    marginRight: '10px',
  },
  dropDownSortIcon: {
    color: colors.lightGreyText,
    height: '2rem',
    verticalAlign: 'middle',
  },
  headerText: {
    fontWeight: 100,
    textAlign: 'left',
  },
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  table: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.highlightMedium}`,
    height: '100%',
    minWidth: '768px',
    tableLayout: 'fixed',
  },
  tinyTableHeader: {
    minWidth: '100px',
    width: '4.5%',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    color: colors.black,
    fontSize: '1rem',
  },
  clinicianTableHeader: {
    minWidth: '100px',
    width: '20%',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    color: colors.black,
    fontSize: '1rem',
  },
  tableHeader: {
    minWidth: '100px',
    width: '13.33%',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    color: colors.black,
    fontSize: '1rem',
  },
  tinyClickableTableHeader: {
    boxSizing: 'border-box',
    color: colors.black,
    cursor: 'pointer',
    fontSize: '1rem',
    padding: '4px',
    textAlign: 'left',
    width: '10%',
    whiteSpace: 'nowrap',
    marginRight: '5px',
  },
  mediumTableHeader: {
    minWidth: '100px',
    width: '18%',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    color: colors.black,
    fontSize: '1rem',
    cursor: 'pointer',
  },
  pagination: {
    display: 'flex',
    fontSize: '.65rem',
    fontWeight: 600,
    margin: '5px 5px 5px 0',
    width: '220px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paginationBox: {
    width: '40px',
    height: '25px',
    margin: '0 10px',
  },
  subHeaderText: {
    display: 'flex',
    color: colors.highlightDark,
    margin: '5px 5px 5px 0',
    fontSize: '0.875rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  swapIcon: {
    marginBottom: '-5px',
  },
  expireAlert: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    color: colors.errorRed,
  },
};

class DashboardTableList extends PureComponent {
  render() {
    const { classes, showAppointmentButtons } = this.props;
    const dropDownSortIcon = <Point style={styles.dropDownSortIcon} />;
    let columns = 6;
    if (!showAppointmentButtons) {
      columns = 5;
    }
    return (
      <div id={this.props.id} style={styles.container}>
        <div style={styles.headerText}>
          <h1 style={styles.headerTitle}>{this.props.title}</h1>
          {
          this.props.user.daysUntilExpired && this.props.user.daysUntilExpired < 14 && (
            <div className={classes.expireAlert}>
              <Typography>
                Password is going to expire in {this.props.user.daysUntilExpired} days
              </Typography>
              <Button component={Link} to="/app/reset" variant="contained" >
                Reset Now
              </Button>
            </div>
          )
        }
        </div>
        <Table
          selectable="false"
          className={classes.table}
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell
                onClick={this.props.sortByName}
                className={classes.clickableTableHeader}
              >Patient Name<SwapVert className={classes.swapIcon} />
              </TableCell>
              <TableCell className={classes.tinyClickableTableHeader} onClick={this.props.sortByFlag} padding="none">Flags{dropDownSortIcon}</TableCell>
              <TableCell className={classes.mediumTableHeader}>Date of Birth</TableCell>
              <TableCell
                className={classes.mediumTableHeader}
                onClick={this.props.sortByProvider}
              >
                Clinician<SwapVert className={classes.swapIcon} />
              </TableCell>
              <TableCell
                className={classes.mediumTableHeader}
                onClick={this.props.sortByRecentPain}
              >
                Pain Level<SwapVert className={classes.swapIcon} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.appointmentRows}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTablePagination
                colSpan={columns}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                count={this.props.totalRecords}
                rowsPerPage={this.props.displayNumber}
                rowsPerPageOptions={[10, 20, 50]}
                page={this.props.page}
                onPageChange={this.props.onPageChange}
                onRowsPerPageChange={this.props.onChangeRowsPerPage}
                className={classes.caption}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

DashboardTableList.propTypes = {
  appointmentRows: PropTypes.array,
  classes: PropTypes.object.isRequired,
  displayNumber: PropTypes.number,
  id: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number,
  showAppointmentButtons: PropTypes.bool.isRequired,
  title: PropTypes.string,
  totalRecords: PropTypes.number,
  sortByProvider: PropTypes.func.isRequired,
  sortByFlag: PropTypes.func.isRequired,
  sortByRecentPain: PropTypes.func.isRequired,
  sortByName: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

DashboardTableList.defaultProps = {
  appointmentRows: [],
  displayNumber: null,
  id: '',
  page: null,
  title: '',
  totalRecords: null,
};

export default withStyles(styles)(DashboardTableList);
