import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { capitalize, compact, get, isNil, max, omitBy, orderBy } from 'lodash';
import { Calendar } from 'common-ui';

import MigraineStats from './migraine-stats';
import { apiFetch } from '../lib/fetch';
import PatientPainHead from './patient-pain-head';
import PatientViewContentSection from './patient-view-content-section';
import headachePainLineBuilder from '../lib/headache-pain-line-builder';
import getMigraineStats from '../lib/get-migraine-stats';
import findItemByDate from '../lib/find-item-by-date';
import { colors } from '../lib/styles';
import PainLineChart from './pain-line-chart';
import memoize from 'memoize-one';

const baseStyles = {
  bottomIcon: {},
  bottomHalf: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 500,
  },
  btn: {
    border: `1px solid ${colors.highlightDark}`,
    borderRadius: '2px',
    boxShadow: 'none',
    margin: '5px',
  },
  btnLabel: {
    textTransform: 'capitalize',
  },
  bottomLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomRight: {
    minWidth: '330px',
    paddingLeft: '5px',
  },
  formContainer: {
    margin: '10px 10px 10px 0px',
  },
  painLevel: {
    color: colors.primaryColor,
    fontSize: '14px',
    paddingTop: '10px',
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  patientPainHead: {
    paddingLeft: '20px',
    minWidth: '200px',
  },
  printAllBtn: {
    boxShadow: 'none',
    margin: '0px 10px 5px 0px',
    width: '97%',
  },
  reviewAllBtn: {
    border: `1px solid ${colors.primaryAccent}`,
    boxShadow: 'none',
    margin: '0px 10px 5px 0px',
    width: '97%',
  },
  reviewAllLabel: {
    fontWeight: 600,
    padding: '10px',
  },
  summary: {
    flex: 1,
    minWidth: '250px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  summaryBtns: {
    display: 'flex',
    flexDirection: 'row',
  },
  topSectionIcon: {
    color: colors.primaryAccent,
    height: '18px',
    width: '18px',
  },
  surgeryData: {
    display: 'flex',
    justifyContent: 'left',
    marginTop: 20,
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  surgeryDates: {
    fontWeight: 600,
    marginRight: '10px',
    width: '93px',
  }
};

function determineMostRecent(mostRecent, data) {
  const allData = compact([mostRecent, ...data]);
  return max(allData, p => new Date(p.end_time).getTime());
}
class ClinicianOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarData: [],
      fetching: false,
      mostRecentMigraine: null,
      selectedDate: null,
      selectedMonth: moment().startOf('day'),
    };
  }
  componentWillMount() {
    const queryOptions = {
      query: omitBy({
        populate: true,
        limit: 1,
        orderBy: 'end_time',
      }, isNil)
    };
    apiFetch(`/users/${this.props.userId}/migraine_hx`, queryOptions)
      .then(([mostRecentMigraine]) => {
        this.setState({
          mostRecentMigraine,
          selectedDate: get(mostRecentMigraine, 'end_time', '').split('T')[0],
        });
      })
      .catch(() => { });
  }

  handleDateChange = (currentMonth, currentYear) => {
    this.setState({ currentMonth, currentYear, fetching: true });

    const startDate = new Date(currentYear, currentMonth, 1);
    let endDate = new Date(currentYear, currentMonth + 1, 0);
    endDate = new Date(endDate.getTime() + 86399999); // 1 second before end of day
    const queryOptions = {
      query: omitBy({
        start_time: startDate.toISOString(),
        end_time: endDate.toISOString(),
        populate: true,
      }, isNil)
    };
    apiFetch(`/users/${this.props.userId}/migraine_hx`, queryOptions)
      .then((data) => {
        this.setState(prevState => ({
          calendarData: data,
          mostRecentPain: determineMostRecent(prevState.mostRecentPain, data),
          fetching: false,
          selectedMonth: moment(startDate).startOf('day'),
        }));
      })
      .catch((e) => {
        this.setState({ error: 'An Error occured fetching pain data', fetching: false });
      });
  }
  handleSelectDate = (selectedDate) => {
    this.setState({
      selectedDate,
      selectedMonth: moment(selectedDate).startOf('day'),
    })
  }
  memoBuildLineChartData = memoize((data, currentMonth, currentYear) => {
    const formattedData = headachePainLineBuilder(data, currentMonth, currentYear);
    return formattedData;
    // const labels = Object.keys(formattedData);
    // const values = Object.values(formattedData);
    // return {
    //   labels,
    //   datasets: [
    //     {
    //       ...dataConfig,
    //       label: 'Pain Scale',
    //       backgroundColor: 'rgb(227,70,87)',
    //       borderColor: colors.calendarRed,
    //       pointBorderColor: colors.calendarRed,
    //       pointBackgroundColor: colors.calendarRed,
    //       pointHoverBackgroundColor: colors.calendarRed,
    //       pointHoverBorderColor: 'rgba(220,220,220,1)',
    //       pointStyle: 'triangle',
    //       data: values
    //     },
    //   ]
    // };
  })


  render() {
    const { props } = this;
    const { calendarData, mostRecentMigraine, selectedDate, selectedMonth } = this.state;
    let displayedPain = mostRecentMigraine;
    const { appointment } = this.props;

    if (selectedDate) {
      const pains = findItemByDate(selectedDate, this.state.calendarData, 'start_time', 'end_time');
      displayedPain = orderBy(pains, 'intensity', 'desc')[0];
    }

    const migraineStats = getMigraineStats(selectedMonth, calendarData);
    const symptoms = get(displayedPain, 'symptoms', []).map(capitalize).join(', ') || 'None Reported';
    const preventiveMeds = get(displayedPain, 'abative_rx', []).map(capitalize).join(', ') || 'None Reported';
    const abortiveMeds = get(displayedPain, 'abortive_rx', []).map(capitalize).join(', ') || 'None Reported';
    const painLevel = displayedPain ? capitalize(`${displayedPain.pain[0].pain_type} Pain ● Level ${displayedPain.pain[0].severity}`) : 'No Pain Recorded';
    const lineChartData = this.memoBuildLineChartData(this.state.calendarData, this.state.currentMonth, this.state.currentYear);

    const curDate = new Date(this.state.currentYear, this.state.currentMonth);
    const startTime = new Date(curDate.getFullYear(), curDate.getMonth(), 1);
    const endTime = new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0);
    const firstDateOfCurMonth = startTime.getDate();
    const lastDateOfCurMonth = endTime.getDate();
    const axisTopDomain = [firstDateOfCurMonth, lastDateOfCurMonth];

    return (
      <Fragment>
        {props.children}
        <PatientViewContentSection>
          <div style={baseStyles.bottomHalf}>
            <div style={{ flex: 1, margin: 10, maxWidth: 300 }}>
              <Calendar
                clickableDates={true}
                data={this.state.calendarData}
                onDateChange={this.handleDateChange}
                onClickDay={this.handleSelectDate}
                selectedDate={this.state.selectedDate}
                selectedDateColor={colors.primaryColor}
                title="Pain - History Calendar"
              />
            </div>
            <div style={{ flex: 1, flexWrap: 'wrap', height: 400, maxWidth: 300 }}>
              <PatientPainHead {...displayedPain} sex={appointment.sex} />
              <div style={baseStyles.painLevel}>{painLevel}</div>
              <MigraineStats currentMonth={selectedMonth.format('MMMM')} stats={migraineStats} />
            </div>
            <div style={{ flex: 1, flexDirection: 'column', maxWidth: 550 }}>
              <div style={{ flex: 1, margin: '10px 40px 0' }}>
                <div style={baseStyles.surgeryData}>
                  <div style={baseStyles.surgeryDates}>Symptoms:</div>
                  <div>{symptoms}</div>
                </div>
                <div style={baseStyles.surgeryData}>
                  <div style={baseStyles.surgeryDates}>Abortive Medications:</div>
                  <div>{abortiveMeds}</div>
                </div>
                <div style={baseStyles.surgeryData}>
                  <div style={baseStyles.surgeryDates}>Preventive Medications:</div>
                  <div>{preventiveMeds}</div>
                </div>
              </div>
              <div style={{ margin: '50px 25px 0 0', minHeight: 500, minWidth: 450, maxWidth: 550, flex: 1 }}>
                <PainLineChart
                  data={lineChartData}
                  color="red"
                  chartHeight={175}
                  chartWidth={425}
                  svgMargins={{
                    top: 0,
                    right: 100,
                    bottom: 25,
                    left: 40,
                  }}
                  axisTopDomain={axisTopDomain}
                  lineChartMargins={{
                    bottom: 20,
                    top: 5,
                  }}
                  defaultMinYScale={10}
                  defaultMaxYScale={25}
                />
              </div>
            </div>
          </div>
        </PatientViewContentSection>
      </Fragment>
    );
  }
}

ClinicianOverview.defaultProps = {
  appointment: {},
  forms: {},
  migraines: [],
  painLevel: 'None Reported',
};

ClinicianOverview.propTypes = {
  appointment: PropTypes.object,
  forms: PropTypes.object,
  router: PropTypes.object.isRequired,
  handleOpenAllForms: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  migraines: PropTypes.array,
  painLevel: PropTypes.string,
  updateAppointment: PropTypes.func.isRequired,
};

export default ClinicianOverview;
