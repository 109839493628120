import SocialPersonOutline from '@material-ui/icons/Assignment';
import FileFolderIcon from '@material-ui/icons/Folder';


// Adding forms here will add them to the sidebar and the submissions section
// Provided the key exactly matches the app_feature string from clinics_app_features table
export const forms = {
  INITIAL_CLINICAL_ASSESSMENT_FORM: {
    urlPath: 'ica',
    name: 'Initial Clinical Assessment',
    icon: SocialPersonOutline,
    submissionIcon: FileFolderIcon,
  },
};
