import React from 'react';
import PropTypes from 'prop-types';
import { forEach } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Timeline from '@material-ui/icons/Timeline';
import Switch from '@material-ui/core/Switch';

const styles = {
  float: {
    float: 'right',
    display: 'flex',
  },
  list: {
    width: 250,
  },
};

class DashboardFilter extends React.Component {
  state = {
    right: false,
    proFiltersOpen: false,
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  toggleProFiltersDrawer = (open) => {
    this.setState({ proFiltersOpen: open });
  }

  render() {
    const {
      classes,
      onCheckFilter,
      toggleUnread,
      filtersChecked,
      sortUnread,
    } = this.props;
    const filters = [];
    forEach(filtersChecked, (value, key) => {
      if (value.enabled) {
        filters.push((
          <ListItem key={key}>
            <ListItemIcon>
              <Switch
                checked={value.checked}
                onChange={() => onCheckFilter(key)}
                name={key}
              />
            </ListItemIcon>
            <ListItemText>{value.displayName}</ListItemText>
          </ListItem>
        ));
      }
    });
    return (
      <div>
        <div className={classes.float}>
          <FormControlLabel
            value="start"
            control={<Switch color="primary" />}
            label="Filter unread"
            labelPlacement="start"
            checked={sortUnread}
            onChange={toggleUnread}
          />
          <Button variant="outlined" onClick={this.toggleDrawer('right', true)}><Timeline /> RPM Filter</Button>
        </div>
        <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={() => this.toggleDrawer('right', false)}
            onKeyDown={this.toggleDrawer('right', false)}
          >
            <div className={classes.sideList}>
              <List>
                {filters}
              </List>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

DashboardFilter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DashboardFilter);