import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, sortBy } from 'lodash';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';

import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { KeypadDate } from 'common-ui';

import Select from './pro-select';
import Hidden from './hidden-content';
import SortableTable from './sortable-table';
import { blueBtnStyle, colors } from '../lib/styles';

const styles = {
  btn: {
    ...blueBtnStyle,
  },
  editSaveButton: {
    height: '32px',
    width: '32px',
    marginTop: '16px',
  },
  editSurgeryArea: {
    display: 'flex',
  },
  error: {
    color: colors.errorRed,
    margin: '10px 0',
  },
  surgerySection: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: '.5rem',
    marginTop: '20px',
  },
};

const columnData = [
  {
    id: 'name', align: false, disablePadding: false, label: 'Treatment', small: true,
  },
  {
    id: 'date', align: false, disablePadding: false, label: 'Date', small: true, sortId: 'year',
  },
];

class SurgeryTab extends Component {
  state = {
    editId: null,
    editDate: null,
    surgeryType: 'DEFAULT',
    surgeryDate: '',
    error: '',
  }
  handleCreateSurgery = () => {
    const { surgeryDate, surgeryType } = this.state;
    this.setState({ error: '' });
    const newSurgery = {
      surgery_id: surgeryType,
      year: moment(surgeryDate, 'MM/DD/YYYY').toISOString(),
    };
    if(!surgeryType || surgeryType === 'DEFAULT') {
      return this.setState({ error: 'You must select a treatment type'})
    }
    if(!surgeryDate) {
      return this.setState({ error: 'You must select a treatment date'})
    }
    this.props.onAddSurgeryHx(this.props.userId, newSurgery)
      .then(() => this.setState({ error: '', surgeryType: 'DEFAULT', surgeryDate: '' }))
      .catch(e => this.setState({ error: 'Something went wrong'}));
  }
  handleSaveSurgery = () => {
    const surgery = {
      year: moment(this.state.editDate).toISOString()
    }

    this.props.onEditSurgeryHx(this.props.userId, this.state.editId, surgery)
      .then(() => {
        this.setState({ editId: null, editDate: null });
      });
  }
    
  handleEditClick = (surgery) => {
    this.setState({
      editId: surgery.id,
      editDate: moment.utc(surgery.year).format('MM/DD/YYYY'),
    })
  }
  render() {
    const { error } = this.state;
    const { classes, surgeries, surgeryTypes } = this.props;
    const sortedSurgeryTypes = sortBy(surgeryTypes, (o) => { return o.name; });

    const formattedSurgeries = map(surgeries, (s) => {
      let date = (
        <React.Fragment>
          {s.date}{!this.state.editId && (<IconButton onClick={() => this.handleEditClick(s)} size="small"><EditIcon fontSize="inherit" /></IconButton>)}
        </React.Fragment>
      );


      if(this.state.editId === s.id) {
        // The moment call in disabled will occasionally throw console warnings. Thats actually okay here
        // as the warning only comes with an invalid date which disables the button. Cant determine how to disable
        date = (
          <div className={classes.editSurgeryArea}>
            <KeypadDate value={this.state.editDate} onChange={editDate => this.setState({ editDate })} />
            <IconButton classes={{ root: classes.editSaveButton }} disabled={!moment(this.state.editDate).toISOString()} onClick={this.handleSaveSurgery} size="small">
              <SaveIcon fontSize="inherit" />
            </IconButton>
          </div>
        );
      }

      const newSurgeryRow = {
        ...s,
        date,
      }
      
      return newSurgeryRow;
    });

    return (
      <Fragment>
        <section className={classes.surgerySection}>
          <Hidden hidden={!sortedSurgeryTypes.length}>
            <FormControl style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
              <Select
                value={this.state.surgeryType}
                onChange={(e) => this.setState({ surgeryType: e.target.value })}
                input={<Input disableUnderline={true} />}
              >
                <MenuItem value='DEFAULT' disabled><em>Select Treatment</em></MenuItem>
                {map(sortedSurgeryTypes, (type) => (
                  <MenuItem key={type.surgery_id} value={type.surgery_id} selected>{type.name}</MenuItem>
                ))}
              </Select>
              <KeypadDate
                includeCalendar={true}
                label="Treatment Date"
                onChange={surgeryDate => this.setState({ surgeryDate })}
                value={this.state.surgeryDate}
              />
              <Button
                variant="contained"
                className={classes.btn}
                onClick={this.handleCreateSurgery}
              >
                Add Treatment
            </Button>
            </FormControl>
          </Hidden>
          <Hidden hidden={sortedSurgeryTypes.length}>
            <p>Ask your clinic admistrator to grant your clinic access to treatment types in order to add treatments to the patient.</p>
          </Hidden>
        </section>
        <Hidden hidden={!error}><div style={styles.error}>{error}</div></Hidden>
        <SortableTable
          columnData={columnData}
          emptyMessage="There are no treatments for this patient."
          classes={{ tableRow: classes.tableRow }}
          initialOrder="desc"
          initialOrderBy="year"
          rows={formattedSurgeries}
        />
      </Fragment>
    )
  }
}

SurgeryTab.propTypes = {
  onAddSurgeryHx: PropTypes.func.isRequired,
  onEditSurgeryHx: PropTypes.func.isRequired,
  surgeries: PropTypes.array.isRequired,
  surgeryTypes: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
}

export default withStyles(styles)(SurgeryTab);
