import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import openForm from '../lib/open-form';
import DashboardTableListItem from './dashboard-table-list-item';

const baseStyles = {
  clinicianDropdown: {
    border: '1px solid black',
    paddingLeft: '5px',
  },
};


class PatientListItem extends PureComponent {
  onChangeProvider = (evt) => {
    evt.nativeEvent.stopImmediatePropagation();
    // if we have an appointment update it
    let { value } = evt.target;
    if (!value) {
      value = null;
    }
    if(this.props.patient.id) {
      this.props.onUpdateAppointment(this.props.patient.id, { provider_id: value });
    }
    // also update patient_clinics
    this.props.onUpdatePatient(this.props.patient.user_id, { provider_id: value });
    this.props.updateProviderId(this.props.patient.user_id, evt.target.value);
  }
  onOpenAppointmentView = () => {
    this.props.addRecent(this.props.patient);
    this.props.router.push({ pathname: '/app/patient', state: { userId: this.props.patient.user_id, contact_preference: this.props.patient.contact_preference }});
  }
  onOpenAllForms = () => {
    openForm(this.props.patient.user_id, 'all');
  }
  onOpenInsuranceForm = () => {
    openForm(this.props.patient.user_id, 'INSURANCE');
  }
  render() {
    const { classes, checkedIn, onCreateAppointment, onUpdateAppointment, patient, showAppointmentButtons } = this.props;
    const providerMenu = (
      <FormControl className={classes.formControl}>
        <NativeSelect
          value={patient.provider_id || ""}
          onChange={this.onChangeProvider}
          name=""
          className={classes.clinicianDropdown}
          >
          <option value="">None Selected</option>
          {this.props.providers.map((provider) => {
            return (<option key={provider.id} value={provider.id}>{provider.name}</option>);
          })}
        </NativeSelect>
      </FormControl>
      
    );
    return (
      <DashboardTableListItem
        lastName={patient.last_name}
        firstName={patient.first_name}
        image={patient.avatarImgSmall}
        flag={this.props.flag}
        unviewedPro={this.props.unviewedPro}
        patient={patient}
        birthdate={patient.birth_date}
        checkedIn={checkedIn}
        onClick={() => {
          this.onOpenAppointmentView();
        }}
        onUpdateAppointment={onUpdateAppointment}
        onCreateAppointment={onCreateAppointment}
        showAppointmentButtons={showAppointmentButtons}
        providerMenu={providerMenu}
        recentPain={patient.recent_pain}
      />
    );
  }
}

PatientListItem.defaultProps = {
  patient: {},
  providers: [],
};

PatientListItem.propTypes = {
  addRecent: PropTypes.func.isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number,
    checked_in: PropTypes.string,
    checked_out: PropTypes.string,
    clinicians: PropTypes.array,
    complete: PropTypes.number,
    in_session: PropTypes.string,
    status: PropTypes.string,
    user_id: PropTypes.string,
  }).isRequired,
  providers: PropTypes.array,
  router: PropTypes.object.isRequired,
  onUpdateAppointment: PropTypes.func.isRequired,
};

export default withStyles(baseStyles)(PatientListItem);
