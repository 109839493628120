import config from '../config';
import { apiFetch } from './fetch';

let peer;

function onsignalingstatechange (state) {
//  console.info('offerer signaling state change:' + state)
}

function oniceconnectionstatechange(event, me) {
  const state = peer.iceConnectionState;
  if (state === 'failed' || state === 'disconnected') {
    me.setShowPoorConnectionMessage(true);
    me.restartCall();
  } else if (state === 'connected') {
    me.setShowPoorConnectionMessage(false);
  }
}

function onicegatheringstatechange (state) {
//  console.info('offerer ice gathering state change:' + state)
}

export async function getIceServers(me, clinicId) {
  const result =  await apiFetch(`/clinics/${clinicId}/signalling`);
//  console.log('signalling result', result);
  
  config.rtc.cfg.iceServers = result.ice_servers;
  me.iceServers = result.ice_servers;
    
  return me.iceServers;
}


async function getPeer(me, clinicId) {
  if(me.peer) {
    return me.peer;
  }
  await getIceServers(me, clinicId)

  me.peer = new RTCPeerConnection(config.rtc.cfg, config.rtc.options);
  // me.events.emit('peerCreated', {});
  return me.peer;
}

export const createLocalOfferAfterReset = async () => {
  const offer = await peer.createOffer({ ...config.sdpConstraints, iceRestart: true });
  await peer.setLocalDescription(offer);
  return offer;
};

export default function createLocalOffer (me, clinicId) {
  return new Promise(async (resolve, reject) => {
    peer = await getPeer(me, clinicId);
    if(me.track) {
      me.peer.addTrack(me.track, me.stream);
    }
    if(me.audioTrack) {
      me.peer.addTrack(me.audioTrack, me.stream);
    }

    peer.onicecandidate = (e) => {
      if (!e.candidate) {
//        console.log('ICE candidate (pc1)', peer.localDescription);
      } else {
        me.events.emit('OFFERER_ICECANDIDATE', e.candidate);
      }
    }

    peer.onsignalingstatechange = onsignalingstatechange;
    peer.onicegatheringstatechange = onicegatheringstatechange;

    peer.onaddstream = (e) => {
      // document.getElementById('audio').srcObject = e.stream;
      // document.getElementById('audio').play();
      me.events.emit('peer_audio_connected', {});
      me.events.emit('gotStream', e.stream);
    }

    peer.ontrack = (e) => {
      me.events.emit('gotTrack', e.streams[0]);
    }

    peer.onconnection = (e) => {
      // console.log('me peer onconnect', e);
    }

    peer.oniceconnectionstatechange = (event) => {
      oniceconnectionstatechange(event, me);
    };
    
    me.dataChannel = peer.createDataChannel('test', {reliable: true});
    me.dataChannel.onopen = (e) => {
      me.dataChannel.send(JSON.stringify({message: 'hello from offerer!'}));
      me.events.emit('peer_connected', {});
    }

    me.dataChannel.onmessage = (e) => {
      if (e.data.charCodeAt(0) === 2) {
        // The first message we get from Firefox (but not Chrome)
        // is literal ASCII 2 and I don't understand why -- if we
        // leave it in, JSON.parse() will barf.
        return;
      }
      try {
        me.events.emit('peer_message', JSON.parse(e.data));
      } catch(err) {
        console.error('unparseable datachannel msg', err);
      }
    }

    const offer = await peer.createOffer(config.sdpConstraints);
    await peer.setLocalDescription(offer);
    resolve(offer);
  });
}
