import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import { filter, find, map, sortBy, upperFirst } from 'lodash';
import { KeypadDate } from 'common-ui';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip'; 
import Select from './pro-select';
import { apiFetch } from '../lib/fetch';
import SortableTable from './sortable-table';
import Hidden from './hidden-content';

import { blueBtnStyle, colors } from '../lib/styles';

const styles = {
  btn: {
    ...blueBtnStyle,
  },
  proSelection: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: '.5rem',
    marginTop: '20px',
  },
  error: {
    color: colors.errorRed,
    margin: '10px 0',
  },
};

const columnData = [
  {
    id: 'type', align: false, disablePadding: false, label: 'Type', small: true,
  },
  {
    id: 'status', align: false, disablePadding: false, label: 'Status', small: true, sortable: false,
  },
  {
    id: 'series_start', align: false, disablePadding: false, label: 'Series Start', sortId: 'series_start_raw',
  },
  {
    id: 'cancel', align: false, disablePadding: false, small: true,
  },
];

const ProCampaignsTab = (props) => {
  const {
    classes,
    clinicId,
    campaign,
    handleDateChange,
    handleCampaignChange,
    hidden,
    proCampaigns,
    error,
    campaign_start,
    handleSendCampaignRequest,
    deleteProCampaign,
    browseProCampaigns,
    editProCampaign,
    userId,
  } = props;

  const handleCancelCarePathway = async (event_series_id) => {
    const data = await browseProCampaigns(userId, { event_series_id });
    const scheduled_event = Boolean(await data.find(e => e.executed_at !== null));
    if (scheduled_event) {
      return data.forEach((event) => {
        event.cancelled_at = moment().toISOString();

        return editProCampaign(event);
      });
    }
    return deleteProCampaign(event_series_id);
  };

  const { data } = useSWR(`/clinics/${clinicId}/campaigns`, apiFetch);
  const { data: bundledEvents } = useSWR(`/clinics/${clinicId}/bundled_events`, apiFetch);
  let campaignTypes = data || [];
  campaignTypes = campaignTypes.filter(campaignType => campaignType.enabled);
  const sortedCampaignTypes = sortBy([...campaignTypes ], (o) => { if (o.name) { return upperFirst(o.name); } return o; });

  let formattedRows = filter(proCampaigns, row => row.id !== null);

  formattedRows = map(formattedRows, (proRow) => {
    if (!proRow) return null;
    if (!proRow.type) return null;
    let cancelable = false;
    proRow.status = map(proRow.events, (campaignEvent) => {
      const scheduleMoment = moment(campaignEvent.scheduled_at);
      const scheduledAt = scheduleMoment.format('MM/DD/YYYY, hh:mm A');
      let executionStatus = 'Submitted';
      if (!campaignEvent.executed_at && !campaignEvent.cancelled_at) {
        executionStatus = 'Scheduled';
        cancelable = true;
      } else if (campaignEvent.cancelled_at) {
        executionStatus = 'Cancelled';
      }
      let name = campaignEvent.type || campaignEvent.event_type;
      let bundledEventList;
      if (campaignEvent.type ==='BUNDLED_EVENT') {
        const bundledEvent = find(bundledEvents, { id: campaignEvent.detail.bundled_event_id });
        if (bundledEvent) {
          bundledEventList = bundledEvent.bundle_spec.events.map(e => e.event_type).join(' => ');
          name = `Bundled Event: ${bundledEvent.name}`;
        }
      }

      if (bundledEventList) {
        return (
          <Tooltip title={bundledEventList}>
            <div key={campaignEvent.id}>
              {`${executionStatus} ${name} - ${scheduledAt}`}
            </div>
          </Tooltip>
        );
      }
      return (
        <div key={campaignEvent.id}>
          {`${executionStatus} ${name} - ${scheduledAt}`}
        </div>
      );
    });
    proRow.cancel = null;
    if (cancelable) {
      proRow.cancel = (
        <Button
          variant="contained"
          className={classes.btn}
          onClick={() => handleCancelCarePathway(proRow.id)}
        >
          Cancel Care Pathway
        </Button>
      );
    }
    return proRow;
  });

  return (
    <Fragment>
      <section className={classes.proSelection}>
        <Hidden hidden={sortedCampaignTypes.length}>
          <p>Create a custom care pathway by clicking the settings icon in order to send care pathways to patients!</p>
        </Hidden>
        <Hidden hidden={!sortedCampaignTypes.length}>
          <FormControl style={{
            display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between',
          }}
          >
            <Select
              value={campaign}
              onChange={handleCampaignChange}
              input={<Input disableUnderline={true} />}
            >
              <MenuItem value={0} disabled><em>Select Care Pathway</em></MenuItem>
              {(sortedCampaignTypes || []).map(c => <MenuItem key={c.id || c} value={c.id || c} selected>{c.name || c}</MenuItem>)}
            </Select>
            <KeypadDate
              disableCalendarPast={true}
              includeCalendar={true}
              label="Start Date"
              onChange={handleDateChange}
              value={campaign_start}
            />
            <Button
              variant="contained"
              className={classes.btn}
              onClick={handleSendCampaignRequest}
            >
              Start Care Pathway
            </Button>
          </FormControl>
        </Hidden>
      </section>
      <Hidden hidden={hidden}><div className={classes.error}>{error}</div></Hidden>
      <SortableTable
        columnData={columnData}
        emptyMessage="There are no active care pathways for this patient."
        rows={formattedRows}
        browseProCampaigns={browseProCampaigns}
      />
    </Fragment>
  );
};

ProCampaignsTab.defaultProps = {
  campaign: 0,
  campaign_start: '',
  error: '',
  hidden: false,
};

ProCampaignsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  clinicId: PropTypes.number.isRequired,
  hidden: PropTypes.bool,
  deleteProCampaign: PropTypes.func.isRequired,
  proCampaigns: PropTypes.array.isRequired,
  campaign: PropTypes.number,
  handleDateChange: PropTypes.func.isRequired,
  handleCampaignChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  campaign_start: PropTypes.string,
  handleSendCampaignRequest: PropTypes.func.isRequired,
  browseProCampaigns: PropTypes.func.isRequired,
  editProCampaign: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default withStyles(styles)(ProCampaignsTab);
