import { filter } from 'lodash';
import config from '../config';

function formatAppointment(item = {}) {
  const appointment = {
    ...item,
    complete: 1,
    name: `${item.first_name} ${item.last_name}`,
    name_email: `${item.first_name} ${item.last_name}(${item.email})`,
    last_first_email: `${item.last_name}, ${item.first_name} (${item.email})`,
    providers: filter(item.providers, provider => provider),
    birth_date: (item.birth_date || '').split('T')[0],
  };

  if (appointment.picture) {
    appointment.avatarImgSmall = `${config.API_URL}/users/${appointment.user_id}/picture/100`;
    appointment.avatarImgLarge = `${config.API_URL}/users/${appointment.user_id}/picture/400`;
  } else {
    appointment.avatarImgSmall = '/img/default-avatar.png';
    appointment.avatarImgLarge = '/img/default-avatar.png';
  }

  return appointment;
}

export default formatAppointment;
