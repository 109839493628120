import { makeDuck, resetReducer } from 'cooldux';
import { apiFetch } from '../lib/fetch';
import config from '../config';

const formatImageUrl = (image) => ({
  ...image,
  url: `${config.API_URL}/users/${image.user_id}/images/${image.id}.jpg`,
})
const formatImages = (images = []) => images.map(formatImageUrl);
const duck = makeDuck({
  browseImages: userId => apiFetch(`/users/${userId}/images`).then(formatImages),
  addImage: (userId, blob, appointment_id) => { 
    const formData = new FormData();
    formData.append('image', blob);
    // formData.append('image_type', blob.type);
    if(appointment_id) {
      formData.append('appointment_id', appointment_id);
    }
    const options = {
      method: 'POST',
      body: formData,
    };
    return apiFetch(`/users/${userId}/images`, options).then(formatImageUrl);
  },
  deleteImage: (userId, imageId) => apiFetch(`/users/${userId}/images/${imageId}`, { method: 'DELETE' }),
});

export const {
 browseImages, addImage, deleteImage, initialStateCombined, reducerCombined 
} = duck;


const reducer = resetReducer(initialStateCombined, (state, action) => {
  return reducerCombined(state, action);
});

export default reducer;
