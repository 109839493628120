import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../lib/styles';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1rem',
    flexWrap: 'wrap',
    textAlign: 'left',
  },
  itemTitle: {
    whiteSpace: 'nowrap',
    marginRight: '5px',
  },
  itemValue: {
    color: colors.black,
    fontWeight: 100,
    marginRight: '5px',
  },
};

function PatientSummaryItem({ classes, title, value}) {
  return (
    <div className={classes.container}>
      <div className={classes.itemTitle}>{title}:</div>
      <div className={classes.itemValue}>{value}</div>
    </div>
  );
}

PatientSummaryItem.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.node,
};

export default withStyles(styles)(PatientSummaryItem);
