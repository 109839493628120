import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { forEach, noop } from 'lodash';
import moment from 'moment';
import Calendar from '../components/calendar';

import PatientViewContentSection from './patient-view-content-section';

import ListWoundsView from './images-overview-list-wounds';
import SingleWoundView from './images-overview-single-wound';
import CompareWoundsView from './images-overview-compare-wounds';

import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const baseStyles = {
  bottomIcon: {},
  bottomHalf: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 500,
    overflow: 'hidden',
  },
  overviewContent: {
    display: 'block',
    width: '100%',
    overflow: 'overlay',
  },
};
class ImagesOverview extends Component {
  constructor(props) {
    super(props);

    const { userId } = this.props;
    const currDate = new Date();
    const month = currDate.getMonth();
    const year = currDate.getFullYear();

    this.state = {
      month,
      year,
      daysWithWounds: new Set(),
      fetching: false,
      selectedDate: null,
      currWoundEntries: [],
      images: [],
      compare: false,
      selectedImages: [],
      showCompare: false,
      showFocused: false,
      note: '',
      allChecked: false,
      showBody: true,
    };

    apiFetch(`/users/${userId}/wounds`, {method: 'GET'})
    .then((res) => {
      this.setState({total: res.length});
      forEach(res, image => {
        apiFetch(`/users/${userId}/wound_images/${image.image_id}`)
          .then(res2 => {
            const image = res2[0];
            image.checked = false;
            image.name = image.name.slice(0, -41);
            this.setState( prev => ({
              images: [...prev.images, image]
            }));
          });
      });
    });

    apiFetch(`/users/${userId}/dates_with_wounds`)
    .then((data) => {
      const daysWithWounds = new Set();
      data.forEach(dateStr => {
        const dateObj = moment(dateStr);
        daysWithWounds.add(dateObj.format('YYYY-MM-DD'));
      });
      this.setState({ daysWithWounds });
    });
  }

  handleSelectedImages = (img) => {
    const { selectedImages } = this.state;

    if(img.checked && !selectedImages.includes(img)){
      selectedImages.push(img)
    } else if (img.checked === false) {
      selectedImages.splice(selectedImages.indexOf[img], 1)
    }
  }

  handleCheckboxChange = (e) => {
    const { images } = this.state;
    const { name, checked } = e.target;

    let count = 0;
    const image = images.find(img => img.name === name);
    image.checked = checked;
    this.setState(prev => ({
      ...prev.images[image],
    }))
    
    for (const idx in images) {
      if (Object.hasOwnProperty.call(images, idx)) {
        const image = images[idx];
        if(image.checked){
          count++;
        } else {
          this.setState({allChecked: false});
        }
        if(count > 1){
          this.setState({compare: true});
        } else {
          this.setState({compare: false});
        }
      }
    }
    this.handleSelectedImages(image);
  };

  handleToggleAll = () => {
    const { images, selectedImages, allChecked } = this.state;
    const newCheckboxValue = !allChecked

    for (const idx in images) {
      const image = images[idx];
      image.checked = newCheckboxValue;
      this.handleSelectedImages(image)
    }
    this.setState({ allChecked: newCheckboxValue });
    if (newCheckboxValue === false) {
      this.handleWoundsHome()
    }
    if(selectedImages.length > 1){
      this.setState({compare: true});
    } else {
      this.setState({compare: false});
    }
  }

  handleTextChange = (e) => {
    const { value } = e.target;
    this.setState({note: value});
  }

  handleCompareImages = () => {
    this.setState({
      showCompare: true,
      showFocused: false,
      showBody: false,
    });
  };

  handleWoundsHome = () => {
    const { images } = this.state;

    for (const idx in images) {
      if (Object.hasOwnProperty.call(images, idx)) {
        const image = images[idx];
        if(image.checked){
          image.checked = false;
        }
      }
    }

    this.setState({
      showCompare: false,
      showFocused: false,
      showBody: true,
      selectedImages: [],
      compare: false,
      note: '',
      selectedDate: null,
      currWoundEntries: [],
      painPointEntries: [],
      allChecked: false,
    });
  };

  handleViewImage = async (img) => {
    const { selectedImages } = this.state;
    const { clinicId, userId } = this.props;

    if(!img.image_id){
      img = selectedImages[0]
    }
    
    await apiFetch(`/clinics/${clinicId}/users/${userId}/wound_images/${img.image_id}/notes`, { method: 'GET' }).then((res) => {
      this.setState({ notebook: res });
    });

    selectedImages.length = 0;
    selectedImages.push(img);
    this.setState({ showFocused: true, showCompare: false, showBody: false });
  };

  handleAddNote = (img) => {
    const { note } = this.state;
    const { clinicId, userId } = this.props;

    apiFetch(`/clinics/${clinicId}/users/${userId}/wound_images/${img.image_id}/notes`, {
      method: 'POST',
      body: {
        note: note
      },
    })
    .catch(e => {
      console.log(e);
    });


    this.handleWoundsHome();
  };

  handleClickCurrentMonth = (month, year) => {
    this.handleChangeMonth(month, year);
    this.handleWoundsHome();
  }

  handlePrevMonth = () => {
    let { month, year } = this.state;

    month = (month === 0) ? 11 : (month - 1);
    year = (month === 11) ? (year - 1) : year;

    this.handleChangeMonth(month, year);
    this.setState({ month, year });
  }

  handleNextMonth = () => {
    let { month, year } = this.state;

    month = (month + 1) % 12;
    year = (month === 0) ? (year + 1) : year;

    this.handleChangeMonth(month, year);
    this.setState({ month, year });
  }

  handleChangeMonth = (month, year) => {
    this.setState({ month, year, fetching: true });
  };

  handleSelectDate = (selectedDate) => {
    this.setState({ selectedDate });
    const { images, selectedImages } = this.state;

    const reduced = images.reduce(function(filtered, image) {
      const date = moment(image.created_at).format('YYYY-MM-DD');
      if (selectedDate === date) {
        filtered.push(image);
      }
      return filtered;
    }, []);

    selectedImages.length = 0;
    selectedImages.push(...reduced);
    this.handleCompareImages();
  };

  render() {
    const { selectedImages, images, compare, showFocused, showCompare, allChecked, total, note, notebook, showBody, selectedDate, month, year, daysWithWounds } = this.state;

    return (
      <Fragment>
        {this.props.children}
        <PatientViewContentSection>
          <div style={baseStyles.bottomHalf}>
            <div style={{ flex: 1, flexWrap: 'wrap', maxWidth: 300, display: showBody ? 'block' : 'none', padding: 20, marginTop: 40 }}>
            <Calendar
                clickableDates={true}
                data={[]}
                onDateChange={this.handleChangeMonth}
                onClickDay={this.handleSelectDate}
                onClickCurrentMonth={this.handleClickCurrentMonth}
                selectedDate={selectedDate}
                selectedDateColor={colors.primaryColor}
                daysWithVitals={daysWithWounds}
                onClickBack={this.handlePrevMonth}
                onClickForward={this.handleNextMonth}
                month={month}
                year={year}
              />
            </div>
            <div style={baseStyles.overviewContent}>
              {!showFocused && !showCompare && 
                <ListWoundsView 
                  images={images}
                  total={total}
                  compare={compare}
                  allChecked={allChecked}
                  handleCompareImages={this.handleCompareImages}
                  handleCheckboxChange={this.handleCheckboxChange}
                  handleViewImage={this.handleViewImage}
                  handleToggleAll={this.handleToggleAll}
                  selectedImages={selectedImages}
                />
              }
              { showCompare && 
                <CompareWoundsView
                  selectedImages={selectedImages}
                  handleWoundsHome={this.handleWoundsHome}
                  handleViewImage={this.handleViewImage}
                />
              }
              { showFocused &&
                <SingleWoundView
                image={selectedImages[0]}
                note={note}
                notebook={notebook}
                handleTextChange={this.handleTextChange}
                handleAddNote={this.handleAddNote}
                handleWoundsHome={this.handleWoundsHome}
                />
              }
            </div>
          </div>
        </PatientViewContentSection>
      </Fragment>
    );
  }
}

ImagesOverview.defaultProps = {
  forms: {},
  handleOpenAllForms: noop,
  handleOpenForm: noop,
  mostRecentMigraine: {},
  migraines: [],
  painLevel: 'None Reported',
  painLocations: {},
  updateAppointment: noop,
};

ImagesOverview.propTypes = {
  appointment: PropTypes.object,
  forms: PropTypes.object,
  router: PropTypes.object,
  handleOpenAllForms: PropTypes.func,
  handleOpenForm: PropTypes.func,
  painLocations: PropTypes.object,
};


export default ImagesOverview;
