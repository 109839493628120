import React from 'react';
import PropTypes from 'prop-types';
import { AxisLeft } from '@vx/axis';
import { min, max } from 'd3-array';
import { GridRows } from '@vx/grid';
import { Group } from '@vx/group';
import { Bar, LinePath } from '@vx/shape';
import { MarkerCircle } from '@vx/marker';
import { scaleLinear, scalePoint } from '@vx/scale';
import { useTooltip, defaultStyles, Tooltip } from '@vx/tooltip';
import { localPoint } from '@vx/event';
import { isNumber } from 'lodash';

import { colors } from '../themes/mindset/styles';

// data accessors
const getX = d => d.date;
const getY = d => d.value;

const VitalsLineChart = (props) => {
  const {
    color,
    data,
    dataSecondary,
    dataSecondaryColor,
    dataType,
    svgMargins,
    chartHeight,
    chartWidth,
    axisTopDomain,
    lineChartMargins,
    allHistoricalMeasurements,
    onClickDataPoint,
    defaultMinYScale,
    defaultMaxYScale,
  } = props;

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip();

  let tooltipTimeout;

  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 30,
    backgroundColor: 'rgba(0,0,0,0.9)',
    color: 'white',
  };

  let xScale;
  if (allHistoricalMeasurements) {
    xScale = scalePoint({
      domain: axisTopDomain,
    });
  } else {
    xScale = scaleLinear({
      domain: axisTopDomain,
    });
  }

  const minYAxisValue = min([...data, ...dataSecondary], getY);
  const maxYAxisValue = max([...data, ...dataSecondary], getY);
  const determinedMinYScale = isNumber(minYAxisValue)
    ? minYAxisValue : (defaultMinYScale || 0);
  const determinedMaxYScale = isNumber(maxYAxisValue)
    ? maxYAxisValue : (defaultMaxYScale || 100);

  let numTicks = 8;
  let tickValues;
  let tickFormat;
  let yScale;
  if (dataType === 'BMI' && data.length) {
    const lowestAxisValue = Math.floor(determinedMinYScale);
    const highestAxisValue = Math.ceil(determinedMaxYScale);
    tickValues = [];
    for (let i = lowestAxisValue; i <= highestAxisValue; i++) {
      tickValues.push(i);
    }
    numTicks = undefined;
    tickFormat = v => v;
    yScale = scaleLinear({
      domain: [lowestAxisValue, highestAxisValue],
      nice: true,
    });
  } else {
    yScale = scaleLinear({
      domain: [determinedMinYScale, determinedMaxYScale],
      nice: true,
    });
  }

  xScale.range([0, chartWidth]);
  yScale.range([chartHeight, 0]);

  const chartColor = color === 'red' ? colors.iconRed : colors.iconBlue;

  // to prevent duplicate dots for data sets with a single value, do not included markerEnd
  let markerEnd;
  let markerSecondaryEnd;
  if (data.length > 1) markerEnd = `url(#marker-circle-${chartColor})`;
  if (dataSecondary.length > 1) markerSecondaryEnd = `url(#marker-circle-secondary-${dataSecondaryColor})`;

  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={chartWidth + svgMargins.left + svgMargins.right}
        height={chartHeight + lineChartMargins.bottom + lineChartMargins.top}
      >
        <MarkerCircle
          id={`marker-circle-${chartColor}`}
          fill={chartColor}
          size={1}
          refX={2}
        />
        <MarkerCircle
          id={`marker-circle-secondary-${dataSecondaryColor}`}
          fill={dataSecondaryColor}
          size={1}
          refX={2}
        />
        <AxisLeft
          left={svgMargins.left}
          top={lineChartMargins.top}
          scale={yScale}
          tickLabelProps={() => ({
            fontSize: 11,
            textAnchor: 'end',
            transform: 'translate(-5, 3)',
          })}
          numTicks={numTicks}
          tickValues={tickValues}
          tickFormat={tickFormat}
        />
        <Group top={lineChartMargins.top} left={svgMargins.left}>
          <GridRows
            scale={yScale}
            numTicks={numTicks}
            height={chartHeight}
            width={chartWidth}
          />
          <LinePath
            data={data}
            x={d => xScale(getX(d))}
            y={d => yScale(getY(d))}
            stroke={chartColor}
            strokeWidth={2}
            strokeOpacity={1}
            shapeRendering="geometricPrecision"
            markerStart={`url(#marker-circle-${chartColor})`}
            markerMid={`url(#marker-circle-${chartColor})`}
            markerEnd={markerEnd}
          />
          {dataSecondary ? (
            <LinePath
              data={dataSecondary}
              x={d => xScale(getX(d))}
              y={d => yScale(getY(d))}
              stroke={dataSecondaryColor}
              strokeWidth={2}
              strokeOpacity={1}
              shapeRendering="geometricPrecision"
              markerStart={`url(#marker-circle-secondary-${dataSecondaryColor})`}
              markerMid={`url(#marker-circle-secondary-${dataSecondaryColor})`}
              markerEnd={markerSecondaryEnd}
            />
          ) : null}
          <Bar
            x={0}
            y={0}
            width={chartWidth}
            height={chartHeight}
            fill="transparent"
            onClick={(event) => {
              const { x } = localPoint(event);
              const normalizedX = x - svgMargins.left;
              let date;
              if (allHistoricalMeasurements) {
                const dateIndex = Math.round(normalizedX / xScale.step());
                date = axisTopDomain[dateIndex];
              } else {
                date = xScale.invert(normalizedX);
                const roundedDate = Math.round(date);
                const datum = data.find(datum => datum.date === roundedDate.toString());
                date = datum.dateString;
              }
              onClickDataPoint(date);
            }}
            onMouseLeave={() => {
              tooltipTimeout = window.setTimeout(() => {
                hideTooltip();
              }, 300);
            }}
            onMouseMove={(event) => {
              if (tooltipTimeout) clearTimeout(tooltipTimeout);
              const { x } = localPoint(event);
              let datum;
              let datumSecondary;

              if (allHistoricalMeasurements) {
                const normalizedX = x - svgMargins.left;
                const dateIndex = Math.round(normalizedX / xScale.step());
                const date = axisTopDomain[dateIndex];
                datum = data.find(datum => datum.date === date);
                if (dataSecondary) {
                  datumSecondary = dataSecondary.find(datum => datum.date === date);
                }
              } else {
                const normalizedX = x - svgMargins.left;
                const date = xScale.invert(normalizedX);
                const roundedDate = Math.round(date);
                datum = data.find(datum => datum.date === roundedDate.toString());
                if (dataSecondary) {
                  datumSecondary = dataSecondary.find(datum => datum.date === roundedDate.toString());
                }
              }

              if (datum && datum.value) {
                const tooltipLeft = xScale(getX(datum)) + svgMargins.left;
                const tooltipTop = yScale(getY(datum)) + lineChartMargins.top;

                let { value } = datum;

                if (dataType === 'BP') {
                  value = `${value}/${datumSecondary.value}`;
                }

                showTooltip({
                  tooltipData: {
                    value,
                  },
                  tooltipTop,
                  tooltipLeft,
                });
              }
            }}
          />
        </Group>
        {tooltipOpen && tooltipData && (
          <circle
            cx={tooltipLeft}
            cy={tooltipTop}
            r={4}
            fill={chartColor}
            stroke="white"
            strokeWidth={2}
            style={{ pointerEvents: 'none' }}
          />
        )}
      </svg>
      {tooltipOpen && tooltipData && (
        <Tooltip
          key={Math.random()} // update tooltip bounds each render
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          {tooltipData.value}
        </Tooltip>
      )}
    </div>
  );
};

VitalsLineChart.defaultProps = {
  dataSecondary: [],
  dataSecondaryColor: colors.iconRed,
  dataType: '',
  defaultMinYScale: undefined,
  defaultMaxYScale: undefined,
};

VitalsLineChart.propTypes = {
  axisTopDomain: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  dataSecondary: PropTypes.array,
  dataSecondaryColor: PropTypes.string,
  dataType: PropTypes.string,
  svgMargins: PropTypes.object.isRequired,
  chartHeight: PropTypes.number.isRequired,
  chartWidth: PropTypes.number.isRequired,
  lineChartMargins: PropTypes.object.isRequired,
  allHistoricalMeasurements: PropTypes.bool.isRequired,
  onClickDataPoint: PropTypes.func.isRequired,
  defaultMinYScale: PropTypes.number,
  defaultMaxYScale: PropTypes.number,
};

export default VitalsLineChart;
