import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Checkbox from '../base/checkbox';
import TableCell from '../base/table-cell';
import TableRow from '../base/table-row';
import { withStyles } from '@material-ui/core/styles';

import openSignatureDocument from '../lib/open-signature-document';

import { colors } from '../lib/styles';

const styles = {
  releaseName: {
    color: colors.highlightDark,
    width: '30rem',
    fontSize: '1rem',
  },
  releaseStatus: {
    fontSize: '1rem',
  },
};

class ReleaseListItem extends Component {
  handleOpenForm = () => {
    const { status, requestId } = this.props;
    if (status === 'Signed') {
      openSignatureDocument(requestId);
    }
  }
  handleChangeCheckbox = () => {
    const { onClickItem, release } = this.props;
    onClickItem(release.id);
  }
  render() {
    const { release, status } = this.props;
    const isSigned = status === 'Signed';

    const nameStyle = Object.assign({}, styles.releaseName, {
      color: isSigned ? colors.primaryAccent : undefined,
      cursor: isSigned ? 'pointer' : 'auto',
    });

    return (
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox checked={this.props.isSelected && !status} disabled={Boolean(status)} onChange={this.handleChangeCheckbox} />
        </TableCell>
        <TableCell onClick={this.handleOpenForm} scope="row" padding="none" style={nameStyle}>{release.name}</TableCell>
        <TableCell scope="row" padding="none" style={styles.releaseStatus}>{status}</TableCell>
      </TableRow>
    );
  }
}

ReleaseListItem.defaultProps = {
  handleSendSignatureRequest: noop,
  release: {
    title: '',
  },
  requestId: null,
  status: '',
  sendSignatureRequest: noop,
};

ReleaseListItem.propTypes = {
  handleSendSignatureRequest: PropTypes.func,
  release: PropTypes.object,
  requestId: PropTypes.number,
  status: PropTypes.string,
};

export default withStyles(styles)(ReleaseListItem);
