import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
// import { merge } from 'lodash';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  Box,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';

import { apiFetch } from '../lib/fetch';
import AppWrapper from '../wrappers/app-wrapper';
import DashboardInvoicing from '../components/dashboard-invoicing';
import DashboardPatientSummary from '../components/dashboard-patient-summary';
import DashboardRemotePatientMonitoring from '../components/dashboard-remote-patient-monitoring';
import { formatProSubmission } from '../state/pro-submissions';
import { formatAppointments } from '../state/appointments';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '25px',
    textAlign: 'left',
    width: '100%',
  },
  date: {
    textAlign: 'left',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    margin: 'auto',
  },
  accordion: {
    padding: '10px',
    textAlign: 'left',
    width: '100%',
    borderRadius: '5px',
    '&::before': {
      height: 0,
    },
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  reportDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  progressCircles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  title: {
    fontWeight: 'bold',
    padding: '5px',
  },
  table: {
    margin: '20px',
    overflow: 'auto',
    maxHeight: 300,
  },
  bold: {
    fontWeight: 'bold',
  },
  expandIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  expansionPanel: {
    position: 'relative',
    paddingRight: 0,
  },
  tabs: {
    marginLeft: 40,
  },
  patientDropdown: {
    marginLeft: 30,
  },
}));

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const Dashboard = ({ router, clinicId, userId }) => {
  const [participationRate, setParticipationRate] = useState({});
  const [proParticipationRate, setProParticipationRate] = useState({});
  const [twilio, setTwilio] = useState({});
  const [broadcast, setBroadcast] = useState([]);
  const [currMonth, setCurrMonth] = useState(0);
  const [painReportData, setPainReportData] = useState([]);
  const [totalBodyPain, setTotalBodyPain] = useState('');
  const [totalHeadache, setTotalHeadache] = useState('');
  const [virtualVisits, setVirtualVisits] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [rpmData, setRpmData] = useState({});
  const [currentPatient, setCurrentPatient] = useState(null);
  const [patientSearchOptions, setPatientSearchOptions] = useState();
  const [patientOptionSelected, setPatientOptionSelected] = useState(null);
  const [proSubmissions, setProSubmissions] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [invoicingEnabled, setInvoicingEnabled] = useState(false);
  const [loadingProSubmissions, setLoadingProSubmissions] = useState(false);
  const [loadingAppointments, setLoadingAppointments] = useState(false);

  const {
    number_of_patients,
    patient_participation_rate,
    patients_responded_to_pros,
    patients_sent_pros,
  } = participationRate;

  const {
    pro_data, total_sent, total_returned, completion_rate,
  } =
    proParticipationRate;

  const { start, stop, undelivered } = twilio;

  const startOfMonth = moment().subtract(currMonth, 'months').startOf('month');
  const endOfMonth = moment().subtract(currMonth, 'months').endOf('month');
  const monthStr = months[startOfMonth.month()];
  const currYear = startOfMonth.year();

  const proTableData = [];

  for (const names in pro_data) {
    const values = pro_data[names];
    proTableData.push({
      name: names,
      sent: values.sent,
      returned: values.returned,
    });
  }

  const classes = useStyles();

  useEffect(() => {
    const options = {
      query: {
        start_time: startOfMonth.toISOString(),
        end_time: endOfMonth.toISOString(),
      },
    };

    setTwilio({});
    setProSubmissions([]);
    setLoadingProSubmissions(true);
    setLoadingAppointments(true);

    apiFetch(`/clinics/${clinicId}/patient_participation_rate`, options)
      .then((res) => {
        setParticipationRate(res);
      })
      .catch((err) => {
        console.log('UseEffect Error: ', err);
      });

    apiFetch(`/clinics/${clinicId}/pro_completion_rate`, options)
      .then((res) => {
        setProParticipationRate(res);
      })
      .catch((err) => {
        console.log('UseEffect Error: ', err);
      });

    apiFetch(`/clinics/${clinicId}/pain_report`, options)
      .then((res) => {
        setPainReportData(res.data);
        setTotalBodyPain(res.totalBodyPain);
        setTotalHeadache(res.totalHeadache);
      })
      .catch((err) => {
        console.log('UseEffect Error: ', err);
      });

    apiFetch(`/clinics/${clinicId}/twilio_kpi`, options)
      .then((res) => {
        setTwilio(res);
      })
      .catch((err) => {
        console.log('UseEffect Error: ', err);
      });

    apiFetch(`/clinics/${clinicId}/broadcast_kpi`, options)
      .then((res) => {
        const { results } = res;
        setBroadcast(results);
      })
      .catch((err) => {
        console.log('UseEffect Error: ', err);
      });

    apiFetch(`/clinics/${clinicId}/virtual_visits`, options)
      .then((res) => {
        setVirtualVisits(res);
      });

    apiFetch(`/clinics/${clinicId}/rpm`, options)
      .then((res) => {
        setRpmData(res);

        const patientSearchOptions = [];
        Object.keys(res.patientTotals).forEach((name) => {
          patientSearchOptions.push({
            name,
            user_id: res.patientTotals[name].user_id,
          });
        });
        setPatientSearchOptions(patientSearchOptions);
      });

    apiFetch(`/clinics/${clinicId}/pro_submissions`, options)
      .then(async (res) => {
        const formattedPros = res.map((pro) => {
          const formattedPro = formatProSubmission(pro, res);
          return formattedPro;
        });
        setProSubmissions(formattedPros);
        setLoadingProSubmissions(false);
      });

    apiFetch(`/clinics/${clinicId}/appointments_report`, options)
      .then(async (res) => {
        const formattedAppointments = res.map((appt) => {
          const formattedAppointment = formatAppointments(appt, res);
          return formattedAppointment;
        });
        setAppointments(formattedAppointments);
        setLoadingAppointments(false);
      });

    apiFetch(`/clinics/${clinicId}/billing`)
      .then(({ invoicing_enabled }) => {
        setInvoicingEnabled(invoicing_enabled);
      })
      .catch((bError) => {
        console.error('error retrieving billing', bError);
      });
  }, [currMonth]);

  const prevMonth = () => {
    setCurrMonth(currMonth + 1);
  };

  const nextMonth = () => {
    setCurrMonth(currMonth - 1);
  };

  const handleChangeSelectedTab = (e, val) => {
    setSelectedTab(val);
  };

  const changePatient = (userName, userId) => {
    const options = {
      query: {
        start_time: startOfMonth.toISOString(),
        end_time: endOfMonth.toISOString(),
      },
    };

    apiFetch(`/clinics/${clinicId}/patients/${userId}/rpm`, options)
      .then((data) => {
        setCurrentPatient({
          name: userName,
          data,
          totalDuration: data.totals.total,
        });
      });
  };

  const handleChangePatient = (_, newValue) => {
    if (newValue) {
      changePatient(newValue.name, newValue.user_id);
    } else {
      setCurrentPatient(null);
    }
    setPatientOptionSelected(newValue);
  };

  return (
    <AppWrapper router={router}>
      <div className={classes.root}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="flex-start">
            <Typography className={classes.date} variant="h4" gutterBottom>
              <strong>
                <ArrowBackIosIcon onClick={() => prevMonth()} />
                {`${monthStr} ${currYear}`}
                { currMonth > 0 && <ArrowForwardIosIcon onClick={() => nextMonth()} />}
              </strong>
            </Typography>
            <Tabs
              value={selectedTab}
              onChange={handleChangeSelectedTab}
              className={classes.tabs}
            >
              <Tab label="Patient Summary" />
              <Tab label="Remote Patient Monitoring" />
              {invoicingEnabled && <Tab label="Invoicing" />}
            </Tabs>
          </Box>
          {selectedTab === 1 && patientSearchOptions && patientSearchOptions.length ? (
            <Autocomplete
              value={patientOptionSelected}
              onChange={handleChangePatient}
              options={patientSearchOptions}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.user_id === value.user_id}
              renderInput={params => <TextField {...params} label="Search" style={{ width: 200 }} />}
              className={classes.patientDropdown}
            />
          ) : null}
        </Box>
        {selectedTab === 0 ? (
          <DashboardPatientSummary
            broadcast={broadcast}
            completion_rate={completion_rate}
            loadingProSubmissions={loadingProSubmissions}
            loadingAppointments={loadingAppointments}
            number_of_patients={number_of_patients}
            painReportData={painReportData}
            patient_participation_rate={patient_participation_rate}
            patients_responded_to_pros={patients_responded_to_pros}
            patients_sent_pros={patients_sent_pros}
            proSubmissions={proSubmissions}
            appointments={appointments}
            proTableData={proTableData}
            start={start}
            startOfMonth={startOfMonth}
            stop={stop}
            totalBodyPain={totalBodyPain}
            totalHeadache={totalHeadache}
            total_returned={total_returned}
            total_sent={total_sent}
            undelivered={undelivered}
            virtualVisits={virtualVisits}
          />
        ) : null}
        {selectedTab === 1 ? (
          <DashboardRemotePatientMonitoring
            rpmData={rpmData}
            clinicId={clinicId}
            currentPatient={currentPatient}
            setCurrentPatient={setCurrentPatient}
            setPatientOptionSelected={setPatientOptionSelected}
            changePatient={changePatient}
            startOfMonth={startOfMonth}
            endOfMonth={endOfMonth}
          />
        ) : null}
        {selectedTab === 2 ? (
          <DashboardInvoicing
            clinicId={clinicId}
            startOfMonth={startOfMonth}
          />
        ) : null}
      </div>
    </AppWrapper>
  );
};

function mapStateToProps(state) {
  const {
    appointments,
    clinic: { clinicId, providers },
    user,
  } = state;

  return {
    clinicId,
    providers,
    appointments,
    userId: user.id,
  };
}

Dashboard.propTypes = {
  router: PropTypes.object.isRequired,
  clinicId: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, {})(Dashboard);
