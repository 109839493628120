import { get, isUndefined } from 'lodash';
import moment from 'moment';

export function stateFormat(appointment, emrDemo) {
  const emrContact = isUndefined(get(emrDemo, 'Contacts[0].FirstName')) ? null : `${get(emrDemo, 'Contacts[0].FirstName')} ${get(emrDemo, 'Contacts[0].LastName') || ''}`;
  return {
    first_name: {
      selection: 'mindset',
      mindset: appointment.first_name,
      emr: emrDemo.FirstName,
      label: 'Name',
    },
    last_name: {
      mindset: appointment.last_name,
      emr: emrDemo.LastName,
    },
    birth_date: {
      selection: 'mindset',
      mindset: moment(appointment.birth_date).format('MM/DD/YYYY'),
      emr: moment(emrDemo.DOB).format('MM/DD/YYYY'),
      label: 'DOB',
    },
    weight: {
      selection: 'mindset',
      mindset: appointment.weight,
      emr: emrDemo.Weight,
      label: 'Weight',
    },
    height: {
      selection: 'mindset',
      mindset: appointment.height,
      emr: emrDemo.Height,
      label: 'Height',
    },
    primary_language: {
      selection: 'mindset',
      mindset: appointment.primary_language,
      emr: emrDemo.Language,
      label: 'Primary Language',
    },
    email: {
      selection: 'mindset',
      mindset: appointment.email,
      emr: (get(emrDemo, 'EmailAddresses') || []).join(', '),
      label: 'Email',
    },
    home_phone: {
      selection: 'mindset',
      mindset: appointment.home_phone,
      emr: get(emrDemo, 'PhoneNumber.Home'),
      label: 'Home Phone',
    },
    work_phone: {
      selection: 'mindset',
      mindset: appointment.work_phone,
      emr: get(emrDemo, 'PhoneNumber.Office'),
      label: 'Work Phone',
    },
    cell_phone: {
      selection: 'mindset',
      mindset: appointment.cell_phone,
      emr: get(emrDemo, 'PhoneNumber.Mobile'),
      label: 'Cell Phone',
    },
    // TODO: These are enums and the data we're getting back from
    // Redox appears to be inconsistent with our pattersn
    //    sex: {
    //      selection: 'mindset',
    //      mindset: appointment.sex,
    //      emr: emrDemo.Sex,
    //      label: 'Sex',
    //    },
    //    marital_status: {
    //      selection: 'mindset',
    //      mindset: appointment.marital_status,
    //      emr: emrDemo.marital_status,
    //      label: 'Marital Status',
    //    },
    address: {
      selection: 'mindset',
      mindset: appointment.address,
      emr: get(emrDemo, 'Address.StreetAddress'),
      label: 'Address',
    },
    city: {
      selection: 'mindset',
      mindset: appointment.city,
      emr: get(emrDemo, 'Address.City'),
      label: 'City',
    },
    state: {
      selection: 'mindset',
      mindset: appointment.state,
      emr: get(emrDemo, 'Address.State'),
      label: 'State',
    },
    zip_code: {
      selection: 'mindset',
      mindset: appointment.zip_code,
      emr: get(emrDemo, 'Address.ZIP'),
      label: 'Zipcode',
    },
    emergency_contact: {
      selection: 'mindset',
      mindset: appointment.emergency_contact,
      emr: emrContact,
      label: 'Emergency Contact',
    },
    emergency_relationship: {
      selection: 'mindset',
      mindset: appointment.emergency_relationship,
      emr: get(emrDemo, 'Contacts[0].RelationToPatient'),
      label: 'Relationship',
    },
    emergency_phone: {
      selection: 'mindset',
      mindset: appointment.emergency_phone,
      emr: (get(emrDemo, 'Contacts[0].PhoneNumber') || []).join(', '),
      label: 'Phone Number',
    },
    emergency_email: {
      selection: 'mindset',
      mindset: appointment.emergency_email,
      emr: (get(emrDemo, 'Contacts[0].EmailAddresses') || []).join(', '),
      label: 'Email',
    },
  };
}

export function mergeFormat(state, dateFormat = 'MM/DD/YYYY') {
  return {
    first_name: state.first_name[state.first_name.selection] || '',
    last_name: state.last_name[state.first_name.selection] || '',
    primary_language: state.primary_language[state.primary_language.selection] || '',
    weight: state.weight[state.weight.selection] || '',
    height: state.height[state.height.selection] || '',
    birth_date: state.birth_date[state.birth_date.selection] ? moment(state.birth_date[state.birth_date.selection]).format(dateFormat) : '',
    email: state.email[state.email.selection] || '',
    home_phone: state.home_phone[state.home_phone.selection] || '',
    work_phone: state.work_phone[state.work_phone.selection] || '',
    cell_phone: state.cell_phone[state.cell_phone.selection] || '',
    //    sex: state.sex[state.sex.selection],
    //    marital_status: state.marital_status[state.marital_status.selection],
    address: state.address[state.address.selection] || '',
    city: state.city[state.city.selection] || '',
    state: state.state[state.state.selection] || '',
    zip_code: state.zip_code[state.zip_code.selection] || '',
    emergency_contact: state.emergency_contact[state.emergency_contact.selection] || '',
    emergency_relationship: state.emergency_relationship[state.emergency_relationship.selection] || '',
    emergency_phone: state.emergency_phone[state.emergency_phone.selection] || '',
    emergency_email: state.emergency_email[state.emergency_email.selection] || '',
  };
}
