import { isString, get, forEach, map, reject, omit, isEmpty, reduce, uniqueId } from 'lodash';
import moment from 'moment';

export function stateFormat(surgery, originalEmrSurgeries) {
  const emrSurg = reduce(reject(originalEmrSurgeries, (surgery) => (surgery.Name === '')), (all, item) => {
    const id = uniqueId('emr-surg'); // No unique id is returned by redox for an entry
    all[id] = {
      emrId: id,
      snomed_code: get(item, 'Code'),
      name: get(item, 'Name'),
      year: get(item, 'DateTime') ? moment(get(item, 'DateTime')).format('YYYY') : '',
    };
    return all;
  }, {});
  const mindset = {};
  if (surgery[0]) {
    forEach(surgery, (item, key) => {
      mindset[`mindset${key}`] = {
	surgery_hx_id: item.id,
        surgery_id: item.surgery_id,
        surgeon_name: item.surgeon_name,
        name: item.name,
        year: item.year,
      };
    });
  }
  return {
    combined: mindset,
    mindset,
    emrSurg,
  };
}

export function mergeFormat(combined) {
  if (isEmpty(combined) || isString(combined)) {
    return {};
  }

  return {
    surgery: map(combined, (item) => {
      return omit(item, ['id', 'checked']);
    }),
  };
}
