export default (pro) => {
  let { data } = pro.pro_data;

  if (Array.isArray(data)) {
    data = data.reduce((prev, current) => {
      return { ...prev, ...current };
    }, {});
  }

  const s = [];

  s.push(`Diarrhea: ${data.diarrhea}`);
  if (data.diarrhea === 'Y') {
    s.push(`Liquid Stools: ${data.threeOrMoreLiquidStools}`);
  }

  s.push(`Constipated: ${data.constipated}`);
  if (data.constipated === 'Y') {
    s.push(`BM 3 Day: ${data.threeDaysSinceBowelMovement}`);
  }

  s.push(`Nausea or Vomiting: ${data.nauseaOrVomiting}`);
  if (data.nauseaOrVomiting === 'Y') {
    s.push(`Vomiting Controlled: ${data.nauseaControlledByMedications}`);
    s.push(`Eat or Drink: ${data.ableToEatOrDrink}`);
    s.push(`Take Medications: ${data.ableToTakeMedications}`);
  }

  s.push(`New Abdominal Pain: ${data.abdominalPain}`);

  s.push(`Weight Change: ${data.weightChanged}`);
  if (data.weightChanged === 'Y') {
    s.push(`Gain or Lose: ${data.weightGainOrLoss}, ${data.weightChange}lbs`);
  }

  pro.score.score = s.join(' | ');
  return pro;
};
