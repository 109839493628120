import React from 'react';
import PropTypes from 'prop-types';
import { map, noop } from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '../base/table';
import TableBody from '../base/table-body';
import TableCell from '../base/table-cell';
import TableHead from '../base/table-head';
import TableRow from '../base/table-row';
import FormListItem from './form-list-item';

import { colors } from '../lib/styles';

const styles = {
  container: {
    border: `2px solid ${colors.highlightBright}`,
    margin: '10px',
  },
  loading: {
    display: 'flex',
    margin: '10px auto',
    textAlign: 'center',
  },
};


function FormList(props) {
  const containerStyle = Object.assign({}, styles.container, props.containerStyle);
  const formRows = map(props.forms, (form) => {
    return (
      <FormListItem
        key={`${form.name}`}
        form={form}
        hideCompletion={props.hideCompletion}
        hidePrint={props.hidePrint}
        hideSummary={props.hideSummary}
        onOpenForm={(formName) => {
          props.onOpenForm(formName);
        }}
        style={styles.row}
      />
    );
  });

  return (
    <div style={containerStyle}>
      {!formRows.length && (<CircularProgress size={150} style={styles.loading} thickness={7} />)}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell tooltip="Form Name">Form</TableCell>
            <TableCell
              tooltip="Percentage Complete"
            >% Complete
            </TableCell>
            <TableCell tooltip="Summary">Summary</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >
          {formRows}
        </TableBody>
      </Table>
    </div>
  );
}

FormList.defaultProps = {
  containerStyle: {},
  forms: {},
  onOpenForm: noop,
  hideCompletion: false,
  hideSummary: false,
};

FormList.propTypes = {
  containerStyle: PropTypes.object,
  forms: PropTypes.object,
  onOpenForm: PropTypes.func,
  hideCompletion: PropTypes.bool,
  hideSummary: PropTypes.bool,
};

export default FormList;
