import globalmedTheme from '../themes/globalmed/theme';
import mindsetTheme from '../themes/mindset/theme';
import * as globalmedStyles from '../themes/globalmed/styles';
import * as mindsetStyles from '../themes/mindset/styles';

const url = new URL(document.location.href);
let brand;

if(url.host.includes('globalmed')) {
  brand = {
    name: 'globalmed',
    source_id: 4,
    theme: globalmedTheme,
    title: 'Encounter',
    styles: {...globalmedStyles},
    loginLogo: 'login_logo.png'
  }
} else {
  brand = {
    name: 'mindset',
    source_id: 1,
    theme: mindsetTheme,
    title: 'Informed',
    styles: {...mindsetStyles},
    loginLogo: 'login_logo.svg',
  }
}

export default brand;