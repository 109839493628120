import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';

import config from '../config';

function ProDevDetails(props) {
  const { pro, content } = props;
  const [ltt, setLtt] = useState(null);
  const [open, setOpen] = useState(false);
  const url = `${config.API_URL}/users/${pro.user_id}/pro_submissions/${pro.id}`;
  const setDevMode = (e) => {
    // mobile workaround for double clicks
    const touchTap = {
      time: Date.now(),
      target: e.currentTarget,
    };
    if (ltt && (ltt.target === touchTap.target) && (touchTap.time - ltt.time < 300)) {
      setOpen(true);
    }
    setLtt(touchTap);
  };
  return (
    <div>
      <div
        onClick={setDevMode}
      >{content}</div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>PRO Details</DialogTitle>
        <DialogContent>
          <div>
            <div>PRO ID: <strong>{pro.id}</strong></div>
            <div>User ID: <strong>{pro.user_id}</strong></div>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >JSON</a>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ProDevDetails;