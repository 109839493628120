import React from 'react';
import useSWR from 'swr';
import { connect } from 'react-redux';
import { filter, includes } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { SortableTable } from 'common-ui';
import { updateTableSetting } from '../state/tables';
import { apiFetch } from '../lib/fetch';
import AppWrapper from '../wrappers/app-wrapper';
import { btnLink, colors } from '../lib/styles';

const columnData = [
  {
    id: 'name', align: false, disablePadding: false, label: 'Name', small: true,
  },
];

const styles = {
  btnLink,
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  root: {
    marginBottom: '150px',
    padding: '25px',
    textAlign: 'left',
    width: '100%',
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: colors.highlightGray,
    },
    cursor: 'pointer',
  },
};

const pageDetails = {
  CAMPAIGN_BUNDLE: {
    button: 'Create a Care Pathway Bundle',
    route: 'care-pathway-bundled-events',
    title: 'Care Pathway Bundled Events',
  },
  APPOINTMENT_BUNDLE: {
    button: 'Create an Appointment Bundle',
    route: 'appointment-bundled-events',
    title: 'Appointment Bundled Events',
  },
};

export const BundledEvents = (props) => {
  const bundle_type = includes(props.route.path, 'care-pathway') ? 'CAMPAIGN_BUNDLE' : 'APPOINTMENT_BUNDLE';
  const { clinicId, classes, tableSettings, updateTableSetting } = props;
  const { data } = useSWR(`/clinics/${clinicId}/bundled_events`, apiFetch);
  const events = filter(data, ['bundle_type', bundle_type]);
  const rowNumber = window.localStorage.getItem('bundled_events_rows');
  return (
    <AppWrapper router={props.router}>
      <div className={classes.root}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 className={classes.headerTitle}>{pageDetails[bundle_type].title}</h1>
          <Link className={classes.btnLink} to={`/app/${pageDetails[bundle_type].route}/new`}>
            <Button color="primary" variant="contained">{pageDetails[bundle_type].button}</Button>
          </Link>
        </div>
        <SortableTable
          columnData={columnData}
          emptyMessage="No bundled events exist at this time."
          rows={events}
          handleClick={(row) => { props.router.push(`/app/${pageDetails[bundle_type].route}/${row.id}`); }}
          hover={true}
          rowClasses={{ row: classes.tableRow }}
          rowNumber={rowNumber && Number(rowNumber)}
          page={tableSettings.page && Number(tableSettings.page)}
          saveSetting={rows => window.localStorage.setItem('bundled_events_rows', rows)}
          updateTableSetting={page => updateTableSetting({ type: 'bundled_events', value: { page } })}
        />
      </div>
    </AppWrapper>
  );
};

function mapStateToProps(state) {
  const {
    clinic: { clinicId },
    tableSettings: { bundled_events },
  } = state;

  return {
    clinicId,
    tableSettings: bundled_events,
  };
}
BundledEvents.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};


export default connect(mapStateToProps, { updateTableSetting })(withStyles(styles)(BundledEvents));
