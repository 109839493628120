import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ArrowLeft } from '../lib/icons';


const baseStyles = {
  backBtn: {
    display: 'flex',
    alignItems: 'center',
  },
  compareBtn: {
    width: 'fit-content',
    margin: '10px auto',
  },
  formControl: {
    margin: 10,
    width: '100%',
    flexDirection: 'row',
    overflow: 'auto',
  },
  iconWrapper: {
    width: 16,
    height: 18,
    marginRight: '5px',
  },
  imageControl: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    alignContent: 'center',
    padding: '10px',
  },
  imageRow: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'overlay',
    padding: '10px',
  },
  viewImage: {
    cursor: 'pointer',
    height: '400px',
    padding: 0,
    margin: 0,
    alignSelf: 'center',
  },
};

const CompareWoundsView = (props) => {
  const {
    selectedImages,
    handleViewImage,
    handleWoundsHome,
  } = props;

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleWoundsHome}
        style={baseStyles.backBtn}
      >
        <div style={baseStyles.iconWrapper}>
          <ArrowLeft />
        </div>
        Return to Home Page
      </Button>
      <div style={baseStyles.formControl}>
        <div style={baseStyles.imageRow}>
          {selectedImages.map(image => (
            <div id="imageDiv" key={image.url}>
              <div style={baseStyles.imageControl} key={image.image_id}>
                <Typography variant="h6">{image.name}</Typography>
                <img
                  value={image.image_id}
                  src={image.url}
                  alt={`thumbnail of ${image.body_location} taken by patient`}
                  style={baseStyles.viewImage}
                  onClick={() => handleViewImage(image)}
                />
              </div>
            </div>
      ))}
        </div>
      </div>
    </>
  );
};

CompareWoundsView.propTypes = {
  selectedImages: PropTypes.array.isRequired,
  handleWoundsHome: PropTypes.func.isRequired,
  handleViewImage: PropTypes.func.isRequired,
};

export default CompareWoundsView;
