import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useSWR from 'swr';

import Campaign from '../components/campaign';
import AppWrapper from '../wrappers/app-wrapper';
import { apiFetch } from '../lib/fetch';

const pageDetails = {
  title: 'Edit Care Pathway',
  route: 'care-pathways',
};

const ViewCarePathway = (props) => {
  const { campaignId, clinicId } = props;
  const { data } = useSWR(`/clinics/${clinicId}/campaigns/${campaignId}`, apiFetch);
  const handleSave = async (name, events) => {
    const opts = {
      method: 'PUT',
      body: {
        name,
        campaign_spec: {
          schedule: events,
        },
      },
    };
    try {
      await apiFetch(`/clinics/${props.clinicId}/campaigns/${props.campaignId}`, opts);
      props.router.push('/app/care-pathways?message=created');
    } catch (e) {
      console.error('error saving: ', e);
    }
  };

  const handleToggleEnabled = async (enabled) => {
    const opts = {
      method: 'PUT',
      body: { enabled },
    };
    const toggleEnabledRes = await apiFetch(`/clinics/${props.clinicId}/campaigns/${props.campaignId}`, opts);
    return toggleEnabledRes;
  };

  return (
    <AppWrapper router={props.router}>
      <Campaign
        campaign={data}
        headerText={pageDetails.title}
        onSave={handleSave}
        instructions={pageDetails.instructions}
        onToggleEnabled={handleToggleEnabled}
      />
    </AppWrapper>
  );
};

function mapStateToProps(state, ownProps) {
  const {
    clinic: { clinicId },
  } = state;

  return {
    clinicId,
    campaignId: Number(ownProps.routeParams.campaignId),
  };
}
ViewCarePathway.propTypes = {
  campaignId: PropTypes.number.isRequired,
  clinicId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
};


export default connect(mapStateToProps, {})(ViewCarePathway);

