import { assign, find, uniqueId } from 'lodash';
import { updateAppointment } from '../state/appointments';
import makeActionCreator from '../lib/make-action-creator';

export const RECEIVE_NOTIFICATION = 'notifications-RECEIVE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'notifications-REMOVE_NOTIFICATION';

const receiveNotification = makeActionCreator(RECEIVE_NOTIFICATION);
const removeNotification = makeActionCreator(REMOVE_NOTIFICATION);

export function createNotification(notification) {
  return function dispatcher(dispatch, getState) {
    const notificationId = uniqueId('notification');
    const receivedAt = Date.now();
    const newNotification = {};
    const appointment = find(getState().appointments.data, { id: notification.appointmentId });
    if (!appointment) {
      return;
    }
    newNotification[notificationId] = assign({
      id: notificationId,
      oldStatus: appointment.status,
      receivedAt,
    }, notification);
    dispatch(receiveNotification(newNotification));
    updateAppointment(notification.appointmentId, { status: notification.status });
  };
}

export function dismissNotification(notificationId) {
  return function dispatcher(dispatch) {
    dispatch(removeNotification(notificationId));
  };
}
