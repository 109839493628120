import React from 'react';
import useSWR from 'swr';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';
import AppWrapper from '../wrappers/app-wrapper';

const styles = {
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  inputField: {
    margin: '10px 10px 0px',
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    marginBottom: '150px',
    padding: '25px',
    textAlign: 'left',
    width: '100%',
  },
  video: {
    margin: '0 auto',
    minWidth: '450px',
    width: '50%',
  },
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '20px',
    width: '100%',
  }
};

export const VideoBroadcasts = (props) => {
  const { broadcastId, clinicId, classes } = props;
  const [values, setValues] = React.useState({
    name: '',
    file: null,
    uploading: false,
    error: null,
  });

  const [url, setUrl] = React.useState('');

  const fileName = get(values, 'file.name');
  const { data } = useSWR(`/clinics/${clinicId}/video_broadcasts/${broadcastId}`, apiFetch);

  const handleDelete = async () => {
    const options = {
      method: 'DELETE',
    };
    try {
      await apiFetch(`/clinics/${clinicId}/video_broadcasts/${broadcastId}`, options);
      props.router.push('/app/video-broadcasts');
    } catch (e) {
      if (e.status === 422) {
        return setValues({
          ...values,
          error: 'Broadcast in use, please remove the broadcast from any care pathways before deleting.',
        });
      }
      setValues({ ...values, error: e.message });
    }
  };

  React.useEffect(() => {
    if (!data || !data.id) return;
    setValues({
      name: data.name,
    });
  }, [data]);

  React.useEffect(() => {
    async function fetchUrl() {
      const res = await apiFetch(`/clinics/${clinicId}/video_broadcasts/${broadcastId}.mp4`);
      setUrl(res.url);
    }
    fetchUrl();
  }, []);

  const handleChange = field => (e) => {
    setValues({ ...values, [field]: e.target.value });
  };

  const handleChangeFile = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      setValues({ ...values, file: files[0] });
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    if (values.file) formData.append('video', values.file);
    if (values.name) formData.append('name', values.name);

    setValues({ ...values, error: null, uploading: true });
    const options = {
      method: 'PUT',
      body: formData,
    };
    try {
      await apiFetch(`/clinics/${clinicId}/video_broadcasts/${broadcastId}`, options);
      setValues({
        ...values,
        error: null,
        uploading: false,
        msg: 'Video Broadcast Successfully Updated',
      });
    } catch (e) {
      setValues({ ...values, uploading: false, error: 'Error Uploading Video Broadcast' });
    }
  };

  const uploadBtnText = fileName ? `${fileName} selected` : 'Select a .mp4 File';
  return (
    <AppWrapper router={props.router}>
      <div className={classes.root}>
        <h1 className={classes.headerTitle}>Update a Video Broadcast</h1>
        <div style={{ color: colors.errorRed, textAlign: 'center' }}>{values.error}</div>
        <div style={{ color: colors.successGreen, textAlign: 'center' }}>{values.msg}</div>
        <div className={classes.videoContainer}>
          <video key={url} className={classes.video} controls>
            <source src={url} type="video/mp4" />
          </video>
        </div>
        <div className={classes.inputRow}>
          <TextField
            placeholder="Broadcast Name"
            label="Broadcast Name"
            value={values.name}
            onChange={handleChange('name')}
          />
          <Button
            variant="contained"
            component="label"
          >{uploadBtnText}
            <Input
              inputProps={{ accept: '.mp4' }}
              onChange={handleChangeFile}
              style={{ display: 'none' }}
              type="file"
            />
          </Button>
        </div>
        <div className={classes.buttons}>
          <Button style={{ backgroundColor: colors.errorRed, color: colors.white }} onClick={handleDelete} variant="contained">
            <DeleteIcon />Delete
          </Button>
          <Button
            color="primary"
            disabled={(!values.file && !values.name) || values.uploading}
            onClick={handleUpload}
            variant="contained"
          >{values.uploading && <CircularProgress />} Save Changes
          </Button>
        </div>
      </div>
    </AppWrapper>
  );
};

function mapStateToProps(state, ownProps) {
  const {
    clinic: { clinicId },
  } = state;

  return {
    broadcastId: ownProps.routeParams.broadcastId,
    clinicId,
  };
}
VideoBroadcasts.propTypes = {
  classes: PropTypes.object.isRequired,
  clinicId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
};


export default connect(mapStateToProps, {})(withStyles(styles)(VideoBroadcasts));
