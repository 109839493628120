import { isNumber } from 'lodash';

function getSeverityRating(severity) {
  if (!isNumber(severity)) {
    return 'Invalid Severity';
  }

  if (severity < 4) {
    return 'light';
  }

  if (severity >= 4 && severity < 7) {
    return 'moderate';
  }

  if (severity >= 7) {
    return 'severe';
  }
}

export default getSeverityRating;
