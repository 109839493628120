import brand from './brand';
import config from '../config';

function setClinicFavicon(clinicId) {
  if(clinicId) {
    const favicon = document.getElementById('favicon');
    favicon.href = `${config.API_URL}/clinics/${clinicId}/logo/100`;
    
    favicon.onError = (e) => {
      e.target.onerror = null;
      favicon.href = `/img/${brand.name}/favicon.ico`;
    };
  }
  
}

export default setClinicFavicon;