export default (pro) => {
  const { data } = pro.pro_data;

  const {
    name,
    birthday,
    allergies,
    additionalHealthConcerns,
    additionalHealthConcernsDetails,
  } = data;

  const s = [];

  s.push(`Child's name: ${name}`);
  s.push(`Birthday: ${birthday}`);

  if (allergies.length) {
    const allergiesStr = allergies.join('; ');
    s.push(`Allergies: ${allergiesStr}`);
  } else {
    s.push('Allergies: N');
  }

  if (additionalHealthConcerns && additionalHealthConcerns === 'yes') {
    s.push('Additional health concerns: Y');
  } else {
    s.push('Additional health concerns: N');
  }

  if (additionalHealthConcerns && additionalHealthConcerns === 'yes' && additionalHealthConcernsDetails) {
    s.push(`Additional health concerns details: ${additionalHealthConcernsDetails}`);
  }

  pro.score.score = s.join(' | ');
  return pro;
};
