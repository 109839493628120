import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, toLower } from 'lodash';

import Avatar from '@material-ui/core/Avatar';

import { colors } from '../lib/styles';

const painHeads = ['FRONT', 'RIGHT', 'BACK', 'LEFT'];
const baseStyles = {
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: '170px',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '200px',
  },
  rightIcon: {
    marginLeft: '100px',
  },
  location: {
    TOP: {
      left: '82px',
      top: '15px',
    },
    LEFT: {
      left: '45px',
      top: '35px',
    },
    MIDDLE: {
      left: '82px',
      top: '35px',
    },
    RIGHT: {
      left: '120px',
      top: '35px',
    },
    BOTTOM: {
      left: '82px',
      top: '70px',
    },
    NECK: {
      left: '82px',
      top: '135px',
    },
  },
  painIndicator: {
    backgroundColor: colors.errorRed,
    borderRadius: '50%',
    height: '18px',
    position: 'absolute',
    zIndex: 100,
    width: '18px',
  },
};

class PatientPainHead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentHead: 'FRONT',
    };
    this.nextHead = this.nextHead.bind(this);
    this.prevHead = this.prevHead.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    const pain = get(nextProps, 'pain[0]', null);
    if (pain && pain.brain_quadrant) {
      this.setState({
        currentHead: pain.brain_quadrant,
      });
    }
  }
  nextHead() {
    const { currentHead } = this.state;
    const idx = painHeads.indexOf(currentHead);
    this.setState({
      currentHead: painHeads[(idx + 1) % 4],
    });
  }
  prevHead() {
    const { currentHead } = this.state;
    const idx = painHeads.indexOf(currentHead);
    let newIndex = idx - 1;
    if (newIndex < 0) {
      newIndex = 3;
    }
    this.setState({
      currentHead: painHeads[newIndex],
    });
  }
  render() {
    const { currentHead } = this.state;
    const pain = this.props.pain[0] || {};
    const { brain_locations, brain_quadrant } = pain;
    const hideDot = (brain_quadrant !== currentHead) || !brain_locations;

    let { sex } = this.props;
    sex = toLower(sex) === 'female' ? 'female' : 'male';

    const painIndicatorStyle = Object.assign({
      display: hideDot ? 'none' : 'inline',
    }, baseStyles.location[brain_locations], baseStyles.painIndicator);

    return (
      <div style={baseStyles.container}>
        <div style={{ height: '180px', position: 'absolute', width: '180px' }}>
          <div style={painIndicatorStyle} />
        </div>
        <Avatar
          src={`/img/${sex}-${this.state.currentHead}.png`}
          style={{ width: '180px', height: '180px' }}
        />
      </div>
    );
  }
}

PatientPainHead.defaultProps = {
  pain: [{
    brain_quadrant: 'FRONT',
  }],
  sex: 'male', // Male front facing image is very androgynous
};

PatientPainHead.propTypes = {
  sex: PropTypes.string,
  pain: PropTypes.array,
};

export default PatientPainHead;
