import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../lib/styles';

const ProSelect = withStyles({
  root: {
    alignSelf: 'center',
    border: `1px solid ${colors.charcoal}`,
    borderRadius: '6px',
    color: colors.charcoal,
    height: '40px',
    lineHeight: '30px',
    width: '200px',
  },
})(Select);

export default ProSelect;