import moment from 'moment';
import memoize from 'memoize-one'
// Takes a date to find, an array of items, and two strings
// representing their start and end dates.
function findItemByDate(date, itemArray, startField, endField) {

  const items = []

  const searchDate = moment(date).seconds(10); // Moment isBetween isn't inclusive

  itemArray.forEach((i) => {
    const startDate = moment(i[startField]).startOf('day');
    const endDate = moment(i[endField]).endOf('day');

    if(searchDate.isBetween(startDate, endDate)) {
      items.push(i);
    }
  });

  return items;
}

export default memoize(findItemByDate);
