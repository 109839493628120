import React from 'react';
import { get, filter, map } from 'lodash';
import useSWR from 'swr';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import HiddenContent from './hidden-content';
import { apiFetch } from '../lib/fetch';
import { browseProTypes } from '../state/app-data';

import BundledEventItem from './bundled-event-item';

const styles = {
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  root: {
    marginBottom: '150px',
    padding: '25px',
    textAlign: 'left',
    width: '100%',
  },
};

const baseEvent = { event_type: '', voice_broadcast_id: '', video_broadcast_id: '' };
const BundledEvents = (props) => {
  const { classes, clinicId } = props;
  const [name, setName] = React.useState('');
  const { data: voiceBroadcastTypes } = useSWR(`/clinics/${clinicId}/voice_broadcasts`, apiFetch);
  const { data: videoBroadcastTypes } = useSWR(`/clinics/${clinicId}/video_broadcasts`, apiFetch);
  const [events, setEvents] = React.useState([{ ...baseEvent }]);

  React.useEffect(() => { props.browseProTypes(); });
  React.useEffect(() => {
    if (!props.bundledEvent) return;
    setName(get(props.bundledEvent, 'name'));
    setEvents(get(props.bundledEvent, 'bundle_spec.events'));
  }, [props.bundledEvent]);

  const allEventsValid = events.reduce((valid, event) => {
    if (!valid || event.delay === '' || !event.event_type) {
      return false;
    }

    if (event.event_type === 'VOICE_BROADCAST' && !event.voice_broadcast_id) {
      return false;
    }

    if (event.event_type === 'VIDEO_BROADCAST' && !event.video_broadcast_id) {
      return false;
    }

    return true;
  }, true);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleAddEvent = () => {
    setEvents([...events, { ...baseEvent }]);
  };
  const handleRemoveEvent = (position) => {
    const filteredEvents = filter(events, (item, idx) => {
      if (idx === position) {
        return false;
      }
      return true;
    });
    setEvents(filteredEvents);
  };

  const handleEditEvent = (position, update) => {
    const newEvents = [...events];
    newEvents[position] = update;
    setEvents(newEvents);
  };

  const handleSave = async () => {
    await props.onSave(name, events);
  };

  let totalDays = 0;

  const displayedEvents = events.map((e, idx) => {
    totalDays += Number(e.delay);
    const totalDelay = totalDays;
    return (
      <BundledEventItem
        bundledEvent={e}
        key={idx}
        usedEvents={events}
        onEditEvent={handleEditEvent}
        onRemoveEvent={handleRemoveEvent}
        bundledEventPosition={idx}
        proTypes={props.proTypes}
        totalDelay={totalDelay}
        videoBroadcastTypes={videoBroadcastTypes || []}
        voiceBroadcastTypes={voiceBroadcastTypes || []}
        bundleType={props.bundleType}
      />
    );
  });

  return (
    <div className={classes.root}>
      <div>
        <h1 className={classes.headerTitle}>{props.headerText}</h1>
        <HiddenContent hidden={props.hideInstructions}>
          <p>{props.instructions}</p>
        </HiddenContent>
        <TextField
          value={name}
          onChange={handleChange}
          label="Bundle Name"
        />
        <ol>
          {displayedEvents}
        </ol>
        <div style={{ display: 'flex' }}>
          <Button color="primary" onClick={handleAddEvent} variant="contained">Add an Event</Button>
          <Button color="primary" disabled={!allEventsValid || !name} onClick={handleSave} variant="contained">{props.saveText}</Button>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    appData: { proTypes },
    clinic: { clinicId },
  } = state;

  return {
    clinicId,
    proTypes: map(proTypes, p => p.pro_type).sort(),
  };
}

BundledEvents.defaultProps = {
  bundledEvent: null,
  headerText: 'Create a Bundle',
  saveText: 'Save Bundle',
  proTypes: [],
  instructions: 'Bundled Events will trigger as a single notification to the patient. Patients are then guided through each of the events in the order listed below. Each event type may only be used a single time. To send a bundled event to a patient, first add it to a care pathway.',
  bundleType: '',
};
BundledEvents.propTypes = {
  browseProTypes: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clinicId: PropTypes.number.isRequired,
  bundledEvent: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  proTypes: PropTypes.array,
  saveText: PropTypes.string,
  instructions: PropTypes.string,
  bundleType: PropTypes.string,
};

export default connect(mapStateToProps, { browseProTypes })(withStyles(styles)(BundledEvents));
