export default (pro) => {
  const { data } = pro.pro_data;

  const {
    name,
    birthday,
    strepThroat,
    earInfections,
    croup,
    febrileSeizure,
    headachesMigraine,
    eczema,
    napsDuringDay,
    troubleWithSleep,
    melatonin,
    lTheanine,
    magnesium,
    noSleepSupplements,
    supplements,
    autismSpectrumDisorder,
    adhd,
    ocd,
    sensoryProcessingDisorder,
    bipolarDisorder,
    generalizedAnxietyDisorder,
    depressions,
    oppositionalDefianceDisorder,
    disruptiveMoodDysregulationDisorder,
  } = data;

  const s = [];

  s.push(`Child's name: ${name}`);
  s.push(`Birthday: ${birthday}`);

  s.push(`Strep Throat: ${strepThroat ? 'Y' : 'N'}`);
  s.push(`Ear Infections: ${earInfections ? 'Y' : 'N'}`);
  s.push(`Croup: ${croup ? 'Y' : 'N'}`);
  s.push(`Febrile Seizure: ${febrileSeizure ? 'Y' : 'N'}`);
  s.push(`Headaches/Migraine: ${headachesMigraine ? 'Y' : 'N'}`);
  s.push(`Eczema: ${eczema ? 'Y' : 'N'}`);
  s.push(`Naps during the day: ${napsDuringDay}`);
  s.push(`Trouble With Sleep: ${troubleWithSleep}`);
  s.push(`Melatonin: ${melatonin ? 'Y' : 'N'}`);
  s.push(`L-theanine: ${lTheanine ? 'Y' : 'N'}`);
  s.push(`Magnesium: ${magnesium ? 'Y' : 'N'}`);
  s.push(`No Sleep Supplements: ${noSleepSupplements ? 'Y' : 'N'}`);

  if (supplements.length) {
    const supplementsStr = supplements.join('; ');
    s.push(`Supplements: ${supplementsStr}`);
  } else {
    s.push('Supplements: N');
  }

  s.push(`Autism Spectrum Disorder: ${autismSpectrumDisorder ? 'Y' : 'N'}`);
  s.push(`ADHD: ${adhd ? 'Y' : 'N'}`);
  s.push(`Obsessive Compulsive Disorder: ${ocd ? 'Y' : 'N'}`);
  s.push(`Sensory Processing Disorder: ${sensoryProcessingDisorder ? 'Y' : 'N'}`);
  s.push(`Bipolar Disorder: ${bipolarDisorder ? 'Y' : 'N'}`);
  s.push(`Generalized Anxiety Disorder: ${generalizedAnxietyDisorder ? 'Y' : 'N'}`);
  s.push(`Depressions: ${depressions ? 'Y' : 'N'}`);
  s.push(`Oppositional Defiance Disorder: ${oppositionalDefianceDisorder ? 'Y' : 'N'}`);
  s.push(`Disruptuve Mood Dysregulation Disorder: ${disruptiveMoodDysregulationDisorder ? 'Y' : 'N'}`);

  pro.score.score = s.join(' | ');
  return pro;
};
