import SocketIO from 'socket.io-client';
import EventEmitter from 'events';

import config from './config';
import store from './store';
import { apiFetch } from './lib/fetch';
import { addAppointment } from './state/appointments';
import { createNotification } from './actions/notifications';
import { killQR } from './state/devices';

let socket;
const otherEvents = new EventEmitter();
export const chatEvents = new EventEmitter();

export const VITAL_CORE_MSGS_IN = {
  initializingCoreModule: 'initializingCoreModule',
  measuring: 'measuring',
  measured: 'measured',
  results: 'results',
  error: 'error',
};

export const VITAL_CORE_MSGS_OUT = {
  enableVitalCore: 'enableVitalCore',
};

async function createConnection() {
  if (!socket) {
    const { token } = await apiFetch('/users/socket/me');
    socket = SocketIO(`${config.API_URL}`, {
      transports: ['websocket'],
    });
    socket.otherEvents = otherEvents;
    socket._token = token;
    window.socket = socket; // devtools
  }
}

export function closeSocket() {
  socket.close();
  socket = null;
}

export async function subscribeToClinic(clinicId) {
  await createConnection();
  if (clinicId) {
    setTimeout(() => {
      socket.emit('subscribe_clinic', { clinic_id: clinicId, token: socket._token});
    }, 250);

    socket.on('reconnect', async () => {
      const { token } = await apiFetch('/users/socket/me');
      socket._token = token;
      socket.emit('subscribe_clinic', { clinic_id: clinicId, token });
    });

    socket.on(`clinic_${clinicId}`, (data) => {
      if (data.type === 'NEW_APPOINTMENT') {
        apiFetch(`/clinics/${clinicId}/appointments/${data.id}`)
          .then((appointment) => {
            const notification = {
              appointmentId: appointment.id,
              newStatus: appointment.status,
            };
            store.dispatch(addAppointment(appointment));
            store.dispatch(createNotification(notification));
          });
      } else if (data.type === 'DEVICE_LOGIN') {
        store.dispatch(killQR(data.id));
      } else if (data.type === 'RTC_ANSWER') {
        otherEvents.emit('RTC_ANSWER', data);
      } else if (data.type === 'RTC_ANSWER_RESTART') {
        otherEvents.emit('RTC_ANSWER_RESTART', data);
      } else if (data.type === 'ANSWERER_ICECANDIDATE') {
        otherEvents.emit('ANSWERER_ICECANDIDATE', data);
      } else if (data.type === 'CALL_REQUEST_ANSWER') {
        otherEvents.emit('CALL_REQUEST_ANSWER', data);
      } else if (data.type === 'PATIENT_HEARTBEAT') {
        otherEvents.emit('PATIENT_HEARTBEAT', data);
      } else if (data.type === 'PATIENT_HEARTBEAT_STOP') {
        otherEvents.emit('PATIENT_HEARTBEAT_STOP', data);
      } else if (data.type === 'PATIENT-SMS') {
        otherEvents.emit('PATIENT-SMS', data);
      } else if (data.type === 'PATIENT-IS-TYPING') {
        otherEvents.emit('PATIENT-IS-TYPING', data);
      } else if (data.type === 'PATIENT-IS-NOT-TYPING') {
        otherEvents.emit('PATIENT-IS-NOT-TYPING', data);
      } else if (data.type === 'CLINIC-IS-TYPING') {
        otherEvents.emit('CLINIC-IS-TYPING', data);
      } else if (data.type === 'CLINIC-IS-NOT-TYPING') {
        otherEvents.emit('CLINIC-IS-NOT-TYPING', data);
      } else if (data.type === 'CLINIC-SMS') {
        otherEvents.emit('CLINIC-SMS', data);
      } else if (data.type === 'PATIENT_CAMERA_MODE') {
        otherEvents.emit('PATIENT_CAMERA_MODE', data);
      } else if (VITAL_CORE_MSGS_IN[data.type]) {
        console.log('data.type:', data.type);
        otherEvents.emit(VITAL_CORE_MSGS_IN[data.type], data);
      }
    });
  }
  return socket;
}

export async function messagePatient(patient_id, message) {
  await createConnection();
  if (patient_id && message) {
    setTimeout(() => {
      socket.emit('message_patient', { patient_id, message });
    }, 500);
  }
}

export async function messageAll(patient_id, clinic_id, message) {
  await createConnection();
  if (patient_id && clinic_id && message) {
    setTimeout(() => {
      socket.emit('message_all', { patient_id, clinic_id, message });
    }, 500);
  }
}

export function getSocket() {
  // createConnection();
  return socket;
}

export default socket;
