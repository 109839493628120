import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { filter, forEach, reduce, take } from 'lodash';
import moment from 'moment';

import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from './pro-select';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import { ProLineChart } from 'common-ui';

import PatientViewContentSection from './patient-view-content-section';
import ProSubmissionTab from './pro-submissions-tab';
import ProCarePathwayTab from './pro-care-pathway-tab';
import SurgeryTab from './surgery-tab';

import { colors } from '../lib/styles';

const styles = {
  chartSelect: {
    marginBottom: '20px',
  },
  chartTitle: {
    color: colors.primaryColor,
    fontWeight: 'bold',
    margin: '20px 0',
    fontSize: '1.2rem',
    height: '42px',
  },
  content: {
    display: 'flex',
    flex: 1,
  },
  tab: {
    color: 'rgb(60, 76, 101)',
    fontSize: '0.8rem',
  },
  tabs: {
    color: colors.primaryColor,
    margin: '0 10px',
  },
  indicator: {
    backgroundColor: colors.primaryColor,
  },
};

const pLabels = ['Baseline', 'Post-Op', 'Day 30', 'Day 60', 'Day 90', 'Day 180', 'Day 360', 'Day 720'];
function buildProLabels() {
  return pLabels.map((l, i)=>{
    return <div style={{ width: '60px' }} key={i}>{l}</div>
  })
}

function getPros(type, data) {
  return filter(data, { pro_type: type });
}

function buildProScores(data) {
  const score = reduce(data, (allScores, val) => {
    if(val.pro_score_data && val.completion_date) {
      allScores.push(val.pro_score_data.detail.score/10);
    }
    return allScores;
  }, []);
  return take(score, 8);
}

function buildVAS(data) {
  const score = [];
  forEach(data, (val) => {
    if(val.pro_data && val.completion_date){
      score.push(val.pro_data.data[0]);
    }
  });
  return score;
}

function buildResponseLabels(data) {
  const labels = reduce(data, (allData, item) => {
    if(item.completion_date) {
      allData.push(moment(item.completion_date).format('MM-DD-YY'));
    }
    return allData;
  }, [])

  return take(labels, 8);
}

class ProSubmissionsOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestedPro: [],
      proChart: 'NDI',
      tab: 0,
      campaign: 0,
      campaign_start: '',
      open: false,
      error: '',
      hidden: true,
    };

    this.handleProChange = this.handleChange.bind(this, 'requestedPro');
    this.handleCampaignChange = this.handleChange.bind(this, 'campaign');
    this.handleChartChange = this.handleChange.bind(this, 'proChart');
  }
  handleTabChange = (event, tab) => {
    this.setState({ tab });
  }
  handleDateChange = (date) => {
    this.setState({
      campaign_start: date
    })
  }
  handleChange(field, evt) {
    this.setState({
      [field]: evt.target.value
    });
  }
  handleSendCampaignRequest = async () => {
      const { contactStatus } = this.props;
      const { campaign_start, campaign } = this.state;
    if(campaign === 0 || campaign_start === null ) {
      return this.setState({ hidden: false, error: (campaign === 0 ? 'Please select a care pathway' : 'Please indicate a start date') })
    }
    if(!campaign_start) {
      return this.setState({ hidden: false, error: 'Please select a start date' })
    }
    if(moment(campaign_start, 'MM-DD-YYYY').isBefore(moment().startOf('date'))) {
      return this.setState({ hidden: false, error: 'Please select a date in the future' })
    }
    if (contactStatus === 'blacklisted') {
      return this.setState({ hidden: false, error: 'Patient has opted out of SMS notifications' })
    }
    if (contactStatus === 'landline') {
      return this.setState({ hidden: false, error: 'The number you are attempting to text is a landline' })
    }
    if (this.props.contactStatus === 'no_phone') {
      return this.setState({ hidden: false, error: 'There is no phone number attached to this patient' })
    }

    const currentTime = new Date();
    const campaignStart = moment(campaign_start, 'MM-DD-YYYY').add(currentTime.getHours(), 'hours').add(currentTime.getMinutes(), 'minutes').toISOString(true);
    this.props.onCreateCampaign(this.props.userId, { campaign_id: campaign, campaign_start: campaignStart });

    this.setState({ campaign: 0, campaign_start: '', error: '', hidden: true });
  }
  handleSendProRequest = () => {
    if (this.props.contactStatus === 'blacklisted') {
      return this.setState({ hidden: false, error: 'Patient has opted out of SMS notifications' })
    }
    if (this.props.contactStatus === 'landline') {
      return this.setState({ hidden: false, error: 'The number you are attempting to text is a landline' })
    }
    if (this.props.contactStatus === 'no_phone') {
      return this.setState({ hidden: false, error: 'There is no phone number attached to this patient' })
    }

    this.state.requestedPro.forEach(async requestedPro => {
      try {
        await this.props.onCreateProSubmission(this.props.userId, requestedPro, {
          cell_phone: this.props.patient.cell_phone,
          first_name: this.props.patient.first_name,
        });
      } catch (e) {
        this.setState({ hidden: false, error: e.message });
      }
    });
    this.setState({ requestedPro: [], cell_phone: this.props.patient.cell_phone });
  }
  handleDeleteProCampaign = (eventSeriesId) => {
    return this.props.deleteProCampaign(this.props.userId, eventSeriesId);
  }
  render() {
    const { props, state, handleTabChange, handleChartChange, handleCampaignChange, handleDateChange, handleSendCampaignRequest } = this;
    const { classes, clinicId, features } = props;
    const { proChart, tab, campaign, hidden, error, campaign_start } = state;
    const pros = getPros(proChart, this.props.proSubmissions)
    const proData = buildProScores(pros);
    const vas = buildVAS(pros);
    const responseLabels = buildResponseLabels(pros);
    const proLabels = buildProLabels();
    return (
      <Fragment>
        {this.props.children}
        <PatientViewContentSection>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              classes={{ root: classes.tabs, indicator: classes.indicator }}
              >
              <Tab classes={{ root: classes.tab }} label="PRO Submissions" />
              <Tab classes={{ root: classes.tab }} label="PRO Care Pathways" />
              <Tab classes={{ root: classes.tab }} label="Treatments" />
            </Tabs>
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
            <div style={{ minWidth: '100%' }}>
              {
                tab === 0 &&
                <ProSubmissionTab
                  handleBrowseProSubmissions={this.props.handleBrowseProSubmissions}
                  handleProChange={this.handleProChange}
                  handleSendProRequest={this.handleSendProRequest}
                  proSubmissions={props.proSubmissions}
                  proTypes={props.proTypes}
                  requestedPro={state.requestedPro}
                  appointment={props.appointment}
                  hidden={hidden}
                  error={error}
                  features={features}
                />
              }
              { tab === 1 &&
                <ProCarePathwayTab 
                  campaign={campaign}
                  clinicId={clinicId}
                  hidden={hidden}
                  handleCampaignChange={handleCampaignChange}
                  handleDateChange={handleDateChange}
                  proCampaigns={props.proCampaigns}
                  error={error}
                  campaign_start={campaign_start}
                  handleSendCampaignRequest={handleSendCampaignRequest}
                  deleteProCampaign={this.handleDeleteProCampaign}
                  editProCampaign={this.props.editProCampaign}
                  browseProCampaigns={props.browseProCampaigns}
                  userId={props.userId}
                />
              }
              { tab === 2 &&
                <SurgeryTab
                  onAddSurgeryHx={props.onAddSurgeryHx}
                  onEditSurgeryHx={props.onEditSurgeryHx}
                  surgeryTypes={props.surgeryTypes}
                  surgeries={props.surgeries}
                  userId={props.userId}
                />
                    }
                </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center' }}>
              <div style={styles.chartTitle}>PRO Responses</div>
              <FormControl style={styles.chartSelect}>
                <Select
                  value={proChart}
                  onChange={handleChartChange}
                  input={<Input disableUnderline={true} />}
                >
                  <MenuItem value='NDI' selected>NDI</MenuItem>
                  <MenuItem value='ODI' selected>ODI</MenuItem>
                  <MenuItem value='HIT-6' selected>HIT-6</MenuItem>
                </Select>
              </FormControl>
              <ProLineChart
                proType={this.state.proChart}
                pro={proData}
                responseLabels={responseLabels}
                proLabels={proLabels}
                surgeries={props.surgeries}
                vas={vas}
              />
            </div>
          </div>
        </div>
        </PatientViewContentSection>
      </Fragment>
    );
  }
}

ProSubmissionsOverview.defaultProps = {
  patient: {},
};

ProSubmissionsOverview.propTypes = {
  blacklistedPhone: PropTypes.bool.isRequired,
  deleteProCampaign: PropTypes.func.isRequired,
  handleBrowseProSubmissions: PropTypes.func.isRequired,
  landlinePhone: PropTypes.bool.isRequired,
  onAddSurgeryHx: PropTypes.func.isRequired,
  onCreateCampaign: PropTypes.func.isRequired,
  onCreateProSubmission: PropTypes.func.isRequired,
  onEditSurgeryHx: PropTypes.func.isRequired,
  patient: PropTypes.object,
  proCampaigns: PropTypes.array.isRequired,
  proSubmissions: PropTypes.array.isRequired,
  proTypes: PropTypes.array.isRequired,
  router: PropTypes.object.isRequired,
  surgeries: PropTypes.array.isRequired,
  surgeryTypes: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
};

export default withStyles(styles)(ProSubmissionsOverview)
