import { isString, get, forEach, map, omit, isEmpty, reject, reduce, uniqueId } from 'lodash';

export function stateFormat(symptoms, originalEmrSymptoms) {
  const emrSymp = reduce(reject(originalEmrSymptoms, (symp) => symp.Name === ''), (all, item) => {
    const id = uniqueId('emr-symp'); // No unique id is returned by redox for an entry
    all[id] = {
      emrId: id,
      snomed_code: get(item, 'Code') || null,
      name: get(item, 'Name') || '',
      explanation: get(item, 'TargetSite.Name') || '',
    };
  }, {});
  const mindset = {};
  if (symptoms[0]) {
    forEach(symptoms, (item, key) => {
      mindset[`mindset${key}`] = {
        symptom_hx_id: item.id,
        symptoms_id: item.symptoms_id,
        name: item.name,
        explanation: item.explanation,
      };
    });
  }
  return {
    combined: mindset,
    mindset,
    emrSymp,
  };
}

export function mergeFormat(combined) {
  if (isEmpty(combined) || isString(combined)) {
    return {};
  }
  return {
    symptoms: map(combined, (item) => {
      return omit(item, ['id', 'checked']);
    }),
  };
}
