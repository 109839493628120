import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { capitalize, forEach, pick, includes } from 'lodash';
import { Button, FormControl, MenuItem, Input, Checkbox } from '@material-ui/core';
import Joi from '@hapi/joi';
import * as csv from 'csvtojson';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { KeypadDate } from 'common-ui';

import Select from './pro-select';
import HiddenContent from './hidden-content';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';
import { phone as phoneValidation } from '../lib/validators';

const BULK_INVITE_EVENT_TYPE = {
  campaign: 'campaign',
  pros: 'pros',
};

const styles = {
  btnStyle: {
    backgroundColor: 'rgba(32, 123, 204)',
    border: `1px solid ${colors.primaryColor}`,
    color: colors.white,
    width: '175px',
  },
  errorMsg: {
    color: colors.errorRed
  },
  errorMsgVerticalMargin: {
    color: colors.errorRed,
    marginBottom: 10,
    marginTop: 10,
  },
  fileTypeErrorMsg: {
    color: colors.errorRed,
    textAlign: 'center',
  },
  inviteText: {
    cursor: 'pointer',
    textAlign: 'center',
  },
  populatedPatientList: {
    minHeight: '100px',
    maxHeight: '300px',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    overflowY: 'scroll',
  },
  patientList: {
    maxHeight: '300px',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
  },
  subHeader: {
    textAlign: 'center',
    width: '540px',
  },
  forms: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonRow:{
    display: 'flex',
    justifyContent: 'end',
  },
  buttonsRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const defaultError = 'An error occured submitting this list, please review your import file carefully to ensure and try again. If this problem persists contact support.'

const PreImportRow = (props) => (
  <li style={props.isValid ? {} : { color: colors.errorRed }}>
    {props.first_name} {props.last_name}, {props.displayed_dob || formatDate(props.birth_date)}, {props.email}, {props.cell_phone}, {props.primary_language}
  </li>
);

const PostImportRow = (props) => {
  const style = includes(props.invite_status, 'FAILED') || props.patientsNotSentPros.has(props.id)
    ? { color: colors.errorRed }
    : {}

  return (
    <li style={style}>
      {props.first_name} {props.last_name}: Status: {capitalize(props.invite_status.split('_').join(' '))}
    </li>
  );
}

const validationSchema = Joi.object({
  first_name: Joi.string().required().messages({
    'string.empty': 'first_name required',
  }),
  last_name: Joi.string().required().messages({
    'string.empty': 'last_name required',
  }),
  //Joi doesn't allow IANA tld validation on client side
  email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).required().messages({
    'string.empty': 'email required',
    'string.email': 'invalid email',
  }),
  cell_phone: Joi.string().regex(phoneValidation).required().messages({
    'string.empty': 'cell_phone required',
    'string.pattern.base': 'invalid cell_phone',
  }),
  birth_date: Joi.date().iso().required().messages({
    'string.empty': 'birth_date required',
    'date.base': 'invalid birth_date',
  }),
  primary_language: Joi.string().allow(''),
});

// \uFFFD is the character code for �, which is used in place of characters that the browser does not recognize
const hasUnknownCharacter = (characters) => {
  if (typeof characters === 'string') {
    return /\uFFFD/.test(characters);
  }
  
  return characters.some((str) => {
    return /\uFFFD/.test(str);
  });
};

const formatDate = (date) => {
  return moment(date).format('MM/DD/YYYY');
};

const initialState = {
    allValid: false,
    imports: [],
    submissionError: '',
    submissionResults: [],
    requestedPro: [],
    proSuccess: false,
    campaign: 'DEFAULT',
    campaign_start: '',
    hidden: true,
    error: '',
    fileHasUnknownCharacter: false,
    fileReadingError: '',
    bulkInviteEventType: null,
    bulkInviteProData: null,
    bulkInviteProWarning: null,
    patientsNotSentPros: new Set(),
};

class InviteDialogBulkPatients extends Component {
  state = {
    ...initialState
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({ ...initialState });
    }
  }
  onChangeFile = (e) => {
    e.persist();

    this.setState({
      fileHasUnknownCharacter: false,
      fileReadingError: '',
    });

    const files = e.target.files;
    // Forces onChange to get called again if same file is resubmitted
    if(files && files.length) {
      this.setState({ ...initialState, allValid: true, error: '' }, () => {
        forEach(files, f => {
          if (f.type !== 'text/csv') {
            this.setState({ error: 'Only .csv files can be uploaded', allValid: false });
          } else {
            const reader = new FileReader();
            reader.onload = (readerEvent) => this.handleAddFileContentsToState(readerEvent.target.result);
            reader.readAsText(f);
          }
        });
      });
    }
  }
  handleAddFileContentsToState = (fileContents) => {
    const fileHasUnknownCharacter = hasUnknownCharacter(fileContents);
    csv({
      noheader: false,
      headers: ['first_name', 'last_name', 'dob', 'email', 'cell_phone', 'primary_language'],
    }).fromString(fileContents)
      .subscribe(({ first_name, last_name, dob, cell_phone, email, primary_language }) => {
        return new Promise((resolve) => {
          if (first_name === 'first_name') return resolve();
          const cleanedRecord = {
            first_name: first_name.trim(),
            last_name: last_name.trim(),
            birth_date: moment(dob).toISOString(),
            displayed_dob: dob,
            email: email.trim().toLowerCase(),
            cell_phone: cell_phone.trim(),
            isValid: true,
            primary_language: primary_language ? primary_language.toLowerCase() : 'en',
          };

          const errors = [];

          if (fileHasUnknownCharacter) {
            const unknownCharacterFound = hasUnknownCharacter([first_name, last_name, email]);
            if (unknownCharacterFound) {
              errors.push('unknown character');
            }
          }

          const validationResult = validationSchema.validate(cleanedRecord, { abortEarly: false, allowUnknown: true });
          if (validationResult.error) {
            validationResult.error.details.forEach((error) => {
              errors.push(error.message);
            });
          }

          if (errors.length) {
            cleanedRecord.isValid = false;
            cleanedRecord.error = errors.join('; ');
            this.setState({ allValid: false });
          }

          this.setState((prevState) => {
            const newState = { imports: prevState.imports.concat(cleanedRecord)}
            
            if (errors.length) {
              newState.fileReadingError = 'Errors found in csv upload. If unknown characters were found, please ensure the file is UTF-8 encoded.';
            }

            return newState;
          }, resolve);
        })
      });
  }
  handleSubmitInvitations = () => {
    const { clinicId } = this.props;
    const options = {
      method: 'POST',
      body: {
        patients: this.state.imports.map((i) => pick(i, ['first_name', 'last_name', 'birth_date', 'cell_phone', 'email', 'primary_language'])),
      },
    }

    apiFetch(`/clinics/${clinicId}/invite_patients`, options)
      .then((response) => {
        if (response.status === 'failed') {
          this.setState({
            allValid: false,
            fileReadingError: response.message,
            imports: response.data,
          });
          return;
        }
        this.setState({ submissionResults: response.data, submissionError: '' });
      })
      .catch((err) => {
        let message = includes(err.message, 'Validation') ? err.message : defaultError;
        // todo: abstract into function for specific validation errors when further error message language is developed
        message = includes(message, 'primary_language') ? 'Only English or Spanish may be used as a primary language. Please replace with EN/ES as the primary language and import the .csv file again.' : message;
        this.setState({ submissionError: message })
      });
  }
  handleClearPreviousFile = (e) => {
    e.target.value = null
  }
  handleCampaignChange = (evt) => {
    this.setState({
      campaign: evt.target.value
    });
  }
  handleProChange = (evt) => {
    this.setState({
      requestedPro: evt.target.value
    });
  }
  handleDateChange = (date) => {
    this.setState({
      campaign_start: date
    })
  }
  handleSendProRequest = () => {
    const { requestedPro, submissionResults } = this.state;
    const proSubmissionRequests = [];

    requestedPro.forEach(requestedPro => {
      submissionResults.forEach((patient) => {
        proSubmissionRequests.push(
          this.props.createProSubmission(patient.id, requestedPro, {
            cell_phone: patient.cell_phone,
            first_name: patient.first_name,
          })
        );
      });
    });

    Promise.allSettled(proSubmissionRequests)
      .then((proRequests) => {
        const bulkInviteProData = [];
        const usersSentPros = new Set();
        let bulkInviteProWarning = '';

        proRequests.forEach((proRequest) => {
          if (proRequest.status === 'fulfilled') {
            bulkInviteProData.push(proRequest.value);
            usersSentPros.add(proRequest.value.user_id);
          } else {
            bulkInviteProWarning = 'Some users were not sent PROs';
          }
        });

        let patientsNotSentPros = submissionResults
          .filter(patient => !usersSentPros.has(patient.id))
          .map(patient => patient.id);
        patientsNotSentPros = new Set(patientsNotSentPros);

        this.setState({
          proSuccess: true,
          bulkInviteEventType: BULK_INVITE_EVENT_TYPE.pros,
          bulkInviteProData,
          bulkInviteProWarning,
          patientsNotSentPros,
        });
      });
  }
  handleSendCampaignRequest = () => {
    const { campaign_start, campaign } = this.state;
    if(campaign === 'DEFAULT' || campaign_start === null ) {
      return this.setState({ hidden: false, error: (campaign === 'DEFAULT' ? 'Please select a care pathway' : 'Please indicate a start date') })
    }
    if(!campaign_start) {
      return this.setState({ hidden: false, error: 'Please select a start date' })
    }
    if(moment(campaign_start, 'MM-DD-YYYY').isBefore(moment().startOf('date'))) {
      return this.setState({ hidden: false, error: 'Please select a date in the future' })
    }
    const currentTime = new Date();
    const campaignStart = moment(campaign_start, 'MM-DD-YYYY').add(currentTime.getHours(), 'hours').add(currentTime.getMinutes(), 'minutes').toISOString(true);
    
    const proCampaignPromises = this.state.submissionResults.map((patient) => {
      return this.props.createProCampaign(
        patient.id,
        { campaign_id: campaign, campaign_start: campaignStart },
        '?specify_pros_in_bundles=true'
      );
    });

    Promise.all(proCampaignPromises)
      .then((proCampaignData) => {
        this.setState({
          campaign: 'DEFAULT',
          campaign_start: '',
          error: '',
          hidden: true,
          proSuccess: true,
          bulkInviteEventType: BULK_INVITE_EVENT_TYPE.campaign,
          bulkInviteProData: proCampaignData,
        });
      });
  }

  handleDownloadReportWithPros = (exportList, bulkInviteProData) => {
    const { providerEmail } = this.props;
    const { requestedPro } = this.state;
    const header = 'First Name, Last Name, DOB, Email, Phone, Primary Language, Invite State, PRO Type, Requested Date, Provider Email, PRO Failed to Send';
    const rows = [header];
    const proData = {};

    bulkInviteProData.forEach((pro) => {
      if (!proData[pro.user_id]) {
        proData[pro.user_id] = [];
      }

      proData[pro.user_id].push({ proType: pro.context.pro_type, createdAt: pro.context.created_at });
    });
    
    exportList.forEach((patient) => {
      const {
        first_name,
        last_name,
        cell_phone,
        email,
        birth_date,
        invite_status,
        primary_language,
      } = patient;
      const patientProData = proData[patient.id];

      if (patientProData) {
        patientProData.forEach((pro) => {
          const { proType, createdAt } = pro;
          rows.push(`${first_name},${last_name},${birth_date},${email},${cell_phone},${primary_language},${invite_status},${proType},${createdAt},${providerEmail},no`);
        });
      } else {
        requestedPro.forEach((proType) => {
          rows.push(`${first_name},${last_name},${birth_date},${email},${cell_phone},${primary_language},${invite_status},${proType},,${providerEmail},yes`)
        });
      }
    });

    const csvReport = rows.join('\n');
    this.handleStartDownload(csvReport);
  }

  handleDownloadReportWithCampaigns = (exportList, bulkInviteProData) => {
    const { providerEmail } = this.props;
    const header = 'First Name, Last Name, DOB, Email, Phone, Primary Language, Invite State, PRO Type, Care Pathway, Requested Date, Provider Email';
    const rows = [header];
    const proData = {};

    bulkInviteProData.forEach((campaign) => {
      if (campaign) {
        campaign.forEach((campaignEvent => {
          if (!proData[campaignEvent.target_user_id]) {
            proData[campaignEvent.target_user_id] = [];
          }

          if (campaignEvent.event_types) {
            campaignEvent.event_types.forEach((pro) => {
              proData[campaignEvent.target_user_id].push({
                proType: pro,
                campaignType: campaignEvent.detail.campaign_type,
                createdAt: campaignEvent.created_at,
              })
            })
          } else {
            proData[campaignEvent.target_user_id].push({
              proType: campaignEvent.type,
              campaignType: campaignEvent.detail.campaign_type,
              createdAt: campaignEvent.created_at,
            });
          }
        }));
      }
    });

    exportList.forEach((patient) => {
      const {
        first_name,
        last_name,
        cell_phone,
        email,
        birth_date,
        invite_status,
        primary_language,
      } = patient;
      const patientProData = proData[patient.id];

      if (patientProData) {
        patientProData.forEach((pro) => {
          const { proType, campaignType, createdAt } = pro;
          rows.push(`${first_name},${last_name},${birth_date},${email},${cell_phone},${primary_language},${invite_status},${proType},${campaignType},${createdAt},${providerEmail}`);
        });
      }
    });

    const csvReport = rows.join('\n');
    this.handleStartDownload(csvReport);
  }

  handleDownloadReport = (exportList) => {
    const { bulkInviteEventType, bulkInviteProData } = this.state;
    const header = 'First Name, Last Name, DOB, Email, Phone, Primary Language, Invite State';
    const rows = [header];

    if (bulkInviteEventType === BULK_INVITE_EVENT_TYPE.pros) {
      this.handleDownloadReportWithPros(exportList, bulkInviteProData);
      return;
    } else if (bulkInviteEventType === BULK_INVITE_EVENT_TYPE.campaign) {
      this.handleDownloadReportWithCampaigns(exportList, bulkInviteProData);
      return;
    }

    exportList.forEach((patient) => {
      const {
        first_name,
        last_name,
        cell_phone,
        email,
        birth_date,
        invite_status,
        primary_language,
      } = patient;
      rows.push(`${first_name},${last_name},${birth_date},${email},${cell_phone},${primary_language},${invite_status}`);
    });

    const csvReport = rows.join('\n');
    this.handleStartDownload(csvReport);
  };

  handleDownloadImports = (exportList) => {
    const header = 'First Name, Last Name, DOB, Email, Phone, Primary Language, Error(s)';
    const rows = [header];

    exportList.forEach((patient) => {
      const {
        first_name,
        last_name,
        cell_phone,
        email,
        birth_date,
        primary_language,
        error,
      } = patient;
      rows.push(`${first_name},${last_name},${formatDate(birth_date)},${email},${cell_phone},${primary_language},${error || ''}`);
    })

    const csvReport = rows.join('\n');
    this.handleStartDownload(csvReport)
  }

  handleStartDownload = (csvData) => {
    const dateStr = Date.now();

    const downloadTag = document.createElement('a');
    downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvData));
    downloadTag.setAttribute('download', `bulk_upload_${dateStr}.csv`);
    downloadTag.style.display = 'none';

    document.body.appendChild(downloadTag);

    downloadTag.click();

    document.body.removeChild(downloadTag);
  }

  render() {
    const { classes, proTypes } = this.props;
    const {
      fileReadingError,
      submissionResults,
      requestedPro,
      bulkInviteProWarning,
      patientsNotSentPros,
    } = this.state;
    let patientRows;
    let exportList;
    if (submissionResults.length > 0) {
      exportList = submissionResults;
      patientRows = submissionResults.map((i, idx) => <PostImportRow key={idx} {...i} number={idx + 1} patientsNotSentPros={patientsNotSentPros} />);
    } else {
      patientRows = this.state.imports.map((i, idx) => <PreImportRow key={idx} {...i} number={idx + 1} />);
    }

    const bulkPatients = (
      <Fragment>
        <ol>
          <li>
            <a href="/informed-bulk-import-template.csv" download>
              Click Here to download the bulk import csv template
              </a>
          </li>
          <li>Add your patients to the list and upload the file.</li>
          <li>Review the information is correct in the box below. Import can only proceed if all records are valid. If Patients are colored red they have information that is not formatted correctly or invalid.  Please verify the information and upload the file again.</li>
          <li>Once the patients have been reviewed click the "Bulk Invite Patients" button below.</li>
          <li>Patients with Informed accounts will be notified of your access request. Accounts will be created for non-account holders.</li>
        </ol>
        <Button
          variant="contained"
          color="secondary"
          component="label"
        >
          Upload File
          <input
            onClick={this.handleClearPreviousFile}
            onChange={this.onChangeFile}
            type="file"
            style={{ display: 'none' }}
          />
        </Button>
        <HiddenContent hidden={!this.state.hidden}>
          <div className={classes.fileTypeErrorMsg}>{this.state.error}</div>
        </HiddenContent>
        <h2 className={classes.subHeader}>Patients to Insert</h2>
        <ol className={patientRows.length ? classes.populatedPatientList : classes.patientList}>
          {patientRows}
        </ol>
        <Button
          disabled={!this.state.allValid || Boolean(submissionResults.length)}
          color="primary"
          variant="contained"
          onClick={this.handleSubmitInvitations}
        >
          Bulk Invite Patients
        </Button>
        <HiddenContent hidden={!this.state.submissionError}>
          <span className={classes.errorMsg}>{this.state.submissionError}</span>
        </HiddenContent>
        <HiddenContent hidden={!fileReadingError}>
          <span className={classes.errorMsg}>{fileReadingError}</span>
        </HiddenContent>
        {fileReadingError ? (
          <Button
            onClick={() => this.handleDownloadImports(this.state.imports)}
            variant="contained"
          >
            Export csv with errors
          </Button>
        ) : null}
        <Button className={classes.inviteText} onClick={this.props.onClickSingle}>Click Here to invite a single patient.</Button>
        <div className={classes.buttonRow}>
          <Button
            onClick={this.props.toggleUserInviteDialog}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </div>
      </Fragment >
    );
    const bulkPRO = (
      <Fragment>
        <h2 className={classes.subHeader}>Send PROs to New Patients</h2>
          { this.state.proSuccess ? (
              <>
                <div style={{ color: colors.successGreen }}>PROs submitted!</div>
                {bulkInviteProWarning ? (
                  <div className={classes.errorMsgVerticalMargin}>{bulkInviteProWarning}</div>
                ) : null}
              </>
            ) : (
              <div className={classes.forms}>
                <div>
                  <HiddenContent hidden={!this.state.hidden}>
                    <span className={classes.errorMsg}>{this.state.error}</span>
                  </HiddenContent>
                  <FormControl>
                    <Select
                      multiple
                      displayEmpty
                      value={requestedPro}
                      onChange={this.handleProChange}
                      input={<Input disableUnderline={true} />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (<em>Select PROs</em>);
                        }
                        return selected.join(', ');
                      }}
                    >
                      <MenuItem value={[]} disabled><em>Select PROs</em></MenuItem>
                      {proTypes.map(pro => (
                        <MenuItem
                          key={pro}
                          value={pro}
                          selected
                        >
                          <Checkbox
                            checked={requestedPro.includes(pro)}
                          />
                          {pro}
                        </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    className={classes.btn}
                    onClick={this.handleSendProRequest}
                  >
                    Send PRO Request(s)
                  </Button>
                </div>
                <span>OR</span>
                <div>
                  <FormControl
                    style={{
                      display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between',
                    }}
                  >
                    <Select
                      value={this.state.campaign}
                      onChange={this.handleCampaignChange}
                      input={<Input disableUnderline={true} />}
                    >
                      <MenuItem value="DEFAULT" disabled><em>Select Care Pathway</em></MenuItem>
                      {(this.props.sortedCampaignTypes || []).map(c => <MenuItem key={c.id || c} value={c.id || c} selected>{c.name || c}</MenuItem>)}
                    </Select>
                    <KeypadDate
                      disableCalendarPast={true}
                      includeCalendar={true}
                      label="Start Date"
                      onChange={this.handleDateChange}
                      value={this.state.campaign_start}
                    />
                    <Button
                      variant="contained"
                      className={classes.btn}
                      onClick={this.handleSendCampaignRequest}
                    >
                      Start Care Pathway
                    </Button>
                  </FormControl>
                </div>
              </div>
            )
          }
        <ol className={patientRows.length ? classes.populatedPatientList : classes.patientList}>
          {patientRows}
          <div className={classes.buttonsRow}>
            <Button
              onClick={() => this.handleDownloadReport(exportList)}
              variant="contained"
            >
              Export
            </Button>
            <Button
              onClick={this.props.toggleUserInviteDialog}
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </div>
        </ol>
      </Fragment >
    );
    return submissionResults.length > 0 ? bulkPRO : bulkPatients;
  }
}

const mapStateToProps = (state) => {
  const { user } = state;

  return { providerEmail: user.email };
}

export default connect(mapStateToProps)(withStyles(styles)(InviteDialogBulkPatients));
