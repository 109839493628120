import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { capitalize, isNil, noop, omitBy, orderBy } from 'lodash';
import memoize from 'memoize-one';
import moment from 'moment';
import { Calendar } from 'common-ui';
import { Box, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { apiFetch } from '../lib/fetch';
import findItemByDate from '../lib/find-item-by-date';
import PainBody from './pain-body';
import PatientViewContentSection from './patient-view-content-section';
import bodyPainLineBuilder from '../lib/body-pain-line-builder';
import PainLineChart from './pain-line-chart';

import { colors } from '../lib/styles';

const baseStyles = {
  bottomIcon: {},
  bottomHalf: {
    display: 'flex',
    flexDirection: 'row',
    minheight: 500,
  },
  painLevel: {
    color: colors.primaryColor,
    fontSize: '14px',
    paddingTop: '10px',
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  surgeryData: {
    display: 'flex',
    justifyContent: 'left',
    marginTop: 20,
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  surgeryDates: {
    flexShrink: 0,
    fontWeight: 600,
    marginRight: '10px',
    width: '93px',
  }
};

const getDominatePainPointsAndSide = (selectedLocations) => {
  // Whichever side has the most pain points is the side shown on the page. If multiple sides
  // share the value of most pain points, show the side with the pain point that has the
  // hightest score.

  const painPointsPerLocation = {
    FRONT: {
      painEntries: [],
      maxIntensity: 0,
      numOfPoints: 0,
    },
    RIGHT: {
      painEntries: [],
      maxIntensity: 0,
      numOfPoints: 0,
    },
    BACK: {
      painEntries: [],
      maxIntensity: 0,
      numOfPoints: 0,
    },
    LEFT: {
      painEntries: [],
      maxIntensity: 0,
      numOfPoints: 0,
    },
  };

  // First organize pain points by sides
  selectedLocations.forEach((locationsEntry) => {
    const bodyLocation = locationsEntry[0].body_location;
    painPointsPerLocation[bodyLocation].painEntries.push(locationsEntry);
    locationsEntry.forEach((location) => {
      painPointsPerLocation[bodyLocation].numOfPoints += 1;
      if (location.intensity > painPointsPerLocation[location.body_location].maxIntensity) {
        painPointsPerLocation[location.body_location].maxIntensity = location.intensity;
      }
    });
  });

  // Find the max number of pain points per sides
  let maxPainPoints = 0;
  Object.values(painPointsPerLocation).forEach((sideData) => {
    if (sideData.numOfPoints > maxPainPoints) maxPainPoints = sideData.numOfPoints;
  });

  // Determine if there are multiple sides that share the max number of pain points
  const sidesWithMaxPainPoints = [];
  Object.entries(painPointsPerLocation).forEach(([side, sideData]) => {
    if (sideData.numOfPoints === maxPainPoints) {
      sidesWithMaxPainPoints.push(side);
    }
  });

  // Of the sides that share the max number of pain points, determine side that has the max intensity
  let maxIntensity = 0;
  let sideWithMaxIntensity = 'FRONT';
  sidesWithMaxPainPoints.forEach((side) => {
    if (painPointsPerLocation[side].maxIntensity > maxIntensity) {
      maxIntensity = painPointsPerLocation[side].maxIntensity; // eslint-disable-line prefer-destructuring
      sideWithMaxIntensity = side;
    }
  });

  return {
    side: sideWithMaxIntensity,
    painPointEntries: painPointsPerLocation[sideWithMaxIntensity].painEntries,
  };
};

const useStylesFactorsMenuEntry = makeStyles({
  factors: {
    fontSize: 14,
    marginLeft: 5,
  },
  location: {
    fontWeight: 'bold',
  },
  root: {
    '&:not(:last-child)': {
      marginBottom: 5,
    },
  },
})

const FactorsMenuEntry = ({ location, factors }) => {
  const [menuOpen, setMenuOpen] = useState(true);
  const classes = useStylesFactorsMenuEntry();

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" marginTop={-3/8}>
        <span className={classes.location}>{location}</span>
        {menuOpen ? (
          <ExpandMore onClick={() => setMenuOpen(prev => !prev)} />
        ) : (
          <ExpandLess onClick={() => setMenuOpen(prev => !prev)} />
        )}
      </Box>
      {menuOpen ? (
        <div className={classes.factors}>{factors}</div>
      ) : null}
    </div>
  );
}

const FactorsMenu = ({ factors }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      {Object.entries(factors).map(([location, factorsStr]) => (
        <FactorsMenuEntry location={location} factors={factorsStr} />
      ))}
    </Box>
  )
}

class BodyOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarData: [],
      fetching: false,
      selectedDate: null,
      currPainEntries: [],
    };
  }

  handleDateChange = (currentMonth, currentYear) => {
    this.setState({ currentMonth, currentYear, fetching: true });

    const startDate = new Date(currentYear, currentMonth, 1);
    let endDate = new Date(currentYear, currentMonth + 1, 0);
    endDate = new Date(endDate.getTime() + 86399999); // 1 second before end of day

    const queryOptions = {
      query: omitBy({
        pain_start_date: startDate.toISOString(),
        pain_end_date: endDate.toISOString(),
        populate: true,
        orderBy: 'pain_end_date',
      }, isNil)
    };
    apiFetch(`/users/${this.props.userId}/pain_hx`, queryOptions)
      .then((data) => {
        let currPainEntries = [];
        let selectedDate = '';
        if (data.length) {
          const recentPainEndDate = data[0].pain_end_date;
          const recentPainEndDateStr = moment(recentPainEndDate).format('YYYY-MM-DD');
          selectedDate = recentPainEndDate.split('T')[0];
          currPainEntries = data.filter((painEntry) => {
            return moment(painEntry.pain_end_date).format('YYYY-MM-DD') === recentPainEndDateStr;
          });
        }

        this.setState(prevState => ({
          calendarData: data,
          fetching: false,
          currPainEntries,
          selectedDate: this.state.selectedDate ? this.state.selectedDate : selectedDate,
        }));
      })
      .catch((e) => {
        this.setState({ error: 'An Error occured fetching pain data', fetching: false });
      });
  }
  handleSelectDate = (selectedDate) => {
    this.setState({ selectedDate })
  }
  memoBuildLineChartData = memoize((data, currentMonth, currentYear) => {
    return bodyPainLineBuilder(data, currentMonth, currentYear);
  })
  render() {
    const { painLocations } = this.props;
    const { currPainEntries, selectedDate } = this.state;

    let selectedPainEntries = currPainEntries

    if (selectedDate) {
      const pains = findItemByDate(selectedDate, this.state.calendarData, 'pain_start_date', 'pain_end_date');
      selectedPainEntries = orderBy(pains, 'intensity', 'desc');
    }

    const aggravatingFactors = {};
    const alleviatingFactors = {};
    const limitingFactors = {};
    const locationsAndIntensities = [];
    const selectedLocations = [];

    selectedPainEntries.forEach((painEntry) => {
      if (painEntry.pain_locations && painEntry.pain_locations.length) {
        const painEntryLocations = painEntry.pain_locations.map((location) => {
          return {
            ...painLocations[location],
            intensity: painEntry.intensity,
            aggravatingFactors: painEntry.aggravating_factors,
            alleviatingFactors: painEntry.alleviating_factors,
            limitations: painEntry.limitations,
          };
        });
        selectedLocations.push(painEntryLocations);

        const locationStr = painEntry.pain_locations.length === 2
          ? `${painLocations[painEntry.pain_locations[0]].name} -> ${painLocations[painEntry.pain_locations[1]].name}`
          : painLocations[painEntry.pain_locations[0]].name;

        locationsAndIntensities.push(`${locationStr}: ${painEntry.intensity}`);

        if (painEntry.aggravating_factors.length) {
          aggravatingFactors[locationStr] = painEntry.aggravating_factors.map(capitalize).join(', ');
        }
        if (painEntry.alleviating_factors.length) {
          alleviatingFactors[locationStr] = painEntry.alleviating_factors.map(capitalize).join(', ');
        }
        if (painEntry.limitations.length) {
          limitingFactors[locationStr] = painEntry.limitations.map(capitalize).join(', ');
        }
      }
    });

    const { side, painPointEntries } = getDominatePainPointsAndSide(selectedLocations);

    const lineChartData = this.memoBuildLineChartData(this.state.calendarData, this.state.currentMonth, this.state.currentYear);

    const curDate = new Date(this.state.currentYear, this.state.currentMonth);
    const startTime = new Date(curDate.getFullYear(), curDate.getMonth(), 1);
    const endTime = new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0);
    const firstDateOfCurMonth = startTime.getDate();
    const lastDateOfCurMonth = endTime.getDate();
    const axisTopDomain = [firstDateOfCurMonth, lastDateOfCurMonth];

    return (
      <Fragment>
        {this.props.children}
        <PatientViewContentSection> 
          <div style={baseStyles.bottomHalf}>
            <div style={{ flex: 1, margin: 10, maxWidth: 300 }}>
              <Calendar
                clickableDates={true}
                data={this.state.calendarData}
                onDateChange={this.handleDateChange}
                onClickDay={this.handleSelectDate}
                selectedDate={this.state.selectedDate}
                selectedDateColor={colors.primaryColor}
                title="Pain - History Calendar"
              />
            </div>
            <div style={{ flex: 1, flexWrap: 'wrap', maxWidth: 300 }}>
              <PainBody
                selectedLocations={selectedLocations}
                hideLabels={false}
                scale={0.8}
                side={side}
                painPointEntries={painPointEntries}
              />
              <div style={baseStyles.painLevel}>
                {locationsAndIntensities.map((locationAndIntensity) => (
                  <div>{locationAndIntensity}</div>
                ))}
              </div>
            </div>
            <div style={{ flex: 1, flexDirection: 'column', maxWidth: 550 }}>
              <div style={{ flex: 1, margin: '10px 40px 0' }}>
                <div style={baseStyles.surgeryData}>
                  <div style={baseStyles.surgeryDates}>Limitations:</div>
                  <div>
                    {Object.keys(limitingFactors).length ? (
                      <FactorsMenu factors={limitingFactors} />
                    ) : (
                      <div>None</div>
                    )}
                  </div>
                </div>
                <div style={baseStyles.surgeryData}>
                  <div style={baseStyles.surgeryDates}>Aggravating Factors:</div>
                  {Object.keys(aggravatingFactors).length ? (
                    <FactorsMenu factors={aggravatingFactors} />
                  ) : (
                    <div>None</div>
                  )}
                </div>
                <div style={baseStyles.surgeryData}>
                  <div style={baseStyles.surgeryDates}>Alleviating Factors:</div>
                  {Object.keys(alleviatingFactors).length ? (
                    <FactorsMenu factors={alleviatingFactors} />
                  ) : (
                    <div>None</div>
                  )}
                </div>
              </div>
              <div style={{ margin: '50px 25px 0 0', minWidth: 450, maxWidth: 550, flex: 1 }}>
                <PainLineChart
                  data={lineChartData}
                  color="red"
                  chartHeight={175}
                  chartWidth={425}
                  svgMargins={{
                    top: 0,
                    right: 100,
                    bottom: 25,
                    left: 40,
                  }}
                  axisTopDomain={axisTopDomain}
                  lineChartMargins={{
                    bottom: 20,
                    top: 5,
                  }}
                  defaultMinYScale={10}
                  defaultMaxYScale={25}
                />
              </div>
            </div>
          </div>
        </PatientViewContentSection>
      </Fragment>
    );
  }
}

BodyOverview.defaultProps = {
  forms: {},
  handleOpenAllForms: noop,
  handleOpenForm: noop,
  mostRecentMigraine: {},
  migraines: [],
  painLevel: 'None Reported',
  painLocations: {},
  updateAppointment: noop,
};

BodyOverview.propTypes = {
  appointment: PropTypes.object,
  forms: PropTypes.object,
  router: PropTypes.object,
  handleOpenAllForms: PropTypes.func,
  handleOpenForm: PropTypes.func,
  painLocations: PropTypes.object,
};


export default BodyOverview;
