import { makeActionCreator } from 'cooldux';

import { LOGOUT } from '../actions/user';

export const closeModalAC = makeActionCreator('closeModal');
export const openModalAC = makeActionCreator('openModal');

export function openModal(type) {
  return function dispatcher(dispatch) {
    dispatch(openModalAC(type));
  };
}

export function closeModal() {
  return function dispatcher(dispatch) {
    dispatch(closeModalAC());
  };
}

const initialState = {
  checkInModalOpen: false,
  deviceModalOpen: false,
};

function modal(state = initialState, action) {
  switch (action.type) {
    case openModalAC.type:
      return { ...initialState, [action.payload]: true };
    case closeModalAC.type:
      return { ...initialState, checkInModalOpen: false, deviceModalOpen: false };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}

export default modal;
