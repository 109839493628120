import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { find, forEach, pickBy, keys } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import PatientListItem from './patient-list-item'; 
import { apiFetch } from '../lib/fetch';
import formatAppointment from '../lib/format-appointment';
import DashboardFilter from './dashboard-filter';
import DashboardTableList from './dashboard-table-list';
import PendingTableRow from './pending-patient-table-row';

const baseStyles = {
  container: {
    minWidth: '768px',
    paddingLeft: '20px',
  },
  displayFilter: {
    textAlign: 'left',
    margin: '0 0 1rem 1rem',
  },
  filterDisplay: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  headerText: {
    fontWeight: 100,
    textAlign: 'left',
  },
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  subHeaderText: {
    display: 'flex',
    margin: '5px 5px 5px 0',
    fontSize: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

class PatientList extends Component {
  constructor(props) {
    super(props);
    const rows = localStorage.getItem('patient_table_rows');
    const sort = localStorage.getItem('patient_table_sort');

    this.state = {
      displayNumber: rows ? Number(rows) : 10,
      sortBy: sort || 'last_name',
      sortDir: 'desc',
      tick: 0,
      page: props.tableSettings.page || 0,
      patients: [],
      flags: null,
      filters: {
        radicularPain: { checked: false, displayName: 'Radicular Pain', enabled: true },
        headachePain: { checked: false, displayName: 'Headache Pain', enabled: true },
        BP_LOW: { checked: false, displayName: 'Low Blood Pressure', enabled: false },
        BP_HIGH: { checked: false, displayName: 'High Blood Pressure', enabled: false },
        SPO2_HIGH: { checked: false, displayName: 'High SPO2', enabled: false },
        SPO2_LOW: { checked: false, displayName: 'Low SPO2', enabled: false },
        HR_HIGH: { checked: false, displayName: 'High Heart Rate', enabled: false },
        HR_LOW: { checked: false, displayName: 'Low Heart Rate', enabled: false },
        BR_HIGH: { checked: false, displayName: 'High Breathing Rate', enabled: false },
        BR_LOW: { checked: false, displayName: 'Low Breathing Rate', enabled: false },
        TEMPERATURE_HIGH: { checked: false, displayName: 'High Temperature', enabled: false },
        TEMPERATURE_LOW: { checked: false, displayName: 'Low Temperature', enabled: false },
        BMI_HIGH: { checked: false, displayName: 'High BMI', enabled: false },
        BMI_LOW: { checked: false, displayName: 'Low BMI', enabled: false },
        SWALLOWING: { checked: false, displayName: 'Swallowing', enabled: false },
        DIARRHEA: { checked: false, displayName: 'Diarrhea', enabled: false },
        WEIGHT_GAIN: { checked: false, displayName: 'Weight Gain', enabled: false },
        WEIGHT_LOSS: { checked: false, displayName: 'Weight Loss', enabled: false },
        WEAKNESS: { checked: false, displayName: 'Weakness', enabled: false },
        FALLS: { checked: false, displayName: 'Falls', enabled: false },
        INCONTINENCE_BLADDER: { checked: false, displayName: 'Bladder Incontinence', enabled: false },
        INCONTINENCE_BOWEL: { checked: false, displayName: 'Bowel Incontinence', enabled: false },
        VOMITING: { checked: false, displayName: 'Vomiting', enabled: false },
        NAUSEA: { checked: false, displayName: 'Nausea', enabled: false },
      },
      sortUnread: false,
    };

    this.updateSort = this.updateSort.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.sortByName = this.updateSort.bind(this, 'last_name');
    this.sortByComplete = this.updateSort.bind(this, 'complete');
    this.sortByFlag = this.updateSort.bind(this, 'flag_id');
    this.sortByRecentPain = this.updateSort.bind(this, 'recent_pain');
    this.sortByProvider = this.updateSort.bind(this, 'provider_name');
    this.sortByUnviewedPro = this.updateSort.bind(this, 'unviewed_pro_id');
    this.updateProviderId = this.updateProviderId.bind(this);
  }
  componentDidMount() {
    this.fetchPatients();
    apiFetch(`/clinics/${this.props.clinicId}/triggers`).then((res) => {
      const filters = this.state.filters;
      forEach(res, (value) => {
        filters[value.trigger_type].enabled = true;
      });
      this.setState({ filters });
    });
    apiFetch(`/clinics/${this.props.clinicId}/flags`).then((flags) => {
      this.setState({ flags });
    });
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  updateSort(col) {
    if (col !== 'unviewed_pro_id') {
      this.setState({ sortUnread: false });
    }
    const { sortBy, sortDir } = this.state;
    window.localStorage.setItem('patient_table_sort', col);
    if (sortBy === col) {
      return this.setState({
        sortDir: sortDir === 'asc' ? 'desc' : 'asc',
      }, this.fetchPatients);
    }
    return this.setState({
      sortDir: 'desc',
      sortBy: col,
    }, this.fetchPatients);
  }
  handleMenuItemClick = (event, index) => {
    this.setState({ displayNumber: index }, this.fetchPatients);
  };
  fetchPatients = () => {
    const { clinicId } = this.props;
    const filters = {};
    forEach(this.state.filters, (val, key) => {
      if(this.state.filters[key].checked) {
        filters[key] = this.state.filters[key].checked;
      }
    });
    const options = {
      query: {
        limit: this.state.displayNumber,
        offset: (this.state.page) * this.state.displayNumber,
        orderBy: this.state.sortBy,
        sortOrder: this.state.sortDir,
        radicularPain: this.state.filters.radicularPain.checked,
        headachePain: this.state.filters.headachePain.checked,
        ...filters,
      }
    };

    this.setState({
      loading: true,
      error: null,
    });
    apiFetch(`/clinics/${clinicId}/patients`, options)
      .then((res) => {
        this.setState({
          loading: false,
          error: null,
          patients: res.data.map(formatAppointment),
          totalPatients: res.totalRecords,
          limit: this.state.displayNumber > 50 ? this.state.displayNumber : !this.state.displayNumber,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: err,
        });
      });
  }
  forwardActive = () => {
    const { page, displayNumber } = this.state;
    const max = Math.ceil(this.state.totalPatients / displayNumber);

    if (page >= max) return false;
    return true;
  }
  backActive = () => {
    const { page } = this.state;

    if (page > 0) return true;
    return false;
  }
  handleChangePage = (event, page) => {
    this.props.updateTableSetting({ type: 'patients', value: { page } });
    this.setState({ page }, this.fetchPatients);
  };

  handleChangeRowsPerPage = (event) => {
    window.localStorage.setItem('patient_table_rows', event.target.value)
    this.setState({ displayNumber: event.target.value }, this.fetchPatients);
  };

  handleCheckFilter = (filter) => {
    const state = this.state;
    state.filters[filter].checked = !state.filters[filter].checked
    this.setState({ ...state }, this.fetchPatients);
  }

  handleToggleUnread = () => {
    const { sortUnread } = this.state;
    if(!sortUnread) {
      this.sortByUnviewedPro();
    }
    this.setState({ sortUnread: !this.state.sortUnread });
  }

  updateProviderId = (userId, providerId) => {
    const { patients } = this.state

    const updatedPatients = patients.map((patient) => {
      if (patient.user_id === userId) {
        return { ...patient, provider_id: providerId };
      } else {
        return patient
      }
    });

    this.setState({ patients: updatedPatients });
  }

  render() {
    let {
      displayNumber,
      page,
      patients,
    } = this.state;
    const {
      addRecent, id, isFetching,
      title, onUpdateAppointment,
    } = this.props;
    const numPatients = patients.length;

    const displayFilters = [];
    forEach(this.state.filters, (value, key) => {
      if (value.checked) {
        displayFilters.push((
          <div style={baseStyles.displayFilter}>
            {(displayFilters.length < 1) ? 'Selected Filter: ' : ' '} <Chip
              label={value.displayName}
              onDelete={() => {
                const filters = this.state.filters;
                filters[key].checked = false;
                this.setState({ filters }, this.fetchPatients);
              }}
              color="primary"
            />
          </div>
        ));
      }
    });

    if (isFetching) {
      return (
        <div style={baseStyles.container}>
          <h1 style={baseStyles.headerTitle}>{title}</h1>
          <p>Loading patients..</p>
          <CircularProgress size={100} thickness={7} />
        </div>
      );
    }
    
    if (!numPatients) {
      const filters = pickBy(this.state.filters, (filter) => filter.checked);
      return (
        <Fragment>
          <div style={baseStyles.filterDisplay}>
            {displayFilters}
          </div>
          <DashboardFilter
            onCheckFilter={this.handleCheckFilter}
            filtersChecked={this.state.filters}
          />
          <div style={baseStyles.container}>
            <div style={baseStyles.headerText}>
              <h1 style={baseStyles.headerTitle}>{title}</h1>
              <p style={baseStyles.subHeaderText}>{keys(filters).length > 0 ? "No patients match selected filters." : "No patients checked in at this time."}</p>
            </div>
          </div>
        </Fragment>
      );
    }
    // Sorts and truncates the patient list
    let patientRows = [];

    if(this.state.loading) {
      let numberOfRows = 0;
      const remainingRows = this.state.totalPatients - (page * displayNumber);
      if(remainingRows < displayNumber) {
        numberOfRows = remainingRows;
      } else {
        numberOfRows = displayNumber;
      }
      for(let i = 0; i < numberOfRows; i++) {
        patientRows.push(<PendingTableRow />);
      }
    } else {
      patientRows = patients.map((patient) => {
        let appointment = find(this.props.appointments, { user_id: patient.user_id });
        appointment = { ...appointment };
        delete appointment.provider_id;
        patient = { ...patient, ...appointment };
        // find and pull flag at index of patient flag id in flags
        const flag = this.state.flags ? find(this.state.flags, { id: patient.flag_id }) : {};
        return (
          <PatientListItem
            addRecent={addRecent}
            checkedIn={!!patient.status}
            unviewedPro={Boolean(patient.unviewed_pro_id)}
            currentTime={this.state.currenttime}
            key={`patient-${patient.user_id}`}
            router={this.props.router}
            patient={patient}
            providers={this.props.providers}
            flag={flag}
            signatureDocuments={this.props.signatureDocuments}
            onUpdateAppointment={onUpdateAppointment}
            onUpdatePatient={this.props.onUpdatePatient}
            onCreateAppointment={this.props.createAppointment}
            showAppointmentButtons={this.props.showAppointmentButtons}
            onFetchPatients={this.fetchPatients}
            updateProviderId={this.updateProviderId}
          />
        );
      });
    }

    return (
      <Fragment>
        <div style={baseStyles.filterDisplay}>
          {displayFilters}
        </div>
        <DashboardFilter
          onCheckFilter={this.handleCheckFilter}
          filtersChecked={this.state.filters}
          toggleUnread={this.handleToggleUnread}
          sortUnread={this.state.sortUnread}
        />
        <DashboardTableList
          id={id}
          title={title}
          appointmentRows={patientRows}
          count={numPatients}
          displayNumber={displayNumber}
          page={page}
          onPageChange={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          showAppointmentButtons={this.props.showAppointmentButtons}
          sortByUnviewedPro={this.sortByUnviewedPro}
          sortByName={this.sortByName}
          sortByRecentPain={this.sortByRecentPain}
          sortByFlag={this.sortByFlag}
          sortByProvider={this.sortByProvider}
          totalRecords={this.state.totalPatients}
          user={this.props.user}
        />
      </Fragment>
    );
  }
}

PatientList.defaultProps = {
  patients: [],
  id: null,
  isFetching: false,
  providers: [],
  waitText: '',
};

PatientList.propTypes = {
  addRecent: PropTypes.func.isRequired,
  createAppointment: PropTypes.func.isRequired,
  id: PropTypes.string,
  isFetching: PropTypes.bool,
  patients: PropTypes.arrayOf(PropTypes.object),
  providers: PropTypes.array,
  title: PropTypes.string,
  onUpdateAppointment: PropTypes.func,
  router: PropTypes.object.isRequired,
  showAppointmentButtons: PropTypes.bool.isRequired,
};

export default withStyles(baseStyles)(PatientList);
