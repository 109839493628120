import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import {
  Schedule,
  HeadsetMic,
  Event,
  BarChart,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../lib/styles';

import FormLink from './form-link';
import RecentlyChecked from './recently-checked';
import HiddenContent from './hidden-content';

import { forms } from '../lib/app-features';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 100,
    minWidth: '200px',
  },
  containerIcon: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 100,
  },
  containerBottom: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: '5px',
    verticalAlign: 'middle',
  },
  link: {
    textDecoration: 'none',
  },
  list: {
    listStyle: 'none',
    padding: 0,
    textAlign: 'left',
  },
  formListItem: {
    display: 'flex',
    color: colors.highlightDarker,
    padding: '5px',
    fontSize: '.85em',
    cursor: 'pointer',
  },
  listItem: {
    color: colors.highlightDarker,
    padding: '10px',
  },
  title: {
    color: colors.highlightMedium,
    fontWeight: 100,
    fontSize: '18px',
    paddingLeft: '10px',
    textAlign: 'left',
  },
  logo: {
    cursor: 'pointer',
    height: '40px',
    margin: '0 auto 10px',
  },
  top: {
    flex: '1 1 auto',
  },
  rightsSection: {
    fontWeight: 500,
    fontSize: '.7rem',
    padding: '0 0 1rem 0.5rem',
    whiteSpace: 'nowrap',
  },
  section: {
    marginTop: '1rem',
  },
  supportIcon: {
    verticalAlign: 'middle',
  },
  supportListItem: {
    color: colors.charcoal,
    padding: '10px 0',
    fontSize: '1rem',
    verticalAlign: 'middle',
  },
  terms: {
    color: 'inherit',
    fontWeight: 600,
    paddingBottom: '10px',
    textDecoration: 'inherit',
  },
  userGuideListItem: {
    color: colors.charcoal,
    padding: '10px 0',
    fontSize: '1rem',
    verticalAlign: 'middle',
  },
  userGuideIcon: {
    verticalAlign: 'middle',
  },
  wordAlign: {
    marginLeft: '5px',
  },
};

function generateForms(features) {
  const links = [];
  features.forEach((feature) => {
    const form = forms[feature];
    if (form) {
      links.push(<FormLink key={feature} {...form} />);
    }
  });

  return links;
}

function generateSubmissions(features) {
  const links = [];
  features.forEach((feature) => {
    const form = forms[feature];
    if (form) {
      links.push(<FormLink key={`${feature}-submission`} {...form} icon={form.submissionIcon} isSubmission={true} />);
    }
  });

  return links;
}

function Sidebar(props) {
  const { appFeatures } = props;
  const formLinks = generateForms(appFeatures);
  const submissionLinks = generateSubmissions(appFeatures);
  const includeVideoCalls = appFeatures.includes('VIDEO_CALLS');
  let mainContainerStyle = props.classes.container;
  if (props.iconSidebar) {
    mainContainerStyle = props.classes.containerIcon;
  }
  return (
    <div className={mainContainerStyle}>
      <section className={props.classes.top}>
        <ul className={props.classes.list}>
          {includeVideoCalls && (<Link
            alt="Virtual Exam Rooms"
            title="Virtual Exam Rooms"
            to="/app/virtual-appointments"
            className={props.classes.link}
          >
            <li className={props.classes.listItem}>
              <HeadsetMic className={props.classes.icon} />
              <HiddenContent hidden={props.iconSidebar}>Virtual Exam Rooms</HiddenContent>
            </li>
          </Link>)
          }
          {includeVideoCalls && (<Link
            alt="Virtual Schedule"
            title="Virtual Schedule"
            to="/app/virtual-schedule"
            className={props.classes.link}
          >
            <li className={props.classes.listItem}>
              <Event className={props.classes.icon} />
              <HiddenContent hidden={props.iconSidebar}>Virtual Schedule</HiddenContent>
            </li>
          </Link>)
          }
          <Link
            alt="Patient List"
            title="Patient List"
            to="/app/patientlisting"
            className={props.classes.link}
          >
            <li className={props.classes.listItem}>
              <img src="/img/avatar.svg" alt="Patients" height="24px" width="24px" className={props.classes.icon} />
              <HiddenContent hidden={props.iconSidebar}>Patients</HiddenContent>
            </li>
          </Link>
          <Link
            alt="Pending Patients"
            title="Pending Patients"
            to="/app/pending-patients"
            className={props.classes.link}
          >
            <li className={props.classes.listItem}>
              <Schedule className={props.classes.icon} />
              <HiddenContent hidden={props.iconSidebar}>
                Pending Invites
              </HiddenContent>
            </li>
          </Link>
          <Link
            alt="Dashboard"
            title="Dashboard"
            to="/app/dashboard"
            className={props.classes.link}
          >
            <li className={props.classes.listItem}>
              <BarChart className={props.classes.icon} />
              <HiddenContent hidden={props.iconSidebar}>
                Dashboard
              </HiddenContent>
            </li>
          </Link>
        </ul>
        <HiddenContent hidden={props.iconSidebar}>
          <hr />
          <div>
            <h2 style={styles.title}>Recent Patients</h2>
            <RecentlyChecked
              recent={props.recent}
              router={props.router}
            />
          </div>
          {formLinks.length ? (
            <div>
              <h2 style={styles.title}>Forms</h2>
              <ul style={styles.list}>
                {formLinks}
              </ul>
            </div>
        ) : null}
          {submissionLinks.length ? (
            <div>
              <h2 style={styles.title}>Recent Submissions</h2>
              <ul style={styles.list}>
                {submissionLinks}
              </ul>
            </div>
        ) : null}
        </HiddenContent>
      </section>
    </div >
  );
}

Sidebar.propTypes = {
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(Sidebar);
