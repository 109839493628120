import React, { Component } from 'react';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import InviteDialogBulkPatients from './invite-dialog-bulk-patients';
import InviteDialogSinglePatient from './invite-dialog-single-patient';

import { invitePatient } from '../state/appointments';
import { email, phone } from '../lib/validators';

const styles = {
  headerTitle: {
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    margin: '10px auto',
    padding: '30px',
  },
};
const initialState = {
  errorMsg: '',
  dialog: false,
  inviteMessage: '',
  singlePatientView: true,
};

class InviteDialog extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({ ...initialState });
    }
  }
  handleInvitePatient = (patient) => {
    this.setState({ inviteMessage: '', errorMsg: '' });

    if (!phone.test(patient.cell_phone)) {
      return this.setState({ errorMsg: 'Please enter a valid phone number and try again.' });
    }
    if (!email.test(patient.email)) {
      return this.setState({ errorMsg: 'Please enter a valid email address and try again.' });
    }
    if (!moment(patient.birth_date).isValid()) {
      return this.setState({ errorMsg: 'Please enter a valid date of birth and try again.' });
    }
    return this.props.onInvitePatient(pick(patient, ['email', 'first_name', 'last_name', 'birth_date', 'cell_phone', 'primary_language']))
      .then((res = {}) => {
        if(res.inviteSent) {
          return this.setState({
            inviteMessage: 'Patient action required! Successfully requested access to patient\'s account. However the patient will need to confirm before you are able to view their record.',
          });
        }
        if(res.exists) {
          return this.setState({
            errorMsg: 'This email is already associated with another patient account.'
          });
        }
        this.setState({ ...initialState }, () => this.props.toggleUserInviteDialog());
      })
      .catch((e) => {
        this.setState({
          errorMsg: 'Error Sending Invite. Please try again later'
        });
      });
  }
  handleInvitePatients = (patients) => {
    return this.props.onBulkInvitePatients(patients);
  }
  render() {
    const {
      classes,
      open,
    } = this.props;

    let headerTitle;
    let content;
    let onBackdropClick = this.props.toggleUserInviteDialog;

    if(this.state.inviteMessage) {
      headerTitle = 'Invite a Patient';
      content = this.state.inviteMessage;
    } else if(this.state.singlePatientView){
      headerTitle = 'Add a new patient'
      content = (
        <InviteDialogSinglePatient
          onInvitePatient={this.handleInvitePatient}
          onClickBulk={() => this.setState({ singlePatientView: false})}
          open={open}
          errorMsg={this.state.errorMsg}
        />
      )
    } else {
      onBackdropClick = null;
      headerTitle = 'Bulk Upload Patients';
      content = (
        <InviteDialogBulkPatients
          clinicId={this.props.clinicId}
          onClickSingle={() => this.setState({ singlePatientView: true})}
          open={open}
          errorMsg={this.state.errorMsg}
          proTypes={this.props.proTypes}
          createProSubmission={this.props.createProSubmission}
          createProCampaign={this.props.createProCampaign}
          sortedCampaignTypes={this.props.sortedCampaignTypes}
          toggleUserInviteDialog={this.props.toggleUserInviteDialog}
        />
      )
    }
    return (
      <div>
        <Dialog
          maxWidth={'md'}
          title="Invite a Patient"
          open={open}
          onClose={onBackdropClick}
        >
          <div className={classes.form}>
            <h1 className={classes.headerTitle}>{headerTitle}</h1>
            {content}
          </div>
        </Dialog>
      </div>
    );
  }
}

InviteDialog.propTypes = {
  clinicId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  handleSendInvite: PropTypes.func,
  toggleUserInviteDialog: PropTypes.func,
};

function mapStateToProps(state) {
  const { clinic: { clinicId } } = state;

  return { clinicId };
}
const actionCreators = {
  onInvitePatient: invitePatient,
}
export default connect(mapStateToProps, actionCreators)(withStyles(styles)(InviteDialog));

