import { arrayToObject } from '../utilities';

export default (pro) => {
  let { data } = pro.pro_data;

  data = arrayToObject(data);

  const s = [];

  s.push(`Fever: ${data.hadFever}`);
  if (data.hadFever === 'Y') {
    s.push(`Temperature: ${data.temperature}`);
    s.push(`Chills or Sweats: ${data.shakingChillsOrSweats}`);
    s.push(`New cough or shortness of breath: ${data.coughOrShortnessOfBreath}`);
  }

  pro.score.score = s.join(' | ');
  return pro;
};
