import moment from 'moment';
import { promiseHandler } from 'cooldux';
import { assign, get, keyBy, groupBy, isUndefined, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';
import config from '../config';

const {
  deleteProCampaignsStart,
  deleteProCampaignsEnd,
  deleteProCampaignsError,
  deleteProCampaignsHandler,
} = promiseHandler('deleteProCampaigns', 'application');

const {
  browseProCampaignsStart,
  browseProCampaignsEnd,
  browseProCampaignsError,
  browseProCampaignsHandler,
} = promiseHandler('browseProCampaigns', 'application');

const {
  browseProSubmissionsStart,
  browseProSubmissionsEnd,
  browseProSubmissionsError,
  browseProSubmissionsHandler,
} = promiseHandler('browseProSubmissions', 'application');

const {
  createProStart,
  createProEnd,
  createProError,
  createProHandler,
} = promiseHandler('createPro', 'application');

const {
  createCampaignStart,
  createCampaignEnd,
  createCampaignError,
  createCampaignHandler,
} = promiseHandler('createCampaign', 'application');
const {
  editProSubmissionsStart,
  editProSubmissionsEnd,
  editProSubmissionsError,
  editProSubmissionsHandler,
} = promiseHandler('editProSubmissions', 'application');
const {
  editProCampaignStart,
  editProCampaignEnd,
  editProCampaignError,
  editProCampaignHandler,
} = promiseHandler('editProCampaign', 'application');

function formatProCampaign(events) {
  let series_start = null;
  let type = null;
  let series_start_raw = null;

  if (events[0] && events[0].scheduled_at) {
    series_start_raw = events[0].scheduled_at; // to sort by
    series_start = moment(events[0].scheduled_at).format('MM/DD/YYYY');
    type = events[0].detail.type || events[0].detail.campaign_type;
  }

  return {
    id: events[0].event_series_id,
    events,
    series_start,
    series_start_raw,
    type,
  };
}

function formatTriggers(trigger) {
  if (trigger.trigger_type === 'BP') {
    return `${trigger.trigger_type} - ${trigger.primary_value}/${trigger.secondary_value}`;
  }
  if (trigger.trigger_type === 'DIARRHEA') {
    return `${trigger.trigger_type} - ${trigger.primary_value}, Instances per day: ${trigger.secondary_value}`;
  }
  if (trigger.trigger_type === 'INCONTINENCE_BLADDER') {
    // eslint-disable-next-line max-len
    return `${trigger.trigger_type} - Difficulty controlling bladder: ${trigger.primary_value}, Unable to urinate: ${trigger.secondary_value}`;
  }
  if (trigger.trigger_type === 'INCONTINENCE_BOWEL') {
    // eslint-disable-next-line max-len
    return `${trigger.trigger_type} - Difficulty controlling bowels: ${trigger.primary_value}, Unable to have BM: ${trigger.secondary_value}`;
  }
  if (trigger.trigger_type === 'WEIGHT_LOSS') {
    return `${trigger.trigger_type}: -${trigger.primary_value}lbs`;
  }
  if (trigger.trigger_type === 'WEIGHT_GAIN') {
    return `${trigger.trigger_type}: +${trigger.primary_value}lbs`;
  }
  return `${trigger.trigger_type} - ${trigger.primary_value}`;
}

export function formatProSubmission(pro, pro_submissions = null) {
  const formatDate = (date) => {
    const formattedDate = moment(date).format('MM/DD/YYYY');
    const formattedTime = moment(date).format('hh:mma');
    return `${formattedDate} ${formattedTime}`;
  };

  pro.requested = `Requested - ${formatDate(pro.created_at)}`;
  let lastAction = `Requested - ${formatDate(pro.created_at)}`;
  let lastActionDate = pro.created_at;
  const triggers = pro.triggers && pro.triggers.map(trigger => formatTriggers(trigger)).join(' | ');
  const proCreatedAt = new Date(pro.created_at).getTime();
  const now = Date.now();

  if (pro.completion_date) {
    lastAction = `Completed - ${formatDate(pro.completion_date)}`;
    lastActionDate = pro.completion_date;
  } else if (pro.locked_out_at && pro.lockout_duration) {
    const proLockedOutAt = new Date(pro.locked_out_at).getTime();
    const proLockoutDuration = Number(pro.lockout_duration);
    if (proLockedOutAt + proLockoutDuration > now) {
      lastAction = `Last login attempt - ${formatDate(pro.locked_out_at)}`;
    }
  } else if (now - proCreatedAt > config.notificationLifetime) {
    lastAction = `Expired - ${formatDate(proCreatedAt + config.notificationLifetime)}`;
  }

  let campaignStatus = '';
  let campaignType = '';

  if (pro.scheduled_events_id) {
    campaignStatus = 'ACTIVE';
    campaignType = pro.scheduled_event_campaign_type;

    if (pro.scheduled_event_cancelled_at) {
      lastAction = `Cancelled - ${formatDate(pro.scheduled_event_cancelled_at)}`;
      lastActionDate = pro.scheduled_event_cancelled_at;
      campaignStatus = 'CANCELLED';
    } else if (pro.completion_date && pro_submissions) {
      const remainingCampaignPros = pro_submissions
        .filter(sub => sub.scheduled_events_id === pro.scheduled_events_id && sub.id !== pro.id);

      const completed = remainingCampaignPros.find(evt => evt.completion_date !== null);
      if (completed) {
        campaignStatus = 'COMPLETED';
      }
    }
  }
  pro.score = { score: pro.score, triggers };
  pro.lastAction = lastAction;
  pro.lastActionDate = moment(lastActionDate).valueOf();
  pro.campaign_status = campaignStatus;
  pro.campaign_type = campaignType;
  return pro;
}

export function editProSubmission(update) {
  return function dispatcher(dispatch) {
    const body = omit(update, 'id', 'user_id');
    const options = {
      method: 'PUT',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(`/users/${update.user_id}/pro_submissions/${update.id}/viewed-results`, options)
      .then(s => formatProSubmission(s));

    return editProSubmissionsHandler(promise, dispatch);
  };
}

export function editProCampaign(data) {
  const { event_series_id, initiating_clinic_id, target_user_id } = data;
  return function dispatcher(dispatch) {
    const options = {
      method: 'PUT',
      body: {
        cancelled_at: data.cancelled_at,
      },
    };

    const promise =
      apiFetch(`/clinic/${initiating_clinic_id}/users/${target_user_id}/event_series/${event_series_id}`, options)
        .then(s => formatProSubmission(s));

    return editProCampaignHandler(promise, dispatch);
  };
}

export function deleteProCampaign(userId, event_series_id) {
  return (dispatch, getState) => {
    const clinicId = get(getState(), 'clinic.clinicId');
    // eslint-disable-next-line max-len
    const promise = apiFetch(`/clinic/${clinicId}/users/${userId}/event_series/${event_series_id}`, { method: 'DELETE' })
      .then(() => event_series_id);
    return deleteProCampaignsHandler(promise, dispatch);
  };
}

export function browseProSubmissions(userId) {
  return (dispatch, getState) => {
    const clinicId = get(getState(), 'clinic.clinicId');
    const promise = apiFetch(`/users/${userId}/pro_submissions?clinic_id=${clinicId}`)
      .then(submissions => submissions.map(formatProSubmission));

    return browseProSubmissionsHandler(promise, dispatch);
  };
}

export function browseProCampaigns(userId, query = null) {
  return (dispatch, getState) => {
    const options = {
      query,
    };
    const clinicId = get(getState(), 'clinic.clinicId');
    const promise = apiFetch(`/clinic/${clinicId}/users/${userId}/scheduled_events`, options);

    return browseProCampaignsHandler(promise, dispatch);
  };
}

export function createProCampaign(target_user_id, body, queryString = '') {
  return (dispatch, getState) => {
    const clinicId = get(getState(), 'clinic.clinicId');
    const options = {
      method: 'POST',
      body,
    };
    const promise = apiFetch(`/clinics/${clinicId}/users/${target_user_id}/campaigns${queryString}`, options);

    return createCampaignHandler(promise, dispatch);
  };
}

export function createProSubmission(userId, pro_type) {
  return async (dispatch, getState) => {
    const clinicId = get(getState(), 'clinic.clinicId');
    const options = {
      method: 'POST',
      body: {
        clinic_id: clinicId,
        notification_type: pro_type,
        trigger_text: true,
      },
    };
    try {
      const promise = await apiFetch(`/users/${userId}/notifications`, options);
      return createProHandler(promise, dispatch);
    } catch (error) {
      throw new Error(error.message);
    }
  };
}

const initialState = {
  browseProSubmissionsError: null,
  browseProSubmissionsPending: false,
  editProSubmissionsError: null,
  editProCampaignError: null,
  editProSubmissionsPending: false,
  proCampaigns: {},
  proSubmissions: {},
};

function finishBrowseProCampaign(state, response) {
  const set = groupBy(response, 'event_series_id');

  Object.keys(set).forEach((key) => {
    set[key] = formatProCampaign(set[key]);
  });

  return {
    ...state,
    proCampaigns: {
      ...state.proCampaigns,
      ...set,
    },
    browseProCampaignError: null,
    browseProCampaignPending: false,
  };
}

function finishBrowse(state, response, key, method) {
  const set = keyBy(response, 'id');
  return {
    ...state,
    [key]: {
      ...state[key],
      ...set,
    },
    [`${method}Error`]: null,
    [`${method}Pending`]: false,
  };
}

function finishEdit(state, item) {
  const proSubmissions = { ...state.proSubmissions, [item.id]: item };
  return {
    ...state,
    editProSubmissionsPending: false,
    editProSubmissionsError: null,
    editProCampaignError: null,
    proSubmissions,
  };
}

function finishDelete(state, id, key, method) {
  const set = omit(state[key], id);
  return {
    ...state,
    [key]: {
      ...set,
    },
    [`${method}Error`]: null,
    [`${method}Pending`]: false,
  };
}

function finishCreatePro(state, createdNotfication) {
  // Notification context on add is the fully created object
  const createdPro = formatProSubmission(createdNotfication.context);
  return {
    ...state,
    createProError: false,
    createProPending: false,
    proSubmissions: {
      ...state.proSubmissions,
      [createdPro.id]: createdPro,
    },
  };
}

function finishCreateCampaign(state, createdCampaign) {
  const newState = assign(
    {},
    state.proCampaigns,
    {
      [createdCampaign[0].event_series_id]: formatProCampaign(createdCampaign),
    },
  );
  return {
    ...state,
    createCampaignError: false,
    createCampaignPending: false,
    proCampaigns: {
      ...newState,
    },
  };
}

function proSubmissions(state = initialState, { type, payload }) {
  switch (type) {
    case deleteProCampaignsStart.type:
      return { ...state, deleteProCampaignsError: null, deleteProCampaignsPending: true };
    case deleteProCampaignsEnd.type:
      return finishDelete(state, payload, 'proCampaigns', 'deleteProCampaigns');
    case deleteProCampaignsError.type:
      return { ...state, deleteProCampaignsError: payload };
    case browseProCampaignsStart.type:
      return { ...state, browseProCampaignsError: null, browseProCampaignsPending: true };
    case browseProCampaignsEnd.type:
      return finishBrowseProCampaign(state, payload);
    case browseProCampaignsError.type:
      return { ...state, browseProCampaignsError: payload };
    case browseProSubmissionsStart.type:
      return { ...state, browseProSubmissionsError: null, browseProSubmissionsPending: true };
    case browseProSubmissionsEnd.type:
      return finishBrowse(state, payload, 'proSubmissions', 'browseProSubmissions');
    case browseProSubmissionsError.type:
      return { ...state, browseProSubmissionsError: payload };
    case createProStart.type:
      return { ...state, createProError: null, createProPending: true };
    case createProEnd.type:
      return finishCreatePro(state, payload);
    case createProError.type:
      return { ...state, createProError: payload, createProPending: false };
    case createCampaignStart.type:
      return { ...state, createCampaignError: null, createCampaignPending: true };
    case createCampaignEnd.type:
      return finishCreateCampaign(state, payload);
    case createCampaignError.type:
      return { ...state, createCampaignError: payload, createCampaignPending: false };
    case editProSubmissionsStart.type:
      return { ...state, isEditing: true };
    case editProSubmissionsEnd.type:
      return finishEdit(state, payload);
    case editProSubmissionsError.type:
      return { ...state, isEditing: false, editError: payload };
    case editProCampaignStart.type:
      return { ...state, isEditing: true };
    case editProCampaignEnd.type:
      return finishEdit(state, payload);
    case editProCampaignError.type:
      return { ...state, isEditing: false, editError: payload };
    default:
      return state;
  }
}

export default proSubmissions;
