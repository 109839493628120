import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../lib/styles';

const baseStyles = {
  container: {
    border: `2px solid ${colors.highlightBright}`,
    fontSize: '14px',
    margin: '15px 5px 5px 10px',
    padding: '8px 8px 3px',
  },
  header: {
    color: colors.highlightDarker,
    fontWeight: 600,
    paddingBottom: '3px',
    textAlign: 'center',
  },
  item: {
    padding: '5px',
  },
  stats: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    textAlign: 'center',
  },
  totals: {
    display: 'flex',
    flexDirection: 'column',
  },
};

function MigraineStats(props) {
  const { stats } = props;
  const { light, moderate, severe } = stats.days;
  const boldStyle = Object.assign({ fontWeight: 600 }, baseStyles.item);

  return (
    <div style={baseStyles.container}>
      <div style={baseStyles.stats}>
        <div style={baseStyles.totals}>
          <div style={baseStyles.header}>Number of Days</div>
          <div style={baseStyles.item}>Severe: {severe}</div>
          <div style={baseStyles.item}>Moderate: {moderate}</div>
          <div style={baseStyles.item}>Light: {light}</div>
          <div style={boldStyle}>Total: {light + moderate + severe}</div>
        </div>
        <div style={baseStyles.totals}>
          <div style={baseStyles.header}>Number of Hours</div>
          <div style={baseStyles.item}>{stats.hours}</div>
          <div style={baseStyles.header}>Average Intensity</div>
          <div style={baseStyles.item}>{stats.severity}</div>
        </div>
      </div>
    </div>
  );
}

MigraineStats.defaultProps = {
  stats: {},
};

MigraineStats.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default MigraineStats;
