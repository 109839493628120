import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find, map, noop, orderBy } from 'lodash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SortedTableHead from './sorted-table-head';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import ReleaseListItem from './release-list-item';
import { blueBtnStyle, colors, tableStyles } from '../lib/styles';
import brand from '../lib/brand';

const StyledTablePagination = withStyles({
  caption: {
    fontSize: '1rem',
  },
  select: {
    marginRight: '4px',
  },
  toolbar: {
    paddingLeft: 5,
  },
})(TablePagination);

const styles = {
  caption: {
    fontSize: '1rem',
  },
  container: {
    minHeight: '200px',
  },
  table: {
    ...tableStyles.table,
    margin: '10px',
  },
  column: {
    ...tableStyles.cell,
  },
  btn: blueBtnStyle,
  fontSize: {
    fontSize: '1rem',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: brand.theme.palette.background.default,
    },
  },
  topSectionIcon: {
    color: colors.white,
    marginRight: '5px',
  },
};

const columnData = [
  {
    id: 'name', disablePadding: true, label: 'Release', small: true,
  },
  {
    id: 'status', disablePadding: true, label: 'Release Status', small: true,
  },
];

const defaults = {
  order: 'asc',
  orderByField: 'name',
};

function sortData(templates, requests, order, orderByField) {
  const combinedDocuments = templates.map((t) => {
    const signatureRequest = find(requests, { esign_template_id: t.id }) || {};
    return { ...t, status: signatureRequest.status, requestId: signatureRequest.id };
  });

  const sortedDocumentTemplates = orderBy(combinedDocuments, [orderByField, 'id'], [order, 'desc']);

  return sortedDocumentTemplates;
}

class ReleaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allChecked: false,
      order: defaults.order,
      orderByField: defaults.orderByField,
      page: 0,
      rowsPerPage: 10,
      selectedItems: [],
      documentTemplates: sortData(props.documentTemplates || {}, props.signatureRequests || {}, defaults.order, defaults.orderByField),
    };
  }
  componentWillMount() {
    this.handleSort();
  }

  componentWillReceiveProps(nextProps) {
    const newDocs = this.props.documentTemplates !== nextProps.documentTemplates;
    const newRequests = this.props.signatureRequests !== nextProps.signatureRequests
    if (newDocs || newRequests) {
      this.handleSort(nextProps.documentTemplates, nextProps.signatureRequests);
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleRequestSort = (event, property) => {
    const orderByField = property;
    let order = 'asc';

    if (this.state.orderByField === property && this.state.order === 'asc') {
      order = 'desc';
    }

    this.setState({ order, orderByField }, this.handleSort);
  };

  handleSort = (newDocuments, newRequests) => {
    const { order, orderByField } = this.state;

    const docs = newDocuments || this.props.documentTemplates;
    const sigRequests = newRequests || this.props.signatureRequests;

    const documentTemplates = sortData(docs, sigRequests, order, orderByField);

    this.setState({ documentTemplates });
  };


  handleChangeAllBox = () => {
    const { allChecked } = this.state;
    const selectAll = !allChecked;
    if(selectAll) {
      const requests = [];
      this.props.documentTemplates.forEach(d => {
        if(!d.requestId) {
          requests.push(d.id);
        }
      });
      return this.setState({ allChecked: true, selectedItems: requests });
    }

    return this.setState({ allChecked: false, selectedItems: [] });
  }
  handleSendReleases = () => {
    this.state.selectedItems.forEach((i) => this.props.handleSendSignatureRequest(i));
    this.setState({ allChecked: false, selectedItems: [] });
  }
  handleClickItem = (id) => {
    const add = !this.state.selectedItems.includes(id);
    if(add) {
      return this.setState(prevState => ({
        selectedItems: prevState.selectedItems.concat(id)
      }));
    }

    return this.setState(prevState => ({
      selectedItems: prevState.selectedItems.filter(i => i !== id),
    }));
  }
  render() {
    const { order, orderByField, documentTemplates, rowsPerPage, page } = this.state;
    const displayedTemplates = documentTemplates.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);
    const signatureRows = map(displayedTemplates, (doc) => {
      return (
        <ReleaseListItem
          key={`${doc.name}`}
          release={doc}
          status={doc.status}
          requestId={doc.requestId}
          onClickItem={this.handleClickItem}
          isSelected={this.state.selectedItems.includes(doc.id)}
          className={this.props.classes.fontSize}
        />
      );
    });
    let templates = null;
    if(displayedTemplates.length) {
      templates = (
        <TableRow>
          <StyledTablePagination
            colSpan={6}
            count={documentTemplates.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 20, 50]}
            className={this.props.classes.caption}
          />
        </TableRow>
      );
    }

    if(!displayedTemplates.length) {
      return (
        <div style={styles.container}>
          <h4> No Release Templates are currently configured for this clinic</h4>
        </div>
      );
    }
    return (
      <div style={styles.container}>
        <Button
          onClick={this.handleSendReleases}
          size="small"
          className={this.props.classes.btn}
          variant="contained"
        >
        Send Signature Request(s)
        </Button>
        <div style={styles.table}>
          <Table>
            <SortedTableHead
              allChecked={this.state.allChecked}
              columnData={columnData}
              order={order}
              orderBy={orderByField}
              onRequestSort={this.handleRequestSort}
              className={this.props.classes.head}
              includeCheckbox={true}
              onChangeCheckbox={this.handleChangeAllBox}
            />
            <TableBody>
              {signatureRows}
            </TableBody>
            <TableFooter>
              {templates}
          </TableFooter>
          </Table>
        </div>
      </div>
    );
  }
}

ReleaseList.defaultProps = {
  appointment: {},
  documentTemplates: [],
  handleSendSignatureRequest: noop,
  signatureRequests: [],
};

ReleaseList.propTypes = {
  appointment: PropTypes.object,
  documentTemplates: PropTypes.array,
  handleSendSignatureRequest: PropTypes.func,
  signatureRequests: PropTypes.array,
};

export default withStyles(styles)(ReleaseList);
