import { FETCH_MIGRAINES_START, FETCH_MIGRAINES_END, FETCH_MIGRAINES_ERROR } from '../actions/migraines';
import { LOGOUT } from '../actions/user';

const initialState = {
  isFetching: false,
};

function migraines(state = initialState, action) {
  switch (action.type) {
    case FETCH_MIGRAINES_START:
      return { ...state, isFetching: true };
    case FETCH_MIGRAINES_END:
      return { ...state, ...action.payload, isFetching: false };
    case FETCH_MIGRAINES_ERROR:
      return { ...state, isFetching: false };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}

export default migraines;
