import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import { withStyles } from '@material-ui/core/styles';

import SortableTable from './sortable-table';

const baseStyles = {
  checkbox: {
    paddingLeft: 13,
  },
  compareBtn: {
    width: '175px',
    margin: '10px auto',
    fontSize: '16px',
  },
  formControl: {
    width: '100%',
    overflow: 'overlay',
  },
  imageControl: {
    display: 'flex',
    textAlign: 'start',
    alignItems: 'center',
    flexDirection: 'row',
    alignContent: 'center',
    padding: '10px',
  },
  imageViewBtn: {
    color: 'blue',
  },
  thumbnail: {
    cursor: 'pointer',
    height: '50px',
    width: '50px',
    backgroundSize: 'contain',
    borderRadius: '5px',
    alignSelf: 'center',
  },
  toggleAll: {
    paddingLeft: '21px',
  },
  sortableTableWrapper: {
    maxWidth: '100%',
    '& td': {
      whiteSpace: 'pre-line',
    },
  },
};

const StyledCheckbox = withStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    '&checked': {
      color: '#fd6120',
    },
  },
}))(Checkbox);

const ListWoundsView = (props) => {
  const {
    images,
    total,
    compare,
    handleCompareImages,
    handleCheckboxChange,
    handleViewImage,
    handleToggleAll,
    selectedImages,
    allChecked,
  } = props;

  const headers = [
    {
      id: 'checkbox',
      label: <StyledCheckbox
        onChange={handleToggleAll}
        checked={allChecked}
      />,
      small: true,
      maxWidth: '100px',
    },
    {
      id: 'name',
      label: 'File Name',
      small: true,
      maxWidth: '100px',
    },
    {
      id: 'primary_location',
      label: 'Primary',
    },
    {
      id: 'secondary_location',
      label: 'Secondary',
    },
    {
      id: 'tertiary_location',
      label: 'Tertiary',
    },
  ];

  const rows = [];
  images.forEach((img) => {
    const {
      name,
      image_id,
      primary_location,
      secondary_location,
      tertiary_location,
      url,
      checked,
    } = img;

    const checkbox = (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Checkbox
          style={baseStyles.checkbox}
          checked={checked}
          onChange={handleCheckboxChange}
          name={name}
        />
        <img
          value={image_id}
          src={url}
          alt={`thumbnail of ${primary_location}-${secondary_location}-${tertiary_location} taken by patient`}
          style={baseStyles.thumbnail}
          onClick={() => handleViewImage(img)}
        />
      </div>
    );

    img.image_details = {
      primary_location,
      secondary_location,
      tertiary_location,
    };

    return rows.push({
      ...img,
      checkbox,
    });
  });

  return (
    <FormControl component="fieldset" style={baseStyles.formControl}>
      <div style={{ marginLeft: 'auto' }}>
        {compare ? (
          <Button
            variant="contained"
            color="primary"
            style={baseStyles.compareBtn}
            onClick={handleCompareImages}
          >
            Compare
          </Button>
        ) : (
          <Button
            variant={selectedImages.length ? 'contained' : 'outlined'}
            color="primary"
            style={baseStyles.compareBtn}
            onClick={selectedImages.length ? handleViewImage : null}
          >
            View
          </Button>
        )}
      </div>
      {total !== images.length ? (
        <div>
          <CircularProgress size={200} style={{ marginTop: 100 }} />
        </div>
      ) : (
        <div style={baseStyles.sortableTableWrapper}>
          <FormGroup>
            <SortableTable
              onChangeCheckbox={handleToggleAll}
              columnData={headers}
              darkMode={true}
              emptyMessage="This patient hasn't submitted any images yet."
              rows={rows}
            />
          </FormGroup>

        </div>
      )}
    </FormControl>
  );
};

ListWoundsView.defaultProps = {
  total: 0,
};

ListWoundsView.propTypes = {
  allChecked: PropTypes.bool.isRequired,
  images: PropTypes.array.isRequired,
  total: PropTypes.number,
  compare: PropTypes.bool.isRequired,
  handleCompareImages: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleViewImage: PropTypes.func.isRequired,
  handleToggleAll: PropTypes.func.isRequired,
  selectedImages: PropTypes.array.isRequired,
};

export default ListWoundsView;
