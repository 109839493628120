import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { colors } from '../lib/styles';

const buttonStyle = {
  fontSize: '16px',
  height: '40px',
  color: '#fff',
  backgroundColor: colors.secondaryColor,
};

class RevokeEmrDialog extends PureComponent {
  render() {
    const {
      open,
    } = this.props;
    return (
      <Dialog
        open={open}
        style={{ textAlign: 'center' }}
      >
        <DialogTitle>Are you sure you wish to revoke this patient's match with the EMR?</DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={this.props.onToggleOpen}
            style={buttonStyle}
          >Cancel
          </Button>
          <Button
            onClick={this.props.onRevoke}
            style={buttonStyle}
          >Revoke Match
          </Button>
        </div>
      </Dialog>
    );
  }
}

RevokeEmrDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggleOpen: PropTypes.func.isRequired,
  onRevoke: PropTypes.func.isRequired,
};

export default RevokeEmrDialog;

