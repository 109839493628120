import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableCell from '../base/table-cell';
import TableRow from '../base/table-row';

const styles = {
  checkoutColumn: {
    textAlign: 'left',
  },
  patientName: {
    width: '20%',
  },
  painTd: {
    fontSize: '1rem',
    marginLeft: '2rem',
    textAlign: 'center',
  },
  providerTd: {
    padding: '4px 0px',
  },
  td: {
    fontSize: '1rem',
  },
};

const PendingTableRow = (props) => {
  const { classes } = props;
  return (
    <Fragment>
      <TableRow>
        <TableCell className={classes.patientName}>
          <CircularProgress size={60} />
        </TableCell>
        <TableCell className={classes.td} padding="none"></TableCell>
        <TableCell className={classes.td}></TableCell>
        <TableCell className={classes.providerTd}></TableCell>
        <TableCell style={styles.checkoutColumn}>
        </TableCell>
        <TableCell className={classes.painTd} padding="none"></TableCell>
      </TableRow>
    </Fragment>
  );
};

export default withStyles(styles)(PendingTableRow);
