// These env vars are set automatically as a result of
// npm run test, start, and build

// TODO: Remove this atrocity that exists
// solely because create-react-app doesnt let us set env vars
function getApiUrl() {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser && window.location.hostname.includes('mindsetmedical')) {
    const [subdomain, hostname, tld] = window.location.href.split('.');
    let api = 'api';

    if (subdomain.includes('staging')) {
      api = 'api-staging';
    }

    return `https://${api}.${hostname}.${tld.split('/')[0]}`;
  }
  return 'http://localhost:3001';
}

const env = {
  test: {
    API_URL: process.env.REACT_APP_API_URL || 'https://api-staging.mindsetmedical.com',
  },
  development: {
    API_URL: process.env.REACT_APP_API_URL || getApiUrl(),
  },
  staging: {
    API_URL: process.env.REACT_APP_API_URL || getApiUrl(),
  },
  demo: {
    API_URL: process.env.REACT_APP_API_URL || getApiUrl(),
  },
  production: {
    API_URL: process.env.REACT_APP_API_URL || getApiUrl(),
  },
};

const sdpConstraints = {
  optional: [],
  mandatory: {
    
  }
};

const config = env[process.env.NODE_ENV];

config.rtc = {
  cfg : {'iceServers': [{'urls': 'stun:stun.l.google.com:19302'}]},
  options : { 'optional': [{'DtlsSrtpKeyAgreement': true}] }
};

config.sdpConstraints = sdpConstraints;
config.notificationLifetime = 259200000;

export default config;
