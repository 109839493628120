import 'isomorphic-fetch';
import React from 'react';
import { render } from 'react-dom';
import { get } from 'lodash';
import { Router, Route, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { createGenerateClassName, MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';

import store from './store';
import activityInitializer from './initializers/activity';
import AppointmentSchedule from './pages/appointment-schedule';
import AppointmentView from './pages/appointment-view';
import Login from './pages/login';
import ClinicSelect from './pages/clinic-selection';
import IcaForm from './pages/ica-form';
import PostOpPro from './pages/pro-compliance-post-op';
import ProCompliance from './pages/pro-compliance';
import PatientList from './pages/patient-listing-view';
import PendingPatients from './pages/pending-patients';
import DeviceList from './pages/device-listing-view';
import Developer from './pages/developer-options';
import CarePathways from './pages/care-pathways';
import CreateCarePathway from './pages/create-care-pathway';
import ViewCarePathway from './pages/view-care-pathway';
import BundledEvents from './pages/bundled-events';
import CreateBundledEvent from './pages/create-bundled-event';
import ViewBundleEvent from './pages/view-bundled-event';
import VideoBroadcasts from './pages/video-broadcasts';
import VideoCall from './pages/video-call';
import VoiceBroadcasts from './pages/voice-broadcasts';
import VideoBroadcast from './pages/single-video-broadcast';
import VoiceBroadcast from './pages/single-voice-broadcast';
import VirtualAppointments from './pages/virtual-appointments';
import ResetPassword from './pages/reset-password';
import { verifyUser } from './actions/user';
import { selectClinic } from './state/clinic';
import './index.css';
import brand from './lib/brand';
import Dashboard from './pages/dashboard';

const generateClassName = createGenerateClassName({
  productionPrefix: 'provider-app',
  seed: 'provider-app',
});

function requireAuth(nextState, replace, cb) {
  return store.dispatch(verifyUser())
    .then(async () => {
      const noClinic = !(get(store.getState(), 'clinic.clinicId'));
      if (!(nextState.location.pathname === '/clinic-select') && noClinic) {
        try {
          await store.dispatch(selectClinic());
        } catch (e) {
          replace({ pathname: '/clinic-select' });
        }
      }
    })
    .then(() => cb())
    .catch(() => {
      replace({ pathname: '/' });
      cb();
    });
}

activityInitializer();
// TODO: Form and form-submission routes should be dynamic rather than hardcoded to ica in v2
render(
  (
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={brand.theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={browserHistory}>
              <Route path="/" component={Login} />
              <Route path="/app/reset" component={ResetPassword} onEnter={requireAuth} />
              <Route path="/app/patientlisting" component={PatientList} onEnter={requireAuth} />
              <Route path="/app/dashboard" component={Dashboard} onEnter={requireAuth} />
              <Route path="/app/pending-patients" component={PendingPatients} onEnter={requireAuth} />
              <Route path="/app/patient" component={AppointmentView} onEnter={requireAuth} />
              <Route path="/app/forms/ica" component={IcaForm} onEnter={requireAuth} />
              <Route path="/app/pro/postop" component={PostOpPro} onEnter={requireAuth} />
              <Route path="/app/pro/compliance" component={ProCompliance} onEnter={requireAuth} />
              <Route path="/clinic-select" component={ClinicSelect} onEnter={requireAuth} />
              <Route path="/app/devicelisting" component={DeviceList} onEnter={requireAuth} />
              <Route path="/app/developer" component={Developer} onEnter={requireAuth} />
              <Route path="/app/care-pathways" component={CarePathways} onEnter={requireAuth} />
              <Route path="/app/care-pathways/new" component={CreateCarePathway} onEnter={requireAuth} />
              <Route path="/app/care-pathways/:campaignId" component={ViewCarePathway} onEnter={requireAuth} />
              <Route path="/app/video-broadcasts" component={VideoBroadcasts} onEnter={requireAuth} />
              <Route path="/app/virtual-appointments" component={VirtualAppointments} onEnter={requireAuth} />
              <Route path="/app/virtual-schedule" component={AppointmentSchedule} onEnter={requireAuth} />
              <Route path="/app/voice-broadcasts" component={VoiceBroadcasts} onEnter={requireAuth} />
              <Route path="/app/appointment-bundled-events" component={BundledEvents} onEnter={requireAuth} />
              <Route path="/app/appointment-bundled-events/new" component={CreateBundledEvent} onEnter={requireAuth} />
              <Route path="/app/care-pathway-bundled-events" component={BundledEvents} onEnter={requireAuth} />
              <Route path="/app/care-pathway-bundled-events/new" component={CreateBundledEvent} onEnter={requireAuth} />
              <Route path="/app/care-pathway-bundled-events/:eventId" component={ViewBundleEvent} onEnter={requireAuth} />
              <Route path="/app/appointment-bundled-events/:eventId" component={ViewBundleEvent} onEnter={requireAuth} />
              <Route path="/app/video-broadcasts/:broadcastId" component={VideoBroadcast} onEnter={requireAuth} />
              <Route path="/app/voice-broadcasts/:broadcastId" component={VoiceBroadcast} onEnter={requireAuth} />
              <Route path="/app/patient/video-call" component={VideoCall} onEnter={requireAuth} />
            </Router>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </Provider>
  ), document.getElementById('root'),
);
