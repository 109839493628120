import { promiseHandler, resetReducer } from 'cooldux';
import { keyBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'painHx');

export function browsePainHx(userId) {
  return function dispatcher(dispatch) {
    const promise = apiFetch(`/users/${userId}/pain_hx`);
    return browseHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  browsePending: false,
  data: {},
};

function finishBrowse(state, painHx) {
  const data = {
    ...state.data,
    ...keyBy(painHx, 'id'),
  };

  return { ...state, data, browsePending: false, browseError: null };
}


const painHx = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };
    default:
      return state;
  }
});

export default painHx;
