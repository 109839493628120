import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  SaveAlt,
} from '@material-ui/icons';
import moment from 'moment';

import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const formattedTimeStr = (duration) => {
  let remainingTime = duration;
  const hours = Math.floor(remainingTime / 3600);
  remainingTime %= 3600;
  const minutes = Math.floor(remainingTime / 60);
  remainingTime %= 60;

  let timeStr = '';
  if (hours) timeStr += `${hours}h `;
  timeStr += `${minutes}m ${remainingTime}s`;

  return timeStr;
};

const useStyles = makeStyles({
  totalsCard: {
    borderRadius: 4,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250,
  },
  totalsSection: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  greyText: {
    color: '#959595',
  },
  root: {
    display: 'flex',
  },
  timeValue: {
    fontSize: '2rem',
  },
  totalTime: {
    background: 'black',
    borderRadius: 4,
    color: 'white',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& th': {
      borderCollapse: 'collapse',
      padding: 5,
    },
    '& td': {
      borderCollapse: 'collapse',
      padding: 5,
    },
    tableLayout: 'fixed',
  },
  thead: {
    background: 'black',
    color: 'white',
  },
  exportButton: {
    backgroundColor: colors.primaryColor,
    border: `1px solid ${colors.primaryColor}`,
    color: colors.white,
    paddingLeft: 5,
    paddingRight: 5,
  },
  monthlySummaryDurationCol: {
    textAlign: 'right',
    width: 120,
  },
  monthlySummaryDownloadCsvCol: {
    width: 80,
  },
  monthlySummaryShowDetailsCol: {
    width: 110,
  },
  monthlySummaryDurationRow: {
    textAlign: 'right',
  },
  monthlySummaryDownloadCsvRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  monthlySummaryNameRow: {
    alignItems: 'center',
    display: 'flex',
  },
  showDetailsBtn: {
    background: 'none',
    border: 'none',
    color: colors.primaryColor,
    cursor: 'pointer',
    padding: 0,
  },
  downloadCsvIcon: {
    cursor: 'pointer',
  },
  hideDetailsBtn: {
    background: 'none',
    border: 'none',
    color: colors.errorRed,
    cursor: 'pointer',
    padding: 0,
  },
  startStopCols: {
    width: 80,
  },
  patientTotalDurationCol: {
    width: 270,
  },
  patientExpandCol: {
    width: 30,
  },
  tableGrey: {
    border: 'solid 1px #d8d8d8',
    marginTop: 10,
    '& td': {
      padding: 10,
    },
  },
  tableGreyHeader: {
    background: '#d8d8d8',
    color: 'black',
  },
  tableGreyRow: {
    borderTop: 'solid 1px #d8d8d8',
  },
  tableBlack: {
    border: 'solid 1px black',
    '& td': {
      padding: 10,
    },
  },
  tableBlackRow: {
    borderTop: 'solid 1px black',
  },
  bold: {
    fontWeight: 'bold',
  },
  avatar: {
    background: 'none',
    border: 'solid 1px black',
    color: 'black',
    marginRight: 5,
  },
});

const buildTotalReport = (patientData, userName) => {
  const { totals } = patientData;
  const { appointments, pros, total } = totals;
  const headers = 'First Name,Last Name,Total Duration,Virtual Exams,Patient Assessments';
  const [lastName, firstName] = userName.split(', ');
  const formattedTotal = formattedTimeStr(total);
  const formattedAppointments = formattedTimeStr(appointments);
  const formattedPros = formattedTimeStr(pros);
  const row = `${firstName},${lastName},${formattedTotal},${formattedAppointments},${formattedPros}`;

  return [headers, row].join('\n');
};

const buildVirtualExamsReport = (patientData) => {
  const { appointments } = patientData;
  const rows = [];
  const headers = 'Virtual Exam Date,Start,Stop,Total Duration';

  appointments.forEach((appointment) => {
    const {
      date,
      duration,
      start_time,
      end_time,
    } = appointment;
    const startTime = moment(start_time).format('HH:mma');
    const endTime = moment(end_time).format('HH:mma');
    rows.push(`${date},${startTime},${endTime},${formattedTimeStr(duration)}`);
  });

  return [headers, ...rows].join('\n');
};

const buildPatientAssessmentsReport = (patientData) => {
  const { pros } = patientData;
  const rows = [];
  const headers = 'Patient Assessments,Total Average Time';

  Object.keys(pros).forEach((pro) => {
    rows.push(`${pro},${formattedTimeStr(pros[pro])}`);
  });

  return [headers, ...rows].join('\n');
};

const buildCsvReport = (patientData, userName) => {
  const totalReport = buildTotalReport(patientData, userName);
  const virtualExamsReport = buildVirtualExamsReport(patientData);
  const patientAssessmentsReport = buildPatientAssessmentsReport(patientData);

  return [totalReport, virtualExamsReport, patientAssessmentsReport].join('\n,\n');
};

const handleBuildPatientReport = (patientData, startOfMonth, userName) => {
  const report = buildCsvReport(patientData, userName);
  const dateStr = startOfMonth.format('YYYY-MM');
  const [lastName, firstName] = userName.split(', ');

  const downloadTag = document.createElement('a');
  downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(report));
  downloadTag.setAttribute('download', `${lastName}_${firstName}_rpm_${dateStr}.csv`);
  downloadTag.style.display = 'none';

  document.body.appendChild(downloadTag);

  downloadTag.click();

  document.body.removeChild(downloadTag);
};

const handleDownloadPatientReport = (userId, userName, clinicId, startOfMonth, endOfMonth) => {
  const options = {
    query: {
      start_time: startOfMonth.toISOString(),
      end_time: endOfMonth.toISOString(),
    },
  };

  apiFetch(`/clinics/${clinicId}/patients/${userId}/rpm`, options)
    .then((data) => {
      handleBuildPatientReport(data, startOfMonth, userName);
    });
};

const buildMonthlyReport = (rpmData) => {
  const { aggregateTotals, patientTotals } = rpmData;
  const { appointments, pros, total } = aggregateTotals;

  const aggregateHeaders = 'Total Time,Virtual Exams,Patient Assessments';
  const aggregateRow = `${formattedTimeStr(total)},${formattedTimeStr(appointments)},${formattedTimeStr(pros)}`;
  const aggregateReport = [aggregateHeaders, aggregateRow].join('\n');

  const patientRows = [];
  const patientHeaders = 'Last Name,First Name,Patient Assessments,Virtual Exams,Total Duration';
  Object.entries(patientTotals).forEach(([name, data]) => {
    const [lastName, firstName] = name.split(', ');
    const proDuration = formattedTimeStr(data.proTotal);
    const appointmentsDuration = formattedTimeStr(data.appointmentsTotal);
    const totalDuration = formattedTimeStr(data.total);
    patientRows.push(`${lastName},${firstName},${proDuration},${appointmentsDuration},${totalDuration}`);
  });
  const patientReport = [patientHeaders, ...patientRows].join('\n');

  return [aggregateReport, patientReport].join('\n,\n');
};

const handleDownloadMonthlyReport = (rpmData, startOfMonth) => {
  const report = buildMonthlyReport(rpmData);

  const dateStr = startOfMonth.format('YYYY-MM');

  const downloadTag = document.createElement('a');
  downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(report));
  downloadTag.setAttribute('download', `monthly_rpm_${dateStr}.csv`);
  downloadTag.style.display = 'none';

  document.body.appendChild(downloadTag);

  downloadTag.click();

  document.body.removeChild(downloadTag);
};

const DashboardRemotePatientMonitoring = ({
  clinicId,
  rpmData,
  currentPatient,
  setCurrentPatient,
  setPatientOptionSelected,
  changePatient,
  startOfMonth,
  endOfMonth,
}) => {
  const [showSelectedUserExams, setShowSelectedUserExams] = useState(false);
  const [showSelectedUserAssessments, setShowSelectedUserAssessments] = useState(false);
  const classes = useStyles();

  const { patientTotals, aggregateTotals } = rpmData;

  if (!patientTotals || !aggregateTotals) return null;

  const { pros, appointments, total } = aggregateTotals;

  return (
    <div className={classes.root}>
      <Box display="flex" flexDirection="column" flexGrow={0}>
        <div className={classes.totalsCard}>
          <div className={`${classes.totalTime} ${classes.totalsSection}`}>
            <div className={classes.greyText}>TOTAL</div>
            <div className={classes.timeValue}>
              {formattedTimeStr(currentPatient ? currentPatient.data.totals.total : total)}
            </div>
          </div>
          <div className={classes.totalsSection}>
            <div>Virtual Exams</div>
            <div className={classes.timeValue}>
              {formattedTimeStr(currentPatient ? currentPatient.data.totals.appointments : appointments)}
            </div>
          </div>
          <div className={classes.totalsSection}>
            <div>Patient Assessment</div>
            <div className={classes.timeValue}>
              {formattedTimeStr(currentPatient ? currentPatient.data.totals.pros : pros)}
            </div>
          </div>
        </div>
        {currentPatient ? (
          <Button
            className={classes.exportButton}
            onClick={() => { handleBuildPatientReport(currentPatient.data, startOfMonth, currentPatient.name); }}
            variant="contained"
          >
            Export Patient Summary
          </Button>
        ) : null}
        {!currentPatient && rpmData ? (
          <Button
            className={classes.exportButton}
            onClick={() => { handleDownloadMonthlyReport(rpmData, startOfMonth); }}
            variant="contained"
          >
            Export Monthly Summary
          </Button>
        ) : null}
      </Box>
      <Box flexGrow={1} marginLeft="10px">
        {currentPatient ? (
          <Box width="100%">
            <table className={`${classes.table} ${classes.tableBlack}`}>
              <thead className={classes.thead}>
                <tr>
                  <th>Patient</th>
                  <th className={classes.monthlySummaryDurationCol}>Total Duration</th>
                  <th className={classes.monthlySummaryDownloadCsvCol}>{' '}</th>
                  <th className={classes.monthlySummaryShowDetailsCol}>{' '}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{currentPatient.name}</td>
                  <td className={classes.monthlySummaryDurationRow}>
                    <span>{formattedTimeStr(currentPatient.totalDuration)}</span>
                  </td>
                  <td className={classes.monthlySummaryDownloadCsvRow}>
                    <span>
                      <SaveAlt
                        className={classes.downloadCsvIcon}
                        onClick={() => {
                          handleBuildPatientReport(currentPatient.data, startOfMonth, currentPatient.name);
                        }}
                      />
                    </span>
                  </td>
                  <td >
                    <button
                      className={classes.hideDetailsBtn}
                      onClick={() => {
                        setCurrentPatient(null);
                        setPatientOptionSelected(null);
                      }}
                    >
                      Hide Details
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={`${classes.table} ${classes.tableGrey}`}>
              <thead className={classes.tableGreyHeader}>
                <tr>
                  <th>Virtual Exam Date</th>
                  <th className={classes.startStopCols}>Start</th>
                  <th className={classes.startStopCols}>Stop</th>
                  <th className={classes.patientTotalDurationCol}>Total Duration</th>
                  <th className={classes.patientExpandCol}>
                    {showSelectedUserExams ? (
                      <ExpandLess onClick={() => setShowSelectedUserExams(false)} />
                    ) : (
                      <ExpandMore onClick={() => setShowSelectedUserExams(true)} />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.bold}>TOTAL</td>
                  <td>{' '}</td>
                  <td>{' '}</td>
                  <td>{formattedTimeStr(currentPatient.data.totals.appointments)}</td>
                  <td>{' '}</td>
                </tr>
                {showSelectedUserExams && (
                  currentPatient.data.appointments.map((appointment) => {
                    const {
                      date,
                      duration,
                      start_time,
                      end_time,
                    } = appointment;
                    const startTime = moment(start_time).format('HH:mma');
                    const endTime = moment(end_time).format('HH:mma');
                    return (
                      <tr className={classes.tableGreyRow} key={`${date}-${startTime}`}>
                        <td>{date}</td>
                        <td>{startTime}</td>
                        <td>{endTime}</td>
                        <td>{formattedTimeStr(duration)}</td>
                        <td>{' '}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>

            <table className={`${classes.table} ${classes.tableGrey}`}>
              <thead className={classes.tableGreyHeader}>
                <tr>
                  <th>Patient Assessments</th>
                  <th className={classes.patientTotalDurationCol}>Total Duration</th>
                  <th className={classes.patientExpandCol}>
                    {showSelectedUserAssessments ? (
                      <ExpandLess onClick={() => setShowSelectedUserAssessments(false)} />
                    ) : (
                      <ExpandMore onClick={() => setShowSelectedUserAssessments(true)} />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.bold}>TOTAL</td>
                  <td>{formattedTimeStr(currentPatient.data.totals.pros)}</td>
                </tr>
                {showSelectedUserAssessments && (
                  Object.keys(currentPatient.data.pros).map(type => (
                    <tr className={classes.tableGreyRow} key={type}>
                      <td>{type}</td>
                      <td>{formattedTimeStr(currentPatient.data.pros[type])}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Box>
        ) : (
          <Box height={300} width="100%">
            <table className={`${classes.table} ${classes.tableBlack}`}>
              <thead className={classes.thead}>
                <tr>
                  <th>Name</th>
                  <th className={classes.monthlySummaryDurationCol}>Total Duration</th>
                  <th className={classes.monthlySummaryDownloadCsvCol}>{' '}</th>
                  <th className={classes.monthlySummaryShowDetailsCol}>{' '}</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(patientTotals).map((patient) => {
                  const userId = patientTotals[patient].user_id;
                  const [lastName, firstName] = patient.split(', ');
                  const firstInitial = firstName[0];
                  const lastInitial = lastName[0];
                  const handleClickShowDetails = () => {
                    changePatient(patient, userId);
                  };
                  return (
                    <tr className={classes.tableBlackRow} key={patient}>
                      <td className={classes.monthlySummaryNameRow}>
                        <Avatar className={classes.avatar}>
                          {`${firstInitial}${lastInitial}`}
                        </Avatar>
                        <span>{patient}</span>
                      </td>
                      <td className={classes.monthlySummaryDurationRow}>
                        {formattedTimeStr(patientTotals[patient].total)}
                      </td>
                      <td className={classes.monthlySummaryDownloadCsvRow}>
                        <span>
                          <SaveAlt
                            className={classes.downloadCsvIcon}
                            onClick={() => {
                              handleDownloadPatientReport(userId, patient, clinicId, startOfMonth, endOfMonth);
                            }}
                          />
                        </span>
                      </td>
                      <td>
                        <button
                          className={classes.showDetailsBtn}
                          onClick={handleClickShowDetails}
                        >
                          Show Details
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        )}
      </Box>
    </div>
  );
};

DashboardRemotePatientMonitoring.defaultProps = {
  currentPatient: null,
};

DashboardRemotePatientMonitoring.propTypes = {
  clinicId: PropTypes.number.isRequired,
  rpmData: PropTypes.object.isRequired,
  currentPatient: PropTypes.object,
  setCurrentPatient: PropTypes.func.isRequired,
  setPatientOptionSelected: PropTypes.func.isRequired,
  changePatient: PropTypes.func.isRequired,
  startOfMonth: PropTypes.object.isRequired,
  endOfMonth: PropTypes.object.isRequired,
};

export default DashboardRemotePatientMonitoring;
