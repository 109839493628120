import { get } from 'lodash';

import config from '../config';
import store from '../store';

// TODO: This is bad, turn into an action

function openForm(esignRequestId) {
  const clinicId = get(store.getState(), 'clinic.clinicId');

  window.open(`${config.API_URL}/clinics/${clinicId}/esign_requests/${esignRequestId}`);
}

export default openForm;
