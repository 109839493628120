import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Campaign from '../components/campaign';
import AppWrapper from '../wrappers/app-wrapper';
import { apiFetch } from '../lib/fetch';

const CreateCarePathway = (props) => {
  const handleSave = async (name, events) => {
    const opts = {
      method: 'POST',
      body: {
        name,
        campaign_spec: {
          schedule: events,
        },
      },
    };
    try {
      await apiFetch(`/clinics/${props.clinicId}/campaigns`, opts);
      props.router.push('/app/care-pathways?message=created');
    } catch (e) {
      console.error('error saving: ', e);
    }
  };

  return (
    <AppWrapper router={props.router}>
      <Campaign
        onSave={handleSave}
      />
    </AppWrapper>
  );
};

function mapStateToProps(state) {
  const {
    clinic: { clinicId },
  } = state;

  return {
    clinicId,
  };
}
CreateCarePathway.propTypes = {
  clinicId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
};


export default connect(mapStateToProps, {})(CreateCarePathway);
