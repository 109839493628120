import makeActionCreator from '../lib/make-action-creator';
import { apiFetch } from '../lib/fetch';

export const FETCH_FORMS_START = 'forms-FETCH_FORMS_START';
export const FETCH_FORMS_END = 'forms-FETCH_FORMS_END';
export const FETCH_FORMS_ERROR = 'forms-FETCH_FORMS_ERROR';

const fetchFormsStart = makeActionCreator(FETCH_FORMS_START);
const fetchFormsEnd = makeActionCreator(FETCH_FORMS_END);
const fetchFormsError = makeActionCreator(FETCH_FORMS_ERROR);

export function fetchForms(userId) {
  return function dispatcher(dispatch) {
    dispatch(fetchFormsStart());

    return apiFetch(`/users/${userId}/form_completion`)
      .then((json) => {
        const res = {};
        res[userId] = json;

        dispatch(fetchFormsEnd(res));
        return json;
      })
      .catch((err) => {
        dispatch(fetchFormsError());
        throw err;
      });
  };
}

