import React from 'react';
const styles = {
  comparisonGrid: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
  },
}

const EmrComparisonGrid = ({ children }) => (
  <div style={styles.comparisonGrid}>{children}</div>
);

export default EmrComparisonGrid;
