import React from 'react';
import PropTypes from 'prop-types';
import { AxisTop } from '@vx/axis';
import { scaleLinear, scalePoint } from '@vx/scale';

import { colors } from '../themes/mindset/styles';
import {
  BloodPressure,
  HeartBeat,
  Lungs,
  Thermometer,
  Scale,
  Spo2,
} from '../lib/icons';
import VitalsLineChart from './vitals-line-chart';

const chartHeight = 150;
const iconContainerWidth = 100;
const lineChartMargins = {
  bottom: 5,
  top: 5,
};
const svgMargins = {
  top: 25,
  right: 50,
  bottom: 0,
  left: 40,
};

const styles = {
  axisTopMask: {
    background: 'white',
    left: 0,
    position: 'sticky',
    width: iconContainerWidth,
    zIndex: 2,
  },
  axisTopWrapper: {
    background: 'white',
    display: 'flex',
    paddingBottom: 10,
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  chartsContainer: {
    position: 'relative',
    width: 'max-content',
  },
  iconBlue: {
    color: colors.iconBlue,
  },
  iconRed: {
    color: colors.iconRed,
  },
  iconContainer: {
    alignItems: 'center',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    left: 0,
    height: chartHeight + lineChartMargins.bottom + lineChartMargins.top,
    justifyContent: 'center',
    position: 'sticky',
    width: iconContainerWidth,
    zIndex: 2,
  },
  iconText: {
    fontSize: 12,
    marginBottom: 5,
  },
  iconWrapper: {
    width: 50,
  },
  noData: {
    margin: 5,
  },
  vitalsLineChartWrapper: {
    display: 'flex',
    marginTop: 20,
  },
};

const VitalsLineCharts = (props) => {
  const {
    axisTopDomain,
    axisTopRange,
    chartWidth,
    vitalsData,
    allHistoricalMeasurements,
    onClickDataPoint,
  } = props;

  let xScale;
  let numTicks;
  if (allHistoricalMeasurements) {
    xScale = scalePoint({
      domain: axisTopDomain,
      range: axisTopRange,
    });
    numTicks = axisTopDomain.length;
  } else {
    xScale = scaleLinear({
      domain: axisTopDomain,
      range: axisTopRange,
    });
    numTicks = axisTopDomain[1]; // eslint-disable-line prefer-destructuring
  }

  let foundData;
  Object.keys(vitalsData).forEach((key) => {
    if (vitalsData[key].length) foundData = true;
  });

  if (!foundData) {
    return (
      <div style={styles.noData}>No measurements found for the month</div>
    );
  }

  return (
    <div style={styles.chartsContainer}>
      <div style={styles.axisTopWrapper}>
        <div style={styles.axisTopMask} />
        <svg
          height={svgMargins.top}
          style={styles.axisTop}
          width={chartWidth + svgMargins.left + svgMargins.right}
        >
          <AxisTop
            left={svgMargins.left}
            top={svgMargins.top}
            scale={xScale}
            numTicks={numTicks}
            tickLabelProps={() => ({
              fontSize: 11,
              textAnchor: 'middle',
              y: -14,
            })}
          />
        </svg>
      </div>
      <div style={{ ...styles.vitalsLineChartWrapper, marginTop: 10 }}>
        <div style={{ ...styles.iconContainer, ...styles.iconRed }}>
          <div style={styles.iconText}>Heart Rate</div>
          <div style={styles.iconWrapper}>
            <HeartBeat />
          </div>
        </div>
        <div>
          <VitalsLineChart
            data={vitalsData.HR}
            color="red"
            chartHeight={chartHeight}
            chartWidth={chartWidth}
            dataType="HR"
            svgMargins={svgMargins}
            axisTopDomain={axisTopDomain}
            lineChartMargins={lineChartMargins}
            allHistoricalMeasurements={allHistoricalMeasurements}
            onClickDataPoint={onClickDataPoint}
            defaultMinYScale={40}
            defaultMaxYScale={90}
          />
        </div>
      </div>
      <div style={styles.vitalsLineChartWrapper}>
        <div style={{ ...styles.iconContainer, ...styles.iconBlue }}>
          <div style={styles.iconText}>Breath Rate</div>
          <div style={styles.iconWrapper}>
            <Lungs />
          </div>
        </div>
        <div>
          <VitalsLineChart
            data={vitalsData.BR}
            color="blue"
            chartHeight={chartHeight}
            chartWidth={chartWidth}
            dataType="BR"
            svgMargins={svgMargins}
            axisTopDomain={axisTopDomain}
            lineChartMargins={lineChartMargins}
            allHistoricalMeasurements={allHistoricalMeasurements}
            onClickDataPoint={onClickDataPoint}
            defaultMinYScale={10}
            defaultMaxYScale={25}
          />
        </div>
      </div>
      <div style={styles.vitalsLineChartWrapper}>
        <div style={{ ...styles.iconContainer, ...styles.iconRed }}>
          <div style={styles.iconText}>Blood Pressure</div>
          <div style={styles.iconWrapper}>
            <BloodPressure />
          </div>
        </div>
        <div>
          <VitalsLineChart
            data={vitalsData.systolic}
            dataSecondary={vitalsData.diastolic}
            color="blue"
            chartHeight={chartHeight}
            chartWidth={chartWidth}
            dataType="BP"
            svgMargins={svgMargins}
            axisTopDomain={axisTopDomain}
            lineChartMargins={lineChartMargins}
            allHistoricalMeasurements={allHistoricalMeasurements}
            onClickDataPoint={onClickDataPoint}
            defaultMinYScale={40}
            defaultMaxYScale={180}
          />
        </div>
      </div>
      <div style={styles.vitalsLineChartWrapper}>
        <div style={{ ...styles.iconContainer, ...styles.iconBlue }}>
          <div style={styles.iconText}>SpO2</div>
          <div style={styles.iconWrapper}>
            <Spo2 />
          </div>
        </div>
        <div>
          <VitalsLineChart
            data={vitalsData.SPO2}
            color="blue"
            chartHeight={chartHeight}
            chartWidth={chartWidth}
            dataType="SPO2"
            svgMargins={svgMargins}
            axisTopDomain={axisTopDomain}
            lineChartMargins={lineChartMargins}
            allHistoricalMeasurements={allHistoricalMeasurements}
            onClickDataPoint={onClickDataPoint}
            defaultMinYScale={85}
            defaultMaxYScale={100}
          />
        </div>
      </div>
      <div style={styles.vitalsLineChartWrapper}>
        <div style={{ ...styles.iconContainer, ...styles.iconBlue }}>
          <div style={styles.iconText}>BMI</div>
          <div style={styles.iconWrapper}>
            <Scale />
          </div>
        </div>
        <div>
          <VitalsLineChart
            data={vitalsData.BMI}
            color="blue"
            chartHeight={chartHeight}
            chartWidth={chartWidth}
            dataType=" "
            svgMargins={svgMargins}
            axisTopDomain={axisTopDomain}
            lineChartMargins={lineChartMargins}
            allHistoricalMeasurements={allHistoricalMeasurements}
            onClickDataPoint={onClickDataPoint}
            defaultMinYScale={15}
            defaultMaxYScale={55}
          />
        </div>
      </div>
      <div style={styles.vitalsLineChartWrapper}>
        <div style={{ ...styles.iconContainer, ...styles.iconRed }}>
          <div style={styles.iconText}>Temperature</div>
          <div style={styles.iconWrapper}>
            <Thermometer />
          </div>
        </div>
        <div>
          <VitalsLineChart
            data={vitalsData.temperature}
            color="red"
            chartHeight={chartHeight}
            chartWidth={chartWidth}
            dataType="temperature"
            svgMargins={svgMargins}
            axisTopDomain={axisTopDomain}
            lineChartMargins={lineChartMargins}
            allHistoricalMeasurements={allHistoricalMeasurements}
            onClickDataPoint={onClickDataPoint}
            defaultMinYScale={95}
            defaultMaxYScale={105}
          />
        </div>
      </div>
    </div>
  );
};

VitalsLineCharts.propTypes = {
  axisTopDomain: PropTypes.array.isRequired,
  axisTopRange: PropTypes.array.isRequired,
  chartWidth: PropTypes.number.isRequired,
  vitalsData: PropTypes.object.isRequired,
  allHistoricalMeasurements: PropTypes.bool.isRequired,
  onClickDataPoint: PropTypes.func.isRequired,
};

export default VitalsLineCharts;
