import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { KeypadDate } from 'common-ui';

import HiddenContent from './hidden-content';
import { colors } from '../lib/styles';

const styles = {
  successMessage: {
    color: colors.successGreen,
    position: 'absolute',
    left: '20px',
    alignSelf: 'center',
  },
  errorMessage: {
    color: colors.errorRed,
    position: 'absolute',
    left: '20px',
    alignSelf: 'center',
  },
  buttonStyle: {
    fontSize: '16px',
    height: '40px',
    color: '#fff',
    backgroundColor: colors.secondaryColor,
  },
  headerTitle: {
    fontWeight: 200,
    fontSize: '1.4rem',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    margin: '40px auto',
    minHeight: '250px',
    padding: '30px',
  },
  field: {
    padding: '10px',
    width: '190px',
    alignSelf: 'center',
  },
  fieldLabel: {
    fontSize: '1rem',
    color: '#000',
    alignSelf: 'center',
  },
  inputAlignment: {
    display: 'flex',
    margin: '1rem',
    justifyContent: 'space-between',
  },
  inviteText: {
    cursor: 'pointer',
    textAlign: 'center',
  },
  btnAlignment: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: 0,
  },
  textFieldInput: {
    width: '100%',
    height: '75%',
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: '-1px -1px 1px #d8d8d8',
    border: '.5px solid #d8d8d8',
    borderRadius: '1px',
    textIndent: '7px',
  },
  input: {
    textIndent: '7px',
  },
  btnStyle: {
    color: '#fff',
    backgroundColor: colors.secondaryColor,
    width: '12rem',
    alignSelf: 'center',
  },
  selectionError: {
    color: colors.errorRed,
    maxWidth: '320px',
    alignSelf: 'center',
  },
  date: {
    alignSelf: 'center',
    padding: '10px',
  },
};

const initialState = {
  first_name: '',
  last_name: '',
  birth_date: '',
  email: '',
  cell_phone: '',
  dialog: false,
  inviteMessage: '',
  primary_language: 'en',
};

class InviteDialogSinglePatient extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.handleUpdateFirstName = this.handleChange.bind(this, 'first_name');
    this.handleUpdateLastName = this.handleChange.bind(this, 'last_name');
    this.handleUpdateDob = this.handleChange.bind(this, 'birth_date');
    this.handleUpdateEmail = this.handleChange.bind(this, 'email');
    this.handleUpdatePhone = this.handleChange.bind(this, 'cell_phone');
    this.handleUpdatePrimaryLanguage = this.handleChange.bind(this, 'primary_language');
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({ ...initialState });
    }
  }
  handleChange(field, evt) {
    this.setState({ [field]: evt.target.value });
  }
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div className={classes.inputAlignment}>
          <div className={classes.fieldLabel}>First Name:</div>
          <TextField
            id="first_name"
            fullWidth
            InputProps={{
              disableUnderline: true,
              className: classes.textFieldInput,
              inputProps: { className: classes.input },
            }}
            className={classes.field}
            onChange={this.handleUpdateFirstName}
            value={this.state.first_name}
          />
        </div>
        <div className={classes.inputAlignment}>
          <div className={classes.fieldLabel}>Last Name:</div>
          <TextField
            id="last_name"
            fullWidth
            onChange={this.handleUpdateLastName}
            InputProps={{
              disableUnderline: true,
              className: classes.textFieldInput,
              inputProps: { className: classes.input },
            }}
            className={classes.field}
            value={this.state.last_name}
          />
        </div>
        <div className={classes.inputAlignment}>
          <div className={classes.fieldLabel}>Date of Birth:</div>
          <KeypadDate onChange={(birth_date) => { this.setState({ birth_date }); }} value={this.state.birth_date} />
        </div>
        <div style={styles.inputAlignment}>
          <div style={styles.fieldLabel}>Email Address:</div>
          <TextField
            id="email"
            fullWidth
            onChange={this.handleUpdateEmail}
            InputProps={{
              disableUnderline: true,
              className: classes.textFieldInput,
              inputProps: { className: classes.input },
            }}
            className={classes.field}
            value={this.state.email}
          />
        </div>
        <div style={styles.inputAlignment}>
          <div style={styles.fieldLabel}>Cell Phone:</div>
          <TextField
            id="phone"
            fullWidth
            onChange={this.handleUpdatePhone}
            InputProps={{
              disableUnderline: true,
              className: classes.textFieldInput,
              inputProps: { className: classes.input },
            }}
            className={classes.field}
            value={this.state.cell_phone}
          />
        </div>
        <div style={styles.inputAlignment}>
          <div style={styles.fieldLabel}>Primary Language</div>
          <Select
            value={this.state.primary_language}
            onChange={this.handleUpdatePrimaryLanguage}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="es">Spanish</MenuItem>
          </Select>
        </div>
        <HiddenContent hidden={!this.props.errorMsg}>
          <div style={styles.selectionError}>
            {this.props.errorMsg}
          </div>
        </HiddenContent>
        <Button
          style={styles.btnStyle}
          onClick={() => this.props.onInvitePatient(this.state)}
        >
          Invite Patient
        </Button>
        <Button className={classes.inviteText} onClick={this.props.onClickBulk}>Click Here to Bulk Upload Patients</Button>
      </Fragment>
    );
  }
}
InviteDialogSinglePatient.defaultProps = {
  errorMsg: '',
};
InviteDialogSinglePatient.propTypes = {
  onInvitePatient: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
  onClickBulk: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default withStyles(styles)(InviteDialogSinglePatient);
