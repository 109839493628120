import { arrayToObject } from '../utilities';
import config from '../../config';

function commonScore(pro, scoreFunc) {
  let { data } = pro.pro_data;
  data = arrayToObject(data);
  pro.score.score = scoreFunc(data);
  return pro;
}

function scoreIvc(data) {
  if (data.declinedTermsAndConditions) {
    return 'Patient declined Terms and Conditions';
  }

  if (data.declinedConsentToRecord) {
    return 'Patient declined Consent to Record Vitals';
  }

  if (data.declinedTermsAndConditions === false) {
    return 'Patient accepted Terms and Conditions';
  }

  // if a user gets to the point where they click 'never mind' after declining consent to record,
  // they have already accepted the terms and conditions, so that decision is reflected in the scoring
  if (data.declinedConsentToRecord === false) {
    return 'Patient accepted Terms and Conditions and Consent to Record Vitals';
  }

  if (data.noValidMeasurements || (data.vitals && data.vitals.HR === 0)) {
    return 'Unable to measure vitals.';
  }

  return `HR ${data.vitals.HR} | IVC Algorithm v ${data.ivcVersion} | IVC App v ${data.webAppVersion}`;
}

function scoreIvcRR(data) {
  if (data.declinedTermsAndConditions) {
    return 'Patient declined Terms and Conditions';
  }

  if (data.declinedConsentToRecord) {
    return 'Patient declined Consent to Record Vitals';
  }

  if (data.declinedTermsAndConditions === false) {
    return 'Patient accepted Terms and Conditions';
  }

  // if a user gets to the point where they click 'never mind' after declining consent to record,
  // they have already accepted the terms and conditions, so that decision is reflected in the scoring
  if (data.declinedConsentToRecord === false) {
    return 'Patient accepted Terms and Conditions and Consent to Record Vitals';
  }

  if (data.noValidMeasurements || (data.vitals && data.vitals.HR === 0)) {
    return 'Unable to measure vitals.';
  }

  let brVal;
  let hrVal;

  // some vital-core-rr submissions have an object with the finalVitalsMeasurementValues property
  // that contains results for BR and RR. Older submissions do not have this property
  if (data.finalVitalsMeasurementValues) {
    brVal = data.finalVitalsMeasurementValues.BR;
    hrVal = data.finalVitalsMeasurementValues.HR;
  } else {
    brVal = data.vitals.BR;
    hrVal = data.vitals.HR || 0;
  }

  if (brVal <= 0) {
    brVal = 'Unable to measure';
  } else if (brVal < 8 || brVal > 30) {
    brVal = 'Out of range';
  }

  if (hrVal <= 0) {
    hrVal = 'Unable to measure';
  } else if (hrVal < 40 || hrVal > 120) {
    hrVal = 'Out of range';
  }

  return `RR: ${brVal} | PR: ${hrVal} | IVC Algorithm v ${data.ivcVersion} | IVC App v ${data.webAppVersion}`;
}

function scoreVitalCoreBenchHr(data) {
  const {
    timestamp,
    userId,
    vitals,
    sex,
    ethnicity,
    race,
    age,
    feet,
    inches,
    weight,
    fitzpatrickScale,
    facialHair,
    facialTattoos,
    glasses,
    makeup,
    makeupAmount,
    noValidMeasurements,
  } = data;

  if (noValidMeasurements) {
    return {
      detail: data,
      string: 'Unable to measure vitals.',
    };
  }

  const { HR, HR2 } = vitals;
  const HRDisp = (HR === 0) ? 'Unable to measure vitals' : HR;
  const makeupResult = makeup ? `${makeup} | Makeup Amount: ${makeupAmount}` : makeup;

  const strings = [
    `User: ${userId}`,
    `Timestamp: ${timestamp}`,
    `Sex: ${sex}`,
    `Ethnicity: ${ethnicity}`,
    `Race: ${race}`,
    `Age: ${age}`,
    `Height: ${feet}' ${inches}"`,
    `Weight: ${weight}`,
    `Fitzpatrick Scale: ${fitzpatrickScale}`,
    `Facial Hair: ${facialHair}`,
    `Facial Tattoos: ${facialTattoos}`,
    `Glasses: ${glasses}`,
    `Makeup: ${makeupResult}`,
    `IVC HR: ${HRDisp}`,
    `Massimo HR: ${HR2}`,
  ];

  return strings.join(' | ');
}

function scoreIvcPrClinicalStudy(data) {
  const { timestamp, userId, vitals } = data;
  const HRDisp = (vitals.HR === 0) ? 'Unable to measure vitals' : vitals.HR;
  const imgUrl = `${config.API_URL}/users/${userId}/vital_images/${timestamp}/${timestamp}.jpeg`;
  return `User: ${userId} | Timestamp: ${timestamp} | HR ${HRDisp} | Image: ${imgUrl}`;
}

function scoreIvcRRClinicalStudy(data) {
  const { timestamp, userId, vitals } = data;

  let brVal = vitals.BR;

  if (vitals.BR <= 0) {
    brVal = 'Unable to measure';
  } else if (vitals.BR < 8 || vitals.BR > 30) {
    brVal = 'Out of range';
  }

  const imgUrl = `${config.API_URL}/users/${userId}/vital_images/${timestamp}/${timestamp}.jpeg`;
  return `User: ${userId} | Timestamp: ${timestamp} | RR: ${brVal} | Image: ${imgUrl}`;
}

const scoreFuncs = {
  VITAL_CORE: (pro) => {
    return commonScore(pro, scoreIvc);
  },
  VITAL_CORE_BENCH_HR: (pro) => {
    return commonScore(pro, scoreVitalCoreBenchHr);
  },
  IVC_PR_CLINICAL_STUDY: (pro) => {
    return commonScore(pro, scoreIvcPrClinicalStudy);
  },
  VITAL_CORE_RR: (pro) => {
    return commonScore(pro, scoreIvcRR);
  },
  IVC_RR_CLINICAL_STUDY: (pro) => {
    return commonScore(pro, scoreIvcRRClinicalStudy);
  },
};

export default scoreFuncs;
