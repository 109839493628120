import React from 'react';
import PropTypes from 'prop-types';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { GridRows } from '@vx/grid';
import { Group } from '@vx/group';
import { Bar, LinePath } from '@vx/shape';
import { MarkerCircle } from '@vx/marker';
import { scaleLinear, scalePoint } from '@vx/scale';
import { useTooltip, defaultStyles, Tooltip } from '@vx/tooltip';
import { localPoint } from '@vx/event';

import { colors } from '../themes/mindset/styles';

// data accessors
const getX = d => d.date;
const getY = d => d.value;

const PainLineChart = (props) => {
  const {
    color,
    data: rawData,
    svgMargins,
    chartHeight,
    chartWidth,
    axisTopDomain,
    lineChartMargins,
    allHistoricalMeasurements,
  } = props;

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip();

  let tooltipTimeout;
  const data = [];

  Object.entries(rawData).forEach(([date, intensity]) => {
    if (intensity !== null) {
      data.push({
        date,
        value: intensity,
      });
    }
  });

  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 90,
    backgroundColor: 'rgba(0,0,0,0.9)',
    color: 'white',
    textAlign: 'start',
  };

  let xScale;
  if (allHistoricalMeasurements) {
    xScale = scalePoint({
      domain: axisTopDomain,
    });
  } else {
    xScale = scaleLinear({
      domain: axisTopDomain,
    });
  }

  const determinedMinYScale = 0;
  const determinedMaxYScale = 10;
  const yScale = scaleLinear({
    domain: [determinedMinYScale, determinedMaxYScale],
    nice: true,
  });

  xScale.range([0, chartWidth]);
  yScale.range([chartHeight, 0]);

  const chartColor = color === 'red' ? colors.iconRed : colors.iconBlue;

  // to prevent duplicate dots for data sets with a single value, do not included markerEnd
  let markerEnd;
  if (data.length > 1) markerEnd = `url(#marker-circle-${chartColor})`;

  return (
    <div style={{ position: 'relative' }}>
      <div>Pain Scale</div>
      <svg
        width={chartWidth + svgMargins.left + svgMargins.right}
        height={chartHeight + lineChartMargins.bottom + lineChartMargins.top}
      >
        <MarkerCircle
          id={`marker-circle-${chartColor}`}
          fill={chartColor}
          size={1}
          refX={2}
        />
        <AxisLeft
          left={svgMargins.left}
          top={lineChartMargins.top}
          scale={yScale}
          tickLabelProps={() => ({
            fontSize: 11,
            textAnchor: 'end',
            transform: 'translate(-5, 3)',
          })}
        />
        <Group top={lineChartMargins.top} left={svgMargins.left}>
          <GridRows
            scale={yScale}
            height={chartHeight}
            width={chartWidth}
          />
          <LinePath
            data={data}
            x={d => xScale(getX(d))}
            y={d => yScale(getY(d))}
            stroke={chartColor}
            strokeWidth={2}
            strokeOpacity={1}
            shapeRendering="geometricPrecision"
            markerStart={`url(#marker-circle-${chartColor})`}
            markerMid={`url(#marker-circle-${chartColor})`}
            markerEnd={markerEnd}
          />
          <Bar
            x={0}
            y={0}
            width={chartWidth}
            height={chartHeight}
            fill="transparent"
            onMouseLeave={() => {
              tooltipTimeout = window.setTimeout(() => {
                hideTooltip();
              }, 300);
            }}
            onMouseMove={(event) => {
              if (tooltipTimeout) clearTimeout(tooltipTimeout);
              const { x } = localPoint(event);
              let datum;

              if (allHistoricalMeasurements) {
                const normalizedX = x - svgMargins.left;
                const dateIndex = Math.round(normalizedX / xScale.step());
                const date = axisTopDomain[dateIndex];
                datum = data.find(datum => datum.date === date);
              } else {
                const normalizedX = x - svgMargins.left;
                const date = xScale.invert(normalizedX);
                const roundedDate = Math.round(date);
                datum = data.find(datum => datum.date === roundedDate.toString());
              }

              if (datum && datum.value) {
                const tooltipLeft = xScale(getX(datum)) + svgMargins.left;
                const tooltipTop = yScale(getY(datum)) + lineChartMargins.top;

                showTooltip({
                  tooltipData: datum,
                  tooltipTop,
                  tooltipLeft,
                });
              }
            }}
          />
        </Group>
        <AxisBottom
          left={svgMargins.left}
          top={chartHeight + lineChartMargins.top}
          scale={xScale}
          tickLabelProps={() => ({
            fontSize: 11,
            textAnchor: 'middle',
            y: 17,
          })}
        />
        {tooltipOpen && tooltipData && (
          <circle
            cx={tooltipLeft}
            cy={tooltipTop}
            r={4}
            fill={chartColor}
            stroke="white"
            strokeWidth={2}
            style={{ pointerEvents: 'none' }}
          />
        )}
      </svg>
      {tooltipOpen && tooltipData && (
        <Tooltip
          key={Math.random()} // update tooltip bounds each render
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <>
            <div>{`Intensity: ${tooltipData.value}`}</div>
            <div>{`Date: ${tooltipData.date}`}</div>
          </>
        </Tooltip>
      )}
    </div>
  );
};

PainLineChart.defaultProps = {
  allHistoricalMeasurements: false,
};

PainLineChart.propTypes = {
  axisTopDomain: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  svgMargins: PropTypes.object.isRequired,
  chartHeight: PropTypes.number.isRequired,
  chartWidth: PropTypes.number.isRequired,
  lineChartMargins: PropTypes.object.isRequired,
  allHistoricalMeasurements: PropTypes.bool,
};

export default PainLineChart;
