import { LOGOUT, LOGIN_START, LOGIN_END, LOGIN_ERROR, UPDATE_USER_MESSAGE } from '../actions/user';

const initialState = {
  loggedIn: false,
  isFetching: false,
  clinics: [],
  userMessage: '',
};

function user(state = initialState, action) {
  switch (action.type) {
    case LOGIN_START:
      return { ...state, isFetching: true, logoutMessage: '' };
    case LOGIN_END:
      return {
 ...state, loggedIn: true, isFetching: false, ...action.payload, logoutMessage: '' 
};
    case LOGIN_ERROR:
    case LOGOUT:
      return { ...initialState, userMessage: action.payload };
    case UPDATE_USER_MESSAGE:
      return { ...state, userMessage: action.payload };
    default:
      return state;
  }
}

export default user;
