import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import { capitalize, get, noop } from 'lodash';

import { apiFetch } from '../lib/fetch';
import { login } from '../actions/user';
import { colors } from '../lib/styles';
import brand from '../lib/brand';
import { isPasswordValid } from '../lib/validators';

const baseStyles = {
  bottomText: {
    color: colors.highlightMedium,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  btnLabel: {
    fontSize: '18px',
    lineHeight: '2.9',
  },
  btnStyle: {
    height: '50px',
    fontSize: '1rem',
    margin: '10px auto',
    paddingTop: '5px',
    width: '100%',
  },
  container: {
    backgroundAttachment: 'scroll',
    background: colors.primaryColor,
    backgroundImage: `url("/img/${brand.name}/loginBackground.png")`,
    backgroundRepeat: 'repeat',
    minHeight: '100%',
    boxSizing: 'border-box',
    paddingBottom: 50,
  },
  headerText: {
    color: colors.loginText,
    margin: '0 auto',
    paddingTop: '60px',
    paddingBottom: '25px',
    textAlign: 'center',
    width: '500px',
  },
  header: {
    margin: 0,
    paddingBottom: '10px',
  },
  headerImg: {
    height: '180px',
    paddingBottom: '30px',
  },
  isStaging: {
    color: colors.errorOrange,
    fontSize: '2rem',
    fontWeight: 'bold',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 40,
    textAlign: 'center',
  },
  loginBox: {
    background: colors.white,
    margin: '0 auto 0',
    textAlign: 'center',
    width: '500px',
  },
  errorText: {
    color: colors.secondaryAccent,
  },
  msgText: {
    color: colors.darkAccent,
  },
  messageContainer: {
    minHeight: 18,
    paddingTop: 18,
    paddingLeft: 18,
    paddingRight: 18,
  },
  form: {
    margin: 'auto',
    padding: '35px 50px 50px',
  },
  subHeader: {
    color: colors.loginText,
    fontSize: '14px',
    fontWeight: 100,
    padding: '3px',
  },
  warningBanner: {
    background: colors.highlightMedium,
    color: colors.darkAccent,
    fontSize: '1rem',
    padding: '15px',
    borderRadius: '10px',
    width: '90%',
    margin: '10px auto',
    textAlign: 'center',
  },
  warningRegular: {
    paddingBottom: '10px',
  },
};

const inputStyles = {
  root: {
    color: '#edeef0',
    background: '#207bcc',
    margin: '10px auto',
  },
  input: {
    background: colors.primaryColor,
    color: colors.highlightBright,
    padding: '27px 12px',
    '&::placeholder': {
      color: colors.white,
      fontSize: '18px',
      margin: '5px 15px',
      opacity: 1,
    },
  },
};
const LoginTextField = withStyles(inputStyles)((props) => {
  return (
    <FilledInput
      disableUnderline
      fullWidth={true}
      variant="filled"
      onKeyDown={props.onKeyDown}
      {...props}
    />
  );
});

const messageTemplates = {
  email: 'Thank you for confirming your email you may now login.',
};

const defaultErrors = {
  response: '',
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export class Login extends Component {
  constructor(props) {
    super(props);

    const isReset = get(props, 'location.query.reset_id', false);
    const msgType = get(props, 'location.query.msg', '');

    const isStaging = document.location.host === 'provider-staging.mindsetmedical.com';

    this.state = {
      errors: defaultErrors,
      msg: messageTemplates[msgType] || '',
      email: '',
      password: '',
      new_password: '',
      verify_password: '',
      currentAction: isReset ? 'RESET' : 'LOGIN',
      isStaging,
      additionalAuthLockoutMessage: '',
      showAuthLockoutMessage: false,
      sendingMFA: false,
      mfaToken: '',
      authedUser: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleMFASubmit = this.handleMFASubmit.bind(this);
    this.handleNewMFACode = this.handleNewMFACode.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleResetPasswordValidatedUser = this.handleResetPasswordValidatedUser.bind(this);
    this.handleUpdateEmail = this.handleChange.bind(this, 'email');
    this.handleUpdatePassword = this.handleChange.bind(this, 'password');
    this.handleUpdateMFAToken = this.handleChange.bind(this, 'mfaToken');
    this.handleUpdateNewPassword = this.handleChange.bind(this, 'new_password');
    this.handleUpdateVerifyPassword = this.handleChange.bind(this, 'verify_password');
    this.showForgotPassword = this.changePageAction.bind(this, 'FORGOT');
    this.showLogin = this.changePageAction.bind(this, 'LOGIN');
    this.handleKeyEnter = this.handleKeyEnter.bind(this);
  }
  componentWillMount() {
    document.title = brand.title;
    const favicon = document.getElementById('favicon');
    favicon.href = `/img/${brand.name}/favicon.ico`;

    const { reset_id } = this.props.location.query;
    if (reset_id) {
      apiFetch(`/reset/${reset_id}/validate`, { method: 'GET' })
        .catch((e) => {
          console.log('error', e);
          this.setState({
            email: '',
            errors: {
              ...this.state.errors,
              response: 'Error Validating Reset Id',
            },
          });
        });
    }

  }
  handleChange(field, evt) {
    this.setState({ [field]: evt.target.value });
  }
  handleLogin() {
    const { email, password } = this.state;
    this.setState({
      errors: defaultErrors,
      msg: '',
      password: '',
      additionalAuthLockoutMessage: '',
      showAuthLockoutMessage: false,
    });
    this.props.login(email, password)
      .then((res) => {
        this.setState({ authedUser: res });
        if (res.roles && res.roles.includes('NEEDS_MFA')) {
          return this.setState({
            currentAction: 'NEEDS_MFA',
          });
        }
        if (res.expired) {
          return this.setState({
            currentAction: 'EXPIRED',
          });
        }
        this.props.router.push('/clinic-select');
      })
      .catch((e) => {
        if (e.status === 401 && 'failedLoginAttemptMessage' in e.content) {
          this.setState({
            showAuthLockoutMessage: true,
            additionalAuthLockoutMessage: e.content.failedLoginAttemptMessage,
          });
          return;
        }
        console.log('CURRENT STATE', this.state)
        this.setState({
          errors: {
            ...this.state.errors,
            response: 'Invalid Email or Password'
          }
        });
      });
  }
  handleMFASubmit() {
    this.setState({
      errors: defaultErrors,
      msg: '',
      mfaToken: '',
      sendingMFA: true,
    });

    apiFetch('/auth/mfa', {
      body: {
        code: this.state.mfaToken,
        user_identifier: this.state.email
      },
      method: 'POST',
    })
    .then((res) => {
      if (this.state.authedUser && this.state.authedUser.expired) {
        return this.setState({
          currentAction: 'EXPIRED',
        });
      }
      document.location.href = '/clinic-select';
    })
    .catch((e) => {
      console.log('error', e);
      this.setState({
        errors: {
          ...this.state.errors,
          response: 'Invalid PIN'
        },
        sendingMFA: false,
        mfaToken: '',
      });

      if (e.status === 401 && 'failedLoginAttemptMessage' in e.content) {
        this.setState({
          showAuthLockoutMessage: true,
          additionalAuthLockoutMessage: e.content.failedLoginAttemptMessage,
        });
        return;
      }
    });
  }
  async handleNewMFACode () {
    apiFetch(`/auth/${this.state.authedUser.id}/resend_mfa`, { method: 'POST' }).then(() => {
      this.setState({
        errors: defaultErrors,
        msg: 'New code sent',
        sendingMFA: false,
        mfaToken: '',
        newMFAsent: true,
      })}
    ).catch(() => {
      this.setState({
        errors: {
          ...this.state.errors,
          response: 'Error sending new code'
        },
        msg: '',
        sendingMFA: false,
        mfaToken: '',
      });
    });
  };
  handleResetPassword() {
    const { password, verify_password } = this.state;
    const resetId = this.props.location.query.reset_id;
    const resetPayload = {
      password,
      verify_password,
    };

    const requestOptions = {
      body: resetPayload,
      method: 'PUT',
    };

    apiFetch(`/reset/${resetId}`, requestOptions)
      .then((res) => {
        return this.setState({
          errors: defaultErrors,
          verify_password: '',
          password: '',
          currentAction: 'LOGIN',
          msg: 'Password reset successfully, you may now login.',
        });
      })
      .catch((error) => {
        let msg = 'Error updating password';
        if (error.content && error.content.message) {
          const { content: { message } } = error;
          msg = message;
        } else if (error.message) {
          msg = error.message;
        }
        const formattedError = capitalize(msg.split(': ')[1].replace('_', ' ').replace(/"/g, ''));
        this.setState({
          email: '',
          errors: {
            ...this.state.errors,
            response: formattedError,
          }
        });
      });
  }

  handleResetPasswordValidatedUser() {
    const { password, new_password, verify_password } = this.state;
    const err = isPasswordValid(password, new_password, verify_password);
    if (Object.keys(err).length > 0) {
      return this.setState({
        errors: err,
      })
    }
    const { user } = this.props;

    const requestOptions = {
      body: {
        old_password: password,
        new_password,
        verify_password,
      },
      method: 'POST',
    };
    apiFetch(`/users/${user.id}/reset`, requestOptions)
      .then(() => {
        document.location.href = '/clinic-select';
      })
      .catch((error) => {
        let msg = 'Error updating password';

        if (error.content && error.content.message) {
          const { content: { message } } = error;
          msg = message;
        } else if (error.message) {
          msg = error.message;
        }
        const formattedError = capitalize(msg.split(': ')[1].replace('_', ' ').replace(/"/g, ''));
        return this.setState({
          errors: {
            ...this.state.errors,
            response: formattedError,
          }
        });
      });
  };
  handleKeyEnter = function (e) {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      this.handleLogin();
    }
  };

  handleForgotPassword() {
    const resetPayload = {
      reset_type: 'PROVIDER',
      email: this.state.email,
      source_id: brand.source_id,
    };
    const requestOptions = {
      body: resetPayload,
      method: 'POST',
    };

    return apiFetch('/reset', requestOptions)
      .then((res) => {
        return this.setState({
          email: '',
          errors: defaultErrors,
          currentAction: 'LOGIN',
          msg: 'If an email is associated with that account, a reset email will be sent to your inbox',
        });
      })
      .catch(() => {
        this.setState({
          email: '',
          errors: {
            ...this.state.errors,
            response: 'Error Sending Password Reset Email',
          },
        });
      });
  }
  changePageAction(view) {
    this.setState({
      currentAction: view,
      errors: defaultErrors,
    });
  }
  render() {
    const { showAuthLockoutMessage, additionalAuthLockoutMessage } = this.state;
    let form;
    if (this.state.currentAction === 'LOGIN') {
      form = [
        <LoginTextField
          autoFocus
          key="email"
          placeholder="Email"
          onChange={this.handleUpdateEmail}
          value={this.state.email}
        />,
        <LoginTextField
          key="password"
          placeholder="Password"
          onChange={this.handleUpdatePassword}
          type="password"
          onKeyDown={this.handleKeyEnter}
          value={this.state.password}
        />,
        <Button
          key="signIn"
          color="secondary"
          style={baseStyles.btnStyle}
          onClick={this.handleLogin}
          variant="contained"
        >Sign In
        </Button>,
        <p key="forgot" onClick={this.showForgotPassword} style={baseStyles.bottomText}>Forgot your password?</p>,
      ];
    }

    if (this.state.currentAction === 'EXPIRED') {
      form = [
        <div style={baseStyles.warningBanner}>
          <div style={baseStyles.warningRegular}>
            Your password has expired.
          </div>
          <div style={baseStyles.warningRegular}>
            Please create a new password to continue.
          </div>
        </div>,
        <FormControl fullWidth error={this.state.errors.oldPassword} variant="standard">
          <LoginTextField
            autoFocus
            key="password"
            placeholder="Old Password"
            onChange={this.handleUpdatePassword}
            type="password"
            value={this.state.password}
            error={this.state.errors.oldPassword}
          />
          <FormHelperText id="password-text">{this.state.errors.oldPassword}</FormHelperText>
        </FormControl>,
        <FormControl fullWidth error={this.state.errors.newPassword} variant="standard">
          <LoginTextField
            autoFocus
            key="password"
            placeholder="New Password"
            onChange={this.handleUpdateNewPassword}
            type="password"
            value={this.state.new_password}
            error={this.state.errors.newPassword}
          />
          <FormHelperText id="component-error-text">{this.state.errors.newPassword}</FormHelperText>
        </FormControl>,
        <FormControl fullWidth error={this.state.errors.confirmPassword} variant="standard">
          <LoginTextField
            autoFocus
            key="verify_password"
            placeholder="Confirm Password"
            onChange={this.handleUpdateVerifyPassword}
            type="password"
            value={this.state.verify_password}
            error={this.state.errors.confirmPassword}
          />
          <FormHelperText id="component-error-text">{this.state.errors.confirmPassword}</FormHelperText>
        </FormControl>,
        <Button
          color="secondary"
          key="reset"
          style={baseStyles.btnStyle}
          onClick={this.handleResetPasswordValidatedUser}
          variant="contained"
        >Update Password
        </Button>,
        <p key="login" onClick={this.showLogin} style={baseStyles.bottomText}>Cancel</p>
      ];
    }
    if (this.state.currentAction === 'RESET') {
      form = [
        <LoginTextField
          autoFocus
          key="newPassword"
          placeholder="New Password"
          onChange={this.handleUpdatePassword}
          type="password"
          value={this.state.password}
          error={this.state.errors.newPassword}
        />,
        <LoginTextField
          key="verify"
          placeholder="Verify Password"
          onChange={this.handleUpdateVerifyPassword}
          type="password"
          value={this.state.verify_password}
          error={this.state.errors.confirmPassword}
        />,
        <Button
          color="secondary"
          key="reset"
          style={baseStyles.btnStyle}
          onClick={this.handleResetPassword}
          variant="contained"
        >Reset Password
        </Button>,
      ];
    }

    if (this.state.currentAction === 'NEEDS_MFA') {
      form = [
        <div style={baseStyles.msgText}>
          A One Time PIN Code has been sent to your email address.  
        </div>,
        <LoginTextField
          key="verify"
          placeholder="One Time PIN Code"
          onChange={this.handleUpdateMFAToken}
          type="text"
          value={this.state.mfaToken}
        />,
        <Button
          color="secondary"
          key="reset"
          style={baseStyles.btnStyle}
          onClick={this.handleMFASubmit}
          variant="contained"
          disabled={this.state.sendingMFA || !this.state.mfaToken}
        >Submit
        </Button>,
        <Button
          key="resendMFA"
          style={{ ...baseStyles.btnStyle, display: this.state.newMFAsent ? 'none' : 'block' }}
          onClick={this.handleNewMFACode}
          variant="contained"
        >Request New Code
        </Button>,
      ];
    }

    if (this.state.currentAction === 'FORGOT') {
      form = [
        <LoginTextField
          autoFocus
          key="email"
          placeholder="Email Address"
          onChange={this.handleUpdateEmail}
          value={this.state.email}
        />,
        <Button
          color="secondary"
          key="reset"
          placeholder="Reset Password"
          style={baseStyles.btnStyle}
          onClick={this.handleForgotPassword}
          variant="contained"
        >Reset Password
        </Button>,
        <p key="login" onClick={this.showLogin} style={baseStyles.bottomText}>Cancel</p>,
      ];
    }

    return (
      <div style={baseStyles.container} >
        {this.state.isStaging ? (
          <div style={baseStyles.isStaging}>
            This environment is for training and development purposes only. DO NOT ENTER PHI.
          </div>
        ) : null}
        <div style={baseStyles.headerText}>
          <img
            alt="Logo"
            src={`img/${brand.name}/${brand.loginLogo}`}
            style={baseStyles.headerImg}
          />
          <h2 style={baseStyles.header}>Welcome to {brand.title}</h2>
          <div style={baseStyles.subHeader}>Get it. Use it. Prove it.</div>
        </div>
        <div style={baseStyles.loginBox}>
          <div style={baseStyles.messageContainer}>
            {this.state.errors.response && <div style={baseStyles.errorText}>{this.state.errors.response}</div>}
            {showAuthLockoutMessage ? (
              <div style={baseStyles.errorText}>
                {`You have entered invalid credentials too many times. ${additionalAuthLockoutMessage ? additionalAuthLockoutMessage + '. ' : ''}Please contact your administrator if you need login assistance.`}
              </div>
            ) : null}
            {this.state.msg || this.props.user.logoutMessage ? (
              <div style={baseStyles.msgText}>{this.state.msg || this.props.user.logoutMessage}</div>
            ) : null}
          </div>
          <div style={baseStyles.form}>
            {form}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

function mapDispatchToProps(dispatch) {
  const actionCreators = { login };

  return bindActionCreators(actionCreators, dispatch);
}

Login.defaultProps = {
  location: {},
  login: noop,
  router: {},
  user: {},
};

Login.propTypes = {
  location: PropTypes.object,
  login: PropTypes.func,
  router: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
