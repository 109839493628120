import React from 'react';
import PropTypes from 'prop-types';
import { uniqBy, take } from 'lodash';

import SocialPersonOutline from '@material-ui/icons/PersonOutline';

import { colors } from '../lib/styles';

const baseStyles = {
  iconStyles: {
    marginRight: '5px',
    color: colors.charcoal,
  },
  icon: {
    marginRight: '5px',
    verticalAlign: 'middle',
  },
  link: {
    textDecoration: 'none',
  },
  list: {
    listStyle: 'none',
    padding: 0,
    textAlign: 'left',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    color: colors.highlightDarker,
    padding: '5px',
    fontSize: '.85em',
    cursor: 'pointer',
  },
};

const RecentlyChecked = (props) => {
  const { recent } = props;
  const recentList = recent.list;
  const prunedList = uniqBy(recentList, e => e.user_id);
  const searched = prunedList.map((x) => {
    return (
      <li
        key={x.user_id}
        onClick={() => props.router.push({ pathname: '/app/patient', state: { userId: x.user_id }})}
        style={baseStyles.listItem}
      >
        <SocialPersonOutline style={baseStyles.iconStyles} />
        { x.first_name } { x.last_name }
      </li>
    );
  });
  return (
    <ul style={baseStyles.list}>
      {take(searched, 5)}
    </ul>
  );
};

RecentlyChecked.propTypes = {
  recent: PropTypes.object,
  router: PropTypes.object.isRequired,
};
export default RecentlyChecked;