import { promiseHandler } from 'cooldux';
import { find, get } from 'lodash';

import { apiFetch } from '../lib/fetch';
import { subscribeToClinic } from '../websocket';

const {
  browseProvidersEnd, browseProvidersHandler,
} = promiseHandler('browseProviders', 'clinic');

const {
  browseImagesHandler,
} = promiseHandler('browseImages', 'clinics');

const { selectClinicEnd } = promiseHandler('selectClinic', 'clinic');

export function browseProviders() {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId');
    const fetchRes = apiFetch(`/clinics/${clinicId}/providers`);
    return browseProvidersHandler(fetchRes, dispatch);
  };
}

export function browseClinicImages(clinic_id) {
  return function dispatcher(dispatch) {
    const promise = apiFetch(`/clinics/${clinic_id}/images`)
      .then((images) => {
        return { images, clinic_id };
      });
    return browseImagesHandler(promise, dispatch);
  };
}

export function selectClinic(newClinicId) {
  return async function dispatcher(dispatch, getState) {
    const state = getState();
    const { clinics } = state.user;
    const existingClinicId = get(state, 'clinic.clinicId', false);
    const localStorageClinic = window.localStorage.getItem('clinicId');
    if (!newClinicId && existingClinicId) {
      return;
    }

    if (!newClinicId && !localStorageClinic) {
      throw new Error('no clinicId in Session');
    }

    if (!newClinicId && localStorageClinic) {
      newClinicId = localStorageClinic;
    }
    newClinicId = Number(newClinicId);

    window.localStorage.setItem('clinicId', newClinicId);
    const clinic = find(clinics, { id: newClinicId });

    const appFeatures = apiFetch(`/clinics/${newClinicId}/app_features`);
    const emrInstances = apiFetch(`/clinics/${newClinicId}/emr_types`);

    const completed = await Promise.all([appFeatures, emrInstances]);
    const newClinic = {
      ...clinic,
      app_features: completed[0].map(f => f.app_feature),
      emr_instances: completed[1],
    };
    dispatch(selectClinicEnd(newClinic));
    subscribeToClinic(newClinicId);
  };
}

const initialState = {
  clinicId: null,
  data: {
    app_features: [],
  },
  providers: [],
};

function clinic(state = initialState, action) {
  switch (action.type) {
    case browseProvidersEnd.type:
      return { ...state, providers: action.payload };
    case selectClinicEnd.type:
      return { ...state, clinicId: action.payload.id, data: action.payload };
    default:
      return state;
  }
}

export default clinic;
