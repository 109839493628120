import { FETCH_FORMS_START, FETCH_FORMS_END, FETCH_FORMS_ERROR } from '../actions/forms';
import { LOGOUT } from '../actions/user';

const initialState = {
  isFetching: false,
};

function forms(state = initialState, action) {
  switch (action.type) {
    case FETCH_FORMS_START:
      return { ...state, isFetching: true };
    case FETCH_FORMS_END:
      return { ...state, ...action.payload, isFetching: false };
    case FETCH_FORMS_ERROR:
      return { ...state, isFetching: false };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default forms;
