import { promiseHandler } from 'cooldux';
import { get } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseDocumentsStart, browseDocumentsEnd, browseDocumentsError, browseDocumentsHandler } = promiseHandler('browseDocuments', 'esign');
const { addSignatureRequestEnd, addSignatureRequestHandler } = promiseHandler('addSignatureRequest', 'esign');
const {
  browseSignatureRequestsStart,
  browseSignatureRequestsEnd,
  browseSignatureRequestsError,
  browseSignatureRequestsHandler,
} = promiseHandler('browseSignatureRequests', 'esign');

export function browseDocuments() {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId');
    const fetchRes = apiFetch(`/clinics/${clinicId}/esign_templates`);
    return browseDocumentsHandler(fetchRes, dispatch);
  };
}

export function browseSignatureRequests() {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId');
    const fetchRes = apiFetch(`/clinics/${clinicId}/esign_requests`);
    return browseSignatureRequestsHandler(fetchRes, dispatch);
  };
}

export function addSignatureRequest(userId, templateId) {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId');

    const options = {
      body: {
        user_id: userId,
        esign_template_id: templateId,
        is_embedded: true,
      },
      method: 'POST',
    };
    const fetchRes = apiFetch(`/clinics/${clinicId}/esign_requests`, options);
    return addSignatureRequestHandler(fetchRes, dispatch);
  };
}


const initialState = {
  clinicId: null,
  documents: [],
  signatureRequests: [],
  isFetching: false
};

function esign(state = initialState, action) {
  switch (action.type) {
    case browseDocumentsStart.type:
    case browseSignatureRequestsStart.type:
      return { ...state, isFetching: true };
    case browseDocumentsEnd.type:
      return { ...state, documents: action.payload, isFetching: false };
    case browseSignatureRequestsEnd.type:
      return { ...state, signatureRequests: action.payload, isFetching: false };
    case browseDocumentsError:
    case browseSignatureRequestsError:
      return { ...state, isFetching: false };
    case addSignatureRequestEnd.type:
      return {
        ...state,
        signatureRequests: [...state.signatureRequests, { ...action.payload }],
      };
    default:
      return state;
  }
}

export default esign;
