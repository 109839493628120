import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';

import Loading from './loading';
import MergePersonalInfo from './merge-personal-info';
import MergeInsurance from './merge-insurance';
import MergeSymptoms from './merge-symptoms';
import MergeMedications from './merge-medications';
import MergeSurgery from './merge-surgery';
import MergeConditions from './merge-conditions';
import MergeHistory from './merge-family-history';
import MergeSocial from './merge-social';

class MergePatientData extends PureComponent {
  componentWillMount() {
    this.props.readUserEhr(this.props.emrPatient.user_id, this.props.emrPatient.merge_status);
    this.props.readInformedData(this.props.emrPatient.user_id);
  }
  render() {
    const { mergeData } = this.props;
    const pages = [
      <MergePersonalInfo {...this.props} />,
      <MergeInsurance {...this.props} />,
      <MergeSymptoms {...this.props} />,
      <MergeMedications {...this.props} />,
      <MergeConditions {...this.props} />,
      <MergeHistory {...this.props} />,
      <MergeSurgery {...this.props} />,
      <MergeSocial {...this.props} />,
    ];
    let page = pages[mergeData.page];
    if (isUndefined(page) || mergeData.isFetching || mergeData.ehrDataFetching) {
      page = <Loading />;
    }
    return page;
  }
}

MergePatientData.propTypes = {
  emrPatient: PropTypes.object,
  mergeData: PropTypes.object,
  mergePatientData: PropTypes.func,
  readUserEhr: PropTypes.func,
  readInformedData: PropTypes.func,
};

export default MergePatientData;
