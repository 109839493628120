export default (pro) => {
  const { data } = pro.pro_data;

  const {
    childsFirstName,
    childsLastName,
    childsNickname,
    birthday,
    gender,
    address,
    city,
    state,
    zipcode,
    pediatrician,
    insuranceCompany,
    guardianOneFirstName,
    guardianOneLastName,
    guardianOneEmail,
    guardianOneTelephone,
    guardianOneCell,
    guardianTwoFirstName,
    guardianTwoLastName,
    guardianTwoEmail,
    guardianTwoTelephone,
    guardianTwoCell,
    happyPlace,
    movement,
    strength,
    speech,
    meetingMilestones,
    fineMotor,
    behavior,
    socialSkills,
    feedingEating,
    grossMotor,
    otherConcerns,
    listOfConcerns,
    firstHadConcerns,
    primaryConcernsToday,
    strategiesAddressingConcerns,
    desiredSkills,
    mostChallengingBehaviors,
    eventsBeforeChallengingBehavior,
    frequentTantrums,
    frequentlyTripsOnFeet,
    walksOnToes,
    frequentlyBumpsIntoThings,
    unawareOfTouchUnlessExtreme,
    unawareOfDirtOnFaceOrHands,
    unsureOfBodyMovement,
    slumpsOrSlouches,
    difficultyLearningMotorTasks,
    inConstantMotion,
    difficultySittingStill,
    chewsOnThings,
    touchesPeopleAndObjects,
    getsInOthersSpace,
    sensitiveToStimuli,
    avoidsTouchingCertainTextures,
    avoidsTouchingCertainTexturesList,
    eatsCertainFoods,
    eatsCertainFoodsList,
    sensitiveToClothing,
    complainsAboutHairBrushing,
    resistsTeethBrushing,
    fingernailTrimmingDislike,
    noBarefootWalking,
    troubleWithSleep,
    difficultyChangingTasks,
    fearfulOnSwings,
    fearfulOnPlaygroundThings,
    behavioralDevelopmentTherapy,
    speechTherapy,
    physicalTherapy,
    occupationalTherapy,
    feedingTherapy,
    otherInterventions,
    otherInterventionsList,
  } = data;

  const s = [];

  s.push(`Child's first name: ${childsFirstName}`);
  s.push(`Child's last name: ${childsLastName}`);

  if (childsNickname) s.push(`Child's nickname: ${childsNickname}`);

  s.push(`Date of birth: ${birthday}`);
  s.push(`Gender: ${gender}`);
  s.push(`Address: ${address}`);
  s.push(`City: ${city}`);
  s.push(`State: ${state}`);
  s.push(`Zipcode: ${zipcode}`);

  if (pediatrician) s.push(`Pediatrician: ${pediatrician}`);
  if (insuranceCompany) s.push(`Insurance company: ${insuranceCompany}`);

  s.push(`Guardian 1 first name: ${guardianOneFirstName}`);
  s.push(`Guardian 1 last name: ${guardianOneLastName}`);
  s.push(`Guardian 1 email: ${guardianOneEmail}`);
  s.push(`Guardian 1 cell number: ${guardianOneCell}`);

  if (guardianOneTelephone) s.push(`Guardian 1 telephone number: ${guardianOneTelephone}`);

  if (guardianTwoFirstName && guardianTwoLastName && guardianTwoEmail && guardianTwoCell) {
    s.push(`Guardian 2 first name: ${guardianTwoFirstName}`);
    s.push(`Guardian 2 last name: ${guardianTwoLastName}`);
    s.push(`Guardian 2 email: ${guardianTwoEmail}`);
    s.push(`Guardian 2 cell number: ${guardianTwoCell}`);
  }

  if (guardianTwoTelephone) s.push(`Guardian 2 telephone number: ${guardianTwoTelephone}`);

  s.push(`Happy place: ${happyPlace}`);
  s.push(`Movement: ${movement ? 'Y' : 'N'}`);
  s.push(`Strength: ${strength ? 'Y' : 'N'}`);
  s.push(`Speech: ${speech ? 'Y' : 'N'}`);
  s.push(`Meeting milestones: ${meetingMilestones ? 'Y' : 'N'}`);
  s.push(`Fine Motor: ${fineMotor ? 'Y' : 'N'}`);
  s.push(`Behavior: ${behavior ? 'Y' : 'N'}`);
  s.push(`Social skills: ${socialSkills ? 'Y' : 'N'}`);
  s.push(`Feeding/eating: ${feedingEating ? 'Y' : 'N'}`);
  s.push(`Gross motor: ${grossMotor ? 'Y' : 'N'}`);
  s.push(`Other concerns: ${otherConcerns ? 'Y' : 'N'}`);

  if (otherConcerns && listOfConcerns) s.push(`Concerns: ${listOfConcerns}`);

  s.push(`When first has concerns: ${firstHadConcerns}`);
  s.push(`Primary concerns: ${primaryConcernsToday}`);
  s.push(`Strategies tried independently: ${strategiesAddressingConcerns}`);
  s.push(`Desired skills: ${desiredSkills}`);
  s.push(`Most challenging behaviors: ${mostChallengingBehaviors}`);
  s.push(`What happens before challenging behaviors: ${eventsBeforeChallengingBehavior}`);
  s.push(`Frequent meltdowns/tantrums: ${frequentTantrums ? 'Y' : 'N'}`);
  s.push(`Frequently trips on feet: ${frequentlyTripsOnFeet ? 'Y' : 'N'}`);
  s.push(`Walks on toes: ${walksOnToes ? 'Y' : 'N'}`);
  s.push(`Frequently bumps into things: ${frequentlyBumpsIntoThings ? 'Y' : 'N'}`);
  s.push(`Unaware of being touched without extreme force: ${unawareOfTouchUnlessExtreme ? 'Y' : 'N'}`);
  s.push(`Unaware of dirt on face or hands: ${unawareOfDirtOnFaceOrHands ? 'Y' : 'N'}`);
  s.push(`Clumsy/awkward: ${unsureOfBodyMovement ? 'Y' : 'N'}`);
  s.push(`Slumps, head in hand when sitting: ${slumpsOrSlouches ? 'Y' : 'N'}`);
  s.push(`Difficulty learning motor tasks: ${difficultyLearningMotorTasks ? 'Y' : 'N'}`);
  s.push(`Constant motion: ${inConstantMotion ? 'Y' : 'N'}`);
  s.push(`Difficulty sitting still: ${difficultySittingStill ? 'Y' : 'N'}`);
  s.push(`Chews on things: ${chewsOnThings ? 'Y' : 'N'}`);
  s.push(`Frequently touches people/objects: ${touchesPeopleAndObjects ? 'Y' : 'N'}`);
  s.push(`Frequently gets in other's space: ${getsInOthersSpace ? 'Y' : 'N'}`);
  s.push(`Overly sensitive touch, noise, smells etc.: ${sensitiveToStimuli ? 'Y' : 'N'}`);
  s.push(`Avoids touching certain textures: ${avoidsTouchingCertainTextures ? 'Y' : 'N'}`);

  if (avoidsTouchingCertainTextures && avoidsTouchingCertainTexturesList) {
    s.push(`Textures avoided: ${avoidsTouchingCertainTexturesList}`);
  }

  s.push(`Only eats certain foods: ${eatsCertainFoods ? 'Y' : 'N'}`);

  if (eatsCertainFoods && eatsCertainFoodsList) {
    s.push(`Foods eaten: ${eatsCertainFoodsList}`);
  }

  s.push(`Sensitive to clothing tags and textures: ${sensitiveToClothing ? 'Y' : 'N'}`);
  s.push(`Complains about hair brushing: ${complainsAboutHairBrushing ? 'Y' : 'N'}`);
  s.push(`Resists teeth brushing: ${resistsTeethBrushing ? 'Y' : 'N'}`);
  s.push(`Does not like to have finger nails trimmed: ${fingernailTrimmingDislike ? 'Y' : 'N'}`);
  s.push(`Refuses to walk barefoot: ${noBarefootWalking ? 'Y' : 'N'}`);
  s.push(`Trouble with sleeping: ${troubleWithSleep ? 'Y' : 'N'}`);
  s.push(`Difficulty changing tasks: ${difficultyChangingTasks ? 'Y' : 'N'}`);
  s.push(`Fearful on swings: ${fearfulOnSwings ? 'Y' : 'N'}`);
  s.push(`Fearful on playgroud structures: ${fearfulOnPlaygroundThings ? 'Y' : 'N'}`);
  s.push(`ABA: ${behavioralDevelopmentTherapy ? 'Y' : 'N'}`);
  s.push(`Speech therapy: ${speechTherapy ? 'Y' : 'N'}`);
  s.push(`Physical therapy: ${physicalTherapy ? 'Y' : 'N'}`);
  s.push(`Occupational therapy: ${occupationalTherapy ? 'Y' : 'N'}`);
  s.push(`Feeding therapy: ${feedingTherapy ? 'Y' : 'N'}`);
  s.push(`Other therapy: ${otherInterventions ? 'Y' : 'N'}`);

  if (otherInterventions && otherInterventionsList) {
    s.push(`Other therapy details: ${otherInterventionsList}`);
  }

  pro.score.score = s.join(' | ');
  return pro;
};
