import { promiseHandler } from 'cooldux';
import { get } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseEmrMessagesStart, browseEmrMessagesEnd, browseEmrMessagesError, browseEmrMessagesHandler } = promiseHandler('browseEmrMessages', 'emr');
const { readEmrMessagesStart, readEmrMessagesEnd, readEmrMessagesError, readEmrMessagesHandler } = promiseHandler('readEmrMessages', 'emr');

export function browseEmrMessages(userId) {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId');
    const fetchRes = apiFetch(`/clinics/${clinicId}/users/${userId}/redox_patient_messages`)
      .then(data => ({ userId, data }));

    return browseEmrMessagesHandler(fetchRes, dispatch);
  };
}

export function readEmrMessages(userId, emrMessageId) {
  return function dispatcher(dispatch, getState) {
    const clinicId = get(getState(), 'clinic.clinicId');
    const fetchRes = apiFetch(`/clinics/${clinicId}/users/${userId}/redox_patient_messages/${emrMessageId}`)
      .then(data => ({ userId, data }));

    return readEmrMessagesHandler(fetchRes, dispatch);
  };
}

const initialState = {
  isFetching: false,
  messages: [],
  message: {},
};

function emrMessages(state = initialState, action) {
  switch (action.type) {
    case readEmrMessagesStart.type:
      return {
        ...state,
        isFetching: true,
      };
    case readEmrMessagesEnd.type:
      return {
        ...state,
        message: action.payload.data,
        isFetching: false,
      };
    case readEmrMessagesError.type:
      return {
        ...state,
        message: {},
        isFetching: false,
      };
    case browseEmrMessagesStart.type:
      return {
        ...state,
        isFetching: true,
      };
    case browseEmrMessagesEnd.type:
      return {
        ...state,
        messages: action.payload.data,
        isFetching: false,
      };
    case browseEmrMessagesError.type:
      return {
        ...state,
        messages: [],
        isFetching: false,
      };
    default:
      return state;
  }
}

export default emrMessages;
