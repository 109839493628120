export const arrayToObject = (data) => {
  let formattedData = data;

  if (Array.isArray(data)) {
    formattedData = data.reduce((prev, current) => {
      return { ...prev, ...current };
    }, {});
  }

  return formattedData;
};
