import { isString, reject, get, forEach, map, omit, isEmpty, reduce, startsWith, uniqueId } from 'lodash';
import moment from 'moment';

export function stateFormat(medication, medsFromEmr) {
  // Reject any empty meds
  const emrMeds = reduce(reject(medsFromEmr, (med) => (med.Product.Code === '')), (data, item) => {
    const id = uniqueId('emr-med'); // No unique id is returned by redox for an entry
    data[id] = {
      id,
      name: get(item, 'Product.Name'),
      dosage: `${get(item, 'Dose.Quantity') || ''} ${get(item, 'Dose.Units') || ''}`,
      prescribing_physician: '',
      start_date: moment(get(item, 'StartDate')).format('MM-DD-YYYY') || null,
      frequency: `${get(item, 'Rate.Quantity') || ''} ${get(item, 'Rate.Units') || ''}`,
      category: null,
    };
    return data;
  }, {});
  const mindset = {};
  if (medication[0]) {
    forEach(medication, (item, key) => {
      mindset[item.id] = {
        id: item.id,
        name: item.name,
        dosage: item.dosage,
        prescribing_physician: item.prescribing_physician,
        start_date: moment(item.start_date).format('MM-DD-YYYY'),
        frequency: item.frequency,
        category: item.category,
      };
    });
  }
  return {
    combined: mindset,
    mindset,
    emrMeds,
  };
}

export function mergeFormat(combined) {
  if (isEmpty(combined) || isString(combined)) {
    return {};
  }
  return {
    medications: map(combined, (item) => {
      item.start_date = new Date(item.start_date);
      if(startsWith(item.id, 'emr')) {
        return omit(item, ['index', 'id', 'checked']); // Remove temporary emr ids
      }
      return omit(item, ['index']);
    }),
  };
}
