import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../lib/styles';
const StyledCheckbox = withStyles({
  root: {
    '&$disabled': {
      color: '#DDDDDA',
      cursor: 'not-allowed',
    },
  },
  checked: {
    '&$checked': {
      color: colors.checkboxChecked,
    },
  },
  disabled: {
    '&$disabled': {
      color: '#DDDDDA',
      cursor: 'not-allowed',
    },
  },
})(Checkbox);

export default StyledCheckbox;
