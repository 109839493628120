/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { orderBy as sort } from 'lodash';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import memo from 'memoize-one';
import Table from '../base/table';
import TableBody from '../base/table-body';
import TableFooter from '../base/table-footer';
import TablePagination from '../base/table-pagination';
import TableRow from '../base/table-row';
import SortedTableHead from './sorted-table-head';
import SortableRow from './sortable-table-row';

const StyledTablePagination = withStyles({
  toolbar: {
    paddingLeft: 5,
  },
})(TablePagination);

const styles = {};

class SortableTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: props.initialOrder || 'asc',
      orderBy: props.initialOrderBy || 'name',
      page: 0,
      rowsPerPage: 5,
    };
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'asc';

    if (this.state.orderBy === property && this.state.order === 'asc') {
      order = 'desc';
    }

    this.setState({ order, orderBy });
  };

  memoSort = memo((rows, order, orderBy) => {
    return sort(rows, [orderBy, 'id'], [order, 'desc']);
  })

  render() {
    const { columnData, emptyMessage, rows, darkMode, includeCheckbox, onChangeCheckbox, allChecked } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;

    const sortedRows = this.memoSort(rows, order, orderBy);
    const displayedRows = sortedRows.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);
    return (
      <div className={this.props.classes.tableContainer}>
        <Paper style={{ overflow: 'scroll'}}>
          <Table>
            <SortedTableHead
              includeCheckbox={includeCheckbox}
              columnData={columnData}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              className={this.props.classes.head}
              darkMode={darkMode}
              onChangeCheckbox={onChangeCheckbox}
              allChecked={allChecked}
            />
            <TableBody>
              {displayedRows.map((item) => {
                if(!item) return null;
                return (
                  <SortableRow
                    columnData={columnData}
                    key={item.id || item.image_id}
                    item={item}
                  />
                );
              })}
            </TableBody>
            <TableFooter>
              {!!displayedRows.length &&
                <TableRow className={this.props.classes.tableRow}>
                  <StyledTablePagination
                    colSpan={6}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 15]}
                  />
                </TableRow>
              }
            </TableFooter>
          </Table>
          {!displayedRows.length &&
            <div className={this.props.classes.tableMessage}>
              {emptyMessage}
            </div>
          }
        </Paper>
      </div>
    )
  }
}



SortableTable.defaultProps = {
  columnData: [],
  emptyMessage: 'No data to display',
  rows: [],
}
export default withStyles(styles)(SortableTable);
