import { isString, get, forEach, map, omit, isEmpty, reduce, reject, uniqueId } from 'lodash';

export function stateFormat(conditions, emrConditions, relation = false) {
  const emrCond = reduce(reject(emrConditions, (cond) => cond.Name === ''), (all, item) => {
    if (relation) {
      forEach(item.Problems, (problem) => {
        const id = uniqueId('emr-con'); // No unique id is returned by redox for an entry
        all[id] = {
          id,
          name: get(item, 'Name') || 'General problem',
          relationship: get(item, 'Relation.Name'),
          snomed_code: get(problem, 'Code', null)
        };
      });
      return all;
    }
    const id = uniqueId('emr-con'); // No unique id is returned by redox for an entry
    all[id] = {
      id,
      snomed_code: get(item, 'Code', null),
      name: get(item, 'Name') || '',
      relationship: 'SELF',
    };
    return all;
  }, {});

  const mindset = {};

  if (conditions[0]) {
    forEach(conditions, (item, key) => {
      mindset[`mindset${key}`] = {
        conditions_hx_id: item.id,
        conditions_id: item.conditions_id,
        name: item.name,
        relationship: item.relationship,
      };
    });
  }
  return {
    combined: mindset,
    mindset,
    emrCond,
  };
}

export function mergeFormat(combined) {
  if (isEmpty(combined) || isString(combined)) {
    return {};
  }
  return {
    conditions: map(combined, (item) => {
      return omit(item, ['id', 'checked']);
    }),
  };
}
