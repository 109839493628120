/* eslint-disable max-len */
export default (pro) => {
  const { data } = pro.pro_data;

  const {
    hipaaAcknowledgement1,
    hipaaGuardian1,
    hipaaRelationship1,
    hipaaAcknowledgement2,
    hipaaGuardian2,
    hipaaRelationship2,
    consentGuardian1,
    consentAcknowledgement1,
    consentGuardian2,
    consentRelationship2,
    consentAcknowledgement2,
    consentInitial1,
    consentInitial2,
    consentInitial3,
    consentInitial4,
    consentInitial5,
    consentAcknowledgement3,
    consentAcknowledgement4,
    consentAcknowledgement5,
    consentAcknowledgement6,
    consentGuardian3,
    consentChild,
    consentRelationship3,
    consentAcknowledgement7,
    consentAcknowledgement8,
    mediaGuardian1,
    mediaInitial1,
    mediaRelationship1,
    mediaAcknowledgement1,
    mediaPermission,
    mediaNoPermission,
    mediaDataCollection,
    mediaTraining,
    mediaPresentations,
    illnessGuardian1,
    illnessChild1,
    illnessDOB,
    illnessRelationship1,
    illnessAcknowledgement1,
    rightsAcknowledgement1,
    rightsGuardian1,
    rightsGuardian2,
    finalGuardian1,
    finalRelationship1,
    finalInitial1,
    finalInitial2,
    finalInitial3,
    finalAcknowledgement1,
    finalAcknowledgement2,
    finalAcknowledgement3,
  } = data;

  const s = [];

  s.push(`HIPAA Notices of Privacy Practices - Authorization, Agreement and Signature: Acknowledgement - ${hipaaAcknowledgement1 ? 'Y' : 'N'}, Parent/Guardian - ${hipaaGuardian1}, Relationship to Client - ${hipaaRelationship1}`);
  s.push(`Patient Consent for Use and Disclosure of Protected Health Information: Acknowledgement - ${hipaaAcknowledgement2 ? 'Y' : 'N'}, Parent/Guardian - ${hipaaGuardian2}, Relationship to Client - ${hipaaRelationship2}`);
  s.push(`Informed Consent - Assessment and Services: Guardian Name Insert - ${consentGuardian1}, Acknowledgement - ${consentAcknowledgement1 ? 'Y' : 'N'}, Parent/Guardian - ${consentGuardian2}, Relationship to Client - ${consentRelationship2}`);
  s.push(`Authorization for Treatment: Acknowledgement - ${consentAcknowledgement2 ? 'Y' : 'N'}, Initial - ${consentInitial1}`);
  s.push(`Acknowledge or Notice of Privacy Practices: Acknowledge right to privacy - ${consentAcknowledgement3 ? 'Y' : 'N'}, Initial - ${consentInitial2}, Acknowledge right to request restrictions - ${consentAcknowledgement4 ? 'Y' : 'N'}, Initial - ${consentInitial3}, Acknowledge right to revoke consent - ${consentAcknowledgement5 ? 'Y' : 'N'}, Initial - ${consentInitial4}, Acknowledge Privacy Policy availability - ${consentAcknowledgement6 ? 'Y' : 'N'}, Initial - ${consentInitial5}`);
  s.push(`Waiver of Liability: Acknowledge agreement of Waiver and Release of Liability - ${consentAcknowledgement7 ? 'Y' : 'N'}, Acknowledge agreement to hold BloomKidz harmless - ${consentAcknowledgement8 ? 'Y' : 'N'}, Child's Name - ${consentChild}, Parent/Guardian - ${consentGuardian3}, Relationship to Client - ${consentRelationship3}`);
  s.push(`BloomKidz Media Release: Acknowledge photo requirement - ${mediaAcknowledgement1 ? 'Y' : 'N'}, Initial - ${mediaInitial1}, Permission to use videotaped material - ${mediaPermission ? 'Y' : 'N'}, Permission for data collection - ${mediaDataCollection ? 'Y' : 'N'}, Permission for training - ${mediaTraining ? 'Y' : 'N'}, Permission for presentations - ${mediaPresentations ? 'Y' : 'N'}, DOES NOT give permission to use videotaped materials - ${mediaNoPermission ? 'Y' : 'N'}, Parent/Guardian - ${mediaGuardian1}, Relationship to Client - ${mediaRelationship1}`);
  s.push(`BloomKidz Illness Policy: Child's Name - ${illnessChild1}, Date of Birth - ${illnessDOB}, COVID Policy acknowledgement - ${illnessAcknowledgement1 ? 'Y' : 'N'}, Parent/Guardian - ${illnessGuardian1}, Relationship to Client - ${illnessRelationship1}`);
  s.push(`Client Rights: Acknowledgement - ${rightsAcknowledgement1 ? 'Y' : 'N'}, Parent 1 Name - ${rightsGuardian1}${rightsGuardian2 ? `, Parent 2 Name - ${rightsGuardian2}` : ''}`);
  s.push(`Final Signatures: Acknowledge responsibility for meals and snacks ${finalAcknowledgement1 ? 'Y' : 'N'}, Initial - ${finalInitial1}, Acknowledge providing relevant nutritional and allergy information - ${finalAcknowledgement2 ? 'Y' : 'N'}, Initial - ${finalInitial2}, Acknowledge providing other health or medical concerns information - ${finalAcknowledgement3 ? 'Y' : 'N'}, Initial - ${finalInitial3}, Parent/Guardian - ${finalGuardian1}, Relationship to Client - ${finalRelationship1}`);

  pro.score.score = s.join(' | ');
  return pro;
};
