import { omit } from 'lodash';

import { RECEIVE_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions/notifications';
import { LOGOUT } from '../actions/user';

const initialState = {};

function user(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_NOTIFICATION:
      return { ...state, ...action.payload };
    case REMOVE_NOTIFICATION:
      return omit(state, action.payload);
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}

export default user;
