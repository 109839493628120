import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AppWrapper from '../wrappers/app-wrapper';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';
import { isPasswordValid } from '../lib/validators';

const styles = {
  formContainer: {
    margin: 'auto',
    padding: '35px 50px 50px',
    maxWidth: '500px',
  },
  btnLabel: {
    fontSize: '18px',
    lineHeight: '2.9',
  },
  errorText: {
    color: colors.errorRed,
    height: '18px',
    paddingTop: '18px',
    marginBottom: '30px',
  },
  successText: {
    color: colors.successGreen,
    height: '18px',
    paddingTop: '18px',
    marginBottom: '10px',
  },
  textField: {
    marginBottom: '20px',
  },
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  root: {
    marginBottom: '150px',
    padding: '25px',
    textAlign: 'left',
    width: '100%',
  },
};

const initialState = {
  response: '',
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ResetPassword = ({ router, classes, user }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState(initialState);
  const [resetSuccess, setResetSuccess] = useState(false);

  const handleUpdatePassword = () => {
    setErrors(initialState);
    setResetSuccess(false);
    const err = isPasswordValid(setErrors, oldPassword, newPassword, confirmPassword);
    if (Object.keys(err).length > 0) {
      return setErrors(err);
    }
    const requestOptions = {
      body: {
        old_password: oldPassword,
        new_password: newPassword,
        verify_password: confirmPassword,
      },
      method: 'POST',
    };
    apiFetch(`/users/${user.id}/reset`, requestOptions)
      .then(() => {
        setResetSuccess(true);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setErrors(initialState);
      })
      .catch((error) => {
        let msg = 'Error updating password';

        if (error.content && error.content.message) {
          const { content: { message } } = error;
          msg = message;
        } else if (error.message) {
          msg = error.message;
        }
        return setErrors({ ...errors, response: msg.replace('Error: ', '').replace('_', ' ') });
      });
  };

  return (
    <AppWrapper router={router}>
      <div className={classes.root}>
        <h1 className={classes.headerTitle}>Reset Password</h1>

        <div className={classes.formContainer}>
          <div className={classes.errorText} style={{ display: errors.response ? 'block' : 'none' }}>
            {errors.response}
          </div>
          <div className={classes.successText} style={{ display: resetSuccess ? 'block' : 'none' }}>
              Password successfully updated
          </div>
          <form noValidate autoComplete="off">
            <TextField
              fullWidth
              error={errors.oldPassword}
              helperText={errors.oldPassword}
              label="Old Password"
              type="password"
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
              className={classes.textField}
            />
            <TextField
              fullWidth
              error={errors.newPassword}
              helperText={errors.newPassword}
              label="New Password"
              type="password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              className={classes.textField}
            />
            <TextField
              fullWidth
              error={errors.confirmPassword}
              helperText={errors.confirmPassword}
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              className={classes.textField}
            />
            <Button
              onClick={handleUpdatePassword}
              type="button"
              variant="contained"
              color="primary"
            >
              <span className={classes.btnLabel}>Update Password</span>
            </Button>
          </form>
        </div>
      </div>
    </AppWrapper>
  );
};

function mapStateToProps(state) {
  const {
    clinic: { clinicId },
    user,
  } = state;

  return {
    clinicId,
    user,
  };
}
ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {})(withStyles(styles)(ResetPassword));
