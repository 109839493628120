import React from 'react';
import Card from '@material-ui/core/Card';
import CardTitle from '@material-ui/core/CardHeader';
import CardText from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles'

const styles = {
  cardText: {
    paddingTop: '0px',
  },
  cardWidth: {
    border: '1px solid #797979',
    boxShadow: '2px 2px 1px rgba(0, 0, 0, 0.349019607843137)',
    minWidth: '325px',
    width: '30%',
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: '18px',
    marginBottom: '0px',
    textAlign: 'center',
  },
};

const EmrCard = ({ cardTitle, classes, children }) => (
  <Card className={classes.cardWidth}>
    <CardTitle
      classes={{ title: classes.cardTitle}}
      title={cardTitle}
    />
    <CardText className={classes.cardText}>
      {children}
    </CardText>
  </Card>
);

export default withStyles(styles)(EmrCard);
