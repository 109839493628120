const lifeImpactGrades = {
  0: 'None',
  1: 'Moderate',
  2: 'Significant',
  3: 'Severe',
};

export default (pro) => {
  const { data } = pro.pro_data;

  const totalScore = data.slice(0, -1).reduce((acc, val) => acc + val, 0);
  let anxietyScore;

  if (totalScore <= 4) {
    anxietyScore = 'Minimal anxiety';
  } else if (totalScore <= 9) {
    anxietyScore = 'mild anxiety';
  } else if (totalScore <= 14) {
    anxietyScore = 'moderate anxiety';
  } else {
    anxietyScore = 'severe anxiety';
  }

  const lifeImpactScore = lifeImpactGrades[data[7]];

  pro.score.score = `Total Score: ${totalScore} | Anxiety Impact: ${anxietyScore} | Life Impact: ${lifeImpactScore}`;
  return pro;
};
