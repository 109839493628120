import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { forEach, reject, get, isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import ListItem from './emr-list-item';
import Checkbox from '../base/checkbox';
import { stateFormat, mergeFormat } from '../lib/merge-format-surgery';
import EmrComparisonGrid from './emr-comparison-grid';
import EmrMergeHeader from './emr-merge-header';
import EmrCard from './emr-card';
import { emrPageStyles } from '../lib/styles';

const styles = { ...emrPageStyles };

function buildList(combined, list, handleSelection, disableSelection, classes) {
  if (isEmpty(list)) {
    return 'None Reported';
  }
  const components = [];
  if (combined) {
    forEach(list, (item, index) => {
      components.push(<ListItem key={`${index}-combined`} primaryText={item.name} secondaryText={item.year} />);
    });
    return components;
  }
  forEach(list, (item, key) => {
    components.push(<ListItem
      leftCheckbox={<Checkbox
          checked={disableSelection || item.checked || false}
          classes={{ root: classes.checkbox }}
          disabled={disableSelection}
          onChange={(e, checked) => handleSelection(item, checked)}
        />}
      primaryText={item.name}
      secondaryText={item.year}
      key={key}
    /> );
  });
  return components;
}

class MergePatientSurgery extends Component {
  constructor(props) {
    super(props);

    this.handleSelection = this.handleSelection.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleNext = this.handleNext.bind(this);

    const emrSurg = get(this.props.mergeData.ehr, 'Procedures.Procedures');
    this.state = stateFormat(this.props.mergeData.informed.surgery, emrSurg);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleNext() {
    const data = mergeFormat(this.state.combined);
    this.setState({ nextLoading: true });
    this.props.editUser(this.props.emrPatient, data, this.props.mergeData.page, 'surgery_merge')
      .catch(() => this.setState({ nextLoading: false }));
  }
  handleSkip() {
    this.props.mergePatientData(false, this.props.emrPatient.id);
  }
  handleSelection(item, checked) {
    const { id } = item;
    if (!checked) {
      this.setState({
        combined: reject(this.state.combined, ['id', id]),
        emrSurg: {
          ...this.state.emrSurg,
          [id]: {
            ...item,
            checked: false,
          },
        },
      });
    } else {
      this.setState({
        combined: {
          ...this.state.combined,
          [id]: {
            ...item,
          },
        },
        emrSurg: {
          ...this.state.emrSurg,
          [id]: {
            ...item,
            checked: true,
          },
        },
      });
    }
  }
  render() {
    const combined = buildList(true, this.state.combined, this.handleSelection, false, this.props.classes);
    const mindset = buildList(false, this.state.mindset, this.handleSelection, true, this.props.classes);
    const emrSurg = buildList(false, this.state.emrSurg, this.handleSelection, false, this.props.classes);
    return (
      <div style={styles.container}>
        <EmrMergeHeader
          nextLoading={this.state.nextLoading}
          onNext={this.handleNext}
          onCancel={this.handleSkip}
          title="Update Surgery History"
        />
        <EmrComparisonGrid>
          <EmrCard cardTitle="Mindset Patient">
            {mindset}
          </EmrCard>
          <EmrCard cardTitle="Combined Patient">
            {combined}
          </EmrCard>
          <EmrCard cardTitle="EMR Patient">
            {emrSurg}
          </EmrCard>
        </EmrComparisonGrid>
      </div>
    );
  }
}

MergePatientSurgery.propTypes = {
  editUser: PropTypes.func.isRequired,
  emrPatient: PropTypes.object.isRequired,
  mergeData: PropTypes.object,
  handleSkip: PropTypes.func,
};

export default withStyles(styles)(MergePatientSurgery);
