import brand from './brand';

export const colors = brand.styles.colors;

export const fontSizes = brand.styles.fontSizes;

export const calendarPainColors = brand.styles.calendarPainColors;

export const checkbox = brand.styles.checkbox;

export const blueBtnStyle = brand.styles.blueBtnStyle;

export const custom = brand.styles.custom;

export const tableStyles = brand.styles.tableStyles;

export const backgroundImageStyle = brand.styles.backgroundImageStyle;

export const emrPageStyles = brand.styles.emrPageStyles;

export const btnLink = brand.styles.btnLink;
