import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const calendarHeaderColors = {
  text: '#2c3135',
};

const { text } = calendarHeaderColors;

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 0',
  },
  headerTitle: {
    alignItems: 'center',
    color: text,
    cursor: 'pointer',
    display: 'flex',
  },
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const CalendarHeader = (props) => {
  const {
    month,
    onClickBackButton,
    onClickForwardButton,
    onClickCurrentMonth,
    year,
  } = props;

  return (
    <div style={styles.header}>
      <IconButton
        onClick={onClickBackButton}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <div
        style={styles.headerTitle}
        onClick={() => onClickCurrentMonth(month, year)}
      >
        {monthNames[month]} {year}
      </div>
      <IconButton
        onClick={onClickForwardButton}
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};

CalendarHeader.propTypes = {
  month: PropTypes.number.isRequired,
  onClickBackButton: PropTypes.func.isRequired,
  onClickForwardButton: PropTypes.func.isRequired,
  onClickCurrentMonth: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
};

export default CalendarHeader;
