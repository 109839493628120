import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import PatientViewContentSection from './patient-view-content-section';
import PatientViewFooter from './patient-view-footer';
import ReleaseList from './release-list';

function ReleasesOverviewTab(props) {
  return (
    <Fragment>
      {props.children}
      <PatientViewContentSection>
        <section>
          <ReleaseList
            documentTemplates={props.documentTemplates}
            signatureRequests={props.signatureRequests}
            handleSendSignatureRequest={(templateId) => {
            props.handleSendSignatureRequest(templateId);
          }}
          />
          <PatientViewFooter router={props.router} />
        </section>
      </PatientViewContentSection>
    </Fragment>
  );
}

ReleasesOverviewTab.defaultProps = {
  appointment: {},
  documentTemplates: [],
  handleSendSignatureRequest: noop,
  painLevel: '',
  signatureRequests: [],
  updateAppointment: noop,
};

ReleasesOverviewTab.propTypes = {
  appointment: PropTypes.object,
  documentTemplates: PropTypes.array,
  handleSendSignatureRequest: PropTypes.func,
  painLevel: PropTypes.string,
  signatureRequests: PropTypes.array,
  updateAppointment: PropTypes.func,
  router: PropTypes.object,
};

export default ReleasesOverviewTab;
