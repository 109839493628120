import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  ExpansionPanel,
  ExpansionPanelSummary,
  Grid,
  LinearProgress,
  List,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import VideocamIcon from '@material-ui/icons/Videocam';
import { withStyles } from '@material-ui/core/styles';
import { PainReportBarChart } from 'common-ui';

import ProgressCircle from '../components/progress-circle';
import formatPro from '../lib/pro-formatter';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '25px',
    textAlign: 'left',
    width: '100%',
  },
  date: {
    textAlign: 'left',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    margin: 'auto',
  },
  accordion: {
    padding: '10px',
    textAlign: 'left',
    width: '100%',
    borderRadius: '5px',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  reportDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  progressCircles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  title: {
    fontWeight: 'bold',
    padding: '5px',
  },
  table: {
    margin: '20px',
    overflow: 'auto',
    maxHeight: 300,
  },
}));

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: 'none',
  },
}))(TableCell);

const StyledTableHead = withStyles(() => ({
  root: {
    borderBottom: '1px solid #e1e1e1',
  },
}))(TableHead);

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 15,
    borderRadius: 3,
    border: '1px solid #1f7bcc',
  },
  colorPrimary: {
    backgroundColor: '#fff',
  },
  bar: {
    borderRadius: 0,
    border: '2px solid #1f7bcc',
    height: '100%',
  },
}))(LinearProgress);

const StyledTypography = withStyles(() => ({
  root: {
    width: '100%',
    borderRadius: 5,
    padding: 5,
    margin: '0 5px',
    background: 'rgba(35, 123, 204, 0.3)',
  },
}))(Typography);

const BoldTypography = withStyles(() => ({
  root: {
    fontWeight: 'bold',
  },
}))(Typography);

const timeFormatter = (seconds) => {
  const formatSeconds = new Date(null);
  formatSeconds.setSeconds(seconds);
  let formatted = formatSeconds.toISOString();
  if (seconds <= 3599) {
    formatted = formatted.substr(14, 5);
  } else {
    formatted = formatted.substr(11, 8);
  }
  return formatted;
};

const handleDownloadProReport = (proSubmissions, startOfMonth) => {
  // eslint-disable-next-line max-len
  const header = 'User ID, PRO ID, First Name, Last Name, Phone, Email, PRO Type, Last Action, Results, Care Pathway, Care Pathway Status, Provider Email, Requested Date';
  const rows = [header];

  proSubmissions.forEach((proSubmission) => {
    formatPro(proSubmission, true);
    const {
      id,
      user_id,
      first_name,
      last_name,
      cell_phone,
      email,
      pro_type,
      lastAction,
      campaign_type,
      campaign_status,
      score: { score },
      provider_email,
      requested,
      scheduling_provider_email,
    } = proSubmission;

    const formattedScore = score.replace(/\n/g, '').replace(/,/g, ' --');
    // eslint-disable-next-line max-len
    rows.push(`${user_id},${id},${first_name},${last_name},${cell_phone},${email},${pro_type},${lastAction},${formattedScore},${campaign_type},${campaign_status},${provider_email || scheduling_provider_email},${requested}`);
  });

  const csvReport = rows.join('\n');
  const dateStr = startOfMonth.format('YYYY-MM');

  const downloadTag = document.createElement('a');
  downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvReport));
  downloadTag.setAttribute('download', `pro_submissions_${dateStr}.csv`);
  downloadTag.style.display = 'none';

  document.body.appendChild(downloadTag);

  downloadTag.click();

  document.body.removeChild(downloadTag);
};

const handleDownloadAppointmentReport = (appointments, startOfMonth) => {
  // eslint-disable-next-line max-len
  const header = 'First Name, Last Name, Phone, Email, Status, Scheduled Date, Start Time, End Time, Duration, Provider';
  const rows = [header];

  appointments.forEach((appointment) => {
    formatPro(appointment);
    const {
      first_name,
      last_name,
      cell_phone,
      email,
      status,
      start_date,
      start_time,
      end_time,
      duration,
      provider_email,
    } = appointment;
    // eslint-disable-next-line max-len
    rows.push(`${first_name},${last_name},${cell_phone},${email},${status},${start_date},${start_time},${end_time},${duration},${provider_email}`);
  });

  const csvReport = rows.join('\n');
  const dateStr = startOfMonth.format('YYYY-MM');

  const downloadTag = document.createElement('a');
  downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvReport));
  downloadTag.setAttribute('download', `appointments_${dateStr}.csv`);
  downloadTag.style.display = 'none';

  document.body.appendChild(downloadTag);

  downloadTag.click();

  document.body.removeChild(downloadTag);
};
const DashboardPatientSummary = (props) => {
  const {
    appointments,
    broadcast,
    completion_rate,
    loadingProSubmissions,
    loadingAppointments,
    number_of_patients,
    painReportData,
    patient_participation_rate,
    patients_responded_to_pros,
    patients_sent_pros,
    proSubmissions,
    proTableData,
    start,
    startOfMonth,
    stop,
    totalBodyPain,
    totalHeadache,
    total_returned,
    total_sent,
    undelivered,
    virtualVisits,
  } = props;
  const classes = useStyles();
  const [showVirtualVisitsTable, setShowVirtualVisitsTable] = useState(false);
  const [showProResponseTable, setShowProResponseTable] = useState(false);
  const [showVideoAudioTable, setShowVideoAudioTable] = useState(false);

  return (
    <Box display="flex">
      <Box >
        <Grid container spacing={1} >
          <Grid
            item
            xs={12}
            container
            style={{ width: '100%' }}
            spacing={1}
          >
            <Grid item xs>
              <Paper className={classes.paper}>
                <BoldTypography variant="body1" gutterBottom>
                Total Patients
                </BoldTypography>
                <BoldTypography variant="h6" gutterBottom>
                  {number_of_patients}
                </BoldTypography>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper}>
                <BoldTypography variant="body1" gutterBottom>
                Patients Sent PROs
                </BoldTypography>
                <BoldTypography variant="h6" gutterBottom>
                  {patients_sent_pros}
                </BoldTypography>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper}>
                <BoldTypography variant="body1" gutterBottom>
                Total Sent PROs
                </BoldTypography>
                <BoldTypography variant="h6" gutterBottom>
                  {total_sent}
                </BoldTypography>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={1}
            style={{
            width: '100%',
            padding: '10px',
            borderBottom: '1px solid #e1e1e1',
          }}
          >
            <Grid item xs={4}>
              <Typography
                variant="body1"
                style={{ textAlign: 'center' }}
                gutterBottom
              >
                <strong>Patient Participation Rate</strong>
              </Typography>
              <Box className={classes.progressCircles} style={{ marginTop: '20px' }}>
                {total_sent === 0 ? (
                  <BoldTypography variant="body1" style={{ marginTop: 60 }}>
                  No activity found
                  </BoldTypography>
                ) : (
                  <ProgressCircle
                    label={null}
                    complete={patient_participation_rate}
                    isloading={false}
                  />
                )}

              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs>
              <Table
                className={classes.table}
                aria-label="Participation Rate table"
              >
                <TableBody>
                  <TableRow>
                    <StyledTableCell
                      align="right"
                      component="th"
                      scope="row"
                    >
                      {patients_responded_to_pros}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                    Number of Patients Replied
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      align="right"
                      component="th"
                      scope="row"
                    >
                      {stop !== undefined ? (
                        stop
                      ) : (
                        <CircularProgress size={20} />
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                    Stopped Requests
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      align="right"
                      component="th"
                      scope="row"
                    >
                      {start !== undefined ? (
                        start
                      ) : (
                        <CircularProgress size={20} />
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                    Started Requests
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      align="right"
                      component="th"
                      scope="row"
                    >
                      {undelivered !== undefined ? (
                        undelivered
                      ) : (
                        <CircularProgress size={20} />
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                    Undeliverable
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
        <Divider variant="middle" flexItem />
        <Grid item xs={12} style={{ marginBottom: 25 }}>
          <Typography
            className={classes.heading}
            style={{ margin: 20 }}
            variant="h6"
            gutterBottom
          >
            Pain Reports
          </Typography>
          <PainReportBarChart
            data={painReportData}
            totalBodyPain={totalBodyPain}
            totalHeadache={totalHeadache}
          />
        </Grid>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box flexGrow={1} marginRight={3}>
        <ExpansionPanel
          className={classes.accordion}
          style={{ marginBottom: 5, marginLeft: '8px' }}
          onChange={() => setShowProResponseTable(!showProResponseTable)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{
                    root: classes.expansionPanel,
                    expandIcon: classes.expandIcon,
                  }}
            aria-controls="proReports-content"
            id="proReports-header"
          >
            <Box display="flex" flexDirection="column" width="100%">
              <Typography
                className={classes.heading}
                variant="h6"
                gutterBottom
              >
                <AssignmentIcon style={{ fontSize: 40 }} /> PRO Reports
              </Typography>
              <Button
                onClick={() => handleDownloadProReport(proSubmissions, startOfMonth)}
                variant="contained"
                startIcon={loadingProSubmissions ? <CircularProgress color="darkgrey" size={20} /> : undefined}
                disabled={loadingProSubmissions}
              >
                {!loadingProSubmissions ? 'Export' : ''}
              </Button>
              {!total_sent ? (
                <Typography>
                  <strong>No Activity Found</strong>
                </Typography>
                ) : (
                  <>
                    <div className={classes.reportDetails}>
                      <Typography>
                        <strong>PRO Completion Rate | {completion_rate}%</strong>
                      </Typography>
                      <Typography>
                        {total_returned} of {total_sent}
                      </Typography>
                    </div>
                    <BorderLinearProgress
                      variant="determinate"
                      value={completion_rate}
                    />
                  </>
                )}
            </Box>
          </ExpansionPanelSummary>
        </ExpansionPanel>
        {showProResponseTable ? (
          <List className={classes.table} aria-label="Response Rate table">
            <StyledTableHead>
              <TableRow>
                <StyledTableCell className={classes.title} >PRO Name</StyledTableCell>
                <StyledTableCell className={classes.title} align="left">
                    Response Rate
                </StyledTableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {proTableData.length > 0 ? (
                  proTableData.map(row => (
                    <TableRow key={row.name}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className={classes.reportDetails}
                        style={{ borderRight: '1px solid #e1e1e1' }}
                      >
                        <Box width={`${(row.returned / row.sent) * 100}%`} display="flex">
                          <StyledTypography >
                            {row.name}
                          </StyledTypography>
                        </Box>

                        <Typography>
                          {Math.round((row.returned / row.sent) * 100)}%
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ verticalAlign: 'top' }}
                        align="left"
                      >
                        {row.returned} of {row.sent}
                      </StyledTableCell>
                    </TableRow>
                  ))
                ) : (
                  <Box
                    style={{
                      textAlign: 'center',
                      margin: 'auto',
                      paddingTop: '5px',
                    }}
                  >
                    No data to report
                  </Box>
                )}
            </TableBody>
          </List>
              ) : null}
        <ExpansionPanel
          className={classes.accordion}
          style={{ marginBottom: 5, marginLeft: '8px' }}
          onChange={() => setShowVirtualVisitsTable(!showVirtualVisitsTable)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{
              root: classes.expansionPanel,
              expandIcon: classes.expandIcon,
            }}
          >
            <Box display="flex" flexDirection="column" width="100%">
              <Typography
                className={classes.heading}
                variant="h6"
                gutterBottom
              >
                <HeadsetMicIcon style={{ fontSize: 40 }} /> Virtual Visits
              </Typography>
              <Button
                onClick={() => handleDownloadAppointmentReport(appointments, startOfMonth)}
                variant="contained"
                startIcon={loadingAppointments ? <CircularProgress color="darkgrey" size={20} /> : undefined}
                disabled={loadingAppointments}
              >
                {!loadingProSubmissions ? 'Export' : ''}
              </Button>
              {!virtualVisits.total ? (
                <Typography>
                  <strong>No Activity Found</strong>
                </Typography>
                ) : (
                  <>
                    <div className={classes.reportDetails}>
                      <Typography>
                        <strong>Appointment Completion Rate | {virtualVisits.completion_rate}%</strong>
                      </Typography>
                      <Typography>
                        {virtualVisits.completed} of {virtualVisits.total}
                      </Typography>
                    </div>
                    <BorderLinearProgress
                      variant="determinate"
                      value={virtualVisits.completion_rate}
                    />
                  </>
                )}
            </Box>
          </ExpansionPanelSummary>
        </ExpansionPanel>
        {showVirtualVisitsTable ? (
          <TableBody>
            {virtualVisits.total ? (
              <>
                <Table>
                  <StyledTableHead>
                    <StyledTableCell className={classes.bold} component="th">
                  Appointments Scheduled
                    </StyledTableCell>
                    <StyledTableCell className={classes.bold} component="th">
                      {virtualVisits.total}
                    </StyledTableCell>
                    <StyledTableCell className={classes.bold} component="th">
                  Rate
                    </StyledTableCell>
                  </StyledTableHead>
                  <TableRow>
                    <StyledTableCell>Completed</StyledTableCell>
                    <StyledTableCell>{`${virtualVisits.completed} of ${virtualVisits.total}`}</StyledTableCell>
                    <StyledTableCell>{`${virtualVisits.completion_rate}%`}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>Cancelled</StyledTableCell>
                    <StyledTableCell>{`${virtualVisits.cancelled} of ${virtualVisits.total}`}</StyledTableCell>
                    <StyledTableCell>{`${virtualVisits.cancellation_rate}%`}</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>Missed</StyledTableCell>
                    <StyledTableCell>{`${virtualVisits.missed} of ${virtualVisits.total}`}</StyledTableCell>
                    <StyledTableCell>{`${virtualVisits.missed_rate}%`}</StyledTableCell>
                  </TableRow>
                </Table>
              </>
            ) : (
              <Table>
                <TableRow>
                  <StyledTableCell><strong>No data to report</strong></StyledTableCell>
                </TableRow>
              </Table>
                )
              }
          </TableBody>
              ) : null}
        {total_sent ? (
          <ExpansionPanel
            className={classes.accordion}
            style={{ marginLeft: '8px' }}
            onChange={() => setShowVideoAudioTable(!showVideoAudioTable)}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              classes={{
                root: classes.expansionPanel,
                expandIcon: classes.expandIcon,
              }}
            >
              <Typography
                className={classes.heading}
                style={{ margin: 20 }}
                variant="h6"
                gutterBottom
              >
                <VideocamIcon style={{ fontSize: 40 }} /> Videos | Audio
              </Typography>
            </ExpansionPanelSummary>
          </ExpansionPanel>
        ) : null}

        {showVideoAudioTable ? (
          <List
            className={classes.table}
            aria-label="Videos &amp; Audio table"
          >
            <StyledTableHead>
              <TableRow>
                <StyledTableCell className={classes.title}>
                  Video Name
                </StyledTableCell>
                <StyledTableCell className={classes.title} align="right">
                    Average Played Length
                </StyledTableCell>
                <StyledTableCell className={classes.title} align="left">
                  View Rate
                </StyledTableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {broadcast &&
                  Object.entries(broadcast).map(title => (
                    <TableRow key={title[0]}>
                      <StyledTableCell component="th" scope="row">
                        {title[0]}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        style={{ borderRight: '1px solid #e1e1e1' }}
                      >
                        {timeFormatter(title[1].average_view_time)} of{' '}
                        {timeFormatter(title[1].duration)}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        className={classes.reportDetails}
                      >
                        <Typography>
                          {title[1].view_rate}% {title[1].num_of_views} of {title[1].num_of_notifications}
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </List>) : null}
      </Box>
    </Box>
  );
};

DashboardPatientSummary.defaultProps = {
  completion_rate: null,
  number_of_patients: null,
  painReportData: null,
  patient_participation_rate: null,
  patients_responded_to_pros: null,
  patients_sent_pros: null,
  proTableData: null,
  start: undefined,
  stop: undefined,
  totalBodyPain: null,
  totalHeadache: null,
  total_returned: null,
  total_sent: null,
  undelivered: undefined,
  virtualVisits: null,
};

DashboardPatientSummary.propTypes = {
  appointments: PropTypes.array.isRequired,
  completion_rate: PropTypes.string,
  loadingAppointments: PropTypes.bool.isRequired,
  loadingProSubmissions: PropTypes.bool.isRequired,
  number_of_patients: PropTypes.number,
  painReportData: PropTypes.array,
  patient_participation_rate: PropTypes.string,
  patients_responded_to_pros: PropTypes.string,
  patients_sent_pros: PropTypes.number,
  proTableData: PropTypes.array,
  start: PropTypes.number,
  stop: PropTypes.number,
  totalBodyPain: PropTypes.number,
  totalHeadache: PropTypes.number,
  total_returned: PropTypes.number,
  total_sent: PropTypes.number,
  undelivered: PropTypes.number,
  virtualVisits: PropTypes.object,
  proSubmissions: PropTypes.array.isRequired,
  startOfMonth: PropTypes.object.isRequired,
};

export default DashboardPatientSummary;
