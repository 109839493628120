import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  circularProgress: {
    position: 'absolute',
  },
  root: {
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    position: 'relative',
  },
});

const ButtonWithProgress = (props) => {
  const { children, inProgress, ...buttonProps } = props;
  const classes = useStyles();

  return (
    <span className={classes.root}>
      <Button {...buttonProps} disabled={inProgress}>{children}</Button>
      {inProgress ? (
        <CircularProgress className={classes.circularProgress} size={24} />
      ) : null}
    </span>
  );
};

ButtonWithProgress.propTypes = {
  children: PropTypes.string.isRequired,
  inProgress: PropTypes.bool.isRequired,
};

export default ButtonWithProgress;
