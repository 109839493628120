import TablePagination from '@material-ui/core/TablePagination';

import { withStyles } from '@material-ui/core/styles';

export default withStyles({
  toolbar: {
    paddingLeft: 5,
  },
  caption: {
    fontSize: '1rem',
  },
  select: {
    marginRight: '4px',
  },
})(TablePagination);
