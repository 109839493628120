import { createTheme } from '@material-ui/core/styles';
import { colors } from './styles';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primaryColor,
    },
    secondary: {
      main: colors.secondaryColor,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        margin: '10px',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Raleway',
      'sans-serif',
    ].join(','),
  },
});

export default theme;
