import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Snackbar from '@material-ui/core/Snackbar'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import AutoComplete from './autocomplete';
import HiddenContent from './hidden-content';
import InviteDialog from './invite-dialog';
import config from '../config';
import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';

const baseStyles = {
  btn: {
    width: '175px',
  },
  inviteButton: {
    backgroundColor: 'rgba(32, 123, 204)',
    border: `1px solid ${colors.primaryColor}`,
    color: colors.white,
    width: '175px',
  },
  logoutButton: {
    backgroundColor: 'rgba(32, 123, 204, 0.2)',
    border: `1px solid ${colors.primaryColor}`,
    color: colors.white,
    width: '175px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.darkAccent,
    maxHeight: '56px',
    minHeight: '56px',
    padding: 0,
    
  },
  headerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerText: {
    color: colors.highlightBright,
    fontWeight: 100,
    paddingLeft: '10px',
  },
  icon: {
    color: colors.white,
    fontColor: colors.white,
  },
  logo: {
    cursor: 'pointer',
    height: '40px',
    padding: '5px 5px 5px 10px',
    marginRight: '50px',
  },
  unread: {
    height: '8px',
    backgroundColor: 'red',
    borderRadius: '50%',
    bottom: '10px',
    position: 'absolute',
    right: '9px',
    width: '8px',
  },
  search: {
    color: colors.highlightBright,
    fontWeight: 100,
  },
  searchIcon: {
    color: colors.highlightBright,
    paddingRight: '20px',
  },
  patientDropdown: {
   width: '250px',
  },
};

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      settingsAnchorEl: null,
      apiSearchResults: [],
      inviteSent: false,
      userInviteDialog: false,
    };
  }
  onSearchSelect = (user, index) => {
    if (index !== -1) {
      this.props.addRecent(user);
      this.props.router.push({ pathname: '/app/patient', state: { userId: user.user_id }});
    }
  }
  handleClose = () => {
    this.setState({ settingsAnchorEl: null });
  }
  handleSettingsMenuClick = (evt) => {
    this.setState({ settingsAnchorEl: evt.currentTarget })
  }
  toggleUserInviteDialog = () => {
    this.setState({ userInviteDialog: !this.state.userInviteDialog });
  }
  handleSendInvite = (email) => {
    const options = {
      method: 'POST',
      body: {
        user_email: email,
      },
    };
    apiFetch(`/clinics/${this.props.clinicId}/clinic_access_request`, options)
      .then(() => {
        this.setState({
          inviteSent: true,
          userInviteDialog: false,
        });
      })
      .catch(() => {
        this.setState({
          inviteSent: true,
          inviteError: 'Error Sending Invite',
        });
      });
  }
  queryApiPatients = (searchText) => {
    if(!searchText) {
      return;
    }
    const fuzzy_field_primary = searchText.split(' ')[0] || '';
    const fuzzy_field_secondary = searchText.split(' ')[1] || '';
    const fuzzy_field_tertiary = searchText.split(' ')[2] || '';
    const fuzzy_field_quaternary = searchText.split(' ')[3] || '';
    return apiFetch(`/clinics/${this.props.clinicId}/patientSearch`, { query: { fuzzy_field_primary, fuzzy_field_secondary, fuzzy_field_tertiary, fuzzy_field_quaternary } })
      .then(async (results) => {
        const apiSearchResults = await results.data.map((i) => {
          i.name = `${i.first_name} ${i.last_name}`;
          i.name_email = `${i.name}(${i.email})`;
          return i;
        });
        return apiSearchResults;
        // this.setState({ apiSearchResults });
      });
  }
  render() {
    const { appFeatures, classes } = this.props;
    const displayCampaigns = appFeatures.includes('CUSTOM_CAMPAIGNS');
    const displayAppointmentBundles = appFeatures.includes('VIDEO_CALLS');
    const displayDeveloperOptions = appFeatures.includes('API_KEYS');
    return (
      <Toolbar className={classes.header}>
        <div className={classes.headerSection}>
          <Snackbar
            open={this.state.inviteSent}
            message={this.state.inviteError || 'Invite Successfully Sent'}
            autoHideDuration={4000}
            onRequestClose={this.handleCloseNotification}
          />
          <Link to="/app/patientlisting">
            <img
              alt="Clinic Logo"
              className={classes.logo}
              src={`${config.API_URL}/clinics/${this.props.clinicId}/logo/100`}
              onError={(e) => { e.target.onerror = null; e.target.src = '/img/logo.svg'; }}
            />
          </Link>
          <SearchIcon className={classes.searchIcon} />
          <div style={{ width: '500px' }}>
            <AutoComplete
              onInputChange={this.queryApiPatients}
              onSelectItem={this.onSearchSelect}
            />
          </div>
        </div>
        <div className={classes.headerSection}>
          <div style={{ width: '200px' }}>
            <Button
              className={classes.inviteButton}
              onClick={this.toggleUserInviteDialog}
            >Invite Patient</Button>
          </div>
          <InviteDialog
            open={this.state.userInviteDialog}
            handleSendInvite={this.handleSendInvite}
            toggleUserInviteDialog={this.toggleUserInviteDialog}
            inviteSent={this.state.inviteSent}
            proTypes={this.props.proTypes}
            createProSubmission={this.props.createProSubmission}
            createProCampaign={this.props.createProCampaign}
            sortedCampaignTypes={this.props.sortedCampaignTypes}
          />
          <IconButton
            onClick={this.handleSettingsMenuClick}
          >
            <SettingsIcon style={baseStyles.icon} />
          </IconButton>
          <Menu
            anchorEl={this.state.settingsAnchorEl}
            onClose={this.handleClose}
            open={Boolean(this.state.settingsAnchorEl)}
          >
            <MenuItem component={Link} to="/app/devicelisting">Devices</MenuItem>
            <HiddenContent hidden={!displayDeveloperOptions}>
              <MenuItem component={Link} to="/app/developer">Developer</MenuItem>
            </HiddenContent>
            <HiddenContent hidden={!displayCampaigns}>
              <MenuItem component={Link} to="/app/care-pathways">Care Pathway</MenuItem>
              <MenuItem component={Link} to="/app/care-pathway-bundled-events">Care Pathway Bundles</MenuItem>
              <HiddenContent hidden={!displayAppointmentBundles}>
                <MenuItem component={Link} to="/app/appointment-bundled-events">Appointment Bundles</MenuItem>
              </HiddenContent>
              <MenuItem component={Link} to="/app/video-broadcasts">Video Broadcasts</MenuItem>
              <MenuItem component={Link} to="/app/voice-broadcasts">Voice Broadcasts</MenuItem>
            </HiddenContent>
            <MenuItem component={Link} to="/app/reset">Reset Password</MenuItem>
            <MenuItem onClick={() => this.props.logout()}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    );
  }
}

Header.defaultProps = {
  clinicId: null,
  logout: noop,
  openModal: noop,
  router: {},
};

Header.propTypes = {
  addRecent: PropTypes.func,
  clinicId: PropTypes.number,
  logout: PropTypes.func,
  openModal: PropTypes.func,
  router: PropTypes.object.isRequired,
};

export default withStyles(baseStyles)(Header);
