import React, { Component } from 'react';

import AppWrapper from '../wrappers/app-wrapper';
import ProSquare from '../components/pro-square';

import { colors } from '../lib/styles';

const baseStyles = {
  bigHeaderText1: {
    fontSize: '2rem',
    fontWeight: 700,
    color: colors.charcoal,
    margin: 0,
  },
  bigHeaderText2: {
    fontSize: '2rem',
    fontWeight: 700,
    color: colors.charcoal,
    marginLeft: '25%',
  },
  bigText: {
    color: colors.charcoal,
  },
  body: {
    marginTop: '5px',
  },
  bottomBtnContainer: {
    padding: '3px 10px 10px',
  },
  bottomBtnFirstRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  complete: {
    color: colors.calendarGreen,
  },
  contentStyle: {
    backgroundColor: colors.white,
    margin: '15px',
    width: '100%',
    minWidth: '768px',
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
  },
  due: {
    color: colors.calendarYellow,
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  h3: {
    textAlign: 'left',
    margin: '1rem'
  },
  patientNumberHeader: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '2rem',
  },
  proSquareAlignment: {
    display: 'flex',
    flexDirection: 'column',
  },
  pastDue: {
    color: colors.calendarRed,
  },
  proHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  reviewAllBtn: {
    boxShadow: 'none',
    margin: '5px',
    width: '200px',
  },
  reviewAllLabel: {
    padding: '10px',
    width: '200px',
  },
  row: {
    backgroundColor: '#fff',
  },
  smallHeaderText: {
    fontSize: '1rem',
    color: colors.charcoal,
  },
  smallText: {
    color: colors.charcoal,
  },
  squareBlank: {
    backgroundColor: colors.white,
    border: '1px solid #711471',
  },
  squareGreen: {
    backgroundColor: colors.calendarGreen,
  },
  squareRed: {
    backgroundColor: colors.calendarRed,
  },
  squareYellow: {
    backgroundColor: colors.calendarYellow,
  },
  symbol: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '1rem',
    padding: '2rem',
  },
  symbolColors: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '7rem',
  },
  tableAlign: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableTitle: {
    fontSize: '1rem',
  },
  top: {
    marginRight: '2rem',
    marginLeft: '1.5rem',
  },
};

class ProCompliance extends Component {
  state = {
    pagination: '',
    open: false,
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {

    return (
      <AppWrapper router={this.props.router}>
        <div style={baseStyles.contentStyle}>
          <h3 style={baseStyles.h3}>PRO Compliance</h3>
          <section style={baseStyles.proHeader}>
            <img src="/img/patient_listing_icon.svg" alt="people" height="80px" width="80px" style={{ marginLeft: '2rem' }} />
            <div style={baseStyles.patientNumberHeader}>
              <p style={baseStyles.bigHeaderText1}>102</p>
              <p style={baseStyles.smallHeaderText}>Patients</p>
            </div>
            <p style={baseStyles.bigHeaderText2}>Overall Compliance: <span style={{ color: colors.calendarGreen }}>80%</span></p>
          </section>
          <section style={baseStyles.proSquareAlignment}>
            <div className="compliance-grid" style={baseStyles.grid}>
              <ProSquare
                text="Post-Op"
                squareStyle={baseStyles.squareGreen}
                percentComplete={92}
                totalPatients={94}
              />
              <ProSquare
                text="Month 3"
                squareStyle={baseStyles.squareGreen}
                percentComplete={86}
                totalPatients={88}
              />
              <ProSquare
                text="Month 6"
                squareStyle={baseStyles.squareYellow}
                percentComplete={80}
                totalPatients={82}
              />
              <ProSquare
                text="Month 12"
                squareStyle={baseStyles.squareYellow}
                percentComplete={79}
                totalPatients={81}
              />
            </div>
            <div className="compliance-grid" style={baseStyles.grid}>
              <ProSquare
                text="Year 2"
                squareStyle={baseStyles.squareRed}
                percentComplete={64}
                totalPatients={65}
              />
              <ProSquare
                text="Year 3"
                squareStyle={baseStyles.squareBlank}
                bigTextStyle={baseStyles.bigText}
                smallTextStyle={baseStyles.smallText}
              />
              <ProSquare
                text="Year 4"
                squareStyle={baseStyles.squareBlank}
                bigTextStyle={baseStyles.bigText}
                smallTextStyle={baseStyles.smallText}
              />
              <ProSquare
                text="Year 5"
                squareStyle={baseStyles.squareBlank}
                bigTextStyle={baseStyles.bigText}
                smallTextStyle={baseStyles.smallText}
              />
            </div>
          </section>
        </div>
      </AppWrapper>
    )
  }
}

export default ProCompliance;
