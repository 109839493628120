import React, { useEffect } from 'react';
import AppWrapper from '../wrappers/app-wrapper';

import { addRecent } from '../state/recent';
import { updateTableSetting } from '../state/tables';
import { createAppointment, fetchAppointments, updateAppointment, updatePatient } from '../state/appointments';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PatientList from '../components/patient-list';
import setClinicFavicon from '../lib/set-clinic-favicon';

const baseStyles = {
  patientLists: {
    padding: '25px',
    width: '100%',
    marginBottom: '150px',
  },
};

const PatientListPage = (props) => {
  const {
    addRecent,
    appointments,
    providers,
    updateAppointment,
    showAppointmentButtons,
    tableSettings,
    updateTableSetting,
    user,
  } = props;

  useEffect(() => {
    props.fetchAppointments();
    setClinicFavicon(props.clinicId);
  }, []);

  return (
    <AppWrapper router={props.router}>
      <div style={baseStyles.patientLists}>
        <PatientList
          addRecent={addRecent}
          appointments={appointments.data}
          clinicId={props.clinicId}
          createAppointment={props.createAppointment}
          id="PatientList"
          title="Patients"
          isFetching={appointments.isFetching}
          providers={providers}
          onUpdateAppointment={updateAppointment}
          onUpdatePatient={props.updatePatient}
          showAppointmentButtons={showAppointmentButtons}
          router={props.router}
          tableSettings={tableSettings}
          updateTableSetting={updateTableSetting}
          user={user}
        />
      </div>
    </AppWrapper>
  );
};

function mapStateToProps(state) {
  const {
    appointments,
    clinic: {
      clinicId,
      data: {
        app_features,
      },
      providers,
    },
    tableSettings: { patients },
    user,
  } = state;


  return {
    clinicId,
    providers,
    appointments,
    tableSettings: patients,
    showAppointmentButtons: app_features.includes('APPOINTMENTS'),
    user,
  };
}

const actionCreators = {
  addRecent,
  createAppointment,
  fetchAppointments,
  updateAppointment,
  updatePatient,
  updateTableSetting,
};

PatientListPage.defaultProps = {
  appointments: {
    data: [],
    isFetching: false,
  },
  providers: [],
};

PatientListPage.propTypes = {
  addRecent: PropTypes.func.isRequired,
  appointments: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
    cobb: PropTypes.array,
  }),
  fetchAppointments: PropTypes.func.isRequired,
  providers: PropTypes.array,
  updateAppointment: PropTypes.func.isRequired,
  updateTableSetting: PropTypes.func.isRequired,
  showAppointmentButtons: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(PatientListPage);
