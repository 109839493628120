import React from 'react';
import useSWR, { trigger } from 'swr';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { SortableTable } from 'common-ui';
import { withStyles } from '@material-ui/core/styles';

import LinearDeterminate from '@material-ui/core/LinearProgress';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { colors } from '../lib/styles';
import { updateTableSetting } from '../state/tables';
import { apiFetch } from '../lib/fetch';
import { xhr } from '../lib/xhr'
import AppWrapper from '../wrappers/app-wrapper';

const columnData = [
  {
    id: 'name', align: false, disablePadding: false, label: 'Name', small: false,
  },
];

const styles = {
  actionCell: {
    padding: '4px 10px 4px 10px',
    width: '100px',
  },
  headerTitle: {
    fontWeight: 100,
    fontSize: '1.4rem',
  },
  inputField: {
    margin: '10px 10px 0px',
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    marginBottom: '150px',
    padding: '25px',
    textAlign: 'left',
    width: '100%',
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: colors.highlightGray,
    },
    cursor: 'pointer',
  },
  progressbarContainer: {
    width: '100%',
  },
  progressbar: {
    margin: '0 15px auto'
  }
}

export const VideoBroadcasts = (props) => {

  const { clinicId, classes, tableSettings, updateTableSetting } = props;
  const [values, setValues] = React.useState({
    name: '',
    file: null,
    uploading: false,
    error: null,
    submitProgress: 0,
    progressBar: false,
  });

  const fileName = get(values, 'file.name');
  const { data } = useSWR(`/clinics/${clinicId}/video_broadcasts`, apiFetch);

  const handleChange = field => (e) => {
    setValues({ ...values, [field]: e.target.value });
  }

  const handleChangeFile = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      setValues({ ...values, file: files[0] });
    }
  };

  const handleXhr = () => {
    const formData = new FormData();
    formData.append('video', values.file);
    formData.append('name', values.name);
    setValues({ ...values, error: null, uploading: true });
    try {
      xhr.xhrData(formData, `clinics/${clinicId}/video_broadcasts`, (event) => {
        const progress = event.loaded / event.total * 100;
        setValues(values => ({ ...values, submitProgress: progress, progressBar: true }))
      }, (res) => {
        if (res === 200) {
          setValues({
            ...values,
            name: '',
            file: null,
            error: null,
            uploading: false,
            submitProgress: 0,
            msg: 'Video Broadcast Successfully Updated',
          });
          trigger(`/clinics/${clinicId}/video_broadcasts`);
        } else {
          setValues({ ...values, uploading: false, error: 'Error Uploading Video Broadcast' });
        }
      });
    } catch (e) {
      setValues({ ...values, uploading: false, error: 'Error Uploading Video Broadcast' });
    }
  }
  const rowNumber = window.localStorage.getItem('video_broadcasts_rows');

  const uploadBtnText = fileName ? `${fileName} selected` : 'Select a .mp4 File';
  return (
    <AppWrapper router={props.router}>
      <div className={classes.root}>
        <h1 className={classes.headerTitle}>Video Broadcasts</h1>
        <div style={{ color: colors.errorRed, textAlign: 'center' }}>{values.error}</div>
        <div className={classes.inputRow}>
          <TextField
            placeholder="Broadcast Name"
            label="Broadcast Name"
            value={values.name}
            onChange={handleChange('name')}
          />
          <Button
            variant="contained"
            component="label"
          >{uploadBtnText}
            <Input
              inputProps={{ accept: '.mp4' }}
              onChange={handleChangeFile}
              style={{ display: 'none' }}
              type="file"
            />
          </Button>
          <Button
            color="primary"
            disabled={!values.file || !values.name || values.uploading}
            onClick={handleXhr}
            variant="contained"
          >Upload Video Broadcast
          </Button>
        </div>
        <div style={styles.progressbarContainer}>
          {values.progressBar && <LinearDeterminate
            variant="determinate"
            value={values.submitProgress}
            style={styles.progressbar}
          />}
        </div>
        <SortableTable
          columnData={columnData}
          emptyMessage="No video broadcasts exist at this time."
          rows={data}
          handleClick={(row) => props.router.push(`/app/video-broadcasts/${row.id}`)}
          hover={true}
          rowClasses={{ row: classes.tableRow }}
          rowNumber={rowNumber && Number(rowNumber)}
          page={tableSettings.page && Number(tableSettings.page)}
          updateTableSetting={page => updateTableSetting({ type: 'video_broadcasts', value: { page } })}
          saveSetting={rows => window.localStorage.setItem('video_broadcasts_rows', rows)}
        />
      </div>
    </AppWrapper>
  );
};

function mapStateToProps(state) {
  const {
    clinic: { clinicId },
    tableSettings: { video_broadcasts },
  } = state;

  return {
    clinicId,
    tableSettings: video_broadcasts,
  };
}
VideoBroadcasts.propTypes = {
  classes: PropTypes.object.isRequired,
  clinicId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
};


export default connect(mapStateToProps, { updateTableSetting })(withStyles(styles)(VideoBroadcasts));