import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../lib/styles';

const styles = {
  completeHeader: {
    color: colors.primaryColor,
    textAlign: 'center',
  },
};

function CircularProgressWithLabel(props) {
  const { value, label } = props;
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="grid"
        alignItems="center"
        justifyContent="center"
        style={styles.completeHeader}
      >
        <Typography variant="h4" component="div" display="block" >
          {`${Math.round(value)}%`}
        </Typography>
        {label && (
        <Typography variant="subtitle1" gutterBottom component="div" >
          <div>{label}</div>
          <div>Completed</div>
        </Typography>
        )}
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.defaultProps = {
  value: 0,
  label: 'Forms',
};

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
};

function ProgressCircle({ complete, label, isloading }) {
  return isloading
    ? (
      <CircularProgress
        size={145}
        thickness={1}
      />
    )
    : (
      <CircularProgressWithLabel
        value={complete}
        size={145}
        thickness={1}
        label={label}
      />
    );
}

ProgressCircle.defaultProps = {
  complete: 0,
  label: 'Forms',
  isloading: true,
};

ProgressCircle.propTypes = {
  complete: PropTypes.number,
  isloading: PropTypes.bool,
  label: PropTypes.string,
};

export default withStyles(styles)(ProgressCircle);
