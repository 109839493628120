import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { orderBy } from 'lodash';
import Zoom from 'react-responsive-pinch-zoom-pan';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { ArrowLeft, PlusSign } from '../lib/icons';

const baseStyles = {
  addNotesBtn: {
    marginLeft: 'auto',
    marginTop: 0,
  },
  backBtn: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogContent: {
    height: '800px',
  },
  header: {
    backgroundColor: 'black',
    width: '99%',
    color: 'white',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  hidden: {
    display: 'none',
  },
  iconWrapper: {
    width: 16,
    height: 18,
    marginRight: '5px',
  },
  imageDetails: {
    fontStyle: 'italic',
    marginRight: '5px',
  },
  notesHist: {
    textAlign: 'start',
  },
  viewImageNotes: {
    display: 'block',
    padding: 15,
  },
  viewImage: {
    height: '400px',
    padding: 0,
    margin: 0,
  },
  viewImageDialog: {
    height: '100%',
    maxHeight: '800px',
    padding: 0,
    margin: 0,
  },
};

const SingleWoundView = (props) => {
  const {
    image,
    note,
    notebook,
    handleTextChange,
    handleAddNote,
    handleWoundsHome,
  } = props;

  const [newNote, setNewNote] = useState(false);
  const [open, setOpen] = useState(false);

  const imageRef = useRef();
  const orderedNotebook = orderBy(notebook, ['created_at'], ['desc']);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleWoundsHome}
        style={baseStyles.backBtn}
      >
        <div style={baseStyles.iconWrapper}>
          <ArrowLeft />
        </div>
        Return to Home Page
      </Button>
      <div style={baseStyles.header}>
        <span style={{ fontWeight: 'bold', marginRight: 'auto' }}>{image.name}</span>
        <span style={baseStyles.imageDetails}>{image.image_details.primary_location},</span>
        <span style={baseStyles.imageDetails}>{image.image_details.secondary_location},</span>
        <span style={baseStyles.imageDetails}>{image.image_details.tertiary_location}</span>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <img
            ref={imageRef}
            value={image.image_id}
            src={image.url}
            alt={`thumbnail of ${image.body_location} taken by patient`}
            style={baseStyles.viewImage}
            onClick={handleClickOpen}
          />
          <Dialog
            maxWidth="lg"
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
          >
            <DialogContent style={baseStyles.dialogContent}>
              <Zoom position="center">
                <img
                  value={image.image_id}
                  src={image.url}
                  alt={`modal of ${image.body_location} taken by patient`}
                  style={baseStyles.viewImageDialog}
                />
              </Zoom>
            </DialogContent>
          </Dialog>
        </Grid>
        <Grid item xs={6} style={baseStyles.gridOverlay}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setNewNote(true)}
              style={newNote ? baseStyles.hidden : baseStyles.addNotesBtn}
            >
              <div style={baseStyles.iconWrapper}>
                <PlusSign />
              </div>
                Add Note
            </Button>
            <div style={newNote ? baseStyles.viewImageNotes : baseStyles.hidden}>
              <TextField
                variant="outlined"
                minRows={8}
                maxRows={8}
                value={note}
                fullWidth
                multiline
                onChange={handleTextChange}
                placeholder="Write notes here..."
              />
              <Button
                variant="contained"
                color="primary"
                style={{ float: 'right' }}
                onClick={() => handleAddNote(image)}
              >Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                style={{ float: 'right' }}
                onClick={() => setNewNote(false)}
              >Cancel
              </Button>
            </div>
          </div>
          <div style={baseStyles.notesHist}>
            <h2>Notes History:</h2>
            {!notebook.length ? (
              <h4>There are currently no notes to display</h4>
            ) : (
              orderedNotebook.map(page => (
                <div key={`${page.image_id}-${page.created_at}`}>
                  <h4>{moment(page.created_at).format('LLL')}</h4>
                  <p>{page.note}</p>
                </div>
              ))
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

SingleWoundView.propTypes = {
  image: PropTypes.object.isRequired,
  note: PropTypes.string.isRequired,
  notebook: PropTypes.array.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleAddNote: PropTypes.func.isRequired,
  handleWoundsHome: PropTypes.func.isRequired,
};

export default SingleWoundView;
